import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAppDispatch } from 'state/helpers/useApp'
import { useAppSelector } from 'state/helpers/useApp'

const OnlineStatusContext = createContext(true)

export const OnlineStatusProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true)
  const isOnline = useAppSelector((state) => state.offline.online)
  const dispatch = useAppDispatch()

  console.log({ isOnline })

  useEffect(() => {
    setOnlineStatus(isOnline)
    if (!isOnline) {
      dispatch({ type: 'PAUSE' })
    } else {
      dispatch({ type: 'RESUME' })
    }
  }, [dispatch, isOnline])

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext)
  return store
}
