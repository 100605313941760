import { call, put, takeLatest } from 'redux-saga/effects'
import { BlockUserFromLocationRequestBody } from 'connect-types/user/blockUserFromLocationRequestBody.type'
import { EndUserPayment } from 'connect-types/user/endUserPayment.type'
import { ActionType, getType } from 'typesafe-actions'
import { post, put as putRequest } from 'utils/api'
import * as actions from './user.actions'

function* userBlockDeviceRequest(
  action: ActionType<typeof actions.updateUserDevicesBlockRequest>
) {
  try {
    const { id, deviceBlock } = action.payload

    const response: {
      status: number
      message: BlockUserFromLocationRequestBody
    } = yield call(async () =>
      putRequest(`/user/${id}/devices/block`, deviceBlock)
    )

    const blockDevice = response.message

    yield put(actions.updateUserDevicesBlockRequestSuccess(blockDevice))
  } catch (error) {
    yield put(actions.updateUserDevicesBlockRequestFailure(error.response))
  }
}

export function* watchUserDevicesBlockRequest() {
  yield takeLatest(
    getType(actions.updateUserDevicesBlockRequest),
    userBlockDeviceRequest
  )
}

function* createUserPaymentRequest(
  action: ActionType<typeof actions.createUserPaymentRequest>
) {
  try {
    const { payment } = action.payload

    const response: {
      status: number
      message: any
    } = yield call(async () => post(`/payments`, payment))

    const changeAuth = response.message

    yield put(actions.createUserPaymentRequestSuccess(changeAuth))
  } catch (error) {
    yield put(actions.createUserPaymentRequestFailure(error.response))
  }
}

export function* watchCreateUserPaymentRequest() {
  yield takeLatest(
    getType(actions.createUserPaymentRequest),
    createUserPaymentRequest
  )
}

function* updateUserPaymentRequest(
  action: ActionType<typeof actions.updateUserPaymentRequest>
) {
  try {
    const { payment, paymentId } = action.payload

    const response: {
      status: number
      message: EndUserPayment
    } = yield call(async () => putRequest(`/payments/${paymentId}`, payment))

    const paymentResponse = response.message

    yield put(actions.updateUserPaymentRequestSuccess(paymentResponse))
  } catch (error) {
    yield put(actions.updateUserPaymentRequestFailure(error))
  }
}

export function* watchUpdateUserPaymentRequest() {
  yield takeLatest(
    getType(actions.updateUserPaymentRequest),
    updateUserPaymentRequest
  )
}

export default [
  watchUserDevicesBlockRequest,

  watchCreateUserPaymentRequest,

  watchUpdateUserPaymentRequest,
]
