import { RemoteConfigType } from 'connect-types/config/config.type'

const templates: RemoteConfigType['templates'] = []

const defaultValues: RemoteConfigType = {
  templates,
  showAddVenue: false,
  experimentalFeatures: false,
  systemColorMode: false,
  loaded: false,
  enterprise: false,
}

export default defaultValues
