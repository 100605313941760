import { call, put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { get, post, put as putRequest, deleter } from 'utils/api'
import { DeviceWhitelistRequestBody } from 'connect-types/locations/deviceWhitelistRequestBody.type'
import { NetworkDevice } from 'connect-types/locations/networkDevice.type'

import * as actions from './manage.actions'

/*
 * Get Whitelist Devices
 * =========================
 */
function* locationsWhitelistGetRequest(
  action: ActionType<typeof actions.locationsWhitelistRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: DeviceWhitelistRequestBody[]
    } = yield call(async () => get(`/locations/${serial}/device/whitelist`))

    const settings = response.message

    yield put(actions.locationsWhitelistRequestSuccess(settings))
  } catch {
    yield put(actions.locationsWhitelistRequestFailure())
  }
}

export function* watchLocationWhitelistGetRequest() {
  yield takeLatest(
    getType(actions.locationsWhitelistRequest),
    locationsWhitelistGetRequest
  )
}

/*
 * Create Whitelist Device for Location
 */
function* locationsCreateWhitelistPostRequest(
  action: ActionType<typeof actions.locationsWhitelistCreateRequest>
) {
  try {
    const { serial, whitelistedDevice } = action.payload

    whitelistedDevice.serial = serial
    const response: {
      status: number
      message: DeviceWhitelistRequestBody
    } = yield call(async () =>
      post(`/locations/${serial}/device/whitelist`, whitelistedDevice)
    )

    const settings = response.message

    yield put(actions.locationsWhitelistCreateRequestSuccess(settings))
  } catch {
    yield put(actions.locationsWhitelistCreateRequestFailure())
  }
}

export function* watchLocationsCreateWhitelistPostRequest() {
  yield takeLatest(
    getType(actions.locationsWhitelistCreateRequest),
    locationsCreateWhitelistPostRequest
  )
}

/*
 * Update Whitelist Device for Location
 */
function* locationsUpdateWhitelistPutRequest(
  action: ActionType<typeof actions.updateLocationsWhitelistRequest>
) {
  try {
    const { serial, whitelistedDevice, id } = action.payload

    const response: {
      status: number
      message: DeviceWhitelistRequestBody
    } = yield call(async () =>
      putRequest(
        `/locations/${serial}/device/whitelist/${id}`,
        whitelistedDevice
      )
    )

    const settings = response.message

    yield put(actions.updateLocationsWhitelistRequestSuccess(settings))
  } catch {
    yield put(actions.updateLocationsWhitelistRequestFailure())
  }
}

export function* watchLocationsUpdateWhitelistPutRequest() {
  yield takeLatest(
    getType(actions.updateLocationsWhitelistRequest),
    locationsUpdateWhitelistPutRequest
  )
}

/*
 * Delete Whitelist Device for Location
 */
function* locationsDeleteWhitelistDeleteRequest(
  action: ActionType<typeof actions.deleteLocationsWhitelistRequest>
) {
  try {
    const { serial, id } = action.payload

    const response: {
      status: number
      message: any
    } = yield call(async () =>
      deleter(`/locations/${serial}/device/whitelist/${id}`)
    )

    const settings = response.message

    yield put(actions.deleteLocationsWhitelistRequestSuccess(settings))
  } catch {
    yield put(actions.deleteLocationsWhitelistRequestFailure())
  }
}

export function* watchLocationsDeleteWhitelistDeleteRequest() {
  yield takeLatest(
    getType(actions.deleteLocationsWhitelistRequest),
    locationsDeleteWhitelistDeleteRequest
  )
}

/*
 * Get Network Devices
 */
function* locationsNetworkDevicesGetRequest(
  action: ActionType<typeof actions.locationsDeviceRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: NetworkDevice[]
    } = yield call(async () => get(`/locations/${serial}/device`))
    const settings = response.message

    yield put(actions.locationsDeviceRequestSuccess(settings))
  } catch {
    yield put(actions.locationsDeviceRequestFailure())
  }
}

export function* watchLocationNetworkDevicesGetRequest() {
  yield takeLatest(
    getType(actions.locationsDeviceRequest),
    locationsNetworkDevicesGetRequest
  )
}

/*
 * Create Network Device
 */
function* locationsNetworkDevicesCreateRequest(
  action: ActionType<typeof actions.createLocationsDeviceRequest>
) {
  try {
    const { serial, networkDevice } = action.payload

    networkDevice.serial = serial
    const response: {
      status: number
      message: NetworkDevice
    } = yield call(async () =>
      post(`/locations/${serial}/device`, networkDevice)
    )

    const settings = response.message

    yield put(actions.createLocationsDeviceRequestSuccess(settings))
  } catch {
    yield put(actions.createLocationsDeviceRequestFailure())
  }
}

export function* watchLocationNetworkDevicesCreateRequest() {
  yield takeLatest(
    getType(actions.createLocationsDeviceRequest),
    locationsNetworkDevicesCreateRequest
  )
}

/*
 * Edit Network Device
 */
function* locationsNetworkDevicesPutRequest(
  action: ActionType<typeof actions.updateLocationsDeviceRequest>
) {
  try {
    const { serial, networkDevice, id } = action.payload
    const response: {
      status: number
      message: NetworkDevice
    } = yield call(async () =>
      putRequest(`/locations/${serial}/device/${id}`, networkDevice)
    )

    const settings = response.message

    yield put(actions.updateLocationsDeviceRequestSuccess(settings))
  } catch {
    yield put(actions.updateLocationsDeviceRequestFailure())
  }
}

export function* watchLocationNetworkDevicesPutRequest() {
  yield takeLatest(
    getType(actions.updateLocationsDeviceRequest),
    locationsNetworkDevicesPutRequest
  )
}

/*
 * Delete Network Device
 */
function* locationsNetworkDevicesDeleteRequest(
  action: ActionType<typeof actions.deleteLocationsDeviceRequest>
) {
  try {
    const { serial, id } = action.payload
    const response: {
      status: number
      message: NetworkDevice
    } = yield call(async () => deleter(`/locations/${serial}/device/${id}`))

    const settings = response.message

    yield put(actions.deleteLocationsDeviceRequestSuccess(settings))
  } catch {
    yield put(actions.deleteLocationsDeviceRequestFailure())
  }
}

export function* watchLocationNetworkDevicesDeleteRequest() {
  yield takeLatest(
    getType(actions.deleteLocationsDeviceRequest),
    locationsNetworkDevicesDeleteRequest
  )
}

export default [
  watchLocationWhitelistGetRequest,
  watchLocationNetworkDevicesGetRequest,
  watchLocationsCreateWhitelistPostRequest,
  watchLocationsUpdateWhitelistPutRequest,
  watchLocationsDeleteWhitelistDeleteRequest,
  watchLocationNetworkDevicesCreateRequest,
  watchLocationNetworkDevicesPutRequest,
  watchLocationNetworkDevicesDeleteRequest,
]
