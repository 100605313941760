import { call, put, takeLeading } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import { put as putRequest } from 'utils/api'
import * as actions from './venue.actions'
import { VenueWiFi4EuType } from './venue.type'

function* updateWiFi4EuSettingsSaga(
  action: ActionType<typeof actions.updateWiFi4EuSettings.request>
) {
  const { serial, orgId, settings } = action.payload

  try {
    const response: VenueWiFi4EuType = yield call(async () =>
      putRequest(
        `/organisations/${orgId}/locations/${serial}/wifi4eu`,
        settings
      )
    )

    yield put(actions.updateWiFi4EuSettings.success(response))
  } catch (error) {
    yield put(actions.updateWiFi4EuSettings.failure(error))
  }
}

export function* watch() {
  yield takeLeading(
    actions.updateWiFi4EuSettings.request,
    updateWiFi4EuSettingsSaga
  )
}

export default [watch]
