import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import * as actions from './home.actions'

export type HomeAction = ActionType<typeof actions>

export interface StateType {
  users: Record<string, unknown>[] // TODO: need a User type here
}

const initialState: StateType = {
  users: [],
}

/* tslint:disable cyclomatic-complexity */
const homeReducer: Reducer<StateType, HomeAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (_draft) => {
    switch (action.type) {
      /**
       * MEMBERS_USERS_REQUEST
       *
       * /members/{uid}/users
       * Get list of users
       */
      case getType(actions.membersUsersRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * MEMBERS_USERS_REQUEST_SUCCESS
       *
       * /members/{uid}/users
       * Get list of users
       */
      case getType(actions.membersUsersRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * MEMBERS_USERS_REQUEST_FAILURE
       *
       * /members/{uid}/users
       * Get list of users
       */
      case getType(actions.membersUsersRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }
    }
  })

export default homeReducer
/* tslint:enable cyclomatic-complexity */
