import { BandwidthSettingsUpdateRequestBody } from 'connect-types/locations/bandwidthSettingsUpdateRequestBody.type'
import {
  ExportCommand,
  GenericCommand,
} from 'connect-types/locations/superCommands.type'
import { TimeoutSettingsUpdateRequestBody } from 'connect-types/locations/timeoutSettingsUpdateRequestBody.type'
import { WifiSettingsUpdateRequestBody } from 'connect-types/locations/wifiSettingsUpdateRequestBody.type'
import { FacebookAccount } from 'connect-types/reviews/facebookAccounts.type'
import { FacebookPage } from 'connect-types/reviews/facebookPages.type'
import { createAction } from 'typesafe-actions'

/**
 * GET Get the timeout settings associated with the location
 *
 * Path: /locations/{serial}/settings/timeouts
 */

// FIXME: add in request and query params
export const locationsTimeoutsSettingsRequest = createAction(
  'LOCATIONS_TIMEOUTS_SETTINGS_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsTimeoutsSettingsRequestSuccess = createAction(
  'LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_SUCCESS',
  (resolve) => (timeoutSettings: TimeoutSettingsUpdateRequestBody) =>
    resolve({ timeoutSettings })
)

export const locationsTimeoutsSettingsRequestFailure = createAction(
  'LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'locations timeouts settings request failed' })
)

export const updateLocationTimeoutsInStore = createAction(
  'UPDATE_LOCATIONS_TIMEOUTS_IN_STORE',
  (resolve) => (timeoutSettings: TimeoutSettingsUpdateRequestBody) =>
    resolve({ timeoutSettings })
)

/**
 * PUT Update the timeout settings associated with the location
 *
 * Path: /locations/{serial}/settings/timeouts
 */

// FIXME: add in request and query params
export const updateLocationsTimeoutsSettingsRequest = createAction(
  'UPDATE_LOCATIONS_TIMEOUTS_SETTINGS_REQUEST',
  (resolve) =>
    (serial: string, timeoutSettings: TimeoutSettingsUpdateRequestBody) =>
      resolve({ serial, timeoutSettings })
)

// FIXME: add in response object from saga
export const updateLocationsTimeoutsSettingsRequestSuccess = createAction(
  'UPDATE_LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_SUCCESS',
  (resolve) => (timeoutSettings: TimeoutSettingsUpdateRequestBody) =>
    resolve({ timeoutSettings })
)

export const updateLocationsTimeoutsSettingsRequestFailure = createAction(
  'UPDATE_LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations timeouts settings request failed' })
)

/**
 * PUT Get the WiFi settings associated with the location
 *
 * Path: /locations/{serial}/settings/wifi
 */

// FIXME: add in request and query params
export const updateLocationsWifiSettingsRequest = createAction(
  'UPDATE_LOCATIONS_WIFI_SETTINGS_REQUEST',
  (resolve) => (serial: string, wifiSettings: WifiSettingsUpdateRequestBody) =>
    resolve({ serial, wifiSettings })
)

// FIXME: add in response object from saga
export const updateLocationsWifiSettingsRequestSuccess = createAction(
  'UPDATE_LOCATIONS_WIFI_SETTINGS_REQUEST_SUCCESS',
  (resolve) => (wifiSettings: WifiSettingsUpdateRequestBody) =>
    resolve({ wifiSettings })
)

export const updateLocationsWifiSettingsRequestFailure = createAction(
  'UPDATE_LOCATIONS_WIFI_SETTINGS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations wifi settings request failed' })
)

/**
 * GET Get the bandwidth settings associate with the location
 *
 * Path: /locations/{serial}/settings/bandwidth
 */

// FIXME: add in request and query params
export const locationsBandwidthSettingsRequest = createAction(
  'LOCATIONS_BANDWIDTH_SETTINGS_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsBandwidthSettingsRequestSuccess = createAction(
  'LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_SUCCESS',
  (resolve) => (bandwidthSettings: BandwidthSettingsUpdateRequestBody) =>
    resolve({ bandwidthSettings })
)

export const locationsBandwidthSettingsRequestFailure = createAction(
  'LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'locations bandwidth settings request failed' })
)

export const updateLocationBandwidthInStore = createAction(
  'UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_IN_STORE',
  (resolve) => (bandwidthSettings: BandwidthSettingsUpdateRequestBody) =>
    resolve({ bandwidthSettings })
)
/**
 * PUT Get the bandwidth settings associate with the location
 *
 * Path: /locations/{serial}/settings/bandwidth
 */

// FIXME: add in request and query params
export const updateLocationsBandwidthSettingsRequest = createAction(
  'UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_REQUEST',
  (resolve) =>
    (serial: string, bandwidthSettings: BandwidthSettingsUpdateRequestBody) =>
      resolve({ serial, bandwidthSettings })
)

// FIXME: add in response object from saga
export const updateLocationsBandwidthSettingsRequestSuccess = createAction(
  'UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_SUCCESS',
  (resolve) => (bandwidthSettings: BandwidthSettingsUpdateRequestBody) =>
    resolve({ bandwidthSettings })
)

export const updateLocationsBandwidthSettingsRequestFailure = createAction(
  'UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations bandwidth settings request failed' })
)

export const locationsFacebookAuthorizeAccountRequest = createAction(
  'LOCATIONS_FACEBOOK_AUTHORIZE_ACCOUNT_REQUEST',
  (resolve) => (orgId: string, redirectUrl: string, appName: string) =>
    resolve({ orgId, redirectUrl, appName })
)

export const locationsFacebookAuthorizeAccountRequestSuccess = createAction(
  'LOCATIONS_FACEBOOK_AUTHORIZE_ACCOUNT_REQUEST_SUCCESS',
  (resolve) => (redirectUrl: string) => resolve({ redirectUrl })
)

export const locationsFacebookAuthorizeAccountRequestFailure = createAction(
  'LOCATIONS_FACEBOOK_AUTHORIZE_ACCOUNT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const locationsFacebookAccountsRequest = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_REQUEST',
  (resolve) => (orgId: string) => resolve({ orgId })
)

export const locationsFacebookAccountsRequestSuccess = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_REQUEST_SUCCESS',
  (resolve) => (facebookAccounts: FacebookAccount[]) =>
    resolve({ facebookAccounts })
)

export const locationsFacebookAccountsRequestFailure = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const locationsFacebookAccountsPagesRequest = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST',
  (resolve) => (orgId: string, accountId: string) =>
    resolve({ orgId, accountId })
)

export const locationsFacebookAccountsPagesRequestSuccess = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_SUCCESS',
  (resolve) => (facebookPages: FacebookPage[]) => resolve({ facebookPages })
)

export const locationsFacebookAccountsPagesRequestFailure = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const updateLocationsFacebookPageRequest = createAction(
  'UPDATE_LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST',
  (resolve) =>
    (orgId: string, pageId: string, token: string, review_id: string) =>
      resolve({ orgId, pageId, token, review_id })
)

export const updateLocationsFacebookPageRequestSuccess = createAction(
  'UPDATE_LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_SUCCESS',
  (resolve) => (page: FacebookPage) => resolve({ page })
)

export const updateLocationsFacebookPageRequestFailure = createAction(
  'UPDATE_LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const updateLocationsIssueSuperCommandInStore = createAction(
  'UPDATE_LOCATIONS_ISSUE_SUPER_COMMAND_IN_STORE',
  (resolve) => (command: GenericCommand) => resolve({ command })
)

export const locationsIssueSuperCommandRequest = createAction(
  'LOCATIONS_ISSUE_SUPER_COMMAND_REQUEST',
  (resolve) => (command: GenericCommand, serial: string) =>
    resolve({ command, serial })
)

export const locationsIssueSuperCommandRequestSuccess = createAction(
  'LOCATIONS_ISSUE_SUPER_COMMAND_REQUEST_SUCCESS',
  (resolve) => (responseMessage: string) => resolve({ responseMessage })
)

export const locationsIssueSuperCommandRequestFailure = createAction(
  'LOCATIONS_ISSUE_SUPER_COMMAND_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const updateLocationsIssueExportCommandInStore = createAction(
  'UPDATE_LOCATIONS_ISSUE_EXPORT_COMMAND_IN_STORE',
  (resolve) => (command: ExportCommand) => resolve({ command })
)

export const locationsIssueExportCommandRequest = createAction(
  'LOCATIONS_ISSUE_EXPORT_COMMAND_REQUEST',
  (resolve) => (command: ExportCommand, serial: string) =>
    resolve({ command, serial })
)

export const locationsIssueExportCommandRequestSuccess = createAction(
  'LOCATIONS_ISSUE_EXPORT_COMMAND_REQUEST_SUCCESS',
  (resolve) => (responseMessage: string) => resolve({ responseMessage })
)

export const locationsIssueExportCommandRequestFailure = createAction(
  'LOCATIONS_ISSUE_EXPORT_COMMAND_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
