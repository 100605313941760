import { RouterProvider } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import { routes } from 'routes'
import * as Sentry from '@sentry/react'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import ErrorContext from 'common/Sentry/Error'
import { ToastContainer } from 'common/message/message'
import { OnlineStatusProvider } from 'connect-context/online-status'
import theme from 'connect-theme'
import FirebaseAnalytics from 'utils/firebase/analytics'
import FirebaseConfig from 'utils/firebase/config'

import SegmentProvider from 'connect-context/tracking/segment'

import FirebaseProvider from 'utils/firebase/provider'
const sentryCreateBrowserRouter =
  /** @ts-ignore */
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const App = () => {
  return (
    <>
      <SegmentProvider>
        <ColorModeScript initialColorMode="light" />
        <FirebaseProvider>
          <ChakraProvider theme={theme}>
            <ErrorContext>
              <FirebaseAnalytics>
                <FirebaseConfig>
                  <OnlineStatusProvider>
                    <RouterProvider
                      router={sentryCreateBrowserRouter(routes)}
                      fallbackElement={<>Loading</>}
                    />
                  </OnlineStatusProvider>
                </FirebaseConfig>
              </FirebaseAnalytics>
            </ErrorContext>
          </ChakraProvider>
        </FirebaseProvider>
      </SegmentProvider>

      <ToastContainer />
    </>
  )
}

export default App
