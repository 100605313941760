import { BlockUserFromLocationRequestBody } from 'connect-types/user/blockUserFromLocationRequestBody.type'
import { EndUserPayment } from 'connect-types/user/endUserPayment.type'
import { EndUser } from 'connect-types/user/user.type'
import { createAction } from 'typesafe-actions'

export const createUserPaymentRequest = createAction(
  'CREATE_USER_PAYMENT_REQUEST',
  (resolve) => (payment: EndUserPayment) => resolve({ payment })
)

export const createUserPaymentRequestSuccess = createAction(
  'CREATE_USER_PAYMENT_REQUEST_SUCCESS',
  (resolve) => (customer: { customer: EndUser }) => resolve({ customer })
)

export const createUserPaymentRequestFailure = createAction(
  'CREATE_USER_PAYMENT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * PUT Update a Payment
 * Path /payments/{paymentId}
 */

export const updateUserPaymentRequest = createAction(
  'UPDATE_USER_PAYMENT_REQUEST',
  (resolve) => (paymentId: number, payment: Partial<EndUserPayment>) =>
    resolve({ paymentId, payment })
)

export const updateUserPaymentRequestSuccess = createAction(
  'UPDATE_USER_PAYMENT_REQUEST_SUCCESS',
  (resolve) => (payment: EndUserPayment) => resolve({ payment })
)

export const updateUserPaymentRequestFailure = createAction(
  'UPDATE_USER_PAYMENT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const updateUserActivePaymentInStore = createAction(
  'UPDATE_USER_PAYMENT_IN_STORE',
  (resolve) => (payment: EndUserPayment) => resolve({ payment })
)

/**
 * PUT Block a user from a location
 *
 * Path: /user/{id}/devices/block
 */
export const updateUserDevicesBlockRequest = createAction(
  'UPDATE_USER_DEVICES_BLOCK_REQUEST',
  (resolve) => (id: string, deviceBlock: BlockUserFromLocationRequestBody) =>
    resolve({ id, deviceBlock })
)

export const updateUserDevicesBlockRequestSuccess = createAction(
  'UPDATE_USER_DEVICES_BLOCK_REQUEST_SUCCESS',
  (resolve) => (deviceBlock: BlockUserFromLocationRequestBody) =>
    resolve({ deviceBlock })
)

export const updateUserDevicesBlockRequestFailure = createAction(
  'UPDATE_USER_DEVICES_BLOCK_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
