import { Reducer } from '@reduxjs/toolkit'
import message from 'common/message/message'
import { produce } from 'immer'

import { VendorAndSubscriptionInformation } from 'connect-types/locations/additionalInformation.type'
import { Location } from 'connect-types/locations/location.type'
import { LocationDebuggerPayload } from 'connect-types/locations/locationDebuggerPayload.type'
import { LocationTemplateType } from 'connect-types/locations/locationTemplate.type'
import { PaymentMethodUpdateRequestBody } from 'connect-types/locations/paymentMethodUpdateRequestBody.type'
import { PaymentPlanCreateRequestBody } from 'connect-types/locations/paymentPlanCreateRequestBody.type'
import { QuestionsHeaderType } from 'connect-types/locations/questions.type'
import { ReviewType } from 'connect-types/locations/reviewTypeUpdateRequestBody.type'
import { ActionType, getType } from 'typesafe-actions'
import * as actions from './locations.actions'

export type LocationsAction = ActionType<typeof actions>

export interface StateType {
  readonly itemsObj: { [key: string]: Location }
  readonly isLoading: boolean
  readonly connectedClients: Record<string, unknown>[]
  connectionDebugger: LocationDebuggerPayload
  informDebugger: LocationDebuggerPayload
  setupDebugger: LocationDebuggerPayload
  facebookDebugger: LocationDebuggerPayload
  configDebugger: LocationDebuggerPayload
  additionalInformation: VendorAndSubscriptionInformation
  questions: QuestionsHeaderType[]
  paymentPlans: PaymentPlanCreateRequestBody[]
  paymentMethods: PaymentMethodUpdateRequestBody
  reviewSetup: ReviewType[]
  locationTemplate: LocationTemplateType
  hasLoadedLocations: boolean
}

const stockDebug: LocationDebuggerPayload = {
  category: 'success',
  description: '',
  show: false,
  debuggerKind: '',
}

const initialState: StateType = {
  itemsObj: {},
  isLoading: false,
  connectedClients: [],
  connectionDebugger: { ...stockDebug },
  informDebugger: { ...stockDebug },
  setupDebugger: { ...stockDebug },
  facebookDebugger: { ...stockDebug },
  configDebugger: { ...stockDebug },
  additionalInformation: null,
  questions: [],
  paymentPlans: [],
  paymentMethods: null,
  reviewSetup: [],
  hasLoadedLocations: false,
  locationTemplate: null,
}

/* tslint:disable cyclomatic-complexity */
const locationsReducer: Reducer<StateType, LocationsAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      /**
       * LOCATIONS_PAYMENT_PLANS_REQUEST
       *
       * /locations/{serial}/payments/plans
       * Get all payment plans for the location
       */
      case getType(actions.locationsPaymentPlansRequest): {
        draft.isLoading = true
        draft.paymentPlans = []
        break
      }

      /**
       * LOCATIONS_PAYMENT_PLANS_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/plans
       * Get all payment plans for the location
       */
      case getType(actions.locationsPaymentPlansRequestSuccess): {
        draft.isLoading = false
        draft.paymentPlans = action.payload.locationPlans
        break
      }

      /**
       * LOCATIONS_PAYMENT_PLANS_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/plans
       * Get all payment plans for the location
       */
      case getType(actions.locationsPaymentPlansRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * CREATE_LOCATIONS_PAYMENT_PLANS_REQUEST
       *
       * /locations/{serial}/payments/plans
       * Get all payment plans for the location
       */
      case getType(actions.createLocationsPaymentPlansRequest): {
        draft.isLoading = true
        message.loading('Adding plan')
        break
      }

      /**
       * CREATE_LOCATIONS_PAYMENT_PLANS_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/plans
       * Get all payment plans for the location
       */
      case getType(actions.createLocationsPaymentPlansRequestSuccess): {
        draft.isLoading = false
        draft.paymentPlans.push(action.payload.locationPlan)
        message.success('Plan added')
        break
      }

      /**
       * CREATE_LOCATIONS_PAYMENT_PLANS_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/plans
       * Get all payment plans for the location
       */
      case getType(actions.createLocationsPaymentPlansRequestFailure): {
        draft.isLoading = false
        message.error('Failed to add plan')
        break
      }

      case getType(actions.locationsPaymentMethodsRequest): {
        draft.isLoading = true
        draft.paymentMethods = null
        break
      }

      case getType(actions.locationsPaymentMethodsRequestSuccess): {
        draft.isLoading = false
        draft.paymentMethods = action.payload.methods
        break
      }

      case getType(actions.locationsPaymentMethodsRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.updateLocationsPaymentMethodsRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.updateLocationsPaymentMethodsRequestSuccess): {
        draft.isLoading = false
        draft.paymentMethods = action.payload.methods
        break
      }

      case getType(actions.updateLocationsPaymentMethodsRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.updateLocationsPaymentPlansRequest): {
        draft.isLoading = true
        message.loading('Updating plan')
        break
      }

      case getType(actions.updateLocationsPaymentPlansRequestSuccess): {
        draft.isLoading = false
        const findIndex = draft.paymentPlans.findIndex(
          (plan: PaymentPlanCreateRequestBody) =>
            plan.id === action.payload.locationPlan.id
        )

        draft.paymentPlans[findIndex] = action.payload.locationPlan
        message.success('Plan updated')
        break
      }

      case getType(actions.updateLocationsPaymentPlansRequestFailure): {
        draft.isLoading = false
        message.error('Failed to update plan')
        break
      }

      case getType(actions.deleteLocationsPaymentPlansRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.deleteLocationsPaymentPlansRequestSuccess): {
        draft.isLoading = false
        const findIndex = draft.paymentPlans.findIndex(
          (plan: PaymentPlanCreateRequestBody) => plan.id === action.payload.id
        )

        draft.paymentPlans.splice(findIndex, 1)
        break
      }

      case getType(actions.deleteLocationsPaymentPlansRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * LOCATIONS_GOOGLE_PLACE_REQUEST
       *
       * /locations/{serial}/settings/location/{placeId}
       * Get the formatted address and name associated with the Google Place ID of the location
       */
      case getType(actions.locationsGooglePlaceRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_GOOGLE_PLACE_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/location/{placeId}
       * Get the formatted address and name associated with the Google Place ID of the location
       */
      case getType(actions.locationsGooglePlaceRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_GOOGLE_PLACE_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/location/{placeId}
       * Get the formatted address and name associated with the Google Place ID of the location
       */
      case getType(actions.locationsGooglePlaceRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_SETTINGS_BRANDING_REQUEST
       *
       * /locations/{serial}/settings/branding/{type}
       * Delete either a background or a header image
       */
      case getType(actions.deleteLocationsSettingsBrandingRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_SETTINGS_BRANDING_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/branding/{type}
       * Delete either a background or a header image
       */
      case getType(actions.deleteLocationsSettingsBrandingRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_SETTINGS_BRANDING_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/branding/{type}
       * Delete either a background or a header image
       */
      case getType(actions.deleteLocationsSettingsBrandingRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_SCHEDULE_SETTINGS_REQUEST
       *
       * /locations/{serial}/settings/schedule
       * Get the current schedule associated with the location
       */
      case getType(actions.locationsScheduleSettingsRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_SCHEDULE_SETTINGS_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/schedule
       * Get the current schedule associated with the location
       */
      case getType(actions.locationsScheduleSettingsRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_SCHEDULE_SETTINGS_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/schedule
       * Get the current schedule associated with the location
       */
      case getType(actions.locationsScheduleSettingsRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_SETTINGS_TEMPLATE_REQUEST
       *
       * /locations/{serial}/settings/template
       * Get which attributes a location is using from a template
       */
      case getType(actions.locationsSettingsTemplateRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.locationTemplate = null
        break
      }

      /**
       * LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/template
       * Get which attributes a location is using from a template
       */
      case getType(actions.locationsSettingsTemplateRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever

        draft.locationTemplate = action.payload.response
        break
      }

      /**
       * LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/template
       * Get which attributes a location is using from a template
       */
      case getType(actions.locationsSettingsTemplateRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever

        draft.locationTemplate = null
        break
      }

      /**
       * CREATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST
       *
       * /locations/{serial}/settings/template
       * Create a Template from a Serial passed in the body so another Location can use the same settings as another
       */
      case getType(actions.createLocationsSettingsTemplateRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * CREATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/template
       * Create a Template from a Serial passed in the body so another Location can use the same settings as another
       */
      case getType(actions.createLocationsSettingsTemplateRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.locationTemplate = action.payload
        break
      }

      /**
       * CREATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/template
       * Create a Template from a Serial passed in the body so another Location can use the same settings as another
       */
      case getType(actions.createLocationsSettingsTemplateRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST
       *
       * /locations/{serial}/settings/template
       * Update the attributes of a Template being used by a Location
       */
      case getType(actions.updateLocationsSettingsTemplateRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/template
       * Update the attributes of a Template being used by a Location
       */
      case getType(actions.updateLocationsSettingsTemplateRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.locationTemplate = action.payload
        break
      }

      /**
       * UPDATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/template
       * Update the attributes of a Template being used by a Location
       */
      case getType(actions.updateLocationsSettingsTemplateRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST
       *
       * /locations/{serial}/settings/template
       * Restore the Default values of the Location and remove the Template
       */
      case getType(actions.deleteLocationsSettingsTemplateRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/template
       * Restore the Default values of the Location and remove the Template
       */
      case getType(actions.deleteLocationsSettingsTemplateRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.locationTemplate = null
        break
      }

      /**
       * DELETE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/template
       * Restore the Default values of the Location and remove the Template
       */
      case getType(actions.deleteLocationsSettingsTemplateRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_DEBUGGER_CONFIG_REQUEST
       *
       * /locations/{serial}/debugger/config
       * Can the location pick up a config?
       */
      case getType(actions.locationsDebuggerConfigRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * LOCATIONS_DEBUGGER_CONFIG_REQUEST_SUCCESS
       *
       * /locations/{serial}/debugger/config
       * Can the location pick up a config?
       */
      case getType(actions.locationsDebuggerConfigRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.configDebugger = action.payload.configDebugger
        break
      }

      /**
       * LOCATIONS_DEBUGGER_CONFIG_REQUEST_FAILURE
       *
       * /locations/{serial}/debugger/config
       * Can the location pick up a config?
       */
      case getType(actions.locationsDebuggerConfigRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }
    }
  })

export default locationsReducer
/* tslint:enable cyclomatic-complexity */
