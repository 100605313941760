import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  getToken,
  setToken,
  refresh_client,
  ApiUserRefreshTokenResponse,
} from './AuthTokenHelper'
import config from 'config'
import { LocalStorageCredentials } from 'connect-types/auth/localStorageCredentials.type'
import * as Sentry from '@sentry/react'
import { AvjErrorType } from 'connect-types/backend/service'

export const customAxios = axios.create({
  //adapter: 'fetch' in window && fetchAdapter,
  timeout: 1000 * 30,
  validateStatus: (status) => {
    return status >= 200 && status < 303
  },
})

export function isAxiosError<T>(error: unknown): error is AxiosError<T> {
  return (error as AxiosError)?.isAxiosError !== undefined
}

export function isAxiosResponse<T>(error: unknown): error is AxiosResponse<T> {
  return (error as AxiosResponse<T>).data !== undefined
}

export const isAjvError = <T extends AvjErrorType>(
  error: unknown
): error is AxiosError<T> => {
  if (!isAxiosError<AvjErrorType>(error)) return false
  if (error.response.data?.context) return true
  return false
}

export const isMorpheusError = <T>(error: unknown): error is AxiosError<T> => {
  if (!isAxiosError<{ stack: any; method: any }>(error)) return false
  console.log('error-here', error.response.data)
  if ('stack' in error.response.data && 'method' in error.response.data)
    return true
  return false
}

export const isBackendError = <T>(error: unknown): error is AxiosError<T> => {
  if (!isAxiosError<{ detail: any }>(error)) return false
  if (error.response.data?.detail) return true
  return false
}

export const isServiceError = <T>(error: unknown): error is AxiosError<T> => {
  if (!isAxiosError<{ message: any; errors: unknown[] }>(error)) return false
  if (error.response.data?.message) return true
  if (error.response.data?.errors) return true
  return false
}

const allowed401 = [
  '/members/me/logout',
  '/oauth/token',
  '/members/me/member',
].map((item) => config.url.api + item)

customAxios.interceptors.request.use((config) => {
  const token = getToken()

  if (!token && !allowed401.includes(config.url)) {
    return config
  }

  if (token) {
    if (config.url.includes('s3')) {
      return config
    }
    config.headers.Authorization = `${token.tokenType} ${token.accessToken}`
  }

  return config
})
customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorResponse = error.response
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        Sentry.captureException(error)
      }
    }
    if (
      isTokenExpiredError(errorResponse) &&
      !allowed401.includes(error.config.url)
    ) {
      return resetTokenAndReattemptRequest(error)
    }

    // If the error is due to other reasons, we just throw it back to axios

    return Promise.reject(error)
  }
)

export const poster = async (
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => customAxios.post(url, data, config)
export const getter = async (url: string, config?: AxiosRequestConfig) =>
  customAxios.get(url, config)
export const putter = async (
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => customAxios.put(url, data, config)
export const deler = async (url: string, config?: AxiosRequestConfig) =>
  customAxios.delete(url, config)

function isTokenExpiredError(errorResponse: any): boolean {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
  if (errorResponse && errorResponse.status === 401) return true

  return false
}

let isAlreadyFetchingAccessToken = false

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: any = []

const resetTokenAndReattemptRequest = async (error: any) => {
  try {
    const { response: errorResponse } = error
    const token = getToken()

    if (!token) {
      // We can't refresh, throw the error anyway

      window.location.href = `/oauth/logout?state=${window.location.pathname}`

      return Promise.reject(error)
    }

    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber((access_token: string) => {
        errorResponse.config.headers.Authorization = `Bearer ${access_token}`
        resolve(axios(errorResponse.config))
      })
    })

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true
      const response = await axios.post<ApiUserRefreshTokenResponse>(
        `${config.url.api}/oauth/token`,
        refresh_client(token.refreshToken)
      )

      if (!response.data) {
        return Promise.reject(error)
      }

      const { access_token, expires_in, refresh_token, scope, token_type } =
        response.data
      const userCredentials: LocalStorageCredentials = {
        accessToken: access_token,
        expiresIn: expires_in,
        refreshToken: refresh_token,
        scope,
        tokenType: token_type,
        expiresAt: expires_in ? Date.now() + expires_in * 1000 : null,
      }

      setToken(userCredentials) // save the newly refreshed token for other requests to use
      isAlreadyFetchingAccessToken = false
      onAccessTokenFetched(access_token)
    }

    return retryOriginalRequest
  } catch (err) {
    if (isAxiosError<{ error: unknown }>(err) && err.response) {
      console.log(err.response.status, err.response.data, 'err')
      if (err.response.status === 400 && 'error' in err.response.data) {
        window.location.href = `/oauth/logout?state=${window.location.pathname}`
      }
    }
    return Promise.reject(err)
  }
}

function onAccessTokenFetched(access_token: string) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback: any) => callback(access_token))
  subscribers = []
}

function addSubscriber(callback: any) {
  subscribers.push(callback)
}
