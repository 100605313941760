// TODO: re-implement when required
import { fork, all } from 'redux-saga/effects'

import auth from './auth/auth.saga'
import monitor from './monitor.saga'
import locations from './entities/locations/locations.saga'
import settings from './entities/settings/settings.saga'
import manage from './entities/manage/manage.saga'
import members from './entities/member/member.saga'
import billing from './entities/billing/billing.saga'
import analytics from './entities/analytics/analytics.saga'
import integrations from './entities/integration/integration.saga'
import user from './entities/user/user.saga'
import organisations from './entities/organisations/organisations.saga'
import venue from './entities/venue/venue.saga'

import global from './global/global.saga'

const sagas = [
  ...monitor,
  ...global,
  ...auth,
  ...locations,
  ...settings,
  ...manage,
  ...members,
  ...billing,
  ...analytics,
  ...integrations,
  ...user,
  ...organisations,
  ...venue,
]

function* rootSaga() {
  yield all(sagas.map(fork))
}

export default rootSaga
