import {
  BaseQueryFn,
  createApi,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'
import message from 'common/message/message'
import { CandidateProfileType } from 'components/EndUserProfilePage/Candidate'
import config from 'config'
import { GiftingReports } from 'connect-types/analytics/giftingAnalytics.type'
import { LocalStorageCredentials } from 'connect-types/auth/localStorageCredentials.type'
import {
  BackendResponseType,
  ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
} from 'connect-types/backend/service'
import {
  CustomQuestionEntriesType,
  CustomQuestionType,
} from 'connect-types/custom-questions'
import { FormType } from 'connect-types/forms/forms.types'
import {
  GiftActivation,
  GiftActivationMutation,
  GiftActivationSingle,
  GiftSettings,
} from 'connect-types/gifting/gifting.types'
import { StripeAccountConnectRequest } from 'connect-types/integrations/stripeAccoountConnectRequest'
import { AddLocation } from 'connect-types/locations/addLocation.type'
import { GeneralSettingsUpdateRequestBody } from 'connect-types/locations/generalSettingsUpdateRequestBody.type'
import { OtherSettingsUpdateRequestBody } from 'connect-types/locations/otherSettingsUpdateRequestBody.type'
import {
  Stories,
  StoryActivityType,
  StoryPage,
} from 'connect-types/nearly/stories.type'
import {
  OrganisationProgress,
  OrganisationUserType,
} from 'connect-types/organisations/organisations.type'
import {
  initPage,
  PagenationRequestType,
  PaginationRepsonse,
} from 'connect-types/pagination/pagination.type'
import { ReviewsReports } from 'connect-types/reviews/reports.type'
import {
  ReviewComprehendKeyword,
  UserReview,
  UserReviewQuery,
} from 'connect-types/reviews/reviews.type'
import { ReviewSettings } from 'connect-types/reviews/settings.type'
import { CsvRequestType, CsvResponseType } from 'connect-types/sources/csv.type'
import { EndUser, User } from 'connect-types/user/user.type'
import { isEqual } from 'lodash'
import { ApiUserCredentials } from 'state/auth/auth.saga'
import { GiftReportChartType } from 'state/entities/gifting/gifting.types'
import { OrganisationRegistrationReportType } from 'state/entities/insight/insight.type'
import {
  InteractionDataSourcesType,
  InteractionRequestType,
  OrganizationRegistration,
  OrganizationRegistrationTransactionType,
  ProfileInteractionType,
} from 'state/entities/interactions/interactions.types'
import {
  LoyaltyDataPaginationType,
  LoyaltyDataType,
  LoyaltySchemeTagType,
  LoyaltySchemeType,
  RedemptionType,
} from 'state/entities/loyalty/loyalty.types'
import {
  OrganizationRegistrationType,
  OrganizationUserBookingType,
  WiFiSessionDataType,
} from 'state/entities/organisation-user/organisation-user.types'
import { MappedProfileItem } from 'state/entities/reports/reports.type'
import { allSegment, parseRoot } from 'state/entities/segment/segment.mock'
import {
  Segment,
  SegmentPreview,
  SegmentPreviewQueryType,
  SegmentPreviewTotalQueryType,
  SegmentReach,
  SegmentType,
  TagType,
} from 'state/entities/segment/segment.types'
import {
  UnifiController,
  UnifiLocation,
  UnifiSite,
} from 'state/entities/unifi/unifi.types'
import {
  VendorSourceType,
  VenueInformType,
} from 'state/entities/venue/venue.type'
import { DefaultQueryType } from 'state/global/global.types'
import { customAxios } from 'utils/axiosHelper'
import { buildUrl } from 'utils/common'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')
      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }
console.log({ base: config.url.api })
const backendApi = createApi({
  reducerPath: 'backend',
  baseQuery: axiosBaseQuery({ baseUrl: config.url.api }),

  tagTypes: [
    'Organisation',
    'Organisations',
    'Locations',
    'LocationsStories',
    'Progress',
    'User',
    'Vendors',
    'Location',
    'SIC',
    'UniFiControllers',
    'UniFiLocation',
    'OrganisationSettings',
    'OrganisationUsers',
    'OrganisationUsersVenues',
    'Forms',
    'CustomQuestions',
    'CustomQuestionsEntries',
    'GiftCards',
    'GiftCardsActivations',
    'StripeAccounts',
    'LoyaltySchemes',
    'LoyaltySchemesTags',
    'ReviewPages',
    'ReviewResponses',
    'MarketingOptOuts',
    'Segments',
    'ProfileTags',
    'EndUser',
    'SupportedRegions',
    'Csv',
    'MarketingMenus',
  ],

  endpoints: (build) => ({
    getAuthWithCode: build.query<LocalStorageCredentials, string>({
      query: (code) => ({
        url: `/oauth/token`,
        method: 'post',
        data: {
          code,
          client_id: config.auth.clientId,
          client_secret: config.auth.clientSecret,
          grant_type: 'authorization_code',
          redirect_uri: config.auth.redirectUri,
        },
      }),
      transformResponse(response: ApiUserCredentials) {
        console.log({ trans: response })
        const credentials: LocalStorageCredentials = {
          accessToken: response.access_token,
          expiresIn: response.expires_in,
          refreshToken: response.refresh_token,
          scope: response.scope, // TODO: what is scope for?
          tokenType: response.token_type,
          expiresAt: response.expires_in
            ? Date.now() + response.expires_in * 1000
            : null,
        }

        localStorage.setItem(
          config.localStorageKeys.userCredentials,
          JSON.stringify(credentials)
        )
        return credentials
      },
      transformErrorResponse(error) {
        console.log({ transrr: error })
      },
    }),

    getStripeAccounts: build.query<
      StripeAccountConnectRequest[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/integration/${orgId}/stripe`,
        method: 'get',
      }),
      transformResponse: (
        results: BackendResponseType<StripeAccountConnectRequest[]>
      ) => {
        return results.message
      },
    }),
    logout: build.mutation({
      query: () => ({
        url: '/members/me/logout',
        method: 'delete',
      }),
      transformResponse() {
        localStorage.setItem(config.localStorageKeys.userCredentials, null)
        return null
      },
      transformErrorResponse() {
        localStorage.setItem(config.localStorageKeys.userCredentials, null)
        return null
      },
    }),
    getVendors: build.query<VendorSourceType[], undefined>({
      query: () => ({
        url: `/public/vendors`,
        method: 'get',
      }),

      providesTags: ['Vendors'],
    }),
    getSupportedRegions: build.query<string[], undefined>({
      query: () => ({
        url: `/public/supported-regions`,
        method: 'get',
      }),
      transformResponse(data: { regions: string[] }) {
        return data.regions
      },
      providesTags: ['SupportedRegions'],
    }),

    getOrganisationProgress: build.query<OrganisationProgress, string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/progress`,
        method: 'get',
      }),

      providesTags: ['Progress'],
    }),

    /**
     * STORIES
     */
    getLocationStories: build.query<Stories, { serial: string }>({
      query: ({ serial }) => ({
        url: `/locations/${serial}/nearly/stories`,
        method: 'get',
      }),
      providesTags: (item) =>
        item
          ? [
              { type: 'LocationsStories', id: item.id },
              { type: 'LocationsStories', id: 'LIST' },
            ]
          : [],
    }),
    createLocationStories: build.mutation<
      Stories,
      { serial: string; stories: Stories }
    >({
      query: ({ serial, stories }) => ({
        url: `/locations/${serial}/nearly/stories`,
        method: 'post',
        data: stories,
      }),
      invalidatesTags: [{ type: 'LocationsStories', id: 'LIST' }],
      transformResponse(data: Stories) {
        message.success('Story settings updated')
        return data
      },
    }),
    addLocationStoryPage: build.mutation<
      StoryPage,
      { serial: string; page: StoryPage }
    >({
      query: ({ serial, page }) => {
        if (page.id) {
          return {
            url: `/locations/${serial}/nearly/stories/${page.id}`,
            method: 'put',
            data: page,
          }
        }

        return {
          url: `/locations/${serial}/nearly/stories/page`,
          method: 'post',
          data: page,
        }
      },
      invalidatesTags: (item) => [
        { type: 'LocationsStories', id: item.story_id },
      ],
      transformResponse(data: StoryPage) {
        message.success('Story saved')
        return data
      },
    }),
    getLocationStoriesActivity: build.query<
      PaginationRepsonse<StoryActivityType>,
      { serial: string; limit: number; offset: number }
    >({
      query: ({ serial, ...rest }) => ({
        url: buildUrl(`/locations/${serial}/nearly/stories/activity`, rest),
        method: 'get',
      }),
    }),
    /**
     * END STORIES
     */

    updateLocationVendor: build.mutation<
      VenueInformType,
      { serial: string; vendor_id: string }
    >({
      query: ({ serial, vendor_id }) => ({
        url: `/locations/${serial}/settings/wifi/vendor?vendor_id=${vendor_id}`,
        method: 'put',
      }),
      invalidatesTags: (_, _error, { serial }) => [
        { type: 'Location', id: serial },
      ],
    }),
    removeVenueFromOrganisation: build.mutation<
      BackendResponseType<string>,
      { orgId: string; serial: string }
    >({
      query: ({ serial, orgId }) => ({
        url: `/organisations/${orgId}/admin/unlink-location-from-organisation`,
        data: { serial },
        method: 'delete',
      }),
      invalidatesTags: ['Organisation', 'Location'],
    }),
    updateVenueGeneralSettings: build.mutation<
      BackendResponseType<GeneralSettingsUpdateRequestBody>,
      { serial: string; data: GeneralSettingsUpdateRequestBody }
    >({
      query: ({ serial, data }) => ({
        url: `/locations/${serial}/settings/general`,
        data,
        method: 'put',
      }),
      invalidatesTags: ['Organisation', 'Location'],
      transformResponse(
        data: BackendResponseType<GeneralSettingsUpdateRequestBody>
      ) {
        message.success('Venue settings updated')
        return data
      },
    }),

    updateVenueOther: build.mutation<
      OtherSettingsUpdateRequestBody,
      { serial: string; data: OtherSettingsUpdateRequestBody }
    >({
      query: ({ serial, data }) => ({
        url: `/locations/${serial}/settings/other`,
        data: data,
        method: 'put',
      }),
      invalidatesTags: (_, _error, { serial }) => [
        { type: 'Location', id: serial },
      ],
    }),

    addVenue: build.mutation<string, { orgId: string; data: AddLocation }>({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/billing/venue`,
        data,
        method: 'post',
      }),
      transformResponse(
        data: BackendResponseType<{ serial: string; venues: number }>
      ) {
        return data.message.serial
      },
      invalidatesTags: ['Organisation', 'Locations'],
    }),
    updateRadiusSecret: build.mutation<
      string,
      { serial: string; secret: string }
    >({
      query: ({ secret, serial }) => ({
        url: `/radius/secret/${serial}`,
        method: 'put',
        data: { secret },
      }),
      invalidatesTags: (_, _error, { serial }) => [
        { type: 'Location', id: serial },
      ],
    }),

    /**
     * UniFi
     */
    getUniFiControllers: build.query<UnifiController[], string>({
      query: (orgId) => ({
        url: `/integration/${orgId}/unifi`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<UnifiController[]>) {
        return data.message
      },
      providesTags: (results) => [
        ...(results || []).map((item) => ({
          type: 'UniFiControllers' as const,
          id: item.id,
        })),
        { type: 'UniFiControllers', id: 'LIST' },
      ],
    }),
    getUniFiController: build.query<UnifiController, { orgId; controllerId }>({
      query: ({ controllerId, orgId }) => ({
        url: `/integration/${orgId}/unifi/${controllerId}`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<UnifiController>) {
        return data.message
      },
      providesTags: (results) => [
        { type: 'UniFiControllers' as const, id: results.id },
      ],
    }),
    getUniFiVenueSettings: build.query<
      UnifiLocation,
      { orgId: string; controllerId: string; serial: string }
    >({
      query: ({ orgId, serial, controllerId }) => ({
        url: `/integration/${orgId}/unifi/${controllerId}/location/${serial}`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<UnifiLocation>) {
        return data.message
      },
      providesTags: (results) =>
        results?.unifiId
          ? [{ type: 'UniFiLocation', id: results.unifiId }]
          : [],
    }),
    updateUniFiVenueSettings: build.mutation<
      UnifiLocation,
      { orgId: string; data: UnifiLocation }
    >({
      query: ({ orgId, data }) => ({
        url: `/integration/${orgId}/unifi/${data.unifiControllerId}/location/${data.serial}`,
        method: 'put',
        data,
      }),
      transformResponse(data: BackendResponseType<UnifiLocation>) {
        return data.message
      },
      invalidatesTags: (results) => [
        { type: 'UniFiLocation', id: results.unifiId },
      ],
    }),
    getUniFiControllerSites: build.query<
      UnifiSite[],
      { orgId: string; controllerId: string }
    >({
      query: ({ orgId, controllerId }) => ({
        url: `/integration/${orgId}/unifi/${controllerId}/location`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<UnifiSite[]>) {
        return Array.isArray(data.message) ? data.message : []
      },
    }),
    createUniFiController: build.mutation<
      UnifiController,
      { orgId: string; data: UnifiController }
    >({
      query: ({ orgId, data }) => ({
        url: `/integration/${orgId}/unifi`,
        method: 'post',
        data,
      }),
      transformResponse(data: BackendResponseType<UnifiController>) {
        return data.message
      },
      invalidatesTags: () => [{ type: 'UniFiControllers', id: 'LIST' }],
    }),
    updateUniFiController: build.mutation<
      UnifiController,
      { orgId: string; data: UnifiController }
    >({
      query: ({ orgId, data }) => ({
        url: `/integration/${orgId}/unifi/${data.id}`,
        method: 'put',
        data,
      }),
      transformResponse(data: BackendResponseType<UnifiController>) {
        return data.message
      },
      invalidatesTags: () => [{ type: 'UniFiControllers', id: 'LIST' }],
    }),
    removeUniFiController: build.mutation<
      string,
      { orgId: string; controllerId: string }
    >({
      query: ({ orgId, controllerId }) => ({
        url: `/integration/${orgId}/unifi/${controllerId}`,
        method: 'delete',
      }),
      transformResponse() {
        return 'ok'
      },
      invalidatesTags: () => [{ type: 'UniFiControllers', id: 'LIST' }],
    }),
    getDataSources: build.query<InteractionDataSourcesType[], string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/interactions/sources`,
        method: 'get',
      }),
    }),
    uploadCsv: build.mutation<CsvResponseType, CsvRequestType>({
      query: ({ orgId, serials, data_source, default_region, data }) => ({
        url: buildUrl(`/organisations/${orgId}/import/csv`, {
          serials,
          'data-source': data_source,
          default_region,
        }),

        method: 'post',
        headers: {
          'Content-Type': 'text/csv',
        },
        timeout: 1000 * 60,
        data,
      }),
      transformResponse(data: BackendResponseType<CsvResponseType>) {
        message.success(
          `Data imported, added ${data.message.recordsSaved} records`
        )
        return data.message
      },
      transformErrorResponse: (error) => {
        message.warning('Something went wrong')
        return error
      },
      invalidatesTags: ['Csv'],
    }),

    getAllAccessibleUsers: build.query<OrganisationUserType[], undefined>({
      query: () => ({
        url: `/organisations/allusers`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<OrganisationUserType[]>) {
        return data.message
      },
      providesTags: (results) => [
        ...(results || []).map((item) => ({
          type: 'OrganisationUsers' as const,
          id: item.uid,
        })),
        { type: 'OrganisationUsers', id: 'LIST' },
      ],
    }),
    getOrganisationUsers: build.query<OrganisationUserType[], string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/users`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<OrganisationUserType[]>) {
        return data.message
      },

      providesTags: (results) => [
        ...(results || []).map((item) => ({
          type: 'OrganisationUsers' as const,
          id: item.uid,
        })),
        { type: 'OrganisationUsers', id: 'LIST' },
      ],
    }),
    updateOrganisationUsers: build.mutation<
      OrganisationUserType,
      {
        orgId
        data: {
          role: number
          email?: string
          uid?: string
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/users`,
        method: 'post',
        data,
      }),
      transformResponse(data: BackendResponseType<OrganisationUserType>) {
        return data.message
      },
      invalidatesTags: (result) => [
        { id: result.uid, type: 'OrganisationUsers' },
        { type: 'OrganisationUsers', id: 'LIST' },
      ],
    }),
    removeOrganisationUser: build.mutation<
      OrganisationUserType,
      { orgId: string; uid: string }
    >({
      query: ({ orgId, uid }) => ({
        url: `/organisations/${orgId}/users/${uid}`,
        method: 'delete',
      }),
      transformResponse(data: BackendResponseType<OrganisationUserType>) {
        return data.message
      },
      invalidatesTags: (_result, _error) => [
        { id: _result.uid, type: 'OrganisationUsers' },
      ],
    }),

    getOrganisationUsersVenues: build.query<
      User,
      { orgId: string; uid: string }
    >({
      query: ({ orgId, uid }) => ({
        url: buildUrl(`/organisations/${orgId}/users/locations`, { uid }),
        method: 'get',
      }),

      providesTags: ['OrganisationUsersVenues'],
    }),

    updateOrganisationUsersVenues: build.mutation<
      User,
      {
        orgId: string
        data: {
          uid: string
          access: string[]
          role: User['role']
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/users/locations`,
        method: 'put',
        data,
      }),
      invalidatesTags: ['OrganisationUsersVenues'],
    }),

    getOrganisationForms: build.query<FormType[], string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/form`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<FormType[]>) {
        return data.message
      },
      providesTags: (results) => {
        if (!results) return []
        return [
          ...(results || []).map((item) => ({
            type: 'Forms' as const,
            id: item.id,
          })),
          { type: 'Forms', id: 'LIST' },
        ]
      },
    }),
    getOrganisationForm: build.query<FormType, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/form/${id}`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<FormType>) {
        return data.message
      },
      providesTags: (results) => [{ type: 'Forms', id: results?.id }],
    }),

    createOrganisationForm: build.mutation<
      FormType,
      { data: Partial<FormType>; orgId: string }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/form`,
        method: 'post',
        data,
      }),
      transformResponse(data: BackendResponseType<FormType>) {
        return data.message
      },
      invalidatesTags: () => [{ type: 'Forms', id: 'LIST' }],
    }),
    updateOrganisationForm: build.mutation<
      FormType,
      { data: FormType; orgId: string }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/form/${data.id}`,
        method: 'put',
        data,
      }),
      transformResponse(data: BackendResponseType<FormType>) {
        return data.message
      },
      invalidatesTags: (results) => [{ type: 'Forms', id: results.id }],
    }),
    deleteOrganisationForm: build.mutation<
      string,
      { id: string; orgId: string }
    >({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/form/${id}`,
        method: 'delete',
      }),

      invalidatesTags: () => [{ type: 'Forms', id: 'LIST' }],
    }),

    /**  */
    getCustomQuestionEntries: build.query<
      PaginationRepsonse<CustomQuestionEntriesType>,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/custom-question-entries`,
        method: 'get',
      }),
      providesTags: (results) => [
        ...(results?.body ?? []).map((item) => ({
          type: 'CustomQuestionsEntries' as const,
          id: item.id,
        })),
        { type: 'CustomQuestionsEntries', id: 'LIST' },
      ],
    }),
    /**  */
    createCustomQuestionEntries: build.mutation<
      CustomQuestionEntriesType,
      {
        orgId: string
        data: {
          custom_question_id: string
          form_id: string
          position: number
          required: boolean
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/custom-question-entries`,
        method: 'post',
        data,
      }),
      invalidatesTags: (_1, _2, { data }) => [
        { type: 'CustomQuestionsEntries', id: 'LIST' },
        {
          type: 'Forms',
          id: data.form_id,
        },
      ],
    }),
    /**  */
    deleteCustomQuestionEntries: build.mutation<
      CustomQuestionEntriesType,
      {
        orgId: string
        id: string
        form_id: string
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/custom-question-entries/${id}`,
        method: 'delete',
      }),
      invalidatesTags: (_1, _2, { form_id }) => {
        return [
          { type: 'CustomQuestionsEntries', id: 'LIST' },
          {
            type: 'Forms',
            id: 'LIST',
          },
          {
            type: 'Forms',
            id: form_id,
          },
        ]
      },
    }),
    /**  */
    sortCustomQuestionEntries: build.mutation<
      { message: string },
      {
        orgId: string
        data: { id: string; position: number }[]
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/custom-question-entries/positions`,
        method: 'put',
        data,
      }),
      invalidatesTags: () => {
        return [
          { type: 'CustomQuestionsEntries', id: 'LIST' },
          {
            type: 'Forms',
            id: 'LIST',
          },
        ]
      },
    }),

    /**  */
    getCustomQuestions: build.query<
      PaginationRepsonse<CustomQuestionType>,
      string
    >({
      query: (orgId) => ({
        url: `/organisations/${orgId}/custom-questions`,
        method: 'get',
      }),
      providesTags: (results) => [
        ...(results.body ?? []).map((item) => ({
          type: 'CustomQuestions' as const,
          id: item.id,
        })),
        { type: 'CustomQuestions', id: 'LIST' },
      ],
    }),

    createCustomQuestions: build.mutation<
      CustomQuestionType,
      {
        orgId
        data: {
          question: string
          question_type: CustomQuestionType['question_type']
          options: string[]
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/custom-questions`,
        method: 'post',
        data,
      }),
      transformResponse(data: {
        message: string
        custom_question: CustomQuestionType
      }) {
        return data.custom_question
      },
      invalidatesTags: [{ type: 'CustomQuestions', id: 'LIST' }],
    }),
    /**
     * GIFTING START
     */
    getGiftCards: build.query<
      GiftSettings[],
      {
        orgId
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/gifting`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<GiftSettings[]>) {
        console.log({ data })
        return data.message ?? []
      },
      transformErrorResponse: (error) => {
        console.log({ error })
        return error
      },
      providesTags: (results) =>
        results ? [{ type: 'GiftCards', id: 'LIST' }] : [],
    }),
    getGiftCard: build.query<
      GiftSettings,
      {
        orgId
        id
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/gifting/schemes/${id}`,
        method: 'get',
      }),
      transformResponse(data: BackendResponseType<GiftSettings>) {
        return data.message
      },
      providesTags: (results) => [{ type: 'GiftCards', id: results?.id }],
    }),
    createGiftCard: build.mutation<
      GiftSettings,
      {
        orgId
        data: Partial<GiftSettings>
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/gifting`,
        method: 'post',
        data,
      }),
      transformResponse(data: BackendResponseType<GiftSettings>) {
        message.success('Gift card created')
        return data.message
      },
      invalidatesTags: () => [{ type: 'GiftCards', id: 'LIST' }],
    }),
    updateGiftCard: build.mutation<
      GiftSettings,
      {
        orgId: string
        data: GiftSettings
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/gifting/schemes/${data.id}`,
        method: 'put',
        data,
      }),
      transformResponse(data: BackendResponseType<GiftSettings>) {
        message.success('Gift card updated')
        return data.message
      },
      invalidatesTags: (result) => [{ type: 'GiftCards', id: result.id }],
    }),

    getGiftActivations: build.query<
      GiftActivation,
      {
        orgId: string
        query: {
          offset: number
          limit: number
          term: string | null
          status: GiftActivationSingle['status'] | null
        }
      }
    >({
      query: ({ orgId, query }) => ({
        url: buildUrl(`/organisations/${orgId}/gifting/search`, query),
        method: 'get',
      }),
      transformResponse(data: GiftActivation) {
        return data ?? initPage
      },
      providesTags: (results) => [
        ...(results?.body || []).map((item) => ({
          type: 'GiftCardsActivations' as const,
          id: item.id,
        })),
        { type: 'GiftCardsActivations', id: 'LIST' },
      ],
    }),
    getGiftTotals: build.query<
      GiftReportChartType,
      {
        orgId: string
        startDate: Date
        endDate: Date
      }
    >({
      query: ({ orgId, startDate, endDate }) => ({
        url: buildUrl(`/organisations/${orgId}/gifting/totals`, {
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
        }),
        method: 'get',
      }),
      transformResponse(data: GiftReportChartType) {
        return data
      },
    }),
    getGiftReports: build.query<
      GiftingReports,
      {
        orgId: string
        startDate: Date
        endDate: Date
      }
    >({
      query: ({ orgId, startDate, endDate }) => ({
        url: buildUrl(`/organisations/${orgId}/gifting/reporting`, {
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
        }),
        method: 'get',
      }),
      transformResponse(data: GiftingReports) {
        return data
      },
    }),

    createGiftActivation: build.mutation<
      GiftActivationSingle,
      {
        orgId: string
        cardId: string
        data: GiftActivationMutation
      }
    >({
      query: ({ cardId, orgId, data }) => ({
        url: `/organisations/${orgId}/gifting/cards/${cardId}`,
        method: 'post',
        data,
      }),
      transformResponse(data: BackendResponseType<GiftActivationSingle>) {
        message.success('Gift card created')
        return data.message
      },
      transformErrorResponse() {
        message.error('Gift card cannot be created')
      },
      invalidatesTags: () => [{ type: 'GiftCardsActivations', id: 'LIST' }],
    }),

    createGiftRefund: build.mutation<
      GiftActivationSingle,
      {
        gift: GiftActivationSingle
      }
    >({
      query: ({ gift }) => ({
        url: `/gifting/cards/${gift.id}/refund`,
        method: 'post',
      }),
      transformResponse(data: BackendResponseType<GiftActivationSingle>) {
        message.success('Gift card refunded')
        return data.message
      },
      transformErrorResponse() {
        message.error('Gift card cannot be refunded')
      },
      invalidatesTags: (item) => [
        { type: 'GiftCardsActivations', id: item.id },
      ],
    }),
    redeemUserGiftCard: build.mutation<
      GiftActivationSingle,
      {
        id: string
      }
    >({
      query: ({ id }) => ({
        url: `/gifting/cards/${id}/redeem`,
        method: 'post',
      }),
      transformResponse(data: BackendResponseType<GiftActivationSingle>) {
        message.success('Gift card redeemed')
        return data.message
      },
      transformErrorResponse() {
        message.error('Gift card cannot be redeemed')
      },
      invalidatesTags: (item) => [
        { type: 'GiftCardsActivations', id: item.id },
        { type: 'GiftCardsActivations', id: 'LIST' },
      ],
    }),
    changeGiftOwner: build.mutation<
      GiftActivationSingle,
      {
        orgId: string
        gift: GiftActivationSingle
        profile: CandidateProfileType
      }
    >({
      query: ({ gift, profile: data, orgId }) => ({
        url: `/organisations/${orgId}/gifting/cards/${gift.id}/owner`,
        method: 'put',
        data,
      }),
      transformResponse(data: GiftActivationSingle) {
        message.success('Gift card owner changed')
        return data
      },
      transformErrorResponse() {
        message.error('Could not change owner of card')
      },
      invalidatesTags: (item) => [
        { type: 'GiftCardsActivations', id: item.id },
      ],
    }),
    /**
     * LOYALTY START
     */
    getLoyaltySchemes: build.query<
      LoyaltySchemeType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes`,
        method: 'get',
      }),

      providesTags: (items) => [
        ...(items || []).map((item) => ({
          type: 'LoyaltySchemes' as const,
          id: item?.id,
        })),
        { id: 'LIST', type: 'LoyaltySchemes' },
      ],
    }),

    createLoyaltyScheme: build.mutation<
      LoyaltySchemeType,
      {
        orgId: string
        scheme: Partial<LoyaltySchemeType>
      }
    >({
      query: ({ orgId, scheme }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes`,
        method: 'post',
        data: scheme,
      }),

      invalidatesTags: () => [{ id: 'LIST', type: 'LoyaltySchemes' }],
    }),
    updateLoyaltyScheme: build.mutation<
      LoyaltySchemeType,
      {
        orgId: string
        scheme: LoyaltySchemeType
      }
    >({
      query: ({ orgId, scheme: s }) => {
        const scheme = { ...s }
        Object.keys(scheme).forEach(
          (key) =>
            scheme[key as keyof LoyaltySchemeType] === null &&
            delete scheme[key as keyof LoyaltySchemeType]
        )

        return {
          url: `/organisations/${orgId}/loyalty/stamp/schemes/${scheme.id}`,
          method: 'put',
          data: scheme,
        }
      },

      transformResponse: (response: LoyaltySchemeType) => {
        message.success('Scheme updated')

        return response
      },
      invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemes' }],
    }),
    deleteLoyaltyScheme: build.mutation<
      LoyaltySchemeType,
      {
        orgId: string
        scheme: LoyaltySchemeType
      }
    >({
      query: ({ orgId, scheme }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${scheme.id}`,
        method: 'delete',
      }),

      invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemes' }],
    }),

    getLoyaltyScheme: build.query<
      LoyaltySchemeType,
      {
        orgId: string
        schemeId: string
      }
    >({
      query: ({ orgId, schemeId }) => ({
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}`,
        method: 'get',
      }),
      providesTags: (item) => [{ id: item?.id, type: 'LoyaltySchemes' }],
    }),

    getLoyaltySchemeUsers: build.query<
      LoyaltyDataPaginationType,
      {
        orgId: string
        schemeId: string
        page: PagenationRequestType
      }
    >({
      query: ({ orgId, schemeId, page }) => ({
        method: 'get',
        url: buildUrl(
          `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/users`,
          page
        ),
      }),
      // providesTags: (item) => [{ id: item.id, type: 'LoyaltySchemes' }],
    }),

    getLoyaltyTags: build.query<
      LoyaltySchemeTagType[],
      {
        orgId: string
        schemeId: string
      }
    >({
      query: ({ orgId, schemeId }) => ({
        method: 'get',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/secondary-id`,
      }),
      providesTags: (items) => [
        ...items.map((item) => ({
          id: item.id,
          type: 'LoyaltySchemesTags' as const,
        })),
        { id: 'LIST', type: 'LoyaltySchemesTags' },
      ],
    }),
    createLoyaltyTags: build.mutation<
      LoyaltySchemeTagType,
      {
        orgId: string
        schemeId: string
        tag: Partial<LoyaltySchemeTagType>
      }
    >({
      query: ({ orgId, schemeId, tag }) => ({
        method: 'post',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/secondary-id`,
        data: tag,
      }),
      invalidatesTags: () => [{ id: 'LIST', type: 'LoyaltySchemesTags' }],
    }),
    updateLoyaltyTags: build.mutation<
      LoyaltySchemeTagType,
      {
        orgId: string
        tag: LoyaltySchemeTagType
      }
    >({
      query: ({ orgId, tag }) => ({
        method: 'put',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${tag.scheme_id}/secondary-id/${tag.id}`,
        data: tag,
      }),
      invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    getLoyaltyRedeptionUsers: build.query<
      PaginationRepsonse<RedemptionType>,
      {
        orgId: string
        schemeId: string
        page: PagenationRequestType
      }
    >({
      query: ({ orgId, schemeId, page }) => ({
        method: 'get',
        url: buildUrl(
          `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/users/redemptions`,
          page
        ),
      }),
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    createLoyaltyStamps: build.mutation<
      PaginationRepsonse<RedemptionType>,
      {
        orgId: string
        schemeId: string
        profileId: number
        stampsQty: number
      }
    >({
      query: ({ orgId, schemeId, stampsQty, profileId }) => ({
        method: 'post',
        url: `/organisations/${orgId}/loyalty/stamp/schemes/${schemeId}/users/${profileId}/stamps`,
        data: { stamps: stampsQty },
      }),
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),

    /**
     * REVIEWS START
     */
    getReviewPages: build.query<
      PaginationRepsonse<ReviewSettings>,
      {
        orgId: string
        page: PagenationRequestType
      }
    >({
      query: ({ orgId, page }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/reviews/settings`, page),
      }),
      providesTags: (items) => [
        ...items.body.map((item) => ({
          id: item.id,
          type: 'ReviewPages' as const,
        })),
        { id: 'LIST', type: 'ReviewPages' },
      ],
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    getReviewPage: build.query<
      ReviewSettings,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        method: 'get',
        url: `/organisations/${orgId}/reviews/settings/${id}`,
      }),
      providesTags: (item) => [{ id: item.id, type: 'ReviewPages' }],
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    deleteReviewPage: build.mutation<
      ReviewSettings,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/reviews/settings/${id}`,
        method: 'delete',
      }),
      invalidatesTags: (item) => [{ id: item.id, type: 'ReviewPages' }],
      // invalidatesTags: (item) => [{ id: item.id, type: 'LoyaltySchemesTags' }],
    }),
    getReviewKeywords: build.query<
      ReviewComprehendKeyword[],
      {
        orgId: string
        pageId: string
      }
    >({
      query: ({ orgId, pageId }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/reviews/sentiment`, { pageId }),
      }),
    }),
    createReviewPage: build.mutation<
      ReviewSettings,
      {
        orgId: string
        page: Partial<ReviewSettings>
      }
    >({
      query: ({ orgId, page }) => ({
        url: `/organisations/${orgId}/reviews/settings`,
        method: 'post',
        data: page,
      }),
      invalidatesTags: () => [{ id: 'LIST', type: 'ReviewPages' }],
    }),
    updateReviewPage: build.mutation<
      ReviewSettings,
      {
        orgId: string
        page: ReviewSettings
      }
    >({
      query: ({ orgId, page }) => ({
        url: `/organisations/${orgId}/reviews/settings/${page.id}`,
        method: 'put',
        data: page,
      }),
      invalidatesTags: (item) => [{ id: item.id, type: 'ReviewPages' }],
    }),

    getReviewReports: build.query<
      ReviewsReports,
      {
        orgId: string
        params: DefaultQueryType
        pageId: string
      }
    >({
      query: ({ orgId, params, pageId }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/reviews/reports`, {
          ...params,
          page_id: pageId,
        }),
      }),
    }),

    getUserReview: build.query<
      UserReview,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        method: 'get',
        url: `/organisations/${orgId}/reviews/responses/${id}`,
      }),
      providesTags: (item) => [
        {
          id: item?.id,
          type: 'ReviewResponses',
        },
      ],
    }),
    getReviewsResponses: build.query<
      ServiceCursorPaginatedResponseType<UserReview>,
      {
        orgId: string
        params: UserReviewQuery
      }
    >({
      query: ({ orgId, params }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/reviews/responses`, params),
      }),
      providesTags: (items) => [
        ...(items?.data ?? []).map((item) => ({
          id: item.id,
          type: 'ReviewResponses' as const,
        })),
        { id: 'LIST', type: 'ReviewResponses' },
      ],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        console.log({ queryArgs, endpointName })
        return JSON.stringify({
          orgId: queryArgs.orgId,
          params: {
            ...queryArgs.params,
            cursor: '',
          },
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.links = newItems.links
        currentCache.meta = newItems.meta

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })
      },
      forceRefetch({ currentArg, previousArg }) {
        console.log(
          { currentArg, previousArg },
          'queryArgs',
          isEqual(currentArg?.params, previousArg?.params)
        )
        return !isEqual(currentArg?.params, previousArg?.params)
        //  return currentArg !== previousArg
      },
    }),
    updateReviewDone: build.mutation<
      UserReview,
      {
        orgId: string

        pageId: string
        done: boolean
      }
    >({
      query: ({ orgId, done, pageId }) => ({
        method: 'put',
        url: `/organisations/${orgId}/reviews/responses/${pageId}`,
        data: { done },
      }),

      invalidatesTags: (item) => [
        { id: item.id, type: 'ReviewResponses' },
        { id: 'LIST', type: 'ReviewResponses' },
      ],
    }),
    /**
     * REVIEWS END
     */

    getRegistrationInteractions: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistration>,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(
          `/organisations/${orgId}/interactions/registrations`,
          query
        ),
      }),
    }),

    getRegistrationInteractionsTotals: build.query<
      OrganisationRegistrationReportType,
      {
        orgId: string
        query: Omit<InteractionRequestType, 'limit'>
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/interactions/totals`, query),
      }),
    }),

    getMarketingOptOuts: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistration>,
      {
        orgId: string
        query: PagenationRequestType
        email?: string
      }
    >({
      query: ({ orgId, query, email }) => ({
        method: 'get',
        url: buildUrl(`/marketing/${orgId}/opt-out`, { ...query, email }),
      }),
      providesTags: (items) => [
        ...items.data.map((item) => ({
          id: item.id,
          type: 'MarketingOptOuts' as const,
        })),
        {
          id: 'LIST',
          type: 'MarketingOptOuts',
        },
      ],
    }),
    createMarketingOptOuts: build.mutation<
      OrganizationRegistration,
      {
        orgId: string
        email: string
        email_opt_in: boolean
        sms_opt_in: boolean
      }
    >({
      query: ({ orgId, email, email_opt_in, sms_opt_in }) => ({
        method: 'post',
        url: buildUrl(`/public/organizations/${orgId}/opt`, {
          email,
          email_opt_in,
          sms_opt_in,
        }),
      }),
      invalidatesTags: (item) => [
        { id: item?.id, type: 'MarketingOptOuts' },
        { id: 'LIST', type: 'MarketingOptOuts' },
      ],
    }),

    //OrganizationRegistration

    /**
     * ----
     * SEGMENTS START
     * ----
     */

    getSegments: build.query<
      PaginationRepsonse<Segment>,
      {
        orgId: string
      }
    >({
      async queryFn({ orgId }, _queryApi, _extra, fetcher) {
        const segments = await fetcher({
          method: 'get',
          url: `/organisations/${orgId}/segments?limit=200`,
        })
        const allReach = await fetcher({
          method: 'post',
          url: `/organisations/${orgId}/segments/reach`,
          data: { root: null },
        })

        const data = segments.data as PaginationRepsonse<Segment>
        if (data && allReach.data) {
          data.body.sort((a, b) => b.reach.reach.all - a.reach.reach.all)

          data.body.unshift({
            ...allSegment,
            reach: allReach.data as Segment['reach'],
          })
        }

        return segments.data
          ? {
              data,
            }
          : { error: segments.error as FetchBaseQueryError }
      },

      providesTags: (items) => [
        ...items.body.map((item) => ({
          id: item.id,
          type: 'Segments' as const,
        })),
        { type: 'Segments', id: 'LIST' },
      ],
      /*
      transformResponse: (items: PaginationRepsonse<Segment>) => {
        //items.body.sort((a, b) => b.reach.reach.all - a.reach.reach.all)
        // items.body.push(allSegment)
        return items
      },
      */
    }),
    getSegment: build.query<
      Segment,
      {
        orgId: string
        id: string
      }
    >({
      async queryFn({ orgId, id }, _queryApi, _extra, fetcher) {
        const segmentResponse = await fetcher({
          method: 'get',
          url: `/organisations/${orgId}/segments/${id}`,
        })

        const reachResponse = await fetcher({
          url: `/organisations/${orgId}/segments/${id}/reach`,
          method: 'get',
        })

        if (!segmentResponse.data) {
          return { error: segmentResponse.error }
        }

        console.log({ reachResponse, segmentResponse })
        const segment = segmentResponse.data as Segment
        segment.reach = reachResponse.data as SegmentReach
        segment.version = segment.reach.segment_version
        return { data: segment }
      },

      providesTags: (item) =>
        item?.id ? [{ type: 'Segments', id: item.id }] : [],
    }),
    createSegment: build.mutation<
      Segment,
      {
        orgId: string
        segment: Partial<Segment>
      }
    >({
      query: ({ orgId, segment }) => {
        const sendNode: any = parseRoot(segment)
        if (!sendNode.segment.is_venue_segment) {
          sendNode.segment.serials = []
        }
        return {
          method: 'post',
          url: `/organisations/${orgId}/segments`,
          data: sendNode,
        }
      },
      invalidatesTags: () => [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: Segment) => {
        message.success(`${item.name} created`)
        return item
      },
    }),
    updateSegment: build.mutation<
      Segment,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => {
        const sendNode: any = parseRoot(segment)
        if (!sendNode.segment.is_venue_segment) {
          sendNode.segment.serials = []
        }

        return {
          method: 'put',
          url: `/organisations/${orgId}/segments/${segment.id}`,
          data: sendNode,
        }
      },
      invalidatesTags: (item) =>
        item?.id ? [{ type: 'Segments', id: item.id }] : [],
      transformResponse: (item: Segment) => {
        message.success(`${item.name} updated`)
        return item
      },
    }),
    getSegmentPreview: build.query<
      PaginationRepsonse<SegmentPreview>,
      {
        orgId: string
        query: SegmentPreviewQueryType
      }
    >({
      query: ({ orgId, query }) => {
        const { limit, offset, should_cache, serials, node } = query

        let root: any = node

        if (node.nodes.length === 1) {
          root = node.nodes[0]
        }

        if (node.nodes.length === 0) {
          root = null
        }

        return {
          method: 'post',
          url: buildUrl(`/organisations/${orgId}/segments/v2/preview`, {
            //  'remove-segment-ordering': 'yes',

            limit,
            offset,
            should_cache,
          }),
          data: { root, serials },
        }
      },
    }),
    getSegmentPreviewReach: build.query<
      SegmentReach,
      {
        orgId: string
        query: SegmentPreviewTotalQueryType
      }
    >({
      query: ({ orgId, query }) => {
        const { serials, node } = query

        let root: any = node

        if (node.nodes.length === 1) {
          root = node.nodes[0]
        }

        if (node.nodes.length === 0) {
          root = null
        }

        return {
          method: 'post',
          url: buildUrl(`/organisations/${orgId}/segments/reach`, {
            'remove-segment-ordering': 'yes',
            'is-visit-override': 'hellyea',
          }),
          data: { root, serials },
        }
      },
    }),
    getMetadataSegments: build.query<
      SegmentType,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/segments/metadata`,
        method: 'get',
      }),
      transformResponse(res: SegmentType) {
        const fieldsArray = Object.entries(res.fields).map(
          ([_key, value]) => value
        )

        return { ...res, fieldsArray }
      },
    }),
    //Apears getDataInSegment to be unused...
    getDataInSegment: build.query<
      PaginationRepsonse<SegmentPreview>,
      {
        orgId: string
        segmentId: string
        query: { offset: number; limit: number }
      }
    >({
      query: ({ orgId, segmentId, query }) => ({
        url: buildUrl(
          `/organisations/${orgId}/segments/${segmentId}/data`,
          query
        ),
        method: 'get',
      }),
    }),
    getSegmentReach: build.mutation<
      SegmentReach,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => ({
        url: `/organisations/${orgId}/segments/${segment.id}/reach`,
        method: 'get',
      }),
      invalidatesTags: (_item, _other, { segment }) => [
        //  { type: 'Segments', id: 'LIST' },
        { type: 'Segments', id: segment.id },
      ],
    }),

    deleteSegment: build.mutation<
      unknown,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => ({
        url: `/organisations/${orgId}/segments/${segment.id}?version=${segment.version}`,
        method: 'delete',
      }),
      invalidatesTags: [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: unknown, _item2, { segment }) => {
        message.success(`${segment.name} deleted`)
        return item
      },
    }),
    /**
     * ----
     * SEGMENTS END
     * ----
     */
    /**
     * ----
     * TAGS START
     * ----
     */
    getTags: build.query<
      TagType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/crm/tags`,
        method: 'get',
      }),
      providesTags: (items) => [
        ...items.map((item) => ({
          id: item.id,
          type: 'ProfileTags' as const,
        })),
        { id: 'LIST', type: 'ProfileTags' },
      ],
      transformResponse: (items: TagType[]) =>
        [...items].sort((a, b) => a.name.localeCompare(b.name)),
    }),
    deleteTag: build.mutation<
      boolean,
      {
        orgId: string
        tag: TagType
      }
    >({
      query: ({ orgId, tag }) => ({
        url: `/organisations/${orgId}/crm/tags/${tag.id}`,
        method: 'delete',
      }),
      invalidatesTags: [{ id: 'LIST', type: 'ProfileTags' }],
      transformResponse: (_1, _2, { tag }) => {
        message.success(`${tag.name} deleted`)
        return true
      },
    }),
    updateTag: build.mutation<
      TagType,
      {
        orgId: string
        tag: TagType
      }
    >({
      query: ({ orgId, tag }) => ({
        url: `/organisations/${orgId}/crm/tags/${tag.id}`,
        method: 'put',
        data: {
          name: tag.name,
        },
      }),
      invalidatesTags: [{ id: 'LIST', type: 'ProfileTags' }],
      transformResponse: (item: TagType) => {
        message.success(`${item.name} updated`)
        return item
      },
    }),
    createTag: build.mutation<
      TagType,
      {
        orgId: string
        name: string
      }
    >({
      query: ({ orgId, name }) => ({
        url: `/organisations/${orgId}/crm/tags`,
        method: 'post',
        data: { name },
      }),
      invalidatesTags: [{ id: 'LIST', type: 'ProfileTags' }],
      transformResponse: (item: TagType) => {
        message.success(`${item.name} created`)
        return item
      },
    }),
    /**
     * ----
     * TAGS END
     * ----
     */

    /**
     * ----
     * USER QUERIES START
     * ---
     */
    getUsers: build.query<
      ServiceCursorPaginatedResponseType<EndUser & { org_reg_id: string }>,
      {
        orgId: string
        search?: string
        limit?: number
        cursor?: string
      }
    >({
      query: ({ orgId, search = '', limit = 5, cursor = '' }) => ({
        url: `/organisations/${orgId}/user-profiles/search?search=${search}&limit=${limit}&cursor=${cursor}`,
        method: 'get',
      }),
    }),
    getUserReviews: build.query<
      ServiceCursorPaginatedResponseType<UserReview>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(`/organisations/${orgId}/user-profiles/${id}/review`, {
          cursor,
        }),
        method: 'get',
      }),
    }),
    getUserTransactions: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistrationTransactionType>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(
          `/organisations/${orgId}/user-profiles/${id}/transactions`,
          { cursor }
        ),
        method: 'get',
      }),
    }),
    getUserBookings: build.query<
      ServiceCursorPaginatedResponseType<OrganizationUserBookingType>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(`/organisations/${orgId}/user-profiles/${id}/booking`, {
          cursor,
        }),
        method: 'get',
      }),
    }),
    getUserWifiLogins: build.query<
      ServiceCursorPaginatedResponseType<WiFiSessionDataType>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(
          `/organisations/${orgId}/user-profiles/${id}/wifi-login`,
          {
            cursor,
          }
        ),
        method: 'get',
      }),
    }),
    getUserRegistrations: build.query<
      ServiceCursorPaginatedResponseType<ProfileInteractionType>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(
          `/organisations/${orgId}/user-profiles/${id}/registrations`,
          {
            cursor,
          }
        ),
        method: 'get',
      }),
    }),
    getUserLoyalty: build.query<
      ServiceCursorPaginatedResponseType<LoyaltyDataType>,
      {
        orgId: string
        id: string
        cursor: string
      }
    >({
      query: ({ orgId, cursor, id }) => ({
        url: buildUrl(`/organisations/${orgId}/user-profiles/${id}/loyalty`, {
          cursor,
        }),
        method: 'get',
      }),
    }),
    getUserById: build.query<
      OrganizationRegistrationType,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/user-profiles/${id}/profile`,
        method: 'get',
      }),
      providesTags: (item) =>
        item?.profileId ? [{ id: item.profileId, type: 'EndUser' }] : [],
    }),
    addTagToUsers: build.mutation<
      unknown,
      { orgId: string; tag: TagType; profileIds: string[] | number[] }
    >({
      query: ({ orgId, tag, profileIds }) => ({
        url: `/organisations/${orgId}/crm/tags/profiles`,
        method: 'put',
        data: { name: tag.name, profiles: profileIds },
      }),
      invalidatesTags: (_1, _2, item) => [
        ...item.profileIds.map((item) => ({
          id: item.id,
          type: 'EndUser' as const,
        })),
        { id: 'LIST', type: 'EndUser' },
      ],
      transformResponse(item: unknown) {
        message.success('Tag added from profile(s)')
        return item
      },
    }),
    removeTagFromUser: build.mutation<
      unknown,
      { tagId: string; orgId: string; profileId: string | number }
    >({
      query: ({ orgId, tagId, profileId }) => ({
        url: `/organisations/${orgId}/crm/users/${profileId}/tags/${tagId}`,
        method: 'delete',
      }),
      invalidatesTags: (_1, _2, item) => [
        { id: item.profileId, type: 'EndUser' },
        { id: 'LIST', type: 'EndUser' },
      ],
      transformResponse(item: unknown) {
        message.success('Tag removed from profile')
        return item
      },
    }),
    createEndUserInteraction: build.mutation<
      { profile: EndUser; newSerials: string[] },
      {
        profile: Partial<EndUser>
        serials: string[]
        orgId: string
        tags: string[]
        source: 'web' | 'in-venue-form'
      }
    >({
      query: ({ profile, orgId, serials = [], source = 'web', tags = [] }) => ({
        url: `/public/organizations/${orgId}/email-registration`,
        data: {
          ...profile,
          source,
          serials,
          tags,
          dataOptIn: true,
          emailOptIn: true,
          smsOptIn: true,
        },
        method: 'post',
      }),
      transformResponse: (data: { profile: EndUser; newSerials: string[] }) => {
        message.success('Customer created')
        return data
      },
      invalidatesTags: [{ id: 'LIST', type: 'Segments' }],
    }),
    /**
     * ----
     * USER QUERIES END
     * ---
     */

    getOriginReport: build.query<
      MappedProfileItem[],
      { serial: string; startDate: Date; endDate: Date; orgId: string }
    >({
      query: ({ orgId, startDate, endDate, serial }) => ({
        url: buildUrl(`/organisations/${orgId}/reports/origin`, {
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          serial,
        }),
        method: 'get',
      }),
    }),
  }),
})

export default backendApi
