import { Config } from './config.type'
import domain from './domain'

const config: Config = {
  appName: 'Stampede Product',
  prod: false,
  blog: `https://${domain}/blog/category/tips/feed/`,
  url: {
    api: '',
    selfservice: `https://api-selfservice.prd.${domain}`,
    accounts: `https://accounts.${domain}`,
    connect: `https://product.${domain}`,
    nearly: 'https://nearly.online',
    mailchimp: `https://mailchimp.${domain}`,
    calculator: '//pricing.stampede.ai/calc',
    reviews: 'https://reviews.stampede.ai',
    gifting: 'https://gifting.stampede.ai',
    forms: 'https://forms.stampede.ai',
    service: `https://service.${domain}`,
    urlGenerator: `https://url-generator.stg.${domain}`,
    bookingIntegration: `https://bookings-intergrations.prd.${domain}`,
    bookings: `https://api-bookings.stg.${domain}`,
    booking_url: `https://booking.${domain}`,
    app: `https://app.stage.${domain}`,
    notifications: `https://notifications.stg.${domain}`,
    reporting: `https://reporting.stg.${domain}`,
    inbox: `https://inbox.stg.${domain}`,
    morpheus: `https://morpheus.stg.${domain}`,
    inbox_email: '@stmpd-mail.com',
  },
  sockets: {
    booking_items: 'wss://tn67hcqv9c.execute-api.eu-west-1.amazonaws.com/stage',
    pusher_client_key: '9ab1d1e08c1b2f35727c',
  },
  auth: {
    clientId: '',
    clientSecret: '1234',
    redirectUri: '',
    scope: 'ALL',
  },
  localStorageKeys: {
    userCredentials: 'user-credentials',
  },
  googleMapsKey: 'AIzaSyAuKRd435IPeG6yP9zkiiHlAEWv4K_Moq8',
  language: 'en_GB',
  stripeKey: 'pk_test_FDv3YMxZU4H6WutGopfWYl7J',
  enableWhyDidYouUpdate: false,
  chargebee: {
    site: 'stampede',
    domain: `https://stampede.chargebee.com`,
    apiKey: 'live_qX2mUppiwjBH3UcdSwP6xUcu3nJpgfWz87',
  },
}

export default config
