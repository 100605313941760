import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import * as actions from './venue.actions'
import { VenueType } from './venue.type'
import message from 'common/message/message'
import { ServiceErrorHandler } from 'connect-types/backend/service'

export type VenueActions = ActionType<typeof actions>

export interface StateType {
  settings: VenueType | null
  loading: boolean
  venue_memory: { [key: string]: VenueType }
}

const initialState: StateType = {
  settings: null,
  loading: false,

  venue_memory: {},
}
const niceTypes = ['free', 'paid', 'hybrid']
/* tslint:disable cyclomatic-complexity */
const venueReducer: Reducer<StateType, VenueActions> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.updateWiFi4EuSettings.request): {
        draft.loading = true
        break
      }
      case getType(actions.updateWiFi4EuSettings.success): {
        draft.settings.wifi_4_eu = action.payload
        draft.venue_memory[draft.settings.serial].wifi_4_eu = action.payload
        draft.loading = false
        message.success('WiFi4EU saved')
        break
      }
      case getType(actions.updateWiFi4EuSettings.failure): {
        ServiceErrorHandler(action.payload)
        draft.loading = false
        break
      }

      case getType(actions.updateVenueSettingsLocal): {
        if (draft.settings === null) return

        const { key, value } = action.payload
        if (key === 'type') {
          draft.settings.nice_type = niceTypes[value as any]
        }
        draft.settings = { ...draft.settings, [key]: value }

        break
      }
    }
  })

export default venueReducer
