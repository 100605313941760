import { createAction } from 'typesafe-actions'
/**
 * GET Get a specific plan
 *
 * Path: /locations/{serial}/payments/plans/{planId}
 */

// FIXME: add in request and query params
export const locationsPaymentPlanRequest = createAction(
  'LOCATIONS_PAYMENT_PLAN_REQUEST',
  (resolve) => (serial: string, planId: string) => resolve({ serial, planId })
)

// FIXME: add in response object from saga
export const locationsPaymentPlanRequestSuccess = createAction(
  'LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const locationsPaymentPlanRequestFailure = createAction(
  'LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'locations payment plan request failed' })
)
/**
 * PUT Update a specific plan
 *
 * Path: /locations/{serial}/payments/plans/{planId}
 */

// FIXME: add in request and query params
export const updateLocationsPaymentPlanRequest = createAction(
  'UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST',
  (resolve) => (serial: string, planId: string) => resolve({ serial, planId })
)

// FIXME: add in response object from saga
export const updateLocationsPaymentPlanRequestSuccess = createAction(
  'UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const updateLocationsPaymentPlanRequestFailure = createAction(
  'UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations payment plan request failed' })
)
/**
 * DELETE Delete a specific plan
 *
 * Path: /locations/{serial}/payments/plans/{planId}
 */

// FIXME: add in request and query params
export const deleteLocationsPaymentPlanRequest = createAction(
  'DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST',
  (resolve) => (serial: string, planId: string) => resolve({ serial, planId })
)

// FIXME: add in response object from saga
export const deleteLocationsPaymentPlanRequestSuccess = createAction(
  'DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const deleteLocationsPaymentPlanRequestFailure = createAction(
  'DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete locations payment plan request failed' })
)
/**
 * GET Get location methods
 *
 * Path: /locations/{serial}/payments/method
 */

// FIXME: add in request and query params
export const locationsPaymentMethodRequest = createAction(
  'LOCATIONS_PAYMENT_METHOD_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsPaymentMethodRequestSuccess = createAction(
  'LOCATIONS_PAYMENT_METHOD_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const locationsPaymentMethodRequestFailure = createAction(
  'LOCATIONS_PAYMENT_METHOD_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'locations payment method request failed' })
)
/**
 * PUT Update payment methods for a transaction
 *
 * Path: /locations/{serial}/payments/method
 */

// FIXME: add in request and query params
export const updateLocationsPaymentMethodRequest = createAction(
  'UPDATE_LOCATIONS_PAYMENT_METHOD_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const updateLocationsPaymentMethodRequestSuccess = createAction(
  'UPDATE_LOCATIONS_PAYMENT_METHOD_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const updateLocationsPaymentMethodRequestFailure = createAction(
  'UPDATE_LOCATIONS_PAYMENT_METHOD_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations payment method request failed' })
)
