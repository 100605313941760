export interface NormalRoutesType {
  path?: string
  component: string
  children?: NormalRoutesType[]
  rootPath?: boolean
  index?: boolean
}

export const newRoutesArray: NormalRoutesType[] = [
  {
    path: '/',
    component: './pages/layout',
    children: [
      {
        index: true,
        component: './pages',
      },

      {
        path: 'marketing/senders',
        component: './pages/marketing/senders',
      },
      {
        path: 'marketing/email-verification',
        component: './pages/marketing/senders',
      },

      {
        path: 'code',
        component: './pages/oauth/code',
      },
      {
        path: 'oauth',
        component: './pages/oauth/layout',
        children: [
          {
            path: 'logout',
            component: './pages/oauth/logout',
          },
          {
            path: 'code',
            component: './pages/oauth/code',
          },
        ],
      },
      {
        path: 'admin',
        component: './pages/admin/layout',
        children: [
          {
            index: true,
            component: './pages/admin',
          },
          {
            path: 'venues',
            component: './pages/admin/venues',
          },
          {
            path: 'queue',
            component: './pages/admin/queue/layout',
            children: [
              {
                index: true,
                component: './pages/admin/queue',
              },
              {
                path: 'failed',
                component: './pages/admin/queue/failed',
              },
            ],
          },
          {
            path: 'marketing',
            component: './pages/admin/marketing/layout',
            children: [
              {
                index: true,
                component: './pages/admin/marketing',
              },
              {
                path: 'email',
                component: './pages/admin/marketing/email',
              },
            ],
          },
          {
            path: 'create',
            component: './pages/admin/create/layout',
            children: [
              {
                index: true,
                component: './pages/admin/create',
              },
              {
                path: 'account',
                component: './pages/admin/create/account',
              },
            ],
          },

          {
            path: 'organisations',
            component: './pages/admin/organisations/layout',
            children: [
              {
                index: true,
                component: './pages/admin/organisations',
              },
              {
                path: ':org_id',
                component: './pages/admin/organisations/:org_id/layout',
                children: [
                  {
                    index: true,
                    component: './pages/admin/organisations/:org_id',
                  },
                  {
                    path: 'report',
                    component: './pages/admin/organisations/:org_id/report',
                  },
                ],
              },
            ],
          },
          {
            path: 'customers',
            component: './pages/admin/customers/layout',
            children: [
              {
                index: true,
                component: './pages/admin/customers',
              },
              {
                path: ':id',
                component: './pages/admin/customers/:id',
              },
            ],
          },
        ],
      },
      {
        path: ':org_id',
        component: './pages/:org_id/layout',
        children: [
          {
            index: true,
            component: './pages/:org_id',
          },
          { path: 'quick-start', component: './pages/:org_id/quick-start' },
          { path: 'test', component: './pages/:org_id/test' },
          {
            path: 'self-serve',
            component: './pages/:org_id/self-serve/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/self-serve',
              },
              {
                path: 'csv',
                component: './pages/:org_id/marketing/data/upload',
              },
              {
                path: 'campaign/:id',
                component:
                  './pages/:org_id/marketing/email/campaigns/:id/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/marketing/email/campaigns/:id',
                  },
                  {
                    path: 'reports',
                    component:
                      './pages/:org_id/marketing/email/campaigns/:id/reports/layout',
                    children: [
                      {
                        index: true,
                        component:
                          './pages/:org_id/marketing/email/campaigns/:id/reports',
                      },
                      {
                        path: 'sent',
                        component:
                          './pages/:org_id/marketing/email/campaigns/:id/reports/sent',
                      },
                      {
                        path: 'events',
                        component:
                          './pages/:org_id/marketing/email/campaigns/:id/reports/events',
                      },
                      {
                        path: 'failed',
                        component:
                          './pages/:org_id/marketing/email/campaigns/:id/reports/failed',
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            path: 'customer',
            component: './pages/:org_id/customer/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/customer',
              },
              {
                path: ':id',
                component: './pages/:org_id/customer/:id/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/customer/:id',
                  },
                ],
              },
            ],
          },
          {
            path: 'example',
            component: './pages/:org_id/example/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/example',
              },
              {
                path: 'b',
                component: './pages/:org_id/example/page.b',
              },
              {
                path: 'c',
                component: './pages/:org_id/example/page.c',
              },
            ],
          },
          /**
           * ALL INBOX ROUTES HERE
           */
          {
            path: 'inbox',
            component: './pages/:org_id/inbox',
          },
          /**
           * ALL BOOKINGS ROUTES HERE
           */
          {
            path: 'bookings',
            component: './pages/:org_id/bookings/layout', //
            children: [
              {
                index: true,
                component: './pages/:org_id/bookings', //
              },
              {
                path: 'new/:serial',
                component: './pages/:org_id/bookings/new/:serial/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/bookings/new/:serial',
                  },
                ],
              },
              {
                path: ':serial',
                component: './pages/:org_id/bookings/:serial/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/bookings/:serial',
                  },
                  {
                    path: 'tables',
                    component: './pages/:org_id/bookings/:serial/tables',
                  },
                  {
                    path: 'design',
                    component: './pages/:org_id/bookings/:serial/design/layout',
                    children: [
                      {
                        component: './pages/:org_id/bookings/:serial/design',
                        index: true,
                      },
                      {
                        component:
                          './pages/:org_id/bookings/:serial/design/email/layout',
                        path: 'email',
                        children: [
                          {
                            component:
                              './pages/:org_id/bookings/:serial/design/email',
                            index: true,
                          },
                          {
                            component:
                              './pages/:org_id/bookings/:serial/design/email/single',
                            path: ':id',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'reports',
                    component: './pages/:org_id/bookings/:serial/reports',
                  },
                  {
                    path: 'view-bookings',
                    component:
                      './pages/:org_id/bookings/:serial/views/timeline',
                  },
                  {
                    path: 'view-bookings-list',
                    component: './pages/:org_id/bookings/:serial/views/list',
                  },
                  {
                    path: 'view-bookings-calendar',
                    component:
                      './pages/:org_id/bookings/:serial/views/calendar',
                  },
                  {
                    path: 'view-bookings-floorplan',
                    component:
                      './pages/:org_id/bookings/:serial/views/foh-floorplan-view',
                  },
                  {
                    path: 'configuration',
                    component:
                      './pages/:org_id/bookings/:serial/configuration/layout',
                    children: [
                      {
                        index: true,
                        component:
                          './pages/:org_id/bookings/:serial/configuration',
                      },
                      {
                        path: 'times',
                        component:
                          './pages/:org_id/bookings/:serial/configuration/time-options',
                      },
                      {
                        path: 'links',
                        component:
                          './pages/:org_id/bookings/:serial/configuration/links',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          /**
           * ALL MARKETING ROUTES HERE
           */

          {
            path: 'marketing',
            component: './pages/:org_id/marketing/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/marketing',
              },

              /**
               * NEW ROUTING START
               */
              {
                path: 'email',
                component: './pages/:org_id/marketing/email/layout',
                children: [
                  {
                    path: 'campaigns',
                    component:
                      './pages/:org_id/marketing/email/campaigns/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/marketing/email/campaigns',
                      },
                      {
                        path: ':id',
                        component:
                          './pages/:org_id/marketing/email/campaigns/:id/layout',
                        children: [
                          {
                            index: true,
                            component:
                              './pages/:org_id/marketing/email/campaigns/:id',
                          },
                          {
                            path: 'revisions',
                            component:
                              './pages/:org_id/marketing/email/campaigns/:id/revisions',
                          },
                          {
                            path: 'reports',
                            component:
                              './pages/:org_id/marketing/email/campaigns/:id/reports/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  './pages/:org_id/marketing/email/campaigns/:id/reports',
                              },
                              {
                                path: 'sent',
                                component:
                                  './pages/:org_id/marketing/email/campaigns/:id/reports/sent',
                              },
                              {
                                path: 'events',
                                component:
                                  './pages/:org_id/marketing/email/campaigns/:id/reports/events',
                              },
                              {
                                path: 'failed',
                                component:
                                  './pages/:org_id/marketing/email/campaigns/:id/reports/failed',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'templates',
                    component:
                      './pages/:org_id/marketing/email/templates/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/marketing/email/templates',
                      },
                      {
                        path: ':id',
                        component:
                          './pages/:org_id/marketing/email/templates/:id/layout',
                        children: [
                          {
                            index: true,
                            component:
                              './pages/:org_id/marketing/email/templates/:id',
                          },
                          {
                            component:
                              './pages/:org_id/marketing/email/templates/:id/revisions',
                            path: 'revisions',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'domains',
                    component: './pages/:org_id/marketing/email/domains',
                  },
                  {
                    path: 'audit',
                    component: './pages/:org_id/marketing/email/audit/layout',
                    children: [
                      {
                        component: './pages/:org_id/marketing/email/audit',
                        index: true,
                      },
                      {
                        component:
                          './pages/:org_id/marketing/email/audit/:id/layout',
                        path: ':id',
                        children: [
                          {
                            component:
                              './pages/:org_id/marketing/email/audit/:id',
                            index: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                component: './pages/:org_id/marketing/foh/layout',
                path: 'foh',
                children: [
                  {
                    component: './pages/:org_id/marketing/foh',
                    index: true,
                  },
                  {
                    component: './pages/:org_id/marketing/foh/menus',
                    path: 'menus',
                    children: [
                      {
                        path: ':id',
                        component: './components/Menu/MenuItem',
                      },
                    ],
                  },
                  {
                    path: 'vouchers',
                    component: './pages/:org_id/marketing/foh/vouchers/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/marketing/foh/vouchers',
                      },
                      {
                        path: ':id',
                        component:
                          './pages/:org_id/marketing/foh/vouchers/:id/layout',
                        children: [
                          {
                            index: true,
                            component:
                              './pages/:org_id/marketing/foh/vouchers/:id',
                          },
                          {
                            path: 'performance',
                            component:
                              './pages/:org_id/marketing/foh/vouchers/:id/performance',
                          },
                          {
                            path: 'imports',
                            component:
                              './pages/:org_id/marketing/foh/vouchers/:id/imports',
                          },
                          {
                            path: 'code-imports/:importId',
                            component:
                              './pages/:org_id/marketing/foh/vouchers/:id/code-imports/:importId/import',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                component: './pages/:org_id/marketing/website/layout',
                path: 'website',
                children: [
                  {
                    path: 'footprint',
                    component:
                      './pages/:org_id/marketing/website/footprint/layout',
                    children: [
                      {
                        index: true,
                        component:
                          './pages/:org_id/marketing/website/footprint',
                      },
                      {
                        path: 'customers',
                        component:
                          './pages/:org_id/marketing/website/footprint/customers',
                      },
                      {
                        path: 'settings',
                        component:
                          './pages/:org_id/marketing/website/footprint/settings',
                      },
                    ],
                  },
                  {
                    path: 'forms',
                    component: './pages/:org_id/marketing/website/forms',
                  },
                  {
                    path: 'links',
                    component: './pages/:org_id/marketing/website/links',
                  },
                ],
              },
              /**
               * NEW ROUTING END
               */
              {
                component: './pages/:org_id/marketing/sms/layout',
                path: 'sms',
                children: [
                  {
                    component: './pages/:org_id/marketing/sms/credits',
                    path: 'credits',
                  },
                  {
                    component: './pages/:org_id/marketing/sms/senders',
                    path: 'senders',
                  },
                  {
                    path: 'audit',
                    component: './pages/:org_id/marketing/sms/audit',
                    children: [
                      {
                        path: ':id/events',
                        component: './pages/:org_id/marketing/sms-audit/single',
                      },
                    ],
                  },
                  {
                    path: 'ledger',
                    component: './pages/:org_id/marketing/sms/ledger',
                  },
                  {
                    component: './pages/:org_id/marketing/sms/campaigns/layout',
                    path: 'campaigns',
                    children: [
                      {
                        component: './pages/:org_id/marketing/sms/campaigns',
                        index: true,
                      },
                      {
                        component:
                          './pages/:org_id/marketing/sms/campaigns/:id/layout',
                        path: ':id',
                        children: [
                          {
                            component:
                              './pages/:org_id/marketing/sms/campaigns/:id',
                            index: true,
                          },
                          {
                            path: 'reports',
                            component:
                              './pages/:org_id/marketing/sms/campaigns/:id/reports/layout',
                            children: [
                              {
                                index: true,
                                component:
                                  './pages/:org_id/marketing/sms/campaigns/:id/reports',
                              },
                              {
                                path: 'sent',
                                component:
                                  './pages/:org_id/marketing/sms/campaigns/:id/reports/sent',
                              },
                              {
                                path: 'events',
                                component:
                                  './pages/:org_id/marketing/sms/campaigns/:id/reports/events',
                              },
                              {
                                path: 'failed',
                                component:
                                  './pages/:org_id/marketing/sms/campaigns/:id/reports/failed',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                path: 'data',
                component: './pages/:org_id/marketing/data/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/marketing/data',
                  },
                  {
                    path: 'segments/:id',
                    component: './pages/:org_id/marketing/data/segment/:id',
                  },
                  {
                    path: 'upload',
                    component: './pages/:org_id/marketing/data/upload',
                  },
                  {
                    path: 'ideas',
                    component: './pages/:org_id/marketing/data/ideas',
                  },
                  {
                    path: 'suppression',
                    component: './pages/:org_id/marketing/data/suppression',
                  },
                ],
              },
            ],
          },
          /**
           * ALL REVIEWS ROUTES HERE
           */
          {
            path: 'reviews',
            component: './pages/:org_id/reviews/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/reviews',
              },
              {
                path: 'responses',
                component: './pages/:org_id/reviews/responses/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/reviews/responses',
                  },
                  {
                    path: ':id',
                    component: './pages/:org_id/reviews/responses/:id',
                  },
                ],
              },
              {
                component: './pages/:org_id/reviews/pages/layout',
                path: 'pages',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/reviews/pages',
                  },
                  {
                    path: ':id',
                    component: './pages/:org_id/reviews/pages/:id',
                  },
                ],
              },
              {
                component: './pages/:org_id/reviews/boost',
                path: 'boost',
              },
              {
                component: './pages/:org_id/reviews/reports',
                path: 'reports',
              },
            ],
          },
          /**
           * ALL VENUES ROUTES HERE
           */
          {
            path: 'venues',
            component: './pages/:org_id/venues/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/venues',
              },
              {
                path: ':serial',
                component: './pages/:org_id/venues/:serial/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/venues/:serial',
                  },
                  {
                    path: 'settings',
                    component: './pages/:org_id/venues/:serial/settings/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/venues/:serial/settings',
                      },
                      {
                        path: 'capture',
                        component:
                          './pages/:org_id/venues/:serial/settings/capture',
                      },
                      {
                        path: 'status',
                        component:
                          './pages/:org_id/venues/:serial/settings/status',
                      },
                      {
                        path: 'integrations',
                        component:
                          './pages/:org_id/venues/:serial/settings/integrations',
                      },
                      {
                        path: 'payments',
                        component:
                          './pages/:org_id/venues/:serial/settings/payments',
                      },
                      {
                        path: 'network',
                        component:
                          './pages/:org_id/venues/:serial/settings/network',
                      },
                    ],
                  },
                  {
                    path: 'upsell',
                    component: './pages/:org_id/venues/:serial/upsell/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/venues/:serial/upsell',
                      },
                      {
                        path: 'events',
                        component:
                          './pages/:org_id/venues/:serial/upsell/events',
                      },
                      {
                        path: 'performance',
                        component:
                          './pages/:org_id/venues/:serial/upsell/performance',
                      },
                    ],
                  },
                  {
                    path: 'survey',
                    component: './pages/:org_id/venues/:serial/survey',
                  },

                  {
                    path: 'design',
                    component: './pages/:org_id/venues/:serial/branding/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/venues/:serial/branding',
                      },
                    ],
                  },

                  {
                    path: 'reports',
                    component: './pages/:org_id/venues/:serial/reports/layout',
                    children: [
                      {
                        index: true,
                        component: './pages/:org_id/venues/:serial/reports',
                      },
                      {
                        path: 'registrations',
                        component:
                          './pages/:org_id/venues/:serial/reports/registrations',
                      },
                      {
                        path: 'customers',
                        component: './pages/:org_id/reports/customers',
                      },
                      {
                        path: 'visits',
                        component:
                          './pages/:org_id/venues/:serial/reports/visits',
                      },
                      {
                        path: 'devices',
                        component:
                          './pages/:org_id/venues/:serial/reports/devices',
                      },
                      {
                        path: 'bandwidth',
                        component:
                          './pages/:org_id/venues/:serial/reports/bandwidth',
                      },
                      {
                        path: 'payments',
                        component:
                          './pages/:org_id/venues/:serial/reports/payments',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          /**
           * LOYALTY OTHER ROUTES HERE
           * 
           *  {
    path: '/gifting',
    component: AsyncGifting,
  },
  {
    path: '/gifting/activations',
    component: AsyncGiftingActivations,
  },
  {
    path: '/gifting/reports',
    component: AsyncGiftingReports,
  },
  {
    path: '/gifting/:id',
    component: AsyncGiftingSingle,
  },

           */
          {
            path: 'loyalty',
            component: './pages/:org_id/loyalty/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/loyalty',
              },
              {
                path: 'new',
                component: './pages/:org_id/loyalty/new',
              },
              {
                path: ':id',
                component: './pages/:org_id/loyalty/:id/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/loyalty/:id',
                  },
                  {
                    path: 'customers',
                    component: './pages/:org_id/loyalty/:id/customers',
                  },
                  {
                    path: 'redemptions',
                    component: './pages/:org_id/loyalty/:id/redemptions',
                  },
                  {
                    path: 'tags',
                    component: './pages/:org_id/loyalty/:id/tags',
                  },
                  {
                    path: 'scheme',
                    component: './pages/:org_id/loyalty/:id/scheme',
                  },
                ],
              },
            ],
          },
          {
            path: 'gifting',
            component: './pages/:org_id/gifting/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/gifting',
              },
              {
                path: 'scan',
                component: './pages/:org_id/gifting/scan',
              },
              {
                path: 'activations',
                component: './pages/:org_id/gifting/activations',
              },
              {
                path: 'reports',
                component: './pages/:org_id/gifting/reports',
              },
              {
                path: 'cards',
                component: './pages/:org_id/gifting/cards',
              },
              {
                path: ':id',
                component: './pages/:org_id/gifting/:id/layout',
                children: [
                  {
                    index: true,
                    component: './pages/:org_id/gifting/:id',
                  },
                ],
              },
            ],
          },

          /**
           * Reports
           */
          {
            path: 'reports',
            component: './pages/:org_id/reports/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/reports',
              },
              {
                path: 'multisite',

                component: './pages/:org_id/reports/multisite',
              },
              {
                path: 'origin',
                component: './pages/:org_id/reports/origin',
              },
              {
                path: 'marketing-email',
                component: './pages/:org_id/reports/marketing/email',
              },
              {
                path: 'marketing-sms',
                component: './pages/:org_id/reports/marketing/sms',
              },
              {
                path: 'interactions',
                component: './pages/:org_id/reports/interactions',
              },
              {
                path: 'customers',
                component: './pages/:org_id/reports/customers',
              },
              {
                path: 'transactions',
                component: './pages/:org_id/reports/transactions',
              },
              {
                path: 'my-reports',
                component: './pages/:org_id/reports/report-builder',
              },
              {
                path: 'monthly-retention',
                component: './pages/:org_id/reports/cohort/monthly',
              },
              {
                path: 'gifting',
                component: './pages/:org_id/gifting/reports',
              },
              {
                path: 'tags',
                component: './pages/:org_id/reports/tags',
              },
              {
                path: 'survey',
                component: './pages/:org_id/reports/survey',
              },
            ],
          },

          /**
           * ALL OTHER ROUTES HERE
           */

          {
            path: 'account',
            component: './pages/:org_id/account/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/account',
              },
              {
                path: 'profile',
                component: './pages/:org_id/account/profile',
              },
              {
                path: 'notifications',
                component: './pages/:org_id/account/notifications',
              },
              {
                path: 'event-log',
                component: './pages/:org_id/account/event-log',
              },
            ],
          },
          {
            path: 'organisation',
            component: './pages/:org_id/organisation/layout',
            children: [
              {
                index: true,
                component: './pages/:org_id/organisation',
              },
              {
                path: 'venues',
                component: './pages/:org_id/organisation/venues',
              },
              {
                path: 'team',
                component: './pages/:org_id/organisation/team',
              },
              {
                path: 'groups',
                component: './pages/:org_id/organisation/groups',
              },
              {
                path: 'settings',
                component: './pages/:org_id/organisation/settings',
              },
              {
                path: 'tags',
                component: './pages/:org_id/organisation/tags',
              },
              {
                path: 'billing',
                component: './pages/:org_id/organisation/billing',
              },
              {
                path: 'integrations',
                component: './pages/:org_id/organisation/integrations',
              },
            ],
          },
        ],
      },
    ],
  },
]
