import * as Sentry from '@sentry/react'

import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react'
import { FunctionComponent, PropsWithChildren } from 'react'

const FallbackComp = (props) => {
  const { error } = props
  const isChunkError = error.toString().includes('ChunkLoad')
  return (
    <Box as="section" h="full" display="flex" alignItems="center">
      <Box
        maxW="2xl"
        mx="auto"
        px={{ base: '6', lg: '8' }}
        py={{ base: '16', sm: '20' }}
        textAlign="center"
      >
        <Stack>
          <Heading
            as="h2"
            size="3xl"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            {isChunkError ? 'Update available' : 'Page Error'}
          </Heading>
          {!isChunkError ? (
            <>
              <Text mt="4" fontSize="lg">
                {error.toString()}
              </Text>
              <Button
                colorScheme="brand"
                size="lg"
                onClick={() => window.location.reload()}
              >
                Update
              </Button>
            </>
          ) : (
            <Button
              colorScheme="brand"
              size="lg"
              onClick={() => window.location.reload()}
            >
              Update
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

const ErrorContext: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Sentry.ErrorBoundary showDialog dialogOptions={{}} fallback={FallbackComp}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorContext
