import { createReducer } from '@reduxjs/toolkit'
import { TimeFormats } from 'common/datepicker/utilities/formats'
import message from 'common/message/message'
import { getDateFromDateString } from 'components/Bookings/helpers/time'
import { format } from 'date-fns'
import { isAxiosError } from 'utils/axiosHelper'
import { getFirst, uuid4 } from 'utils/common'
import {
  clearConfig,
  clearSelectedFloorPlan,
  createBookingNote,
  createCardCharge,
  createCombination,
  createConfig,
  createFloorPlan,
  createManualBooking,
  createNewManualBooking,
  createSection,
  createSpecialDate,
  createTable,
  deleteArea,
  deleteAuxShape,
  deleteBookingNote,
  deleteCombination,
  deleteCombinationUnit,
  deleteFloorPlan,
  deleteSection,
  deleteSpecialDate,
  deleteTable,
  deleteUnitFromFloorPlan,
  getAllBookingNotes,
  getAreas,
  getBooking,
  getBookingConfigBlockTimes,
  getBookings,
  getBookingsConfig,
  getBookingsCoversTotal,
  getBookingsLocations,
  getCombination,
  getCombinations,
  getDailyStats,
  getFloorPlans,
  getFloorPlanTables,
  getIndividualFloorPlan,
  getOverlap,
  getRangeSummary,
  getSearchBookings,
  getSections,
  getSpecialDates,
  getTables,
  getTimes,
  getWeeklyStats,
  postArea,
  putAllAreas,
  putArea,
  saveConfig,
  setCurrentConfigSerial,
  setTable,
  updateBookingConfigBlockTimes,
  updateBookingDateTimeWithManual,
  updateBookingDetails,
  updateBookingFromWebSocket,
  updateBookingNote,
  updateBookingStatus,
  updateCombination,
  updateFloorPlan,
  updateFloorPlanUnits,
  updateSection,
  updateSpecialDate,
  updateTable,
} from './bookings.actions'
import { FormatDateForBackend } from './bookings.helpers'
import {
  AreaType,
  BookingConfigBlockTableTimesType,
  BookingConfigType,
  BookingProgressType,
  BookingsByDayWeekMonthYear,
  CalendarResult,
  ChargeResult,
  CombinationType,
  DailyStatsResult,
  DeleteTableFloorPlanEdgeType,
  FloorPlanType,
  GetSearchBookingsType,
  SectionAndLoadingType,
  SectionType,
  SpecialDatesAndLoadingType,
  TableType,
  TimesStateType,
  UserBookingNoteType,
  // AreaType,
  UserBookingType,
  WeeklyStatsResult,
} from './bookings.types'

export const initTimes: TimesStateType = {
  times: {},
  isLoading: false,
}

export interface BookingType {
  ignore_sockets_for: string[]
  config: BookingConfigType
  currentConfig: string
  loading: boolean
  saveConfigloading: boolean
  organisationConfigs: BookingConfigType[]
  tables: { [serial: string]: TableType[] }
  combinations: { data: CombinationType[]; loading: boolean }
  combination: { data: CombinationType | null; loading: boolean }
  floorPlanTables: TableType[]
  selectedFloorPlan: { plan: FloorPlanType; isLoading: boolean }
  floorPlans: { plans: FloorPlanType[] | null; isLoading: boolean }
  table: TableType | null
  times: TimesStateType
  isTimesLoading: boolean
  bookings: UserBookingType[]
  deleteUnitFromFloorPlan: DeleteTableFloorPlanEdgeType
  getBookings: {
    bookings: {
      [key: string]: BookingsByDayWeekMonthYear[]
    }
    isLoading: boolean
  }
  getBooking: { booking: UserBookingType; isLoading: boolean }
  overlap: UserBookingType
  sections: SectionAndLoadingType[]
  section: SectionType | null
  progress: BookingProgressType
  specialDates: SpecialDatesAndLoadingType[]
  specialDate: SpecialDatesAndLoadingType | null
  bookingsCovers: number
  searchBookings: { bookings: GetSearchBookingsType; isLoading: boolean }
  cardCharge: { chargeResult: ChargeResult; isLoading: boolean }
  dailyStats: { dailyStatsResult: DailyStatsResult; isLoading: boolean }
  weeklyStats: { weeklyStatsResult: WeeklyStatsResult; isLoading: boolean }
  rangeSummary: {
    rangeSummaryResult: CalendarResult
    isLoading: boolean
  }

  areas: {
    items: AreaType[]
    isLoading: boolean
    isSorting: boolean
  }
  /**
   * clean
   */
  booking_notes: {
    isLoading: boolean
    items: {
      [key: string]: UserBookingNoteType[]
    }
  }
  block_times: BookingConfigBlockTableTimesType & { isLoading: boolean }
}

const initialState: BookingType = {
  booking_notes: {
    isLoading: false,
    items: {},
  },
  block_times: {
    isLoading: false,
    tables: {},
    times: {},
  },
  config: null,
  tables: {},
  combinations: { data: [], loading: false },
  combination: { data: null, loading: false },
  organisationConfigs: [],
  floorPlanTables: [],
  selectedFloorPlan: { plan: null, isLoading: false },
  floorPlans: { plans: [], isLoading: false },
  table: null,
  times: initTimes,
  isTimesLoading: false,
  loading: false,
  saveConfigloading: false,
  currentConfig: '',
  bookings: [],
  deleteUnitFromFloorPlan: {
    unitId: '',
  },
  getBookings: { bookings: {}, isLoading: false },
  getBooking: { booking: null, isLoading: false },
  overlap: null,
  sections: [],
  section: null,
  specialDates: [],
  specialDate: null,
  progress: { hasSetUp: true, hasTable: true, hasFloorPlan: true },
  bookingsCovers: 0,
  searchBookings: {
    bookings: {
      bookings: [],
      next_offset: 0,
      prev_offset: 0,
      total: 0,
    },
    isLoading: false,
  },
  cardCharge: {
    chargeResult: {
      stripeResponse: '',
      booking: [] as any,
    },
    isLoading: false,
  },
  dailyStats: {
    dailyStatsResult: {
      totalCovers: 0,
      totalBookings: 0,
      totalCancelaltions: 0,
      totalNoShows: 0,
    },
    isLoading: false,
  },
  weeklyStats: {
    weeklyStatsResult: {
      '1970-01-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
      '1970-02-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
      '1970-03-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
      '1970-04-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
      '1970-05-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
      '1970-06-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
      '1970-07-01': {
        totalCovers: 0,
        averageCovers: 0,
        sub: 0,
        delta: false,
      },
    },
    isLoading: false,
  },
  rangeSummary: {
    rangeSummaryResult: {
      totalCovers: 0,
      rangeSummary: [],
      totalCancelledCovers: 0,
      totalNoShowCovers: 0,
      totalWalkinCovers: 0,
      mostPopularStartStartTime: '13:00',
      averageDuration: '',
    },
    isLoading: false,
  },

  areas: {
    items: [],
    isLoading: false,
    isSorting: false,
  },
  ignore_sockets_for: [],
}

const getABookingDateFromBackendString = (date: string) =>
  FormatDateForBackend(getDateFromDateString(date))

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(saveConfig.pending, (draft) => {
      draft.saveConfigloading = true
    })
    .addCase(saveConfig.fulfilled, (draft, action) => {
      draft.saveConfigloading = false

      draft.config = action.payload
      message.success('Settings published')
    })
    .addCase(saveConfig.rejected, (draft) => {
      draft.saveConfigloading = false
    })
    .addCase(updateBookingConfigBlockTimes.pending, (draft, _action) => {
      draft.block_times.isLoading = true
    })
    .addCase(updateBookingConfigBlockTimes.fulfilled, (draft, action) => {
      draft.block_times.isLoading = false

      draft.block_times.times = {
        ...draft.block_times.times,
        ...action.payload.times,
      }

      draft.block_times.tables = {
        ...draft.block_times.tables,
        ...action.payload.tables,
      }

      message.success('Block times updated')
    })
    .addCase(updateBookingConfigBlockTimes.rejected, (draft) => {
      draft.block_times.isLoading = false
    })
    .addCase(getBookingConfigBlockTimes.pending, (draft) => {
      draft.block_times.isLoading = true
    })
    .addCase(getBookingConfigBlockTimes.fulfilled, (draft, action) => {
      draft.block_times.isLoading = false

      const date = action.meta.arg.date

      draft.block_times.times[date] = action.payload.times
      draft.block_times.tables[date] = action.payload.tables
    })
    .addCase(getBookingConfigBlockTimes.rejected, (draft) => {
      draft.block_times.isLoading = false
    })
    .addCase(deleteBookingNote.pending, (draft, action) => {
      const id = action.meta.arg.note.id
      const bookingId = action.meta.arg.note.bookingId

      const item = draft.booking_notes.items[bookingId].find(
        (item) => item.id === id
      )
      if (item) {
        item.isLoading = true
      }
    })
    .addCase(deleteBookingNote.fulfilled, (draft, action) => {
      const id = action.payload.id
      const bookingId = action.payload.bookingId
      draft.booking_notes.items[bookingId] = draft.booking_notes.items[
        bookingId
      ].filter((item) => item.id !== id)
    })
    .addCase(deleteBookingNote.rejected, (draft) => {
      Object.entries(draft.booking_notes.items).forEach(([, item]) => {
        item = item.map((i) => ({ ...i, isLoading: false }))
      })
    })
    .addCase(getAllBookingNotes.pending, (draft) => {
      draft.booking_notes.isLoading = true
    })
    .addCase(getAllBookingNotes.fulfilled, (draft, action) => {
      draft.booking_notes.isLoading = false
      if (action.payload.length >= 1) {
        const bookingId = getFirst(action.payload).bookingId
        draft.booking_notes.items[bookingId] = action.payload
      }
    })
    .addCase(getAllBookingNotes.rejected, (draft) => {
      draft.booking_notes.isLoading = false
    })
    .addCase(createBookingNote.pending, (draft) => {
      draft.booking_notes.isLoading = true
    })
    .addCase(createBookingNote.fulfilled, (draft, action) => {
      draft.booking_notes.isLoading = false
      if (!(action.payload.bookingId in draft.booking_notes.items)) {
        draft.booking_notes.items[action.payload.bookingId] = []
      }
      draft.booking_notes.items[action.payload.bookingId].push(action.payload)
      message.success('Note created')
    })
    .addCase(createBookingNote.rejected, (draft) => {
      draft.booking_notes.isLoading = false
    })

    .addCase(updateBookingNote.pending, (draft) => {
      draft.booking_notes.isLoading = true
    })

    .addCase(updateBookingNote.fulfilled, (draft, action) => {
      draft.booking_notes.isLoading = false
      const notes = draft.booking_notes.items[action.payload.bookingId]
      const noteId = notes.findIndex((item) => item.id === action.payload.id)
      notes[noteId] = action.payload
      message.success('Note updated')
    })
    .addCase(updateBookingNote.rejected, (draft) => {
      draft.booking_notes.isLoading = false
    })
    .addCase(deleteTable.pending, (draft, action) => {
      const table = draft.tables[action.meta.arg.serial].find(
        (item) => item.id === action.meta.arg.id
      )
      if (table) {
        table.isDeleteing = true
      }
    })
    .addCase(deleteTable.fulfilled, (draft, action) => {
      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].filter((table) => table.id !== action.payload)

      message.success('Table deleted')
    })
    .addCase(deleteTable.rejected, (draft, action) => {
      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].map((item) => ({
        ...item,
        isDeleteing: false,
      }))

      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message || "Couldn't delete table"
        )
      } else {
        message.warning("Something went wrong, we couldn't delete this table")
      }
    })
    .addCase(getBookingsConfig.pending, (draft) => {
      draft.loading = true
    })
    .addCase(getBookingsConfig.fulfilled, (draft, action) => {
      draft.loading = false
      if (Object.keys(action.payload).length === 0) {
        draft.config = null
        return
      }
      if (!action.payload.floorPlans) {
        draft.progress.hasFloorPlan = false
      } else {
        draft.progress.hasFloorPlan = true
      }
      console.log(action.payload, 'get config action payload')
      draft.config = action.payload
    })
    .addCase(getBookingsConfig.rejected, (draft) => {
      draft.loading = true
    })
    /**
     * Get all locations
     */
    .addCase(getBookingsLocations.pending, (draft) => {
      draft.loading = true
    })
    .addCase(getBookingsLocations.fulfilled, (draft, action) => {
      draft.organisationConfigs = action.payload
      draft.loading = false
    })
    .addCase(getBookingsLocations.rejected, (draft) => {
      draft.loading = false
    })

    /**
     * Get all tables
     */
    .addCase(getTables.pending, (draft, action) => {
      draft.loading = true
      draft.tables[action.meta.arg.serial] = []
    })
    .addCase(getTables.fulfilled, (draft, action) => {
      if (action.payload.length === 0) {
        draft.progress.hasTable = false
      } else {
        draft.progress.hasTable = true
      }
      draft.tables[action.meta.arg.serial] = action.payload
      draft.loading = false
    })
    .addCase(getTables.rejected, (draft) => {
      draft.loading = false
    })

    .addCase(updateTable.pending, (draft, action) => {
      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].map((table) => ({
        ...table,
        isLoading: table.id === action.meta.arg.id,
      }))
    })
    .addCase(updateTable.fulfilled, (draft, action) => {
      const index = draft.tables[action.meta.arg.serial].findIndex(
        (item) => item.id === action.payload.id
      )
      draft.table = action.payload

      if (index >= 0) {
        draft.tables[action.meta.arg.serial][index] = action.payload
      }
      /*
      if (action.payload.areaId) {
        draft.areas.items = draft.areas.items.map(({ area, units }) =>
          area.id === action.payload.areaId
            ? {
                area,
                units: [
                  ...units.filter((u) => u.id !== action.payload.id),
                  action.payload,
                ],
              }
            : { area, units }
        )
      }
      */
      message.success('Table Updated')
    })
    .addCase(updateTable.rejected, (draft, action) => {
      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].map((table) => ({
        ...table,
        isLoading: false,
      }))
      message.error('Failed to update table')
    })
    .addCase(getFloorPlanTables.pending, (draft) => {
      draft.loading = true
    })
    .addCase(getFloorPlanTables.fulfilled, (draft, action) => {
      draft.floorPlanTables = action.payload
      draft.loading = false
    })
    .addCase(getFloorPlanTables.rejected, (draft) => {
      draft.loading = false
    })
    .addCase(getIndividualFloorPlan.pending, (draft) => {
      draft.selectedFloorPlan.isLoading = true
    })
    .addCase(getIndividualFloorPlan.fulfilled, (draft, action) => {
      draft.selectedFloorPlan.plan = action.payload
      draft.selectedFloorPlan.isLoading = false
    })
    .addCase(getIndividualFloorPlan.rejected, (draft) => {
      draft.selectedFloorPlan.isLoading = false
    })

    .addCase(getFloorPlans.pending, (draft) => {
      draft.floorPlans.isLoading = true
    })
    .addCase(getFloorPlans.fulfilled, (draft, action) => {
      draft.floorPlans.plans = action.payload
      draft.floorPlans.isLoading = false
    })
    .addCase(getFloorPlans.rejected, (draft) => {
      draft.floorPlans.plans = []
      draft.floorPlans.isLoading = false
    })
    .addCase(deleteFloorPlan.pending, (draft) => {
      draft.floorPlans.isLoading = true
    })
    .addCase(deleteFloorPlan.fulfilled, (draft, action) => {
      draft.floorPlans.plans = draft.floorPlans.plans.filter(
        (item) => item.id !== action.payload
      )
      if (draft.selectedFloorPlan.plan.floorPlanId === action.payload) {
        draft.selectedFloorPlan = null
      }
      message.success('Floorplan Removed')
      draft.floorPlans.isLoading = false
    })
    .addCase(deleteFloorPlan.rejected, (draft) => {
      message.error('Something went wrong')
      draft.floorPlans.isLoading = false
    })
    .addCase(updateFloorPlan.pending, (draft) => {
      draft.floorPlans.isLoading = true
    })
    .addCase(updateFloorPlan.fulfilled, (draft, action) => {
      if (draft.selectedFloorPlan.plan.id === action.payload.id) {
        draft.selectedFloorPlan.plan = {
          ...action.payload,
          units: draft.selectedFloorPlan.plan.units,
          combinations: draft.selectedFloorPlan.plan.combinations,
        }
      }
      draft.floorPlans.plans = draft.floorPlans.plans.map((item) => {
        if (item.id === action.payload.id) {
          item = action.payload
          return item
        }
        return item
      })
      message.success('Floor Plan updated')
      draft.floorPlans.isLoading = false
    })
    .addCase(updateFloorPlan.rejected, (draft) => {
      message.error('Something went wrong, we could not update your floor plan')
      draft.floorPlans.isLoading = false
    })
    .addCase(createFloorPlan.pending, (draft) => {
      draft.floorPlans.isLoading = true
    })
    .addCase(createFloorPlan.fulfilled, (draft, action) => {
      draft.progress.hasFloorPlan = true
      console.log(action.payload, 'create floor action payload')
      draft.selectedFloorPlan.plan = { ...action.payload, auxShapes: [] }
      if (draft.floorPlans.plans === null) {
        draft.floorPlans.plans = []
      }
      draft.floorPlans.plans.push({ ...action.payload, auxShapes: [] })
      //update the config?
      draft.floorPlans.isLoading = false

      if (action.meta.arg.isSilent) return
      message.success('Floor Plan Created')
    })
    .addCase(createFloorPlan.rejected, (draft, action) => {
      draft.floorPlans.isLoading = false
      if (action.meta.arg.isSilent) return
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(`${action.payload.message}`)
      } else {
        message.error(
          "Something went wrong, we couldn't create this floor plan"
        )
      }
    })
    .addCase(updateFloorPlanUnits.pending, (draft) => {
      draft.floorPlans.isLoading = true
    })
    .addCase(updateFloorPlanUnits.fulfilled, (draft, action) => {
      draft.selectedFloorPlan.plan.units = action.payload.units
      message.success('Floor Plan Updated')
      draft.floorPlans.isLoading = false
    })
    .addCase(updateFloorPlanUnits.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(`${action.payload.message}`)
        draft.floorPlans.isLoading = false
      }
    })
    .addCase(deleteUnitFromFloorPlan.pending, (draft) => {
      draft.floorPlans.isLoading = true
    })
    .addCase(deleteUnitFromFloorPlan.fulfilled, (draft, action) => {
      draft.selectedFloorPlan.plan.units =
        draft.selectedFloorPlan.plan.units.filter(
          (item) => item.unitId !== action.payload.unitId
        )
      message.success('Removed')
      draft.floorPlans.isLoading = false
    })
    .addCase(deleteUnitFromFloorPlan.rejected, (draft) => {
      message.error('Something went wrong')
      draft.floorPlans.isLoading = false
    })
    .addCase(createTable.pending, (draft, action) => {
      if (action.meta.arg.table.id) {
        const item = draft.tables[action.meta.arg.serial].find(
          (table) => table.id === action.meta.arg.table.id
        )
        if (item) {
          item.isCloning = true
        }
      } else {
        draft.loading = true
      }
    })

    .addCase(createTable.fulfilled, (draft, action) => {
      /**
       * =./
       */
      draft.progress.hasTable = true

      draft.tables[action.meta.arg.serial].unshift(action.payload)

      if (action.payload.areaId) {
        const area = draft.areas.items.find(
          (item) => item.id === action.payload.areaId
        )
        if (area) {
          area.units.push(action.payload.id)
        }
      }

      if (action.meta.arg.table.id) {
        const item = draft.tables[action.meta.arg.serial].find(
          (table) => table.id === action.meta.arg.table.id
        )
        if (item) {
          item.isCloning = false
        }
      }
      message.success('Table Created')
      draft.loading = false
    })
    .addCase(createTable.rejected, (draft, action) => {
      message.error('Something went wrong, we could not create your table')
      if (action.meta.arg.table.id) {
        const item = draft.tables[action.meta.arg.serial].find(
          (table) => table.id === action.meta.arg.table.id
        )
        if (item) {
          item.isCloning = false
        }
      }
      draft.loading = false
    })
    .addCase(deleteAuxShape.pending, (draft) => {
      draft.loading = true
    })
    .addCase(deleteAuxShape.fulfilled, (draft, action) => {
      draft.selectedFloorPlan.plan.auxShapes = action.payload.updatedAuxShapes
      message.success('Removed')
      draft.loading = false
    })
    .addCase(deleteAuxShape.rejected, (draft) => {
      message.error('Something went wrong')
      draft.loading = false
    })
    .addCase(createConfig.pending, (draft) => {
      draft.loading = true
    })
    .addCase(createConfig.fulfilled, (draft, action) => {
      draft.config = action.payload
      message.success('Config Created')
      draft.loading = false
    })
    .addCase(createConfig.rejected, (draft) => {
      message.error('Something went wrong')
      draft.loading = false
    })
    .addCase(getCombination.pending, (draft) => {
      draft.combination.loading = true
    })
    .addCase(getCombination.fulfilled, (draft, action) => {
      draft.combination.data = action.payload
      draft.combination.loading = false
    })
    .addCase(getCombination.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not get your table combination'
        )
        draft.combination.loading = false
        return
      }
      message.error(
        'Something went wrong, we could not get your table combination'
      )
      draft.combination.loading = false
    })
    .addCase(getCombinations.pending, (draft) => {
      draft.combinations.loading = true
    })
    .addCase(getCombinations.fulfilled, (draft, action) => {
      draft.combinations.data = action.payload
      draft.combinations.loading = false
    })
    .addCase(getCombinations.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not get your table combinations'
        )
        draft.combinations.loading = false
        return
      }
      message.error(
        'Something went wrong, we could not get your table combinations'
      )
      draft.combinations.loading = false
    })

    .addCase(createCombination.pending, (draft) => {
      draft.combinations.loading = true
    })
    .addCase(createCombination.fulfilled, (draft, action) => {
      draft.combinations.data.push(action.payload)
      message.success('Combination Created')
      draft.combinations.loading = false
    })
    .addCase(createCombination.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not create your table combination'
        )
        draft.combinations.loading = false
        return
      }
      message.error(
        'Something went wrong, we could not create your table combination'
      )
      draft.combinations.loading = false
    })
    .addCase(updateCombination.pending, (draft) => {
      draft.combinations.loading = true
    })
    .addCase(updateCombination.fulfilled, (draft, action) => {
      draft.combinations.data = draft.combinations.data.map((combination) => {
        if (combination.id === action.payload.id) return action.payload
        return combination
      })
      message.success('Combination Updated')
      draft.combinations.loading = false
    })
    .addCase(updateCombination.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not update your table combination'
        )
        draft.combinations.loading = false
      }
    })
    .addCase(deleteCombination.pending, (draft) => {
      draft.combinations.loading = true
    })
    .addCase(deleteCombination.fulfilled, (draft, action) => {
      draft.combinations.data = draft.combinations.data.filter(
        (i) => i.id !== action.payload
      )
      draft.combinations.loading = false
      message.success('Combination deleted')
    })
    .addCase(deleteCombination.rejected, (draft, action) => {
      draft.combinations.loading = false

      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not delete your table combination'
        )
        return
      }
      message.error(
        'Something went wrong, we could not delete your table combination'
      )
    })
    .addCase(deleteCombinationUnit.pending, (draft) => {
      draft.combination.loading = true
    })
    .addCase(deleteCombinationUnit.fulfilled, (draft) => {
      draft.combination.loading = false
    })
    .addCase(deleteCombinationUnit.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not delete your table from this combination'
        )
      }
      draft.combination.loading = false
    })
    .addCase(createManualBooking.pending, (draft) => {
      console.log('action-pending', draft)
      draft.getBooking.isLoading = true
    })
    .addCase(createManualBooking.fulfilled, (draft, action) => {
      console.log('action', action)
      draft.getBooking.booking = action.payload.booking
      // draft.getBookings.bookings.push(action.payload)
      const date = getABookingDateFromBackendString(
        action.payload.booking.startDateTime
      )

      const findIndex = draft.getBookings.bookings[date].findIndex((item) => {
        return item.booking.id === action.payload.booking.id
      })
      if (findIndex >= 0) {
        draft.getBookings.bookings[date][findIndex] = action.payload
      } else {
        draft.getBookings.bookings[date].push(action.payload)
      }
      draft.getBooking.isLoading = false

      message.success('Booking Created')
    })
    .addCase(createManualBooking.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message || "Couldn't create booking"
        )
      }
      draft.getBooking.isLoading = false
    })
    .addCase(createNewManualBooking.pending, (draft, action) => {
      console.log('action-pending', action)
      draft.getBooking.isLoading = true
      const feId = action.meta.requestId
      const date = format(
        action.meta.arg.item.startDate,
        TimeFormats.BackendYearMonthDay
      )
      if (!(date in draft.getBookings.bookings)) {
        draft.getBookings.bookings[date] = []
      }
      draft.getBookings.bookings[date].push({
        booking: {
          ...action.meta.arg.item.booking,
          id: uuid4(),
          startDateTime: format(
            action.meta.arg.item.startDate,
            TimeFormats.FullCal
          ),
          endDateTime: format(
            action.meta.arg.item.endDate,
            TimeFormats.FullCal
          ),
        },
        isLoading: true,
        startDate: action.meta.arg.item.startDate,
        endDate: action.meta.arg.item.endDate,
        units: action.meta.arg.item.tables.map((table) => table.id),
        isFake: true,
        feId: feId,
      })
      draft.ignore_sockets_for.push('new')
    })
    .addCase(createNewManualBooking.fulfilled, (draft, action) => {
      //draft.getBooking.booking = action.payload.booking

      draft.getBooking.isLoading = false
      const date = getABookingDateFromBackendString(
        action.payload.booking.startDateTime
      )
      const feId = action.meta.requestId

      const findIndex = draft.getBookings.bookings[date].findIndex(
        (item) => item.feId === feId
      )

      draft.getBookings.bookings[date][findIndex] = action.payload

      message.success('Booking Created')

      draft.ignore_sockets_for = draft.ignore_sockets_for.filter(
        (item) => !['new'].includes(item)
      )
    })
    .addCase(createNewManualBooking.rejected, (draft, action) => {
      console.log('action-fail', action)
      if (isAxiosError<{ message?: string }>(action.payload)) {
        message.error(
          action.payload.response?.data?.message || "Couldn't create booking"
        )
      } else {
        message.error("Couldn't create booking")
      }
      draft.getBooking.isLoading = false

      const date = format(
        action.meta.arg.item.startDate,
        TimeFormats.BackendYearMonthDay
      )
      draft.getBookings.bookings[date] = draft.getBookings.bookings[
        date
      ].filter(({ feId }) => feId !== action.meta.requestId)
    })
    .addCase(updateBookingFromWebSocket, (draft, action) => {
      console.log('action-socket', action)

      action.payload.forEach((newBooking) => {
        if (draft.ignore_sockets_for.includes(newBooking.booking.id)) {
          draft.ignore_sockets_for = draft.ignore_sockets_for.filter(
            (id) => id !== newBooking.booking.id
          )
          return
        }

        const date = getABookingDateFromBackendString(
          newBooking.booking.startDateTime
        )
        if (!draft.getBookings.bookings[date]) {
          draft.getBookings.bookings[date] = []
        }

        if (newBooking.feId) {
          const item = draft.getBookings.bookings[date].find(
            (item) => item.feId === newBooking.feId
          )
          if (item) {
            /**
             * This item exists in state
             * update will happen via fulfulled reducer
             */
            return
          }
        }

        const findIndex = draft.getBookings.bookings[date].findIndex((item) => {
          return item.booking.id === newBooking.booking.id
        })
        if (findIndex >= 0) {
          draft.getBookings.bookings[date][findIndex] = newBooking
        } else {
          draft.getBookings.bookings[date].push(newBooking)
        }
        const findIndexSearch =
          draft.searchBookings.bookings.bookings.findIndex((item) => {
            return item.id === newBooking.booking.id
          })
        if (findIndexSearch >= 0) {
          draft.searchBookings.bookings.bookings[findIndexSearch] =
            newBooking.booking
        } else {
          draft.searchBookings.bookings.bookings.push(newBooking.booking)
        }
      })
    })
    .addCase(getBookings.pending, (draft, action) => {
      const date = FormatDateForBackend(action.meta.arg.date)
      if (!(date in draft.getBookings.bookings)) {
        draft.getBookings.bookings[date] = []
      }
      draft.getBookings.isLoading = true
    })
    .addCase(getBookings.fulfilled, (draft, action) => {
      const date = FormatDateForBackend(action.meta.arg.date)
      draft.getBookings.bookings[date] = action.payload
      draft.getBookings.isLoading = false
      console.log(draft.getBookings.bookings)
    })
    .addCase(getBookings.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message || 'Something went wrong'
        )
      }
      // draft.getBookings.bookings = []
      draft.getBookings.isLoading = false
    })
    .addCase(getBooking.pending, () => {
      /*
      const item = draft.getBookings.bookings.find(
        (item) => item.booking.id === action.payload.bookingId
      )
      if (item) {
        item.isLoading = true
      }
      */
    })
    .addCase(getBooking.fulfilled, (draft, action) => {
      const booking = action.payload
      const date = getABookingDateFromBackendString(
        booking.booking.startDateTime
      )
      if (date in draft.getBookings.bookings) {
        const findIndex = draft.getBookings.bookings[date].findIndex(
          (item) => item.booking.id === action.payload.booking.id
        )
        if (findIndex >= 0) {
          draft.getBookings.bookings[date][findIndex] = action.payload
        } else {
          draft.getBookings.bookings[date].push(action.payload)
        }
      } else {
        draft.getBookings.bookings[date] = [action.payload]
      }

      /*
      draft.getBookings.bookings = draft.getBookings.bookings.map((item) => ({
        ...item,
        isLoading: false,
      }))
      */
    })
    .addCase(getBooking.rejected, () => {
      message.error('Something went wrong')
      /*
      draft.getBookings.bookings = draft.getBookings.bookings.map((item) => ({
        ...item,
        isLoading: false,
      }))

      */
    })
    .addCase(updateBookingDetails.pending, (draft) => {
      draft.getBooking.isLoading = true
    })
    .addCase(updateBookingDetails.fulfilled, (draft, action) => {
      draft.getBooking.booking = action.payload.booking
      const date = getABookingDateFromBackendString(
        draft.getBooking.booking.startDateTime
      )
      const key = draft.getBookings.bookings[date].findIndex(
        (item) => item.booking.id === action.payload.booking.id
      )
      if (key >= 0) {
        draft.getBookings.bookings[date][key] = action.payload
      }
      draft.getBooking.isLoading = false
      message.success('Booking details updated')
    })
    .addCase(updateBookingDetails.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message || "Couldn't update booking"
        )
      }
      draft.getBooking.isLoading = false
    })
    .addCase(updateBookingDateTimeWithManual.pending, (draft, action) => {
      // draft.getBooking.isLoading = true
      draft.ignore_sockets_for.push(action.meta.arg.booking.id)

      const date = format(
        action.meta.arg.booking.startDate,
        TimeFormats.BackendYearMonthDay
      )

      const booking = draft.getBookings.bookings[date].find(
        (item) => item.booking.id === action.meta.arg.booking.id
      )
      if (booking) {
        booking.booking.startDateTime =
          action.meta.arg.booking.startDate.toISOString()
        booking.booking.endDateTime =
          action.meta.arg.booking.endDate.toISOString()
        booking.units = action.meta.arg.unitId

        booking.isLoading = true
      }
    })
    .addCase(updateBookingDateTimeWithManual.fulfilled, (draft, action) => {
      // draft.getBooking.booking = action.payload.booking

      const date = getABookingDateFromBackendString(
        action.payload.booking.startDateTime
      )
      // let preDate = null
      /*
      if (action.meta.arg.previousDate) {
        preDate = getABookingDateFromBackendString(action.meta.arg.previousDate)
      }
      if (preDate && date !== preDate) {
        draft.getBookings.bookings[preDate] = draft.getBookings.bookings[
          preDate
        ].filter((item) => item.booking.id !== action.payload.booking.id)
      }
      */

      const item = draft.getBookings.bookings[date].find(
        (item) => item.booking.id === action.payload.booking.id
      )

      // draft.getBooking.isLoading = false
      if (item) {
        item.booking = action.payload.booking
        item.units = action.payload.units

        item.isLoading = false
      }
      message.success('Booking Updated')
    })
    .addCase(updateBookingDateTimeWithManual.rejected, (draft, action) => {
      const date = format(
        action.meta.arg.booking.startDate,
        TimeFormats.BackendYearMonthDay
      )

      const booking = draft.getBookings.bookings[date].find(
        (item) => item.booking.id === action.meta.arg.booking.id
      )
      if (booking) {
        booking.isLoading = false
      }
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message || "Couldn't update booking"
        )
      } else {
        message.error("Couldn't update booking")
      }
      /*
      Object.entries(draft.getBookings.bookings).forEach(([, item]) => {
        item = item.map((i) => ({ ...i, isLoading: false }))
      })
      */
      //        draft.getBookings.bookings = draft.getBookings.bookings.map((b) => b)
      draft.getBooking.isLoading = false
    })
    .addCase(getTimes.pending, (draft) => {
      draft.times.isLoading = true
    })
    .addCase(getTimes.fulfilled, (draft, action) => {
      draft.times.isLoading = false
      draft.times.times = action.payload
    })
    .addCase(getTimes.rejected, (draft, action) => {
      draft.times.isLoading = false

      draft.times = initTimes

      if (isAxiosError<{ message?: string }>(action.payload)) {
        message.warning(
          action.payload.response?.data?.message || 'Something went wrong'
        )
      }
    })
    .addCase(updateBookingStatus.pending, (draft, action) => {
      draft.getBooking.isLoading = true
      draft.ignore_sockets_for.push(action.meta.arg.id)
      const date = getABookingDateFromBackendString(
        action.meta.arg.startDateTime
      )

      /**
       * mimic status
       */
      const booking = draft.getBookings.bookings[date].find(
        (item) => item.booking.id === action.meta.arg.id
      )
      if (booking) {
        booking.booking.status = action.meta.arg.status
      }
    })
    .addCase(updateBookingStatus.fulfilled, (draft, action) => {
      message.success('Booking Status Updated')
      if (
        ['cancelled', 'no-show', 'complete'].includes(action.payload.status)
      ) {
        draft.getBooking.booking = null
      }
      const date = getABookingDateFromBackendString(
        action.payload.startDateTime
      )

      const findIndex = draft.getBookings.bookings[date].findIndex(
        (item) => item.booking.id === action.payload.id
      )
      if (findIndex >= 0) {
        draft.getBookings.bookings[date][findIndex].booking = action.payload
      }

      draft.getBooking.booking = action.payload
      draft.getBooking.isLoading = false
      draft.ignore_sockets_for = draft.ignore_sockets_for.filter(
        (item) => item !== action.payload.id
      )
    })
    .addCase(updateBookingStatus.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message || 'Something went wrong'
        )
      }
      draft.getBooking.isLoading = false
    })
    .addCase(getOverlap.pending, (draft) => {
      draft.loading = true
      draft.overlap = null
    })
    .addCase(getOverlap.fulfilled, (draft, action) => {
      draft.overlap = action.payload
      draft.loading = false
    })
    .addCase(getOverlap.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message || 'Something went wrong'
        )
      }
      draft.loading = false
    })
    .addCase(getSections.pending, (draft) => {
      draft.loading = true
      draft.overlap = null
    })
    .addCase(getSections.fulfilled, (draft, action) => {
      draft.sections = action.payload
      draft.loading = false
    })
    .addCase(getSections.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message || 'Something went wrong'
        )
      }
      draft.loading = false
    })
    .addCase(createSection.pending, (draft, action) => {
      if (action.meta.arg.section.id) {
        draft.sections = draft.sections.map((section) => ({
          ...section,
          isLoading: section.id === action.meta.arg.section.id,
        }))
      } else {
        draft.loading = true
      }
    })
    .addCase(createSection.fulfilled, (draft, action) => {
      draft.section = action.payload
      draft.sections = [...draft.sections, action.payload].map((section) => ({
        ...section,
        isLoading: false,
      }))
      message.success('Section created')
      draft.loading = false
    })
    .addCase(createSection.rejected, (draft) => {
      message.error('Something went wrong, we could not create your section')
      draft.loading = false
      draft.sections = draft.sections.map((section) => ({
        ...section,
        isLoading: false,
      }))
    })
    .addCase(updateSection.pending, () => {})
    .addCase(updateSection.fulfilled, (draft, action) => {
      const index = draft.sections.findIndex(
        (item) => item.id === action.payload.id
      )

      if (index >= 0) {
        draft.sections[index] = action.payload
      }
      draft.sections = draft.sections.map((section) => ({
        ...section,
        isLoading: false,
      }))
      message.success('Section Updated')
    })
    .addCase(updateSection.rejected, (draft) => {
      draft.sections = draft.sections.map((section) => ({
        ...section,
        isLoading: false,
      }))
      message.error('Failed to update section')
    })
    .addCase(deleteSection.pending, (draft, action) => {
      const section = draft.sections.find(
        (item) => item.id === action.meta.arg.id
      )
      if (section) {
        section.isDeleteing = true
      }
    })
    .addCase(deleteSection.fulfilled, (draft, action) => {
      draft.sections = draft.sections.filter(
        (section) => section.id !== action.payload.id
      )
      message.success('Section deleted')
    })
    .addCase(deleteSection.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message || "Couldn't delete section"
        )
      } else {
        message.error("Couldn't delete section")
      }
      draft.sections = draft.sections.map((item) => ({
        ...item,
        isDeleteing: false,
      }))
    })
    .addCase(getSpecialDates.pending, (draft) => {
      draft.loading = true
      draft.overlap = null
    })
    .addCase(getSpecialDates.fulfilled, (draft, action) => {
      draft.loading = false
      draft.specialDates = action.payload
    })
    .addCase(getSpecialDates.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message || 'Something went wrong'
        )
      }
      draft.loading = false
    })
    .addCase(deleteSpecialDate.pending, (draft, action) => {
      const specialDate = draft.specialDates.find(
        (item) => item.id === action.meta.arg.id
      )
      if (specialDate) {
        specialDate.isDeleteing = true
      }
    })
    .addCase(deleteSpecialDate.fulfilled, (draft, action) => {
      draft.specialDates = draft.specialDates.filter(
        (specialDate) => specialDate.id !== action.payload.id
      )
      message.success('Special Date Deleted')
    })
    .addCase(deleteSpecialDate.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message ||
            "Couldn't delete special date"
        )
      } else {
        message.error(
          "Something went wrong, we couldn't delete this special date"
        )
      }
      draft.specialDates = draft.specialDates.map((item) => ({
        ...item,
        isDeleteing: false,
      }))
    })
    .addCase(createSpecialDate.pending, (draft) => {
      draft.loading = true
    })
    .addCase(createSpecialDate.fulfilled, (draft, action) => {
      draft.specialDate = action.payload
      draft.specialDates = [...draft.specialDates, action.payload].map(
        (specialDate) => ({
          ...specialDate,
          isLoading: false,
        })
      )
      message.success('Special Date Created')
      draft.loading = false
    })
    .addCase(createSpecialDate.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(action.payload.message)
      } else {
        message.error(
          'Something went wrong, we could not create your specialDate'
        )
      }
      draft.loading = false
      draft.specialDates = draft.specialDates.map((specialDate) => ({
        ...specialDate,
        isLoading: false,
      }))
    })
    .addCase(updateSpecialDate.pending, () => {})
    .addCase(updateSpecialDate.fulfilled, (draft, action) => {
      const index = draft.specialDates.findIndex(
        (item) => item.id === action.payload.id
      )

      if (index >= 0) {
        draft.specialDates[index] = action.payload
      }
      draft.specialDates = draft.specialDates.map((specialDate) => ({
        ...specialDate,
        isLoading: false,
      }))
      message.success('Special Date Updated')
    })
    .addCase(updateSpecialDate.rejected, (draft, action) => {
      draft.specialDates = draft.specialDates.map((specialDate) => ({
        ...specialDate,
        isLoading: false,
      }))
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message ||
            "Couldn't Update Special Date"
        )
      } else {
        message.error('Failed to update specialDate')
      }
    })
    .addCase(getBookingsCoversTotal.pending, (draft) => {
      draft.loading = true
    })
    .addCase(getBookingsCoversTotal.fulfilled, (draft, action) => {
      draft.bookingsCovers = action.payload
      draft.loading = false
    })
    .addCase(getBookingsCoversTotal.rejected, (draft, action) => {
      draft.bookingsCovers = 0
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.warning(
          action.payload.response?.data?.message ||
            "Couldn't fetch total covers"
        )
      } else {
        message.error('Failed fetch total covers')
      }
      draft.loading = false
    })
    .addCase(getSearchBookings.pending, (draft) => {
      draft.searchBookings.isLoading = true
    })
    .addCase(getSearchBookings.fulfilled, (draft, action) => {
      draft.searchBookings.bookings = action.payload
      draft.searchBookings.isLoading = false
    })
    .addCase(getSearchBookings.rejected, (draft) => {
      draft.searchBookings = initialState.searchBookings
    })
    .addCase(createCardCharge.pending, (draft) => {
      draft.cardCharge.isLoading = true
    })
    .addCase(createCardCharge.fulfilled, (draft, action) => {
      draft.cardCharge.chargeResult = action.payload
      draft.cardCharge.isLoading = false
    })
    .addCase(createCardCharge.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not charge customers card'
        )
        draft.cardCharge = initialState.cardCharge
        return
      }
      message.error('Something went wrong, we could not charge customers card')
      draft.cardCharge = initialState.cardCharge
    })
    .addCase(setTable, (draft, action) => {
      draft.table = action.payload
    })

    .addCase(clearConfig, (draft) => {
      draft.config = null
    })
    .addCase(setCurrentConfigSerial, (draft, action) => {
      draft.currentConfig = action.payload
    })
    .addCase(clearSelectedFloorPlan, (draft) => {
      draft.selectedFloorPlan.plan = null
    })
    .addCase(getDailyStats.pending, (draft) => {
      draft.dailyStats.isLoading = true
    })
    .addCase(getDailyStats.fulfilled, (draft, action) => {
      draft.dailyStats.dailyStatsResult = action.payload
      draft.dailyStats.isLoading = false
    })
    .addCase(getDailyStats.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not fetch daily stats'
        )
        draft.dailyStats.isLoading = false
        draft.dailyStats = initialState.dailyStats
        return
      }
      message.error('Something went wrong, we could not fetch daily stats')
      draft.dailyStats.isLoading = false
      draft.dailyStats = initialState.dailyStats
    })
    .addCase(getWeeklyStats.pending, (draft) => {
      draft.weeklyStats.isLoading = true
    })
    .addCase(getWeeklyStats.fulfilled, (draft, action) => {
      draft.weeklyStats.weeklyStatsResult = action.payload
      draft.weeklyStats.isLoading = false
    })
    .addCase(getWeeklyStats.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not fetch weekly stats'
        )
        draft.weeklyStats.isLoading = false
        draft.weeklyStats.weeklyStatsResult =
          initialState.weeklyStats.weeklyStatsResult
        return
      }
      message.error('Something went wrong, we could not fetch weekly stats')
      draft.weeklyStats.isLoading = false
      draft.weeklyStats = initialState.weeklyStats
    })
    .addCase(getRangeSummary.pending, (draft) => {
      draft.rangeSummary.isLoading = true
    })
    .addCase(getRangeSummary.fulfilled, (draft, action) => {
      draft.rangeSummary.rangeSummaryResult = action.payload
      draft.rangeSummary.isLoading = false
    })
    .addCase(getRangeSummary.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(
          action.payload.response?.data?.message ||
            'Something went wrong, we could not fetch your stats for these dates'
        )
        draft.rangeSummary.isLoading = false
        draft.rangeSummary.rangeSummaryResult = null
        return
      }
      message.error(
        'Something went wrong, we could not fetch your stats for these dates'
      )
      draft.rangeSummary.isLoading = false
      draft.rangeSummary.rangeSummaryResult = null
    })

    .addCase(getAreas.pending, (draft) => {
      // draft.areas.loading.all = true
      draft.areas.isLoading = true
    })
    .addCase(getAreas.fulfilled, (draft, action) => {
      // draft.areas.loading.all = false
      draft.areas.isLoading = false
      draft.areas.items = action.payload
    })
    .addCase(getAreas.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(action.payload?.response?.data?.message)
      } else {
        message.error('Something went wrong, we could not fetch your areas')
      }
      draft.areas.isLoading = false
    })
    .addCase(postArea.pending, (_draft) => {
      //draft.areas.loading.create = true
    })
    .addCase(postArea.fulfilled, (draft, action) => {
      //draft.areas.loading.create = false

      const removeFrom = draft.areas.items.filter((item) =>
        item.units.find((item) => action.meta.arg.area.units.includes(item))
      )
      removeFrom.forEach((item) => {
        item.units = item.units.filter(
          (item) => !action.meta.arg.area.units.includes(item)
        )
      })
      draft.areas.items.push(action.payload)
    })
    .addCase(postArea.rejected, (_draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(action.payload?.response?.data?.message)
      } else {
        message.error('Something went wrong, we could not create this area')
      }
      // draft.areas.loading.create = false
    })
    .addCase(putArea.pending, (draft, action) => {
      const item = draft.areas.items.find(
        (item) => item.id === action.meta.arg.area.id
      )
      if (action.meta.arg.reorder) {
        // item.isSorting = true
        draft.areas.isSorting = true
        return
      }

      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].map((item) => ({
        ...item,
        isLoading: action.meta.arg.area.units.includes(item.id),
      }))
      if (item) {
        item.isLoading = true
      }
      //  draft.areas.loading.edit = true
    })
    .addCase(putArea.fulfilled, (draft, action) => {
      /**
       *
       */
      if (!action.meta.arg.reorder) {
        const removeFrom = draft.areas.items.filter((item) =>
          item.units.find((item) => action.meta.arg.area.units.includes(item))
        )

        removeFrom.forEach((item) => {
          item.units = item.units.filter(
            (item) => !action.meta.arg.area.units.includes(item)
          )
        })
      }
      draft.areas.isSorting = false

      /**
       *
       */
      const index = draft.areas.items.findIndex(
        (item) => item.id === action.payload.id
      )
      if (index >= 0) {
        draft.areas.items[index] = action.payload
      }

      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].map((item) => ({
        ...item,
        isLoading: false,
      }))
    })
    .addCase(putArea.rejected, (draft, action) => {
      draft.areas.isSorting = false
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(action.payload?.response?.data?.message)
      } else {
        message.error('Something went wrong, we could not amend this area')
      }
      const item = draft.areas.items.find(
        (item) => item.id === action.meta.arg.area.id
      )
      if (item) {
        item.isLoading = false
        item.isSorting = false
      }

      draft.tables[action.meta.arg.serial] = draft.tables[
        action.meta.arg.serial
      ].map((item) => ({
        ...item,
        isLoading: false,
      }))
    })
    .addCase(putAllAreas.pending, (draft) => {
      draft.areas.isSorting = true
    })
    .addCase(putAllAreas.fulfilled, (draft, _action) => {
      draft.areas.isSorting = false
      /**
       * Units mising in this response
       */
      //draft.areas.items = action.payload
    })
    .addCase(putAllAreas.rejected, (draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(action.payload?.response?.data?.message)
      } else {
        message.error('Something went wrong, we could not amend your areas')
      }
      draft.areas.isSorting = false
    })
    .addCase(deleteArea.pending, (draft, action) => {
      // draft.areas.loading.all = true
      const area = draft.areas.items.find(
        (item) => item.id === action.meta.arg.area.id
      )
      if (area) {
        area.isLoading = true
      }
    })
    .addCase(deleteArea.fulfilled, (draft, action) => {
      draft.areas.items = draft.areas.items.filter(
        (item) => item.id !== action.meta.arg.area.id
      )
      const item = draft.areas.items.find((item) => item.position === 0)
      if (item) {
        item.units = [...item.units, ...action.meta.arg.area.units]
      }
    })
    .addCase(deleteArea.rejected, (_draft, action) => {
      if (
        action.payload &&
        isAxiosError<{ message?: string }>(action.payload)
      ) {
        message.error(action.payload?.response?.data?.message)
      } else {
        message.error('Something went wrong, we could not delete this area')
      }
      // draft.areas.loading.all = false
    })
)

export default reducer
