import { Reducer } from '@reduxjs/toolkit'
import message from 'common/message/message'
import { produce } from 'immer'

import uniqBy from 'lodash/uniqBy'
import { ListSync } from 'connect-types/integrations/listSync.type'
import { AddressUpdateRequestBody } from 'connect-types/locations/addressUpdateRequestBody.type'
import { BandwidthSettingsUpdateRequestBody } from 'connect-types/locations/bandwidthSettingsUpdateRequestBody.type'
import { GooglePlaceId } from 'connect-types/locations/googlePlaceId.type'
import { SocialSettingsUpdateRequestBody } from 'connect-types/locations/socialSettingsUpdateRequestBody.type'
import {
  ExportCommand,
  GenericCommand,
} from 'connect-types/locations/superCommands.type'
import { TimeoutSettingsUpdateRequestBody } from 'connect-types/locations/timeoutSettingsUpdateRequestBody.type'
import { WifiSettingsUpdateRequestBody } from 'connect-types/locations/wifiSettingsUpdateRequestBody.type'
import { FacebookAccount } from 'connect-types/reviews/facebookAccounts.type'
import { FacebookPage } from 'connect-types/reviews/facebookPages.type'
import { ActionType, getType } from 'typesafe-actions'
import * as actions from './settings.actions'

export type SettingsAction = ActionType<typeof actions>

export interface StateType {
  addressSettings: AddressUpdateRequestBody
  timeoutSettings: TimeoutSettingsUpdateRequestBody
  bandwidthSettings: BandwidthSettingsUpdateRequestBody
  wifiSettings: WifiSettingsUpdateRequestBody

  socialSettings: SocialSettingsUpdateRequestBody

  isLoading: boolean
  isGeneralSettingsUpdateRequired: boolean
  isAddressSettingsUpdateRequired: boolean
  isTimeoutSettingsUpdateRequired: boolean
  isBandwidthSettingsUpdateRequired: boolean
  isWifiSettingsUpdateRequired: boolean
  isLocationSicTypeUpdateRequired: boolean
  isCaptureSettingsUpdateRequired: boolean
  isOtherSettingsUpdateRequired: boolean
  isSocialSettingsUpdateRequired: boolean
  isBrandingSettingsUpdateRequired: boolean
  isScheduleUpdateRequired: boolean
  isBrandingLoading: boolean
  listSync: ListSync[]
  serial: string
  googlePlaceId: GooglePlaceId
  facebookAccounts: FacebookAccount[]
  facebookAuthorizationUrl: string
  facebookPages: FacebookPage[]
  radiusSecret: string
  superCommand: GenericCommand
  exportCommand: ExportCommand
}

const initialState: StateType = {
  addressSettings: null,
  timeoutSettings: null,
  bandwidthSettings: null,
  wifiSettings: null,

  socialSettings: {
    enabled: true,
    page: ' ',
    kind: 'facebook',
  },

  isLoading: false,
  isGeneralSettingsUpdateRequired: false,
  isAddressSettingsUpdateRequired: false,
  isTimeoutSettingsUpdateRequired: false,
  isBandwidthSettingsUpdateRequired: false,
  isWifiSettingsUpdateRequired: false,
  isLocationSicTypeUpdateRequired: false,
  isCaptureSettingsUpdateRequired: false,
  isOtherSettingsUpdateRequired: false,
  isSocialSettingsUpdateRequired: false,
  isBrandingSettingsUpdateRequired: false,
  isScheduleUpdateRequired: false,
  isBrandingLoading: false,
  listSync: [],
  serial: null,
  googlePlaceId: null,
  facebookAccounts: [],
  facebookAuthorizationUrl: null,
  facebookPages: [],
  radiusSecret: null,
  superCommand: {
    type: 'COMMAND',
    command: null,
  },
  exportCommand: {
    type: 'EXPORT',
    to: null,
  },
}

/* tslint:disable cyclomatic-complexity */
const settingsReducer: Reducer<StateType, SettingsAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      /**
       * LOCATIONS_TIMEOUTS_SETTINGS_REQUEST
       *
       * /locations/{serial}/settings/timeouts
       * Get the timeout settings associated with the location
       */
      case getType(actions.locationsTimeoutsSettingsRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/timeouts
       * Get the timeout settings associated with the location
       */
      case getType(actions.locationsTimeoutsSettingsRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.timeoutSettings = action.payload.timeoutSettings
        break
      }

      /**
       * LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/timeouts
       * Get the timeout settings associated with the location
       */
      case getType(actions.locationsTimeoutsSettingsRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      /**
       * UPDATE_LOCATIONS_TIMEOUTS_SETTINGS_REQUEST
       *
       * /locations/{serial}/settings/timeouts
       * Get the timeout settings associated with the location
       */
      case getType(actions.updateLocationsTimeoutsSettingsRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * UPDATE_LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/timeouts
       * Get the timeout settings associated with the location
       */
      case getType(actions.updateLocationsTimeoutsSettingsRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.timeoutSettings = action.payload.timeoutSettings
        draft.isTimeoutSettingsUpdateRequired = false
        break
      }

      /**
       * UPDATE_LOCATIONS_TIMEOUTS_SETTINGS_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/timeouts
       * Get the timeout settings associated with the location
       */
      case getType(actions.updateLocationsTimeoutsSettingsRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      /**
       * UPDATE_LOCATIONS_WIFI_SETTINGS_REQUEST
       *
       * /locations/{serial}/settings/wifi
       * Get the WiFi settings associated with the location
       */
      case getType(actions.updateLocationsWifiSettingsRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * UPDATE_LOCATIONS_WIFI_SETTINGS_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/wifi
       * Get the WiFi settings associated with the location
       */
      case getType(actions.updateLocationsWifiSettingsRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.wifiSettings = action.payload.wifiSettings
        draft.isWifiSettingsUpdateRequired = false
        break
      }

      /**
       * UPDATE_LOCATIONS_WIFI_SETTINGS_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/wifi
       * Get the WiFi settings associated with the location
       */
      case getType(actions.updateLocationsWifiSettingsRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      /**
       * LOCATIONS_BANDWIDTH_SETTINGS_REQUEST
       *
       * /locations/{serial}/settings/bandwidth
       * Get the bandwidth settings associate with the location
       */
      case getType(actions.locationsBandwidthSettingsRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/bandwidth
       * Get the bandwidth settings associate with the location
       */
      case getType(actions.locationsBandwidthSettingsRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.bandwidthSettings = action.payload.bandwidthSettings
        break
      }

      /**
       * LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/bandwidth
       * Get the bandwidth settings associate with the location
       */
      case getType(actions.locationsBandwidthSettingsRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      case getType(actions.updateLocationBandwidthInStore): {
        draft.bandwidthSettings = action.payload.bandwidthSettings
        draft.isBandwidthSettingsUpdateRequired = true
        break
      }

      case getType(actions.updateLocationTimeoutsInStore): {
        draft.timeoutSettings = action.payload.timeoutSettings
        draft.isTimeoutSettingsUpdateRequired = true
        break
      }

      /**
       * UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_REQUEST
       *
       * /locations/{serial}/settings/bandwidth
       * Get the bandwidth settings associate with the location
       */
      case getType(actions.updateLocationsBandwidthSettingsRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_SUCCESS
       *
       * /locations/{serial}/settings/bandwidth
       * Get the bandwidth settings associate with the location
       */
      case getType(actions.updateLocationsBandwidthSettingsRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.isBandwidthSettingsUpdateRequired = false
        draft.bandwidthSettings = action.payload.bandwidthSettings
        break
      }

      /**
       * UPDATE_LOCATIONS_BANDWIDTH_SETTINGS_REQUEST_FAILURE
       *
       * /locations/{serial}/settings/bandwidth
       * Get the bandwidth settings associate with the location
       */
      case getType(actions.updateLocationsBandwidthSettingsRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      case getType(actions.locationsFacebookAccountsRequest): {
        draft.isLoading = true
        draft.facebookAccounts = []
        break
      }

      case getType(actions.locationsFacebookAccountsRequestSuccess): {
        draft.isLoading = false

        draft.facebookAccounts = uniqBy(
          action.payload.facebookAccounts,
          'accessToken'
        ).map((item) => item)
        break
      }

      case getType(actions.locationsFacebookAccountsRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.locationsFacebookAccountsPagesRequest): {
        draft.isLoading = true
        draft.facebookPages = []
        break
      }

      case getType(actions.locationsFacebookAccountsPagesRequestSuccess): {
        draft.isLoading = false
        draft.facebookPages = action.payload.facebookPages
        break
      }

      case getType(actions.locationsFacebookAccountsPagesRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.locationsFacebookAuthorizeAccountRequest): {
        draft.isLoading = true
        draft.facebookAuthorizationUrl = null
        break
      }

      case getType(actions.locationsFacebookAuthorizeAccountRequestSuccess): {
        draft.isLoading = false
        draft.facebookAuthorizationUrl = action.payload.redirectUrl
        break
      }

      case getType(actions.locationsFacebookAuthorizeAccountRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.updateLocationsFacebookPageRequest): {
        draft.isLoading = true

        break
      }

      case getType(actions.updateLocationsFacebookPageRequestSuccess): {
        draft.isLoading = false

        message.success('Facebook settings updated')
        break
      }

      case getType(actions.updateLocationsFacebookPageRequestFailure): {
        draft.isLoading = false

        break
      }

      case getType(actions.updateLocationsIssueSuperCommandInStore): {
        draft.superCommand = action.payload.command
        break
      }

      case getType(actions.locationsIssueSuperCommandRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.locationsIssueSuperCommandRequestSuccess): {
        draft.isLoading = false
        draft.superCommand.command = null
        break
      }

      case getType(actions.locationsIssueSuperCommandRequestFailure): {
        draft.isLoading = false
        draft.superCommand.command = null
        break
      }

      case getType(actions.updateLocationsIssueExportCommandInStore): {
        draft.exportCommand = action.payload.command
        break
      }

      case getType(actions.locationsIssueExportCommandRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.locationsIssueExportCommandRequestSuccess): {
        draft.isLoading = false
        draft.exportCommand.to = null
        break
      }

      case getType(actions.locationsIssueExportCommandRequestFailure): {
        draft.isLoading = false
        draft.exportCommand.to = null
        break
      }
    }
  })

export default settingsReducer
/* tslint:enable cyclomatic-complexity */
