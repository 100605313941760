import { combineReducers } from '@reduxjs/toolkit'

import billing from './billing/billing.reducer'
import home from './home/home.reducer'
import integration from './integration/integration.reducer'
import locations from './locations/locations.reducer'
import manage from './manage/manage.reducer'
import member from './member/member.reducer'
import payment from './payment/payment.reducer'

import settings from './settings/settings.reducer'
import user from './user/user.reducer'
import analytics from './analytics/analytics.reducer'
import organisations from './organisations/organisations.reducer'
import gifting from './gifting/gifting.reducer'
import loaded from './loaded/loaded.reducer'
import config from './config/config.reducer'
import venue from './venue/venue.reducer'
import ui from './ui/ui.reducer'
import bookings from './bookings/bookings.reducer'
import organisation_user from './organisation-user/organisation-user.reducer'
import reports from './reports/reports.reducer'
import personalisation from './personalisation/personalisation.reducer'
import inbox from './inbox/inbox.reducer'

export default combineReducers({
  inbox,
  personalisation,
  billing,
  home,
  integration,
  locations,
  manage,
  reports,
  member,
  payment,
  settings,
  user,
  analytics,
  organisations,
  gifting,

  loaded,
  config,
  venue,
  ui,

  bookings,
  organisation_user,
})
