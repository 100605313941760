import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'


import * as actions from './config.actions'
import { RemoteConfigType } from 'connect-types/config/config.type'
import defaultValues from './config.defaults'

export type ConfigAction = ActionType<typeof actions>

export type StateType = {
  values: RemoteConfigType
}

const initialState: StateType = {
  values: defaultValues,
}

/* tslint:disable cyclomatic-complexity */
const configReducer: Reducer<StateType, ConfigAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setConfig): {
        draft.values = { ...draft.values, ...action.payload }
        break
      }
    }
  })

export default configReducer
/* tslint:enable cyclomatic-complexity */
