import { createAction } from 'typesafe-actions'
import { DeviceWhitelistRequestBody } from 'connect-types/locations/deviceWhitelistRequestBody.type'
import { NetworkDevice } from 'connect-types/locations/networkDevice.type'

/**
 * GET Devices in the Locations whitelist
 *
 * Path: /locations/{serial}/device/whitelist
 */

// FIXME: add in request and query params
export const locationsWhitelistRequest = createAction(
  'LOCATIONS_WHITELIST_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsWhitelistRequestSuccess = createAction(
  'LOCATIONS_WHITELIST_REQUEST_SUCCESS',
  (resolve) => (listOfWhitelistedDevices: DeviceWhitelistRequestBody[]) =>
    resolve({ listOfWhitelistedDevices })
)

export const locationsWhitelistRequestFailure = createAction(
  'LOCATIONS_WHITELIST_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations whitelist request failed' })
)

/**
 * POST Devices in the Locations whitelist
 *
 * Path: /locations/{serial}/device/whitelist
 */

// FIXME: add in request and query params
export const locationsWhitelistCreateRequest = createAction(
  'LOCATIONS_CREATE_WHITELIST_REQUEST',
  (resolve) =>
    (serial: string, whitelistedDevice: DeviceWhitelistRequestBody) =>
      resolve({ serial, whitelistedDevice })
)

// FIXME: add in response object from saga
export const locationsWhitelistCreateRequestSuccess = createAction(
  'LOCATIONS_CREATE_WHITELIST_REQUEST_SUCCESS',
  (resolve) => (whitelistedDevice: DeviceWhitelistRequestBody) =>
    resolve({ whitelistedDevice })
)

export const locationsWhitelistCreateRequestFailure = createAction(
  'LOCATIONS_CREATE_WHITELIST_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'create locations whitelist request failed' })
)

/**
 * PUT Delete device from the whitelist
 *
 * Path: /locations/{serial}/device/whitelist/{id}
 */

// FIXME: add in request and query params
export const updateLocationsWhitelistRequest = createAction(
  'UPDATE_LOCATIONS_WHITELIST_REQUEST',
  (resolve) =>
    (
      serial: string,
      id: string,
      whitelistedDevice: DeviceWhitelistRequestBody
    ) =>
      resolve({ serial, id, whitelistedDevice })
)

// FIXME: add in response object from saga
export const updateLocationsWhitelistRequestSuccess = createAction(
  'UPDATE_LOCATIONS_WHITELIST_REQUEST_SUCCESS',
  (resolve) => (whitelistedDevice: DeviceWhitelistRequestBody) =>
    resolve({ whitelistedDevice })
)

export const updateLocationsWhitelistRequestFailure = createAction(
  'UPDATE_LOCATIONS_WHITELIST_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations whitelist request failed' })
)
/**
 * DELETE Delete device from the whitelist
 *
 * Path: /locations/{serial}/device/whitelist/{id}
 */

// FIXME: add in request and query params
export const deleteLocationsWhitelistRequest = createAction(
  'DELETE_LOCATIONS_WHITELIST_REQUEST',
  (resolve) => (serial: string, id: string) => resolve({ serial, id })
)

// FIXME: add in response object from saga
export const deleteLocationsWhitelistRequestSuccess = createAction(
  'DELETE_LOCATIONS_WHITELIST_REQUEST_SUCCESS',
  (resolve) => (id: string) => resolve({ id })
)

export const deleteLocationsWhitelistRequestFailure = createAction(
  'DELETE_LOCATIONS_WHITELIST_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete locations whitelist request failed' })
)

export const locationsDeviceRequest = createAction(
  'LOCATIONS_DEVICE_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

export const locationsDeviceRequestSuccess = createAction(
  'LOCATIONS_DEVICE_REQUEST_SUCCESS',
  (resolve) => (listOfNetworkDevices: NetworkDevice[]) =>
    resolve({ listOfNetworkDevices })
)

export const locationsDeviceRequestFailure = createAction(
  'LOCATIONS_DEVICE_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'Failed to Fetch devices' })
)

/**
 * POST Create a device
 *
 * Path: /locations/{serial}/device
 */

export const createLocationsDeviceRequest = createAction(
  'CREATE_LOCATIONS_DEVICE_REQUEST',
  (resolve) => (serial: string, networkDevice: NetworkDevice) =>
    resolve({ serial, networkDevice })
)
export const createLocationsDeviceRequestSuccess = createAction(
  'CREATE_LOCATIONS_DEVICE_REQUEST_SUCCESS',
  (resolve) => (networkDevice: NetworkDevice) => resolve({ networkDevice })
)

export const createLocationsDeviceRequestFailure = createAction(
  'CREATE_LOCATIONS_DEVICE_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'Failed to Create device' })
)

/**
 * PUT Update a device
 *
 * Path: /locations/{serial}/device/{id}
 */

// FIXME: add in request and query params
export const updateLocationsDeviceRequest = createAction(
  'UPDATE_LOCATIONS_DEVICE_REQUEST',
  (resolve) => (serial: string, id: string, networkDevice: NetworkDevice) =>
    resolve({ serial, id, networkDevice })
)

// FIXME: add in response object from saga
export const updateLocationsDeviceRequestSuccess = createAction(
  'UPDATE_LOCATIONS_DEVICE_REQUEST_SUCCESS',
  (resolve) => (networkDevice: NetworkDevice) => resolve({ networkDevice })
)

export const updateLocationsDeviceRequestFailure = createAction(
  'UPDATE_LOCATIONS_DEVICE_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations device request failed' })
)
/**
 * DELETE Delete a device
 *
 * Path: /locations/{serial}/device/{id}
 */

// FIXME: add in request and query params
export const deleteLocationsDeviceRequest = createAction(
  'DELETE_LOCATIONS_DEVICE_REQUEST',
  (resolve) => (serial: string, id: string) => resolve({ serial, id })
)

// FIXME: add in response object from saga
export const deleteLocationsDeviceRequestSuccess = createAction(
  'DELETE_LOCATIONS_DEVICE_REQUEST_SUCCESS',
  (resolve) => (networkDevice: NetworkDevice) => resolve({ networkDevice })
)

export const deleteLocationsDeviceRequestFailure = createAction(
  'DELETE_LOCATIONS_DEVICE_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete locations device request failed' })
)
