import { TimeFormats } from 'common/datepicker/utilities/formats'
import { eachDayOfInterval, isAfter, isBefore, parseISO } from 'date-fns'
import { formatInTimeZone, toDate, utcToZonedTime } from 'date-fns-tz'
import { TimezoneName } from 'countries-and-timezones'
import format from 'date-fns/format'
import { FormatDateForBackend } from 'state/entities/bookings/bookings.helpers'
import {
  BookingConfigType,
  BusinessHoursType,
  daysMap,
} from 'state/entities/bookings/bookings.types'
import { getFirst, getLast } from 'utils/common'

export function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  const d = new Date(str)
  return d.toISOString() === str
}

export const splitHoursMinutesTimeString = (time = '') => {
  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])

  return {
    hours,
    minutes,
  }
}

export const hoursToMins = (HHmm: string) => {
  const { hours, minutes } = splitHoursMinutesTimeString(HHmm)
  return hours * 60 + minutes
}

export const convertDateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

export const newDateWithConfigTz = (timezone: TimezoneName) => {
  const newDate = new Date()
  return utcToZonedTime(
    toDate(formatInTimeZone(newDate, timezone, TimeFormats.iso)),
    timezone
  )
}

export const getDateFromDateString = (
  dateString: string,
  timezone: TimezoneName = 'UTC'
) => {
  const date = toDate(dateString)
  const tzDate = utcToZonedTime(date, timezone)

  if (isNaN(Date.parse(dateString))) return newDateWithConfigTz(timezone)

  return tzDate
}

export const getAUsefullDateTimeFromTimeAndDate = (
  date: string,
  time: string
) => {
  if (!time || !date) return
  const { hours, minutes } = splitHoursMinutesTimeString(time)
  const timeNow = new Date(date)
  timeNow.setUTCHours(hours)
  timeNow.setUTCMinutes(minutes)
  timeNow.setUTCSeconds(0)
  return timeNow
}

export const getHourMinsFromDateAndTime = (
  date: Date = new Date(),
  time = '00:00'
) => {
  try {
    const { hours, minutes } = splitHoursMinutesTimeString(time)
    date.setUTCHours(hours)
    date.setUTCMinutes(minutes)
    date.setUTCSeconds(0)

    return date
  } catch {
    return new Date()
  }
}

export const getUtcHourMinsFromDateAndTime = (
  date: Date = new Date(),
  time = '00:00'
) => {
  try {
    const { hours, minutes } = splitHoursMinutesTimeString(time)
    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)

    return `${date.getUTCHours()}:${String(date.getUTCMinutes()).padStart(
      2,
      '0'
    )}`
  } catch {
    // return new Date()
  }
}

export const dateFromTimezone = (
  date: Date = new Date(),
  timeZone = 'America/Chicago'
) => {
  const newDate = date.toLocaleString('en-US', { timeZone })
  return new Date(newDate)
}

export const formatForTimezone = (
  value: string,
  timezone = 'Europe/London'
) => {
  const { hours, minutes } = splitHoursMinutesTimeString(value)
  const timeZone = timezone ? timezone : 'Europe/London'
  const d = new Date()

  d.setUTCHours(hours)
  d.setUTCMinutes(minutes)
  d.setUTCSeconds(0)

  return d
    .toLocaleTimeString('en-US', {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    })
    .substr(0, 5)
}

export const startOfHourFromString = (HHmm = '') => {
  return HHmm.slice(0, 3) + '00'
}

//currently unused
export const isSpecialDate = (
  date: Date,
  config: BookingConfigType | null
): { value: boolean; data: BusinessHoursType } => {
  if (!config) return { value: false, data: null }
  if (!config.specialDates) return { value: false, data: null }

  const dateAsDayOfWeek = format(
    date,
    'EEEE'
  ).toLowerCase() as (typeof daysMap)[number]

  const formattedToDateString = FormatDateForBackend(date)

  let returnVal = { value: false, data: null }

  config.specialDates.forEach((date) => {
    if (date.dates.find((d) => d === formattedToDateString)) {
      returnVal = { value: true, data: date.businessHours[dateAsDayOfWeek] }
      return
    }
  })

  return returnVal
}

export const isDateClosed = (
  currentDate: Date,
  config: BookingConfigType | null,
  ignoreOfflineCheck?: boolean
) => {
  if (!config) return false
  if (config.offlineBookings && !ignoreOfflineCheck) return false
  const formattedDay = format(
    currentDate,
    'EEEE'
  ).toLowerCase() as (typeof daysMap)[number]
  const selectedDay = config?.businessHours[formattedDay]

  let shouldSpecialReturn = false
  let specialReturn = false

  if (config?.specialDates) {
    const formattedToDateString = format(
      currentDate,
      TimeFormats.BackendYearMonthDay
    )

    config.specialDates.forEach((date) => {
      if (date.dates.find((d) => d === formattedToDateString)) {
        shouldSpecialReturn = true
        const selectedSpecialDay = date.businessHours[formattedDay]
        if (
          !getFirst(selectedSpecialDay.open) ||
          !getLast(selectedSpecialDay.close)
        ) {
          specialReturn = true
        }
      }
    })
  }
  if (shouldSpecialReturn) return specialReturn

  if (!getFirst(selectedDay.open) || !getLast(selectedDay.close)) {
    return true
  }

  /**
   * Cycle through open/close times, see if we are closed
   */

  return false
}

export const isDateCurrentlyClosed = (
  currentDate: Date,
  config: BookingConfigType | null
) => {
  if (!config) return false
  const formattedDay = format(
    currentDate,
    'EEEE'
  ).toLowerCase() as (typeof daysMap)[number]
  const selectedDay = config?.businessHours[formattedDay]
  if (!getFirst(selectedDay.open) || !getLast(selectedDay.close)) {
    return true
  }
  const compareDate = new Date(
    new Date(0).setHours(currentDate.getHours(), currentDate.getMinutes())
  )

  const openWindows = selectedDay.open.filter((openItem, index) => {
    const closedItem = selectedDay.close[index]
    const closedDate = parseISO(closedItem)
    const openDate = parseISO(openItem)

    return isAfter(compareDate, openDate) && isBefore(compareDate, closedDate)
  })

  if (openWindows.length === 0) {
    return true
  }

  return false
}

export const friendlyDate = (date: number): string => {
  let d = ''
  if (date === 1) {
    d = date + 'st'
  } else if (date === 2) {
    d = date + 'nd'
  } else if (date === 3) {
    d = date + 'rd'
  } else if (date >= 4 && date <= 20) {
    d = date + 'th'
  } else if (date === 21) {
    d = date + 'st'
  } else if (date === 22) {
    d = date + 'nd'
  } else if (date === 23) {
    d = date + 'rd'
  } else if (date >= 24 && date < 31) {
    d = date + 'th'
  } else if (date === 31) {
    d = date + 'st'
  }
  return d
}

export const getDatesInRange =
  (startDate: Date) =>
  (endDate: Date): string[] => {
    return eachDayOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    }).map((date) => {
      return format(date, TimeFormats.BackendYearMonthDay)
    })
  }
