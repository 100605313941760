import { Button, Center, Heading, Spinner, Stack, Text } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { NormalRoutesType, newRoutesArray } from 'paths'
import {
  IndexRouteObject,
  Link,
  NonIndexRouteObject,
  RouteObject,
  isRouteErrorResponse,
  matchPath,
  matchRoutes,
  useRouteError,
} from 'react-router-dom'

function ErrorBoundary() {
  const error = useRouteError()
  const isChunkError =
    error.toString().includes('ChunkLoad') ||
    error.toString().includes('Loading CSS chunk')

  if (isRouteErrorResponse(error) && error.status === 401) {
    // the response json is automatically parsed to
    // `error.data`, you also have access to the status
    return (
      <div>
        <h1>{error.status}</h1>
        <h2>{error.data.sorry}</h2>
        <p>
          Go ahead and email {error.data.hrEmail} if you feel like this is a
          mistake.
        </p>
      </div>
    )
  }
  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Center boxSize="full">
        <Stack align="center">
          <Heading>{error.status}</Heading>

          <Text>this page doesnt exist</Text>
          <Button size="lg" as={Link} to="..">
            Reload
          </Button>
        </Stack>
      </Center>
    )
  }
  if (isChunkError) {
    window.location.reload()
    return <Spinner />
  }
  Sentry.captureException(error)

  console.error(error)
  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  return <>{error.toString()}</>
}

const RoutesToItem = (routes: NormalRoutesType[]): RouteObject[] =>
  routes.map((item) => {
    if (item.index) {
      const newItem: IndexRouteObject = {
        index: true,
        lazy: async () => {
          const Component = await import(`${item.component}`)
          return { Component: Component.default }
        },
        path: item.path,
        errorElement: <ErrorBoundary />,
      }
      return newItem
    }

    const newItem: NonIndexRouteObject = {
      path: item.path,
      errorElement: <ErrorBoundary />,
      lazy: async () => {
        const Component = await import(`${item.component}`)

        return { Component: Component.default }
      },
      children: RoutesToItem(item?.children ?? []),
      /** @ts-ignore */
      preload: () => {
        console.log('IS-PRE_LOADING')
        import(`${item.component}`)
      },
    }

    return newItem
  })

export const routes = RoutesToItem(newRoutesArray)

export const findComponentForRoute = (path = '') => {
  const matches = matchRoutes(routes, `${path}`) ?? []
  const matchingRoute = matches.find((route) => {
    return matchPath(`${path}`, route.pathname)
  })

  return matchingRoute ? matchingRoute?.route : null
}

export const preloadRouteComponent = (path: string) => {
  const component = findComponentForRoute(path)
  console.log({ component, path })
  /** @ts-ignore */
  if (component && component.preload) {
    /** @ts-ignore */
    component.preload()
  }
}
