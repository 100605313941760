import { User } from '../connect-types/user/user.type'

import { useAnalytics } from 'reactfire'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useSegment } from 'connect-context/tracking/useSegment'
import { logEvent, setCurrentScreen } from 'firebase/analytics'

declare global {
  interface Window {
    Intercom: any
    FS: any
    advocately: any
    HubSpotConversations: any
    hsConversationsSettings: {
      loadImmediately: boolean
      identificationEmail?: string
      identificationToken?: string
    }
  }
}

window.hsConversationsSettings = {
  loadImmediately: false,
}

export const whenAvailable = (name: string, callback: any): void => {
  const interval = 300 // ms

  window.setTimeout(() => {
    if (window[name as keyof Window]) {
      return callback(window[name as keyof Window])
    }

    whenAvailable(name, callback)
  }, interval)
}

export const track = (user: User) => {
  if (window.location.host === 'localhost') return

  Sentry.setUser({ email: user.email, id: user.uid })
}

export const usePageViewLogger = (): any => {
  const analytics = useAnalytics()
  const { pathname } = useLocation()
  const segment = useSegment()

  useEffect(() => {
    if (!segment) return
    logEvent(analytics, 'page_view', { path_name: pathname })
    setCurrentScreen(analytics, pathname)
  }, [analytics, pathname, segment])

  useEffect(() => {
    if (!segment) return
    setTimeout(() => {
      segment.page('Product', pathname)
      segment.screen('Product', pathname)
    }, 500)
  }, [pathname, segment])

  return null
}
