import { Spinner, Flex } from '@chakra-ui/react'
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'
import { FC, PropsWithChildren } from 'react'
import { RemoteConfigProvider, useInitRemoteConfig } from 'reactfire'

const FirebaseConfig: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { status, data: remoteConfigInstance } = useInitRemoteConfig(
    async (firebaseApp) => {
      const remoteConfig = getRemoteConfig(firebaseApp)
      /*
      remoteConfig.settings = {
         minimumFetchIntervalMillis: 10000,
        fetchTimeoutMillis: 10000,
      }
      */
      try {
        await fetchAndActivate(remoteConfig)
        return remoteConfig
      } catch {}
    }
  )

  if (status === 'loading') {
    return (
      <Flex boxSize="full" justify="center" align="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <RemoteConfigProvider sdk={remoteConfigInstance}>
      {children}
    </RemoteConfigProvider>
  )
}

export default FirebaseConfig
