import { AccessVenueType } from 'components/IntegrationsPage/Access/access.types'
import { RollerVenuesType } from 'components/IntegrationsPage/Roller/roller.types'
import isEmpty from 'lodash/isEmpty'
import { call, put, takeLeading } from 'redux-saga/effects'
import { MarketingCampaignReport } from 'connect-types/marketing/marketingCampaign.type'
import { ActionType, getType } from 'typesafe-actions'
import { deleter, get, post } from 'utils/api'
import { buildUrl } from 'utils/common'
import { OrganisationRegistrationReportType } from '../insight/insight.type'
import { integrationEnabledRequestSuccess } from '../integration/integration.actions'
import { addTagToImage } from '../member/member.actions'
import * as actions from './organisations.actions'
import { LinkGeneratorType, OrganisationLabelType } from './organisations.types'

function* organisationRegistrationReport(
  action: ActionType<typeof actions.organisationRegistrationReport>
) {
  try {
    const { orgId, query } = action.payload
    const url = buildUrl(`/organisations/${orgId}/reports/customers/totals`, {
      startDate: query.start,
      endDate: query.end,
      serial: query.serial,
    })
    const response: OrganisationRegistrationReportType = yield call(async () =>
      get(url)
    )

    yield put(actions.organisationRegistrationReportSuccess(response))
  } catch (error) {
    yield put(actions.organisationRegistrationReportFailure(error.status))
  }
}

function* getComparisonReportSaga(
  action: ActionType<typeof actions.getComparisonReport.request>
) {
  try {
    const { orgId, query } = action.payload

    const now: OrganisationRegistrationReportType = yield call(async () =>
      get(
        buildUrl(`/organisations/${orgId}/reports/customers/totals`, {
          startDate: query.start,
          endDate: query.end,
        })
      )
    )

    const previous: OrganisationRegistrationReportType = yield call(async () =>
      get(
        buildUrl(`/organisations/${orgId}/reports/customers/totals`, {
          startDate: query.prevStart,
          endDate: query.prevEnd,
        })
      )
    )

    yield put(actions.getComparisonReport.success({ now, previous }))
  } catch (error) {
    yield put(actions.getComparisonReport.failure(error))
  }
}

function* organisationMarketingReport(
  action: ActionType<typeof actions.organisationMarketingReport>
) {
  try {
    const { orgId } = action.payload

    const response: {
      message: MarketingCampaignReport
      status: number
    } = yield call(async () => get(`/marketing/${orgId}/report`))

    yield put(actions.organisationMarketingReportSuccess(response.message))
  } catch (error) {
    yield put(actions.organisationMarketingReportFailure(error.status))
  }
}

function* createOrganisationLinks(
  action: ActionType<typeof actions.createOrganisationLinks.request>
) {
  try {
    const response: { payload: LinkGeneratorType } = yield call(async () =>
      post(`/generate`, action.payload, null, 'url_generator')
    )

    yield put(actions.createOrganisationLinks.success(response.payload))
  } catch (error) {
    yield put(actions.createOrganisationLinks.failure(error.status))
  }
}

function* getOrganisationLinks(
  action: ActionType<typeof actions.getOrganisationLinks.request>
) {
  try {
    const response: { payload: LinkGeneratorType[] } = yield call(async () =>
      post(`/urls`, action.payload, null, 'url_generator')
    )
    yield put(
      integrationEnabledRequestSuccess({
        storekit:
          response.payload.filter(
            (item) => item.intergrations_provider === 'store-kit'
          ).length >= 1,
      })
    )
    yield put(actions.getOrganisationLinks.success(response.payload))
  } catch (error) {
    yield put(actions.getOrganisationLinks.failure(error.status))
  }
}

function* getAccessBookingsVenuesSaga(
  action: ActionType<typeof actions.getAccessBookingsVenues.request>
) {
  try {
    const {
      body,
    }: {
      body: { data: { venues: AccessVenueType[] } }
    } = yield call(async () =>
      get(`/${action.payload.orgId}/access/venues`, 'booking_integration')
    )

    yield put(
      integrationEnabledRequestSuccess({
        access: body.data.venues.length >= 1,
      })
    )
    yield put(actions.getAccessBookingsVenues.success(body.data.venues))
  } catch (error) {
    yield put(actions.getAccessBookingsVenues.failure(error))
  }
}

function* getRollerBookingsVenuesSaga(
  action: ActionType<typeof actions.getRollerData.request>
) {
  try {
    const response: {
      [key: string]: RollerVenuesType
    } = yield call(async () =>
      get(`/${action.payload.orgId}/roller/venues`, 'booking_integration')
    )

    yield put(
      integrationEnabledRequestSuccess({
        roller: !isEmpty(response),
      })
    )

    yield put(actions.getRollerData.success(response ? response : {}))
  } catch (error) {
    yield put(actions.getRollerData.failure(error))
  }
}

function* postRollerBookingsVenuesSaga(
  action: ActionType<typeof actions.postRollerData.request>
) {
  try {
    const response: {
      pk: string
      venues: {
        [key: string]: RollerVenuesType
      }
    } = yield call(async () =>
      post(
        `/${action.payload.orgId}/roller/venues`,
        action.payload.venues,
        null,
        'booking_integration'
      )
    )

    yield put(actions.postRollerData.success(response.venues))
  } catch (error) {
    yield put(actions.postRollerData.failure(error))
  }
}

function* deleteRollerBookingsVenuesSaga(
  action: ActionType<typeof actions.deleteRollerData.request>
) {
  try {
    yield call(async () =>
      deleter(
        `/${action.payload.orgId}/roller/venues?serial=${action.payload.serial}`,
        null,
        'booking_integration'
      )
    )

    yield put(actions.deleteRollerData.success(action.payload))
  } catch (error) {
    yield put(actions.deleteRollerData.failure(error))
  }
}

function* getOrganisationLabelsSaga(
  action: ActionType<typeof actions.getOrganisationLabels.request>
) {
  try {
    const response: OrganisationLabelType[] = yield call(async () =>
      get(`/organisations/${action.payload.orgId}/labels`)
    )

    yield put(actions.getOrganisationLabels.success(response))
  } catch (error) {
    yield put(actions.getOrganisationLabels.failure(error))
  }
}

function* addOrganisationLabelToImageSaga(
  action: ActionType<typeof actions.addOrganisationLabelToImage.request>
) {
  try {
    const response: { label: OrganisationLabelType } = yield call(async () =>
      post(
        `/organisations/${action.payload.orgId}/gallery/${action.payload.imageId}/labels`,
        {
          name: action.payload.name,
        }
      )
    )
    yield put(addTagToImage(action.payload.imageId, response.label))
    yield put(actions.addOrganisationLabelToImage.success(response.label))
  } catch (error) {
    yield put(actions.addOrganisationLabelToImage.failure(error))
  }
}

function* deleteOrganisationLabelSaga(
  action: ActionType<typeof actions.deleteOrganisationLabel.request>
) {
  try {
    yield call(async () =>
      deleter(
        `/organisations/${action.payload.orgId}/labels/${action.payload.label.id}`
      )
    )
    // yield put(addTagToImage(action.payload.imageId, response.label))
    yield put(actions.deleteOrganisationLabel.success(action.payload.label))
  } catch (error) {
    yield put(actions.deleteOrganisationLabel.failure(error))
  }
}

export function* watchOrganisationRequest() {
  yield takeLeading(
    getType(actions.deleteOrganisationLabel.request),
    deleteOrganisationLabelSaga
  )
  yield takeLeading(
    getType(actions.addOrganisationLabelToImage.request),
    addOrganisationLabelToImageSaga
  )
  yield takeLeading(
    getType(actions.getOrganisationLabels.request),
    getOrganisationLabelsSaga
  )
  yield takeLeading(
    getType(actions.getRollerData.request),
    getRollerBookingsVenuesSaga
  )
  yield takeLeading(
    getType(actions.postRollerData.request),
    postRollerBookingsVenuesSaga
  )
  yield takeLeading(
    getType(actions.deleteRollerData.request),
    deleteRollerBookingsVenuesSaga
  )

  yield takeLeading(
    getType(actions.getAccessBookingsVenues.request),
    getAccessBookingsVenuesSaga
  )
  yield takeLeading(
    getType(actions.createOrganisationLinks.request),
    createOrganisationLinks
  )

  yield takeLeading(
    getType(actions.getAccessBookingsVenues.request),
    getAccessBookingsVenuesSaga
  )
  yield takeLeading(
    getType(actions.createOrganisationLinks.request),
    createOrganisationLinks
  )
  yield takeLeading(
    getType(actions.getOrganisationLinks.request),
    getOrganisationLinks
  )

  yield takeLeading(
    getType(actions.organisationRegistrationReport),
    organisationRegistrationReport
  )
  yield takeLeading(
    getType(actions.organisationMarketingReport),
    organisationMarketingReport
  )

  yield takeLeading(
    getType(actions.getComparisonReport.request),
    getComparisonReportSaga
  )
}

export default [watchOrganisationRequest]
