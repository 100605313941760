import { createAction, createAsyncAction } from 'typesafe-actions'
import { VenueType, VenueWiFi4EuType } from './venue.type'
type ValueOf<T> = T[keyof T]

export const updateWiFi4EuSettings = createAsyncAction(
  'UPDATE_WIFI_4_EU_SETTINGS_REQUEST',
  'UPDATE_WIFI_4_EU_SETTINGS_REQUEST_SUCCESS',
  'UPDATE_WIFI_4_EU_SETTINGS_REQUEST_FAILURE'
)<
  {
    serial: string
    orgId: string
    settings: VenueWiFi4EuType
  },
  VenueWiFi4EuType,
  Error
>()

export const updateVenueSettingsLocal = createAction(
  'UPDATE_VENEU_SETTINGS_LOCAL',
  (resolve) => (key: keyof VenueType, value: ValueOf<VenueType>) =>
    resolve({ key, value })
)
