import { CancelReason } from 'connect-types/members/cancel.types'
import { Filter } from 'connect-types/members/filter.type'
import { FilterCreateRequestBody } from 'connect-types/members/filterCreateRequestBody.type'
import {
  GalleryGetResponse,
  GalleryItem,
} from 'connect-types/members/galleryGetResponse.type'
import { NotificationsCreateRequestBody } from 'connect-types/members/notificationsCreateRequestBody.type'
import { NotificationList } from 'connect-types/members/profileNotificationList.type'
import { SingleTemplate } from 'connect-types/members/template.type'
import { MembersSubscriptionResponse } from 'connect-types/subscriptions/subscription.type'
import { SubscriptionsQueryParams } from 'connect-types/subscriptions/subscriptionsQueryParams.type'
import { User } from 'connect-types/user/user.type'
import { createAction, createAsyncAction } from 'typesafe-actions'
import { OrganisationLabelType } from '../organisations/organisations.types'

/**
 * GET Get a user
 *
 * Path: /members/{uid}
 */

export const setSelectedMember = createAction(
  'SET_MEMBER',
  (resolve) => (member: User) => resolve({ member })
)

/**
 * PUT Update a user
 *
 * Path: /members/{uid}
 */

// FIXME: add in request and query params

export const updateMembers = createAsyncAction(
  'UPDATE_MEMBERS_REQUEST',
  'UPDATE_MEMBERS_REQUEST_SUCCESS',
  'UPDATE_MEMBERS_REQUEST_FAILURE'
)<{ uid: string; user: User }, { user: User; uid: string }, Error>()

/**
 * GET Get Images belonging to a User
 */
export const membersGalleryContentRequest = createAction(
  'MEMBERS_GALLERY_CONTENT_REQUEST',
  (resolve) =>
    (
      orgId: string,
      offset: number,
      kind?: string,
      path?: string,
      label_like?: string,
      name_like?: string
    ) =>
      resolve({ orgId, offset, kind, path, label_like, name_like })
)

export const membersGalleryContentRequestSuccess = createAction(
  'MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS',
  (resolve) => (gallery: GalleryGetResponse, kind?: string, path?: string) =>
    resolve({ gallery, kind, path })
)

export const membersGalleryContentRequestFailure = createAction(
  'MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const membersGalleryPushContent = createAction(
  'SET_MEMBERS_GALLERY_CONTENT',
  (resolve) => (item: GalleryItem) => resolve({ item })
)

/**
 * DELETE Delete a piece of content
 *
 * Path: /members/{uid}/gallery/{id}
 */

// FIXME: add in request and query params
export const deleteMembersGalleryContentRequest = createAction(
  'DELETE_MEMBERS_GALLERY_CONTENT_REQUEST',
  (resolve) => (orgId: string, id: number) => resolve({ orgId, id })
)

// FIXME: add in response object from saga
export const deleteMembersGalleryContentRequestSuccess = createAction(
  'DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS',
  (resolve) => (id: number) => resolve({ id })
)

export const deleteMembersGalleryContentRequestFailure = createAction(
  'DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * POST Create template
 *
 * Path: /members/{uid}/templates
 */

export const senderVisible = createAction(
  'SET_SENDER_VISIBLE',
  (resolve) => (visible: boolean) => resolve({ visible })
)

// FIXME: add in request and query params
export const createMembersTemplateRequest = createAction(
  'CREATE_MEMBERS_TEMPLATE_REQUEST',
  (resolve) => (uid: string, template: SingleTemplate) =>
    resolve({ uid, template })
)

// FIXME: add in response object from saga
export const createMembersTemplateRequestSuccess = createAction(
  'CREATE_MEMBERS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => (template: SingleTemplate) => resolve({ template })
)

export const createMembersTemplateRequestFailure = createAction(
  'CREATE_MEMBERS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get/delete a template
 *
 * Path: /members/{uid}/templates/{id}
 */

// FIXME: add in request and query params
export const membersTemplateRequest = createAction(
  'MEMBERS_TEMPLATE_REQUEST',
  (resolve) => (uid: string, id?: string) => resolve({ uid, id })
)

// FIXME: add in response object from saga
export const membersTemplateRequestSuccess = createAction(
  'MEMBERS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => (templates: SingleTemplate[]) => resolve({ templates })
)

export const membersTemplateRequestFailure = createAction(
  'MEMBERS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
/**
 * DELETE Get/delete a template
 *
 * Path: /members/{uid}/templates/{id}
 */

// FIXME: add in request and query params
export const deleteMembersTemplateRequest = createAction(
  'DELETE_MEMBERS_TEMPLATE_REQUEST',
  (resolve) => (uid: string, id: string) => resolve({ uid, id })
)

// FIXME: add in response object from saga
export const deleteMembersTemplateRequestSuccess = createAction(
  'DELETE_MEMBERS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => (id: string) => resolve({ id })
)

export const deleteMembersTemplateRequestFailure = createAction(
  'DELETE_MEMBERS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * Set a filter
 *
 *
 */

export const setFilterMember = createAction(
  'SET_FILTER_MEMBER',
  (resolve) => (filter: FilterCreateRequestBody) => resolve({ filter })
)

/**
 * GET the list of filters
 *
 * Path: /members/{uid}/filters
 */

export const membersAllFilterRequest = createAction(
  'MEMBERS_ALL_FILTER_REQUEST',
  (resolve) => (orgId: string, type: string) => resolve({ orgId, type })
)

export const membersAllFilterRequestSuccess = createAction(
  'MEMBERS_ALL_FILTER_REQUEST_SUCCESS',
  (resolve) => (filters: Filter[]) => resolve({ filters })
)

export const membersAllFilterRequestFailure = createAction(
  'MEMBERS_ALL_FILTER_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get a specific filter list
 *
 * Path: /members/{uid}/filters/{id}
 */
export const membersFilterListRequest = createAction(
  'MEMBERS_FILTER_LIST_REQUEST',
  (resolve) => (orgId: string, id: string) => resolve({ orgId, id })
)

export const membersFilterListRequestSuccess = createAction(
  'MEMBERS_FILTER_LIST_REQUEST_SUCCESS',
  (resolve) => (filter: FilterCreateRequestBody) => resolve({ filter })
)

export const membersFilterListRequestFailure = createAction(
  'MEMBERS_FILTER_LIST_REQUEST_FAILURE',
  // resolve => () => resolve({ error: 'members filter list request failed' })
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * Delete a specific filter list
 *
 * Path: /members/{uid}/filters/{id}
 */
export const membersFilterListDeleteRequest = createAction(
  'MEMBERS_FILTER_LIST_DELETE_REQUEST',
  (resolve) => (orgId: string, id: string) => resolve({ orgId, id })
)

export const membersFilterListDeleteRequestSuccess = createAction(
  'MEMBERS_FILTER_LIST_DELETE_REQUEST_SUCCESS',
  (resolve) => (status: number, id: string) => resolve({ status, id })
)

export const membersFilterListDeleteRequestFailure = createAction(
  'MEMBERS_FILTER_LIST_DELETE_REQUEST_FAILURE',
  // resolve => () => resolve({ error: 'members filter list request failed' })
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * PUT update a specific filter
 *
 * Path: /members/{uid}/filters
 */
export const updateMembersFilterRequest = createAction(
  'UPDATE_MEMBERS_FILTER_REQUEST',
  (resolve) => (orgId: string, filter: FilterCreateRequestBody) =>
    resolve({ orgId, filter })
)

export const updateMembersFilterRequestSuccess = createAction(
  'UPDATE_MEMBERS_FILTER_REQUEST_SUCCESS',
  (resolve) => (filter: FilterCreateRequestBody) => resolve({ filter })
)

export const updateMembersFilterRequestFailure = createAction(
  'UPDATE_MEMBERS_FILTER_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get User's List of Subscribed Notifications
 *
 * Path: /members/{uid}/notifications
 */

export const membersNotificationsRequest = createAction(
  'MEMBERS_NOTIFICATIONS_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const membersNotificationsRequestSuccess = createAction(
  'MEMBERS_NOTIFICATIONS_REQUEST_SUCCESS',
  (resolve) => (notifications: NotificationList) => resolve({ notifications })
)

export const membersNotificationsRequestFailure = createAction(
  'MEMBERS_NOTIFICATIONS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
/**
 * PUT
 *
 * Path: /members/{uid}/notifications
 */

// FIXME: add in request and query params
export const updateMembersNotificationsRequest = createAction(
  'UPDATE_MEMBERS_NOTIFICATIONS_REQUEST',
  (resolve) => (uid: string, notification: NotificationsCreateRequestBody) =>
    resolve({ uid, notification })
)

// FIXME: add in response object from saga
export const updateMembersNotificationsRequestSuccess = createAction(
  'UPDATE_MEMBERS_NOTIFICATIONS_REQUEST_SUCCESS',
  (resolve) => (notifications: NotificationList) => resolve({ notifications })
)

export const updateMembersNotificationsRequestFailure = createAction(
  'UPDATE_MEMBERS_NOTIFICATIONS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
/**
 * DELETE
 *
 * Path: /members/{uid}/notifications
 */

// FIXME: add in request and query params
export const deleteMembersNotificationsRequest = createAction(
  'DELETE_MEMBERS_NOTIFICATIONS_REQUEST',
  (resolve) => (uid: string, id: string) => resolve({ uid, id })
)

// FIXME: add in response object from saga
export const deleteMembersNotificationsRequestSuccess = createAction(
  'DELETE_MEMBERS_NOTIFICATIONS_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const deleteMembersNotificationsRequestFailure = createAction(
  'DELETE_MEMBERS_NOTIFICATIONS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete members notifications request failed' })
)
/**
 * PUT Change password of user
 *
 * Path: /password/{uid}
 */

// FIXME: add in request and query params

export const updatePassword = createAsyncAction(
  'UPDATE_PASSWORD_REQUEST',
  'UPDATE_PASSWORD_REQUEST_SUCCESS',
  'UPDATE_PASSWORD_REQUEST_FAILURE'
)<{ uid: string; password: string }, number, Error>()

/**
 * GET Load all subscriptions
 *
 * Path: /members/{uid}/subscriptions
 */

export const membersAllSubscriptionsRequest = createAction(
  'MEMBERS_ALL_SUBSCRIPTIONS_REQUEST',
  (resolve) => (request: SubscriptionsQueryParams) => resolve(request)
)

export const membersAllSubscriptionsRequestSuccess = createAction(
  'MEMBERS_ALL_SUBSCRIPTIONS_REQUEST_SUCCESS',
  (resolve) => (response: MembersSubscriptionResponse) => resolve({ response })
)

export const membersAllSubscriptionsRequestFailure = createAction(
  'MEMBERS_ALL_SUBSCRIPTIONS_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'members subscription request failed' })
)

export const sendSupportEmailRequest = createAction(
  'SEND_SUPPORT_EMAIL_REQUEST',
  (resolve) => (request: CancelReason) => resolve({ request })
)

export const sendSupportEmailRequestSuccess = createAction(
  'SEND_SUPPORT_EMAIL_REQUEST_SUCCESS',
  (resolve) => (response: any) => resolve({ response })
)

export const sendSupportEmailRequestFailure = createAction(
  'SEND_SUPPORT_EMAIL_REQUEST_FAILURE',
  (resolve) => () => resolve()
)

export const setNotificationRequest = createAction(
  'SET_NOTIFICATION_REQUEST',
  (resolve) => (token: string) => resolve({ token })
)

export const setNotificationRequestSuccess = createAction(
  'SET_NOTIFICATION_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const setNotificationRequestFailure = createAction(
  'SET_NOTIFICATION_REQUEST_FAILURE',
  (resolve) => () => resolve()
)

export const addTagToImage = createAction(
  'ADD_TAG_TO_IMAGE',
  (resolve) => (imageId: string, label: OrganisationLabelType) =>
    resolve({ imageId, label })
)

export const removeTagToImage = createAction(
  'REMOVE_TAG_TO_IMAGE',
  (resolve) => (imageId: string, label: OrganisationLabelType) =>
    resolve({ imageId, label })
)
