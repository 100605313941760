import { subDays } from 'date-fns'
import { TimeGroupTypes } from 'utils/TimeGenerator'

export interface DefaultQueryType {
  startDate: number
  endDate: number
  limit: number
  offset: number
  cursor: string
  sort: 'desc' | 'asc'
  group: TimeGroupTypes

  serial?: string
}

export const initialDefaultQuery: DefaultQueryType = {
  startDate: subDays(new Date(), 30).getTime(),
  endDate: new Date().getTime(),
  offset: 0,
  limit: 25,
  sort: 'desc',
  cursor: '',
  group: 'day',
  serial: '',
}

export interface SimpleOrgType {
  id: string
  name: string
}

export interface SimpleVenueType {
  id: string
  organisation_id: string
  name: string
  primary_color: string
  header_color: string
  icon: string
}
