import { call, put, select, takeLeading } from 'redux-saga/effects'
import * as loadedActions from 'state/entities/loaded/loaded.actions'
import { RootState } from 'state/reducers'
import { AirshipAuthType } from 'connect-types/integrations/airship.type'
import { IntegrationEnabled } from 'connect-types/integrations/integrationEnabled.type'
import { ListSync } from 'connect-types/integrations/listSync.type'
import {
  ExternalListType,
  ListUnifyRequestBody,
} from 'connect-types/integrations/listUnifyRequestBody'
import { PaypalAccountUpdateRequestBody } from 'connect-types/integrations/paypalAccountUpdateRequestBody.type'
import { PaypalLocationAccount } from 'connect-types/integrations/paypalLocationAccount.type'
import { UnifyUserCredentialCreateRequestBody } from 'connect-types/integrations/unifyUserCredentialCreateRequestBody.type'
import { ActionType, getType } from 'typesafe-actions'
import { deleter, get, post, put as putRequest } from 'utils/api'
import { buildUrl } from 'utils/common'
import * as actions from './integration.actions'

/* Get enabled
 * ========================================================================== */
function* integrationEnabledRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global

    const response: {
      status: number
      message: IntegrationEnabled
    } = yield call(async () => get(`/integration/${orgId}/connected`))

    const integrationsEnabledDetails = response.message

    yield put(loadedActions.setLoaded('enabled_integrations', true))
    yield put(
      actions.integrationEnabledRequestSuccess(integrationsEnabledDetails)
    )
  } catch (error) {
    yield put(actions.integrationEnabledRequestFailure())
  }
}

export function* watchIntegrationEnabledRequest() {
  yield takeLeading(
    getType(actions.integrationEnabledRequest),
    integrationEnabledRequest
  )
}

/* Get List of PayPal Accounts associated with a User
 * ========================================================================== */
function* integrationPaypalAccountsAllRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const response: {
      status: number
      message: PaypalAccountUpdateRequestBody[]
    } = yield call(async () => get(`/integration/${orgId}/paypal`))

    const sites = response.message

    yield put(actions.integrationPaypalAccountsAllRequestSuccess(sites))
  } catch (error) {
    yield put(actions.integrationPaypalAccountsAllRequestFailure())
  }
}

export function* watchIntegrationPaypalAccountsAllRequest() {
  yield takeLeading(
    getType(actions.integrationPaypalAccountsAllRequest),
    integrationPaypalAccountsAllRequest
  )
}

/* POST Create PayPal Account
 * ========================================================================== */
function* integrationCreatePaypalAccountRequest(
  action: ActionType<typeof actions.createIntegrationPaypalRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { paypalAccount } = action.payload

    const response: {
      status: number
      message: PaypalAccountUpdateRequestBody
    } = yield call(async () =>
      post(`/integration/${orgId}/paypal`, paypalAccount)
    )

    const sites = response.message

    yield put(actions.createIntegrationPaypalRequestSuccess(sites))
  } catch (error) {
    yield put(actions.createIntegrationPaypalRequestFailure())
  }
}

export function* watchIntegrationCreatePaypalAccountRequest() {
  yield takeLeading(
    getType(actions.createIntegrationPaypalRequest),
    integrationCreatePaypalAccountRequest
  )
}

/* Get Specific PayPal Account
 * ========================================================================== */
function* integrationSpecificPaypalAccountRequest(
  action: ActionType<typeof actions.integrationPaypalAccountRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { id } = action.payload

    const response: {
      status: number
      message: PaypalAccountUpdateRequestBody
    } = yield call(async () => get(`/integration/${orgId}/paypal/${id}`))

    const sites = response.message

    yield put(actions.integrationPaypalAccountRequestSuccess(sites))
  } catch (error) {
    yield put(actions.integrationPaypalAccountRequestFailure())
  }
}

export function* watchIntegrationSpecificPaypalAccountRequest() {
  yield takeLeading(
    getType(actions.integrationPaypalAccountRequest),
    integrationSpecificPaypalAccountRequest
  )
}

/* Update Specific PayPal Account
 * ========================================================================== */
function* integrationUpdateSpecificPaypalAccountRequest(
  action: ActionType<typeof actions.updateIntegrationPaypalAccountRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { id, paypalAccount } = action.payload

    const response: {
      status: number
      message: PaypalAccountUpdateRequestBody
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/paypal/${id}`, paypalAccount)
    )

    const sites = response.message

    yield put(actions.updateIntegrationPaypalAccountRequestSuccess(sites))
  } catch (error) {
    yield put(actions.updateIntegrationPaypalAccountRequestFailure())
  }
}

export function* watchIntegrationUpdateSpecificPaypalAccountRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationPaypalAccountRequest),
    integrationUpdateSpecificPaypalAccountRequest
  )
}

/* Associate PayPal Account With Site
 * ========================================================================== */
function* updateIntegrationPaypalAccountLocationRequest(
  action: ActionType<
    typeof actions.updateIntegrationPaypalAccountLocationRequest
  >
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { id, serial } = action.payload

    const response: {
      status: number
      message: PaypalLocationAccount
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/paypal/${id}/location/${serial}`)
    )

    const sites = response.message

    yield put(
      actions.updateIntegrationPaypalAccountLocationRequestSuccess(sites)
    )
  } catch (error) {
    yield put(actions.updateIntegrationPaypalAccountLocationRequestFailure())
  }
}

export function* watchUpdateIntegrationPaypalAccountLocationRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationPaypalAccountLocationRequest),
    updateIntegrationPaypalAccountLocationRequest
  )
}

/* GET Associated PayPal Account With Site
 * ========================================================================== */
function* integrationPaypalAccountLocationRequest(
  action: ActionType<typeof actions.integrationPaypalAccountLocationRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { id, serial } = action.payload

    const response: {
      status: number
      message: PaypalLocationAccount
    } = yield call(async () =>
      get(`/integration/${orgId}/paypal/${id}/location/${serial}`)
    )

    const sites = response.message

    yield put(actions.integrationPaypalAccountLocationRequestSuccess(sites))
  } catch (error) {
    yield put(actions.integrationPaypalAccountLocationRequestFailure())
  }
}

export function* watchIntegrationPaypalAccountLocationRequest() {
  yield takeLeading(
    getType(actions.integrationPaypalAccountLocationRequest),
    integrationPaypalAccountLocationRequest
  )
}

/* Delete PayPal Account
 * ========================================================================== */
function* integrationPaypalAccountDeleteRequest(
  action: ActionType<typeof actions.deleteIntegrationPaypalAccountRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { id } = action.payload

    const response: {
      status: number
      message: any
    } = yield call(async () => deleter(`/integration/${orgId}/paypal/${id}`))

    const sites = response.message.accountId

    yield put(actions.deleteIntegrationPaypalAccountRequestSuccess(sites))
  } catch (error) {
    yield put(actions.deleteIntegrationPaypalAccountRequestFailure())
  }
}

export function* watchDeleteIntegrationPaypalAccountRequest() {
  yield takeLeading(
    getType(actions.deleteIntegrationPaypalAccountRequest),
    integrationPaypalAccountDeleteRequest
  )
}

/* Get MailChimp API Key Belonging To User
 * ========================================================================== */
function* integrationMailchimpRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global

    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () => get(`/integration/${orgId}/mailchimp`))

    const apikey = response.message

    yield put(actions.integrationMailchimpRequestSuccess(apikey))
  } catch (error) {
    yield put(actions.integrationMailchimpRequestFailure())
  }
}

export function* watchIntegrationMailchimpRequest() {
  yield takeLeading(
    getType(actions.integrationMailchimpRequest),
    integrationMailchimpRequest
  )
}

/* Update MailChimp API Key Belonging To User
 * ========================================================================== */
function* integrationMailchimpPUTRequest(
  action: ActionType<typeof actions.updateIntegrationMailchimpRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { mailchimpApiKey } = action.payload

    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/mailchimp`, mailchimpApiKey)
    )

    const apikey = response.message

    yield put(actions.updateIntegrationMailchimpRequestSuccess(apikey))
  } catch (error) {
    yield put(actions.updateIntegrationMailchimpRequestFailure(error.response))
    yield put(actions.updateIntegrationMailchimpRequestFailure(error.response))
  }
}

export function* watchIntegrationMailchimpPUTRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationMailchimpRequest),
    integrationMailchimpPUTRequest
  )
}

/* GET MailChimp Lists Belonging To User And Location
 * ========================================================================== */
function* nativeIntegrationListRequest(
  action: ActionType<typeof actions.nativeIntegrationListRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { serial, integration } = action.payload

    const response: {
      status: number
      message: ListUnifyRequestBody[]
    } = yield call(async () =>
      get(`/integration/${orgId}/${integration}/lists/${serial}`)
    )

    const lists = response.message

    yield put(loadedActions.setLoaded('venue_integrations', true))
    yield put(actions.nativeIntegrationListRequestSuccess(lists))
  } catch (error) {
    yield put(loadedActions.setLoaded('venue_integrations', true))
    yield put(actions.nativeIntegrationListRequestFailure(error.response))
  }
}

export function* watchNativeIntegrationListRequest() {
  yield takeLeading(
    getType(actions.nativeIntegrationListRequest),
    nativeIntegrationListRequest
  )
}

/* GET Specific MailChimp List Belonging To User And Location
 * ========================================================================== */
function* nativeIntegrationSpecificListRequest(
  action: ActionType<typeof actions.nativeIntegrationSpecificListRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { serial, integration, id } = action.payload

    const response: {
      status: number
      message: ListSync
    } = yield call(async () =>
      get(`/integration/${orgId}/${integration}/lists/${serial}/${id}`)
    )

    const lists = response.message

    yield put(actions.nativeIntegrationSpecificListRequestSuccess(lists))
  } catch (error) {
    yield put(
      actions.nativeIntegrationSpecificListRequestFailure(error.response)
    )
  }
}

export function* watchNativeIntegrationSpecificListRequest() {
  yield takeLeading(
    getType(actions.nativeIntegrationSpecificListRequest),
    nativeIntegrationSpecificListRequest
  )
}

/* GET Specific MailChimp List Belonging To User And Location
 * ========================================================================== */
function* removeNativeIntegrationSpecificListRequest(
  action: ActionType<typeof actions.removeNativeIntegrationSpecificListRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { serial, integration, id } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      deleter(`/integration/${orgId}/${integration}/lists/${serial}/${id}`)
    )

    const listsId = response.message

    yield put(
      actions.removeNativeIntegrationSpecificListRequestSuccess(listsId)
    )
  } catch (error) {
    yield put(
      actions.removeNativeIntegrationSpecificListRequestFailure(error.response)
    )
  }
}

export function* watchRemoveNativeIntegrationSpecificListRequest() {
  yield takeLeading(
    getType(actions.removeNativeIntegrationSpecificListRequest),
    removeNativeIntegrationSpecificListRequest
  )
}

/* GET Specific MailChimp List Belonging To User And Location
 * ========================================================================== */
function* nativeIntegrationExternalListRequest(
  action: ActionType<typeof actions.nativeIntegrationExternalListRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { integration, detailId } = action.payload
    const params: { detailsId?: string } = {}
    const url = `/integration/${orgId}/${integration}/lists`

    if (detailId) {
      params.detailsId = detailId
    }

    const response: {
      status: number
      message: ExternalListType[]
    } = yield call(async () => get(buildUrl(url, params)))

    const lists = response.message

    yield put(actions.nativeIntegrationExternalListRequestSuccess(lists))
  } catch (error) {
    yield put(
      actions.nativeIntegrationExternalListRequestFailure(error.response)
    )
  }
}

export function* watchNativeIntegrationExternalListRequest() {
  yield takeLeading(
    getType(actions.nativeIntegrationExternalListRequest),
    nativeIntegrationExternalListRequest
  )
}

/* Update Specific MailChimp List Belonging To User And Location
 * ========================================================================== */
function* updateNativeIntegrationSpecificListRequest(
  action: ActionType<typeof actions.updateNativeIntegrationSpecificListRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { serial, integration, details } = action.payload

    const response: {
      status: number
      message: ListSync
    } = yield call(async () =>
      putRequest(
        `/integration/${orgId}/${integration}/lists/${serial}`,
        details
      )
    )

    const lists = response.message

    yield put(actions.updateNativeIntegrationSpecificListRequestSuccess(lists))
  } catch (error) {
    yield put(
      actions.updateNativeIntegrationSpecificListRequestFailure(error.response)
    )
  }
}

export function* watchUpdateNativeIntegrationSpecificListRequest() {
  yield takeLeading(
    getType(actions.updateNativeIntegrationSpecificListRequest),
    updateNativeIntegrationSpecificListRequest
  )
}

/* Get Campaign Monitor API Key Belonging To User
 * ========================================================================== */
function* integrationCampaignMonitorRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () => get(`/integration/${orgId}/campaignMonitor`))

    const apikey = response.message

    yield put(actions.integrationCampaignMonitorRequestSuccess(apikey))
  } catch (error) {
    yield put(actions.integrationCampaignMonitorRequestFailure(error.response))
  }
}

export function* watchIntegrationCampaignMonitorRequest() {
  yield takeLeading(
    getType(actions.integrationCampaignMonitorRequest),
    integrationCampaignMonitorRequest
  )
}

/* Update Campaign Monitor API Key Belonging To User
 * ========================================================================== */
function* integrationCampaignMonitorPUTRequest(
  action: ActionType<typeof actions.updateIntegrationCampaignMonitorRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { campaignMonitorApiKey } = action.payload

    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/campaignMonitor`, campaignMonitorApiKey)
    )

    const apikey = response.message

    yield put(actions.updateIntegrationCampaignMonitorRequestSuccess(apikey))
  } catch (error) {
    yield put(
      actions.updateIntegrationCampaignMonitorRequestFailure(error.response)
    )
  }
}

export function* watchIntegrationCampaignMonitorPUTRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationCampaignMonitorRequest),
    integrationCampaignMonitorPUTRequest
  )
}

/* Get Campaign Monitor API Key Belonging To User
 * ========================================================================== */
function* integrationAirshipRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global

    const response: {
      status: number
      message: [{ id: string; apiKey: string }]
    } = yield call(async () => get(`/integration/${orgId}/airship`))

    const airshipAccounts: AirshipAuthType[] = []
    const apiKey = response.message

    apiKey.map((key) => {
      const split = key.apiKey.split(';')
      const account: AirshipAuthType = {
        username: split[0],
        password: split[1],
        sourceId: split[2],
        name: split[3],
        id: key.id,
      }

      return airshipAccounts.push(account)
    })

    yield put(actions.integrationAirshipRequestSuccess(airshipAccounts))
  } catch (error) {
    yield put(actions.integrationAirshipRequestFailure(error.response))
  }
}

export function* watchIntegrationAirshipRequest() {
  yield takeLeading(
    getType(actions.integrationAirshipRequest),
    integrationAirshipRequest
  )
}

/* Update Campaign Monitor API Key Belonging To User
 * ========================================================================== */
function* integrationAirshipPUTRequest(
  action: ActionType<typeof actions.updateIntegrationAirshipRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { airship } = action.payload

    const send = {
      id: airship.id,
      apiKey: `${airship.username};${airship.password};${airship.sourceId};${airship.name}`,
    }

    const response: {
      status: number
      message: { id: string; apiKey: string }
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/airship`, send)
    )

    const apikey = response.message
    const split = apikey.apiKey.split(';')
    const account: AirshipAuthType = {
      username: split[0],
      password: split[1],
      sourceId: split[2],
      name: split[3],
      id: apikey.id,
    }

    yield put(actions.updateIntegrationAirshipRequestSuccess(account))
  } catch (error) {
    yield put(actions.updateIntegrationAirshipRequestFailure(error.response))
    yield put(actions.updateIntegrationAirshipRequestFailure(error.response))
  }
}

export function* watchIntegrationAirshipPUTRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationAirshipRequest),
    integrationAirshipPUTRequest
  )
}

/* Create or update a list of a unify
 * ========================================================================== */
function* createUpdateIntegrationSpecificListRequest(
  action: ActionType<typeof actions.createUpdateIntegrationSpecificListRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { integration, serial, listSync } = action.payload

    const response: {
      status: number
      message: ListSync
    } = yield call(async () =>
      putRequest(
        `/integration/${orgId}/${integration}/lists/${serial}`,
        listSync
      )
    )

    const list = response.message

    yield put(actions.createUpdateIntegrationSpecificListRequestSuccess(list))
  } catch (error) {
    yield put(
      actions.createUpdateIntegrationSpecificListRequestFailure(error.response)
    )
  }
}

export function* watchCreateUpdateIntegrationSpecificListRequest() {
  yield takeLeading(
    getType(actions.createUpdateIntegrationSpecificListRequest),
    createUpdateIntegrationSpecificListRequest
  )
}

export default [
  watchIntegrationEnabledRequest,

  watchIntegrationPaypalAccountsAllRequest,
  watchIntegrationSpecificPaypalAccountRequest,
  watchIntegrationCreatePaypalAccountRequest,
  watchIntegrationUpdateSpecificPaypalAccountRequest,
  watchUpdateIntegrationPaypalAccountLocationRequest,
  watchIntegrationPaypalAccountLocationRequest,
  watchDeleteIntegrationPaypalAccountRequest,
  watchIntegrationMailchimpRequest,
  watchIntegrationMailchimpPUTRequest,
  watchIntegrationCampaignMonitorRequest,
  watchIntegrationCampaignMonitorPUTRequest,

  watchCreateUpdateIntegrationSpecificListRequest,
  watchNativeIntegrationListRequest,
  watchNativeIntegrationExternalListRequest,
  watchNativeIntegrationSpecificListRequest,

  watchIntegrationAirshipPUTRequest,
  watchIntegrationAirshipRequest,
  watchRemoveNativeIntegrationSpecificListRequest,
]
