import { Reducer } from '@reduxjs/toolkit'
import message from 'common/message/message'
import { AccessVenueType } from 'components/IntegrationsPage/Access/access.types'
import { RollerVenuesType } from 'components/IntegrationsPage/Roller/roller.types'
import { ServiceErrorHandler } from 'connect-types/backend/service'
import { MarketingCampaignReport } from 'connect-types/marketing/marketingCampaign.type'
import {
  OrganisationServerType,
  OrganisationType,
  OrganisationUserType,
} from 'connect-types/organisations/organisations.type'
import { User } from 'connect-types/user/user.type'
import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'
import { relDiff } from 'utils/common'
import {
  OrganisationRegistrationReportType,
  OrganisationRegistrationType,
  OrganisationTransactionTotalType,
} from '../insight/insight.type'
import * as actions from './organisations.actions'
import { LinkGeneratorType, OrganisationLabelType } from './organisations.types'

interface OrganisationByType {
  reseller: OrganisationType[]
  default: OrganisationType[]
  root: OrganisationType[]
}
export type OrgRegKeys = keyof OrganisationRegistrationType
export type OrgTransactionKeys = keyof OrganisationTransactionTotalType
export interface StateType {
  selectedUser?: User | null
  /**
   *
   */

  labels: {
    items: OrganisationLabelType[]
    isLoading: boolean
  }
  organisationsByType: OrganisationByType
  canQuote: boolean
  loading: boolean

  serverOrganisations: OrganisationServerType[]
  serverOrganisationAccess: OrganisationUserType[]
  integrations: {
    hooks: {
      isLoading: boolean
      links: LinkGeneratorType[]
    }
    access: {
      venues: AccessVenueType[]
      isLoading: boolean
    }
    roller: {
      venues: {
        [key: string]: RollerVenuesType
      }
      isLoading: boolean
    }
  }
  reports: {
    isLoading: boolean
    marketing: MarketingCampaignReport
    users: OrganisationRegistrationReportType

    comparison: {
      now: OrganisationRegistrationReportType
      previous: OrganisationRegistrationReportType
      difference: {
        [key in OrgRegKeys]: {
          positive: boolean
          value: number
        }
      }
    }
  }
}

export const initialState: StateType = {
  integrations: {
    hooks: {
      isLoading: false,
      links: [],
    },
    access: {
      venues: [],
      isLoading: false,
    },
    roller: {
      venues: {},
      isLoading: false,
    },
  },
  selectedUser: null,

  organisationsByType: {
    reseller: [],
    default: [],
    root: [],
  },
  canQuote: false,
  loading: false,

  serverOrganisations: [],
  serverOrganisationAccess: [],
  reports: {
    isLoading: false,
    marketing: null,
    users: null,

    comparison: {
      now: null,
      previous: null,
      difference: null,
    },
  },

  labels: {
    items: [],
    isLoading: false,
  },
}

export type OrganisationsAction = ActionType<typeof actions>

export const orgNameToSlug = (name: string) => {
  if (!name) return null
  return name.toLowerCase().replace(' ', '-')
}

const organisationsReducer: Reducer<StateType, OrganisationsAction> = (
  state = initialState,
  action
) =>
  // tslint:disable-next-line: cyclomatic-complexity
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.addOrganisationLabelToImage.request): {
        draft.labels.isLoading = true
        break
      }
      case getType(actions.addOrganisationLabelToImage.success): {
        draft.labels.isLoading = false
        if (
          !draft.labels.items.find((item) => item.name === action.payload.name)
        ) {
          draft.labels.items.push(action.payload)
        }

        message.success('Tag added')
        break
      }
      case getType(actions.addOrganisationLabelToImage.failure): {
        draft.labels.isLoading = false
        ServiceErrorHandler(action.payload)
        break
      }
      case getType(actions.getOrganisationLabels.request): {
        draft.labels.isLoading = true
        break
      }
      case getType(actions.getOrganisationLabels.success): {
        draft.labels.isLoading = false

        draft.labels.items = [
          ...new Map(
            action.payload.map((item) => [item['name'], item])
          ).values(),
        ]

        break
      }
      case getType(actions.getOrganisationLabels.failure): {
        draft.labels.isLoading = false

        break
      }

      case getType(actions.deleteOrganisationLabel.request): {
        draft.labels.isLoading = true

        break
      }
      case getType(actions.deleteOrganisationLabel.success): {
        draft.labels.isLoading = false

        draft.labels.items = draft.labels.items.filter(
          (item) => item.id !== action.payload.id
        )

        break
      }
      case getType(actions.deleteOrganisationLabel.failure): {
        draft.labels.isLoading = false

        break
      }

      /*
      case getType(actions.organisationPutUsersRequest): {
        draft.loading = true
        draft.userAdded = false
        break
      }
      case getType(actions.organisationPutUsersRequestSuccess): {
        draft.loading = false
        const findIndex = draft.users.findIndex(
          (user) => user.uid === action.payload.response.uid
        )

        if (findIndex >= 0) {
          draft.users[findIndex] = action.payload.response
        } else {
          draft.users.push(action.payload.response)
        }

        message.success('User added')
        draft.needsToCreateUser = false
        draft.userAdded = true
        break
      }
      case getType(actions.organisationPutUsersRequestFailure): {
        draft.loading = false
        if (isAxiosError(action.payload.error)) {
          console.log(action.payload.error.response.status, 'status-here')
          if (action.payload.error.response.status === 404) {
            draft.needsToCreateUser = true
          }
        }

        break
      }
      */

      /*
      case getType(actions.organisationDeleteUsersRequest): {
        draft.loading = true
        break
      }
      
      case getType(actions.organisationDeleteUsersRequestSuccess): {
        draft.loading = false
        const index = draft.users.findIndex(
          (member) => member.uid === action.payload.uid
        )

        draft.users.splice(index, 1)
        message.success('User removed')

        break
      }
      case getType(actions.organisationDeleteUsersRequestFailure): {
        draft.loading = false
        break
      }
*/

      case getType(actions.organisationRegistrationReport): {
        draft.reports.isLoading = true
        break
      }
      case getType(actions.organisationRegistrationReportSuccess): {
        draft.reports.isLoading = false
        draft.reports.users = action.payload.report

        break
      }
      case getType(actions.organisationRegistrationReportFailure): {
        draft.reports.isLoading = false
        draft.reports.users = initialState.reports.users
        break
      }

      case getType(actions.organisationMarketingReport): {
        draft.loading = true
        break
      }
      case getType(actions.organisationMarketingReportSuccess): {
        draft.loading = false
        draft.reports.marketing = action.payload.report
        break
      }
      case getType(actions.organisationMarketingReportFailure): {
        draft.loading = false
        draft.reports.marketing = initialState.reports.marketing
        break
      }

      case getType(actions.organisationPushNewCustomer): {
        draft.serverOrganisations = [
          ...draft.serverOrganisations,
          action.payload.customer,
        ]

        break
      }

      case getType(actions.getComparisonReport.request): {
        draft.reports.isLoading = true
        break
      }
      case getType(actions.getComparisonReport.success): {
        draft.reports.comparison.now = action.payload.now
        draft.reports.comparison.previous = action.payload.previous
        draft.reports.comparison.difference = {
          new_users: diffCalc(draft.reports.comparison, 'new_users'),
          users: diffCalc(draft.reports.comparison, 'users'),
          active_users: diffCalc(draft.reports.comparison, 'active_users'),
          return_users: diffCalc(draft.reports.comparison, 'return_users'),
          opt_in_users: diffCalc(draft.reports.comparison, 'opt_in_users'),
          opt_in_email_users: diffCalc(
            draft.reports.comparison,
            'opt_in_email_users'
          ),
          opt_in_sms_users: diffCalc(
            draft.reports.comparison,
            'opt_in_sms_users'
          ),
          email_verified: diffCalc(draft.reports.comparison, 'email_verified'),
        }
        draft.reports.isLoading = false
        break
      }
      case getType(actions.getComparisonReport.failure): {
        draft.reports.isLoading = false

        break
      }

      case getType(actions.getOrganisationLinks.request): {
        draft.integrations.hooks.isLoading = true

        break
      }

      case getType(actions.getOrganisationLinks.success): {
        draft.integrations.hooks.isLoading = false
        draft.integrations.hooks.links = action.payload

        break
      }
      case getType(actions.getOrganisationLinks.failure): {
        draft.integrations.hooks.isLoading = false
        ServiceErrorHandler(action.payload)
        break
      }
      case getType(actions.createOrganisationLinks.request): {
        draft.integrations.hooks.isLoading = true

        break
      }

      case getType(actions.createOrganisationLinks.success): {
        draft.integrations.hooks.isLoading = false
        draft.integrations.hooks.links.push(action.payload)
        message.success('Link created')
        break
      }
      case getType(actions.createOrganisationLinks.failure): {
        draft.integrations.hooks.isLoading = false
        ServiceErrorHandler(action.payload)
        break
      }

      case getType(actions.getAccessBookingsVenues.request): {
        draft.integrations.access.isLoading = true

        break
      }

      case getType(actions.getAccessBookingsVenues.success): {
        draft.integrations.access.isLoading = false
        draft.integrations.access.venues = action.payload

        break
      }
      case getType(actions.getAccessBookingsVenues.failure): {
        draft.integrations.access.isLoading = false

        break
      }
      /*
      case getType(actions.getTransactionReport.request): {
        draft.reports.isLoading = true

        break
      }

      case getType(actions.getTransactionReport.success): {
        draft.reports.transactions.now = action.payload.now
        draft.reports.transactions.previous = action.payload.previous
        draft.reports.transactions.difference = {
          customers: diffTransCalc(draft.reports.transactions, 'customers'),
          transactions: diffTransCalc(
            draft.reports.transactions,
            'transactions'
          ),
          repeat_customers: diffTransCalc(
            draft.reports.transactions,
            'repeat_customers'
          ),
          avg: {
            value: relDiff(
              draft.reports.transactions.now.avg.cash.customers.penny_price,
              draft.reports.transactions.previous.avg.cash.customers.penny_price
            ),
            positive:
              draft.reports.transactions.now.avg.cash.customers.penny_price >
              draft.reports.transactions.previous.avg.cash.customers
                .penny_price,
          },
          total: {
            value: relDiff(
              draft.reports.transactions.now.total.penny_price,
              draft.reports.transactions.previous.total.penny_price
            ),
            positive:
              draft.reports.transactions.now.total.penny_price >
              draft.reports.transactions.previous.total.penny_price,
          },
        }
        draft.reports.isLoading = false

        break
      }
      case getType(actions.getTransactionReport.failure): {
        draft.reports.isLoading = false
        ServiceErrorHandler(action.payload)
        break
      }
      */

      /**
       * GET ROLLER DATA
       */
      case getType(actions.getRollerData.request): {
        draft.integrations.roller.isLoading = true

        break
      }
      case getType(actions.getRollerData.success): {
        draft.integrations.roller.venues = action.payload
        draft.integrations.roller.isLoading = false
        break
      }
      case getType(actions.getRollerData.failure): {
        draft.integrations.roller.venues = {}
        draft.integrations.roller.isLoading = false
        break
      }

      case getType(actions.postRollerData.request): {
        draft.integrations.roller.isLoading = true

        break
      }
      case getType(actions.postRollerData.success): {
        draft.integrations.roller.venues = action.payload
        draft.integrations.roller.isLoading = false
        message.success('Roller venue saved')
        break
      }
      case getType(actions.postRollerData.failure): {
        draft.integrations.roller.venues = {}
        draft.integrations.roller.isLoading = false
        message.warning('Roller venue could not be saved')
        break
      }

      case getType(actions.deleteRollerData.request): {
        draft.integrations.roller.isLoading = true

        break
      }
      case getType(actions.deleteRollerData.success): {
        if (action.payload.serial in draft.integrations.roller.venues) {
          delete draft.integrations.roller.venues[action.payload.serial]
        }
        message.success('Roller venue deleted')
        draft.integrations.roller.isLoading = false
        break
      }
      case getType(actions.deleteRollerData.failure): {
        draft.integrations.roller.isLoading = false
        break
      }
    }
  })

const diffCalc = (
  totals: StateType['reports']['comparison'],
  key: OrgRegKeys
) => {
  return {
    positive: totals.now.totals[key] > totals.previous.totals[key],
    value: relDiff(totals.now.totals[key], totals.previous.totals[key]),
  }
}

export default organisationsReducer
