import { LocationDebuggerPayload } from 'connect-types/locations/locationDebuggerPayload.type'
import { LocationTemplateType } from 'connect-types/locations/locationTemplate.type'
import { PaymentMethodUpdateRequestBody } from 'connect-types/locations/paymentMethodUpdateRequestBody.type'
import { PaymentPlanCreateRequestBody } from 'connect-types/locations/paymentPlanCreateRequestBody.type'
import { createAction } from 'typesafe-actions'

/**
 * GET Get all payment plans for the location
 *
 * Path: /locations/{serial}/payments/plans
 */

export const locationsPaymentPlansRequest = createAction(
  'LOCATIONS_PAYMENT_PLANS_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

export const locationsPaymentPlansRequestSuccess = createAction(
  'LOCATIONS_PAYMENT_PLANS_REQUEST_SUCCESS',
  (resolve) => (locationPlans: PaymentPlanCreateRequestBody[]) =>
    resolve({ locationPlans })
)

export const locationsPaymentPlansRequestFailure = createAction(
  'LOCATIONS_PAYMENT_PLANS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
/**
 * POST Get all payment plans for the location
 *
 * Path: /locations/{serial}/payments/plans
 */

export const createLocationsPaymentPlansRequest = createAction(
  'CREATE_LOCATIONS_PAYMENT_PLANS_REQUEST',
  (resolve) => (serial: string, locationPlan: PaymentPlanCreateRequestBody) =>
    resolve({ serial, locationPlan })
)

export const createLocationsPaymentPlansRequestSuccess = createAction(
  'CREATE_LOCATIONS_PAYMENT_PLANS_REQUEST_SUCCESS',
  (resolve) => (locationPlan: PaymentPlanCreateRequestBody) =>
    resolve({ locationPlan })
)

export const createLocationsPaymentPlansRequestFailure = createAction(
  'CREATE_LOCATIONS_PAYMENT_PLANS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get Current Payment Methods for Location
 *
 * Path: /locations/{serial}/payments/method
 */

export const locationsPaymentMethodsRequest = createAction(
  'LOCATIONS_PAYMENT_METHODS_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

export const locationsPaymentMethodsRequestSuccess = createAction(
  'LOCATIONS_PAYMENT_METHODS_REQUEST_SUCCESS',
  (resolve) => (methods: PaymentMethodUpdateRequestBody) => resolve({ methods })
)

export const locationsPaymentMethodsRequestFailure = createAction(
  'LOCATIONS_PAYMENT_METHODS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * UPDATE Update Current Payment Methods for Location
 *
 * Path: /locations/{serial}/payments/method
 */

export const updateLocationsPaymentMethodsRequest = createAction(
  'UPDATE_LOCATIONS_PAYMENT_METHODS_REQUEST',
  (resolve) => (serial: string, method: PaymentMethodUpdateRequestBody) =>
    resolve({ serial, method })
)

export const updateLocationsPaymentMethodsRequestSuccess = createAction(
  'UPDATE_LOCATIONS_PAYMENT_METHODS_REQUEST_SUCCESS',
  (resolve) => (methods: PaymentMethodUpdateRequestBody) => resolve({ methods })
)

export const updateLocationsPaymentMethodsRequestFailure = createAction(
  'UPDATE_LOCATIONS_PAYMENT_METHODS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * PUT Update Payment plan
 *
 * Path: /locations/{serial}/payments/plans/{planId}
 */

export const updateLocationsPaymentPlansRequest = createAction(
  'UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST',
  (resolve) =>
    (
      serial: string,
      planId: string,
      locationPlan: PaymentPlanCreateRequestBody
    ) =>
      resolve({ serial, planId, locationPlan })
)

export const updateLocationsPaymentPlansRequestSuccess = createAction(
  'UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS',
  (resolve) => (locationPlan: PaymentPlanCreateRequestBody) =>
    resolve({ locationPlan })
)

export const updateLocationsPaymentPlansRequestFailure = createAction(
  'UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * DELETE Delete Payment plan
 *
 * Path: /locations/{serial}/payments/plans/{planId}
 */

export const deleteLocationsPaymentPlansRequest = createAction(
  'DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST',
  (resolve) => (serial: string, planId: string) => resolve({ serial, planId })
)

export const deleteLocationsPaymentPlansRequestSuccess = createAction(
  'DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS',
  (resolve) => (id: string) => resolve({ id })
)

export const deleteLocationsPaymentPlansRequestFailure = createAction(
  'DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get the formatted address and name associated with the Google Place ID of the location
 *
 * Path: /locations/{serial}/settings/location/{placeId}
 */

// FIXME: add in request and query params
export const locationsGooglePlaceRequest = createAction(
  'LOCATIONS_GOOGLE_PLACE_REQUEST',
  (resolve) => (serial: string, placeId: string) => resolve({ serial, placeId })
)

// FIXME: add in response object from saga
export const locationsGooglePlaceRequestSuccess = createAction(
  'LOCATIONS_GOOGLE_PLACE_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const locationsGooglePlaceRequestFailure = createAction(
  'LOCATIONS_GOOGLE_PLACE_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'locations google place request failed' })
)

/**
 * DELETE Delete either a background or a header image
 *
 * Path: /locations/{serial}/settings/branding/{type}
 */

// FIXME: add in request and query params
export const deleteLocationsSettingsBrandingRequest = createAction(
  'DELETE_LOCATIONS_SETTINGS_BRANDING_REQUEST',
  (resolve) => (serial: string, type: 'head' | 'bg') =>
    resolve({ serial, type })
)

// FIXME: add in response object from saga
export const deleteLocationsSettingsBrandingRequestSuccess = createAction(
  'DELETE_LOCATIONS_SETTINGS_BRANDING_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const deleteLocationsSettingsBrandingRequestFailure = createAction(
  'DELETE_LOCATIONS_SETTINGS_BRANDING_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete locations settings branding request failed' })
)
/**
 * GET Get the current schedule associated with the location
 *
 * Path: /locations/{serial}/settings/schedule
 */

// FIXME: add in request and query params
export const locationsScheduleSettingsRequest = createAction(
  'LOCATIONS_SCHEDULE_SETTINGS_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsScheduleSettingsRequestSuccess = createAction(
  'LOCATIONS_SCHEDULE_SETTINGS_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const locationsScheduleSettingsRequestFailure = createAction(
  'LOCATIONS_SCHEDULE_SETTINGS_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'locations schedule settings request failed' })
)

/**
 * GET Get which attributes a location is using from a template
 *
 * Path: /locations/{serial}/settings/template
 */

// FIXME: add in request and query params
export const locationsSettingsTemplateRequest = createAction(
  'LOCATIONS_SETTINGS_TEMPLATE_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsSettingsTemplateRequestSuccess = createAction(
  'LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => (response: LocationTemplateType) => resolve({ response })
)

export const locationsSettingsTemplateRequestFailure = createAction(
  'LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'locations settings template request failed' })
)
/**
 * POST Create a Template from a Serial passed in the body so another Location can use the same settings as another
 *
 * Path: /locations/{serial}/settings/template
 */

// FIXME: add in request and query params
export const createLocationsSettingsTemplateRequest = createAction(
  'CREATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST',
  (resolve) => (serial: string, data: LocationTemplateType) =>
    resolve({ serial, data })
)

// FIXME: add in response object from saga
export const createLocationsSettingsTemplateRequestSuccess = createAction(
  'CREATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => (response: LocationTemplateType) => resolve(response)
)

export const createLocationsSettingsTemplateRequestFailure = createAction(
  'CREATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'create locations settings template request failed' })
)
/**
 * PUT Update the attributes of a Template being used by a Location
 *
 * Path: /locations/{serial}/settings/template
 */

// FIXME: add in request and query params
export const updateLocationsSettingsTemplateRequest = createAction(
  'UPDATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST',
  (resolve) => (serial: string, data: LocationTemplateType) =>
    resolve({ serial, data })
)

// FIXME: add in response object from saga
export const updateLocationsSettingsTemplateRequestSuccess = createAction(
  'UPDATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => (response: LocationTemplateType) => resolve(response)
)

export const updateLocationsSettingsTemplateRequestFailure = createAction(
  'UPDATE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update locations settings template request failed' })
)
/**
 * DELETE Restore the Default values of the Location and remove the Template
 *
 * Path: /locations/{serial}/settings/template
 */

// FIXME: add in request and query params
export const deleteLocationsSettingsTemplateRequest = createAction(
  'DELETE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const deleteLocationsSettingsTemplateRequestSuccess = createAction(
  'DELETE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const deleteLocationsSettingsTemplateRequestFailure = createAction(
  'DELETE_LOCATIONS_SETTINGS_TEMPLATE_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete locations settings template request failed' })
)

/**
 * GET Can the location pick up a config?
 *
 * Path: /locations/{serial}/debugger/config
 */

// FIXME: add in request and query params
export const locationsDebuggerConfigRequest = createAction(
  'LOCATIONS_DEBUGGER_CONFIG_REQUEST',
  (resolve) => (serial: string) => resolve({ serial })
)

// FIXME: add in response object from saga
export const locationsDebuggerConfigRequestSuccess = createAction(
  'LOCATIONS_DEBUGGER_CONFIG_REQUEST_SUCCESS',
  (resolve) => (configDebugger: LocationDebuggerPayload) =>
    resolve({ configDebugger })
)

export const locationsDebuggerConfigRequestFailure = createAction(
  'LOCATIONS_DEBUGGER_CONFIG_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'locations debugger config request failed' })
)
