import { Reducer } from '@reduxjs/toolkit'
import message from 'common/message/message'
import { produce } from 'immer'

import { AirshipAuthType } from 'connect-types/integrations/airship.type'
import { IntegrationEnabled } from 'connect-types/integrations/integrationEnabled.type'
import { ListSync } from 'connect-types/integrations/listSync.type'
import {
  ExternalListType,
  ListUnifyRequestBody,
} from 'connect-types/integrations/listUnifyRequestBody'
import { PaypalAccountUpdateRequestBody } from 'connect-types/integrations/paypalAccountUpdateRequestBody.type'
import { PaypalLocationAccount } from 'connect-types/integrations/paypalLocationAccount.type'
import { UnifyUserCredentialCreateRequestBody } from 'connect-types/integrations/unifyUserCredentialCreateRequestBody.type'
import { ActionType, getType } from 'typesafe-actions'
import * as actions from './integration.actions'

export type IntegrationAction = ActionType<typeof actions>

export interface StateType {
  paypalAccounts: PaypalAccountUpdateRequestBody[]
  paypalCurrentSiteSetup: PaypalLocationAccount
  mailchimpApiKey: UnifyUserCredentialCreateRequestBody
  campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody
  airshipAccounts: AirshipAuthType[]
  integrationListDetail: ListSync
  integrationList: ListUnifyRequestBody[]
  integrationExternalList: ExternalListType[]

  isIntegrationRequestLoading: boolean
  integrationEnabled: IntegrationEnabled
  isPaypalSaveRequired: boolean

  isMailChimpSaveRequired: boolean
  isCampaignMonitorSaveRequired: boolean
  isAirshipSaveRequired: boolean
}

const initialState: StateType = {
  airshipAccounts: [],
  integrationEnabled: {
    paypal: false,
    unifi: false,
    stripe: false,
    mailchimp: false,
    zapier: false,
    campaignMonitor: false,
    airship: false,
    storekit: false,
    access: false,
    roller: false,
    opentable: false,
    toggle: false,
  },

  paypalAccounts: [],
  paypalCurrentSiteSetup: {
    paypalAccount: null,
  },
  mailchimpApiKey: {
    apiKey: null,
  },
  integrationListDetail: null,
  integrationList: [],
  integrationExternalList: [],
  campaignMonitorApiKey: {
    apiKey: null,
  },
  isIntegrationRequestLoading: false,
  isPaypalSaveRequired: false,
  isMailChimpSaveRequired: false,
  isCampaignMonitorSaveRequired: false,

  isAirshipSaveRequired: false,
}

/* tslint:disable cyclomatic-complexity */
const integrationReducer: Reducer<StateType, IntegrationAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      /*
   
      case getType(actions.integrationPaypalAccountsAllRequest): {
        draft.isIntegrationRequestLoading = true
        draft.paypalAccounts = []
        break
      }

      case getType(actions.integrationPaypalAccountsAllRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.paypalAccounts = action.payload.paypalAccounts
        break
      }

      case getType(actions.integrationPaypalAccountsAllRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * INTEGRATION_PAYPAL_ACCOUNT_REQUEST
       *
       * /integration/{uid}/paypal/{id}
       * Retrieve a Specific PayPal Account
       */
      case getType(actions.integrationPaypalAccountRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      /**
       * INTEGRATION_PAYPAL_ACCOUNT_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal/{id}
       * Retrieve a Specific PayPal Account
       */
      case getType(actions.integrationPaypalAccountRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        const find = draft.paypalAccounts.findIndex(
          (account) => account.id === action.payload.paypalAccount.id
        )

        draft.paypalAccounts[find] = action.payload.paypalAccount
        break
      }

      /**
       * INTEGRATION_PAYPAL_ACCOUNT_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal/{id}
       * Retrieve a Specific PayPal Account
       */
      case getType(actions.integrationPaypalAccountRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * UPDATE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST
       *
       * /integration/{uid}/paypal/{id}
       * Update a PayPal Account
       */
      case getType(actions.updateIntegrationPaypalAccountRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      /**
       * UPDATE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal/{id}
       * Update a PayPal Account
       */
      case getType(actions.updateIntegrationPaypalAccountRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        const find = draft.paypalAccounts.findIndex(
          (account: PaypalAccountUpdateRequestBody) =>
            account.id === action.payload.paypalAccount.id
        )

        draft.paypalAccounts[find] = action.payload.paypalAccount

        break
      }

      /**
       * UPDATE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal/{id}
       * Update a PayPal Account
       */
      case getType(actions.updateIntegrationPaypalAccountRequestFailure): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * DELETE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST
       *
       * /integration/{uid}/paypal/{id}
       * Delete a PayPal Account
       */
      case getType(actions.deleteIntegrationPaypalAccountRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      /**
       * DELETE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal/{id}
       * Delete a PayPal Account
       */
      case getType(actions.deleteIntegrationPaypalAccountRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        const findIndex = draft.paypalAccounts.findIndex(
          (account) => account.id === action.payload.id
        )

        draft.paypalAccounts.splice(findIndex, 1)
        break
      }

      /**
       * DELETE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal/{id}
       * Delete a PayPal Account
       */
      case getType(actions.deleteIntegrationPaypalAccountRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      case getType(actions.integrationPaypalAccountLocationRequest): {
        draft.isIntegrationRequestLoading = true
        draft.paypalCurrentSiteSetup.paypalAccount = null
        break
      }

      case getType(actions.integrationPaypalAccountLocationRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.paypalCurrentSiteSetup = action.payload.paypalLocationAccount
        break
      }

      case getType(actions.integrationPaypalAccountLocationRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * UPDATE_INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST
       *
       * /integration/{uid}/paypal/{id}/location/{serial}
       * Associate a PayPal account with a Location
       */
      case getType(actions.updateIntegrationPaypalAccountLocationRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      /**
       * UPDATE_INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal/{id}/location/{serial}
       * Associate a PayPal account with a Location
       */
      case getType(
        actions.updateIntegrationPaypalAccountLocationRequestSuccess
      ): {
        draft.isIntegrationRequestLoading = false
        draft.paypalCurrentSiteSetup = action.payload.paypalLocationAccount
        break
      }

      /**
       * UPDATE_INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal/{id}/location/{serial}
       * Associate a PayPal account with a Location
       */
      case getType(
        actions.updateIntegrationPaypalAccountLocationRequestFailure
      ): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * INTEGRATION_MAILCHIMP_REQUEST
       *
       * /integration/{uid}/mailchimp
       * Get the user's current details
       */
      case getType(actions.integrationMailchimpRequest): {
        draft.isIntegrationRequestLoading = true
        draft.mailchimpApiKey.apiKey = null
        break
      }

      /**
       * INTEGRATION_MAILCHIMP_REQUEST_SUCCESS
       *
       * /integration/{uid}/mailchimp
       * Get the user's current details
       */
      case getType(actions.integrationMailchimpRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.mailchimpApiKey = action.payload.mailchimpApiKey
        break
      }

      /**
       * INTEGRATION_MAILCHIMP_REQUEST_FAILURE
       *
       * /integration/{uid}/mailchimp
       * Get the user's current details
       */
      case getType(actions.integrationMailchimpRequestFailure): {
        draft.isIntegrationRequestLoading = false
        draft.mailchimpApiKey.apiKey = null
        break
      }

      /**
       * UPDATE_INTEGRATION_MAILCHIMP_REQUEST
       *
       * /integration/{uid}/mailchimp
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationMailchimpRequest): {
        draft.isIntegrationRequestLoading = true
        message.loading('Loading')
        break
      }

      /**
       * UPDATE_INTEGRATION_MAILCHIMP_REQUEST_SUCCESS
       *
       * /integration/{uid}/mailchimp
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationMailchimpRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.mailchimpApiKey = action.payload.mailchimpApiKey
        draft.integrationEnabled.mailchimp = true
        message.success('Mailchimp key updated')
        break
      }

      /**
       * UPDATE_INTEGRATION_MAILCHIMP_REQUEST_FAILURE
       *
       * /integration/{uid}/mailchimp
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationMailchimpRequestFailure): {
        draft.isIntegrationRequestLoading = false
        message.warning('Error updating Mailchimp key')
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.updateIntegrationMailchimpInStore): {
        draft.mailchimpApiKey = action.payload.mailChimpApiKey
        draft.isMailChimpSaveRequired = true
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.addNewListSync): {
        draft.integrationList.push({
          contactListName: 'New list sync',
          id: null,
        })
        draft.integrationListDetail = action.payload.list

        break
      }

      case getType(actions.updateNewListSync): {
        draft.integrationListDetail = action.payload.list

        break
      }

      case getType(actions.nativeIntegrationExternalListRequest): {
        draft.isIntegrationRequestLoading = true
        draft.integrationExternalList = []
        break
      }

      case getType(actions.nativeIntegrationExternalListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.integrationExternalList = action.payload.list
        break
      }

      case getType(actions.nativeIntegrationExternalListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      case getType(actions.createUpdateIntegrationSpecificListRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(actions.createUpdateIntegrationSpecificListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        let index = draft.integrationList.findIndex((item) => !item.id)

        if (index >= 0) {
          draft.integrationList[index].id = action.payload.listSync.id
        } else {
          index = draft.integrationList.findIndex(
            (item) => item.id === action.payload.listSync.id
          )
        }

        if (index) {
          draft.integrationList[index].contactListName =
            action.payload.listSync.contactListName
        }

        message.success('Integration saved')
        break
      }

      case getType(actions.createUpdateIntegrationSpecificListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        message.success('Integration could not be saved')
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.nativeIntegrationListRequest): {
        draft.isIntegrationRequestLoading = true
        draft.integrationList = []
        break
      }

      case getType(actions.nativeIntegrationListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.integrationList = action.payload.list
        break
      }

      case getType(actions.nativeIntegrationListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        draft.integrationList = []
        break
      }

      /**
       *
       */

      case getType(actions.removeNativeIntegrationSpecificListRequest): {
        draft.isIntegrationRequestLoading = true

        break
      }

      case getType(actions.removeNativeIntegrationSpecificListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        const index = draft.integrationList.findIndex(
          (list) => list.id === action.payload.listId
        )

        draft.integrationList.splice(index, 1)

        break
      }

      case getType(actions.removeNativeIntegrationSpecificListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /*
       *
       */

      case getType(actions.nativeIntegrationSpecificListRequest): {
        draft.isIntegrationRequestLoading = true

        break
      }

      case getType(actions.nativeIntegrationSpecificListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.integrationListDetail = action.payload.list

        break
      }

      case getType(actions.nativeIntegrationSpecificListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.updateNativeIntegrationSpecificListRequest): {
        draft.isIntegrationRequestLoading = true

        break
      }

      case getType(actions.updateNativeIntegrationSpecificListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.integrationListDetail = action.payload.list

        break
      }

      case getType(actions.updateNativeIntegrationSpecificListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * INTEGRATION_CAMPAIGN_MONITOR_REQUEST
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationCampaignMonitorRequest): {
        draft.isIntegrationRequestLoading = true
        draft.campaignMonitorApiKey.apiKey = null

        break
      }

      /**
       * INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationCampaignMonitorRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.campaignMonitorApiKey = action.payload.campaignMonitorApiKey

        break
      }

      /**
       * INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationCampaignMonitorRequestFailure): {
        draft.isIntegrationRequestLoading = false
        draft.campaignMonitorApiKey.apiKey = null

        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationCampaignMonitorRequest): {
        draft.isIntegrationRequestLoading = true
        message.loading('Loading')
        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationCampaignMonitorRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.campaignMonitorApiKey = action.payload.campaignMonitorApiKey
        draft.integrationEnabled.campaignMonitor = true
        message.warning('Campaign Monitor key updated')
        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationCampaignMonitorRequestFailure): {
        draft.isIntegrationRequestLoading = false
        message.warning('Error updating Campaign Monitor key', {
          description: 'Invalid API Key',
        })
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.updateIntegrationCampaignMonitorInStore): {
        draft.campaignMonitorApiKey = action.payload.campaignMonitorApiKey
        draft.isCampaignMonitorSaveRequired = true
        break
      }

      /**
       * INTEGRATION_AIRSHIP_REQUEST
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */

      case getType(actions.integrationAirshipRequest): {
        draft.isIntegrationRequestLoading = true
        draft.airshipAccounts = []
        break
      }

      /**
       * INTEGRATION_AIRSHIP_REQUEST_SUCCESS
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationAirshipRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.airshipAccounts = action.payload.airship
        break
      }

      /**
       * INTEGRATION_AIRSHIP_REQUEST_FAILURE
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationAirshipRequestFailure): {
        draft.isIntegrationRequestLoading = false
        draft.airshipAccounts = []
        break
      }

      /**
       * UPDATE_INTEGRATION_AIRSHIP_REQUEST
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationAirshipRequest): {
        draft.isIntegrationRequestLoading = true
        message.loading('Loading')
        break
      }

      /**
       * UPDATE_INTEGRATION_AIRSHIP_REQUEST_SUCCESS
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationAirshipRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        message.success('Updated Airship account')
        draft.integrationEnabled.airship = true
        const index = draft.airshipAccounts.findIndex(
          (airship) => airship.id === action.payload.airship.id
        )

        if (index < 0) {
          draft.airshipAccounts.push(action.payload.airship)
        } else {
          draft.airshipAccounts[index] = action.payload.airship
        }

        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationAirshipRequestFailure): {
        draft.isIntegrationRequestLoading = false
        message.warning('Error updating Airship account')

        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.integrationCampaignMonitorListRequest): {
        draft.isIntegrationRequestLoading = true
        draft.integrationList = []
        break
      }

      case getType(actions.integrationCampaignMonitorListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.integrationList = action.payload.campaignMonitorLists
        break
      }

      case getType(actions.integrationCampaignMonitorListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      case getType(actions.integrationCampaignMonitorSpecificListRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(
        actions.integrationCampaignMonitorSpecificListRequestSuccess
      ): {
        draft.isIntegrationRequestLoading = false
        draft.integrationListDetail = action.payload.campaignMonitorList

        break
      }

      case getType(
        actions.integrationCampaignMonitorSpecificListRequestFailure
      ): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(
        actions.updateIntegrationCampaignMonitorSpecificListRequest
      ): {
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(
        actions.updateIntegrationCampaignMonitorSpecificListRequestSuccess
      ): {
        draft.isIntegrationRequestLoading = false
        draft.integrationListDetail = action.payload.campaignMonitorListDetails

        break
      }

      case getType(
        actions.updateIntegrationCampaignMonitorSpecificListRequestFailure
      ): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * INTEGRATION_PAYPAL_REQUEST
       *
       * /integration/{uid}/paypal
       * Retrieve PayPal Account associated with the user
       */
      case getType(actions.integrationPaypalRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * INTEGRATION_PAYPAL_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal
       * Retrieve PayPal Account associated with the user
       */
      case getType(actions.integrationPaypalRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * INTEGRATION_PAYPAL_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal
       * Retrieve PayPal Account associated with the user
       */
      case getType(actions.integrationPaypalRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * CREATE_INTEGRATION_PAYPAL_REQUEST
       *
       * /integration/{uid}/paypal
       * Add a PayPal account
       */
      case getType(actions.createIntegrationPaypalRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      /**
       * CREATE_INTEGRATION_PAYPAL_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal
       * Add a PayPal account
       */
      case getType(actions.createIntegrationPaypalRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.paypalAccounts.push(action.payload.paypalAccount)
        break
      }

      /**
       * CREATE_INTEGRATION_PAYPAL_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal
       * Add a PayPal account
       */
      case getType(actions.createIntegrationPaypalRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * INTEGRATION_ENABLED_REQUEST
       *
       * /integration/{uid}/connected
       * Get the Enabled unities
       */
      case getType(actions.integrationEnabledRequest): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(actions.integrationEnabledRequestSuccess): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = false
        draft.integrationEnabled = {
          ...draft.integrationEnabled,
          ...action.payload.integrationEnabled,
        }
        break
      }

      case getType(actions.integrationEnabledRequestFailure): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = false
        break
      }
    }
  })

export default integrationReducer
/* tslint:enable cyclomatic-complexity */
