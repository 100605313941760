import { call, put, takeLeading } from 'redux-saga/effects'

import { User } from 'connect-types/user/user.type'
import { getType } from 'typesafe-actions'
import { get } from 'utils/api'
import * as actions from './auth.actions'

export interface ApiUserCredentials {
  access_token: string
  expires_in: number
  refresh_token: string
  scope: null // FIXME: what type should this be
  token_type: 'Bearer'
}

function* fetchOauthTestRequest() {
  try {
    const response: {
      message: User
      status: number
    } = yield call(async () => get('/oauth/test'))

    yield put(actions.fetchOauthTestRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.fetchOauthTestRequestFailure(error))
  }
}

export function* watchFetchOauthTestRequest() {
  yield takeLeading(
    getType(actions.fetchOauthTestRequest),
    fetchOauthTestRequest
  )
}

export default [watchFetchOauthTestRequest]
