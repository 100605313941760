import { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { ActionType, getType } from 'typesafe-actions'
import * as actions from './gifting.actions'

export type GiftingAction = ActionType<typeof actions>

export interface StateType {
  shareModalVisible: boolean
}

const initialState: StateType = {
  shareModalVisible: false,
}

/* tslint:disable cyclomatic-complexity */
const giftingReducer: Reducer<StateType, GiftingAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setShareModalVisible): {
        draft.shareModalVisible = action.payload.shareModalVisible
        break
      }
    }
  })

export default giftingReducer
/* tslint:enable cyclomatic-complexity */
