import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import message from 'common/message/message'
import * as authActions from 'state/auth/auth.actions'

import {
  MembersResponse,
  MemberRole,
} from 'connect-types/members/getMembers.type'
import { User } from 'connect-types/user/user.type'

import { SingleTemplate } from 'connect-types/members/template.type'
import { MembersSubscriptionResponse } from 'connect-types/subscriptions/subscription.type'
import { GalleryGetResponse } from 'connect-types/members/galleryGetResponse.type'
import { NotificationList } from 'connect-types/members/profileNotificationList.type'
import { Filter } from 'connect-types/members/filter.type'
import { FilterCreateRequestBody } from 'connect-types/members/filterCreateRequestBody.type'

import * as actions from './member.actions'
import { initPage } from 'connect-types/pagination/pagination.type'

export type MemberAction = ActionType<typeof actions & typeof authActions>

export interface StateType {
  members: MembersResponse
  selectedMember: User
  pendingSave: boolean
  templates: SingleTemplate[]
  notifications: NotificationList
  isLoading: boolean
  isEmailSending: boolean
  memberError: boolean
  roles: MemberRole[]
  subscriptionResponse: MembersSubscriptionResponse
  gallery: GalleryGetResponse
  updatePasswordSuccess: boolean
  filters: Filter[]
  filtersDetails: FilterCreateRequestBody[]
  newFilter: FilterCreateRequestBody
  selectedFilter: FilterCreateRequestBody
  senderVisible: boolean
}

export const initialState: StateType = {
  members: {
    has_more: false,
    total: 0,
    next_offset: 0,
    results: [],
    request: null,
  },
  isEmailSending: false,
  selectedMember: null,
  updatePasswordSuccess: false,
  pendingSave: false,
  templates: [],
  notifications: null,
  isLoading: false,
  memberError: false,
  roles: [
    {
      name: 'Super Admin',
      code: 'superadmin',
      orgName: 'Super Admin',
      int: 0,
      min: 0,
      description: 'Has access to everything',
    },
    {
      name: 'Reseller',
      orgName: 'Reseller',
      code: 'reseller',
      int: 1,
      min: 0,
      description: 'Has full access to mutiple admin accounts',
    },
    {
      name: 'Admin',
      orgName: 'Admin',
      code: 'admin',
      int: 2,
      min: 1,
      description: 'Has full access to mutiple admin accounts',
    },
    {
      name: 'Moderator',
      code: 'moderator',
      orgName: 'Moderator',
      int: 3,
      min: 2,
      description: 'Has admin level access at selected locations',
    },
    {
      name: 'Analytics',
      orgName: 'Reports',
      code: 'report',
      int: 4,
      min: 2,
      description: 'Has access to analytics at selected locations',
    },
    {
      name: 'Marketeer',
      orgName: 'Marketeer',
      code: 'market',
      int: 5,
      min: 2,
      description: 'Has access to marketing at selected locations',
    },
    {
      name: 'Booking',
      orgName: 'Booking',
      code: 'booking',
      int: 6,
      min: 2,
      description: 'Has access to bookings',
    },
  ],
  subscriptionResponse: {
    has_more: false,
    next_offset: 0,
    subscriptions: [],
    total: 0,
  },
  gallery: initPage,
  filters: [],
  filtersDetails: [],
  newFilter: {
    type: '',
    name: '',
    events: [
      {
        joinType: '',
        operand: '',
        position: 0,
        question: '',
        value: '',
      },
    ],
  },
  selectedFilter: null,
  senderVisible: false,
}

/* tslint:disable cyclomatic-complexity */
const memberReducer: Reducer<StateType, MemberAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.addTagToImage): {
        const item = draft.gallery.body.find(
          (item) => item.id.toString() === action.payload.imageId
        )
        if (item) {
          item.labels.push(action.payload.label)
        }

        break
      }

      case getType(actions.removeTagToImage): {
        const item = draft.gallery.body.find(
          (item) => item.id.toString() === action.payload.imageId
        )
        if (item) {
          item.labels = item.labels.filter(
            (item) => item.id !== action.payload.label.id
          )
        }

        break
      }

      /** SET_SELECTED_USER */
      case getType(actions.setSelectedMember): {
        if (
          !Object.is(draft.selectedMember, action.payload.member) &&
          draft.selectedMember
        ) {
          draft.pendingSave = true
        }

        draft.selectedMember = action.payload.member
        break
      }

      /**
       * UPDATE_MEMBERS_REQUEST
       *
       * /members/{uid}
       * Get/create/update/delete a user
       */
      case getType(actions.updateMembers.request): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.isLoading = true
        draft.memberError = false

        break
      }

      /**
       * UPDATE_MEMBERS_REQUEST_SUCCESS
       *
       * /members/{uid}
       * Get/create/update/delete a user
       */
      case getType(actions.updateMembers.success): {
        // TODO: implemement logic here
        // draft.something = whatever

        draft.pendingSave = false
        draft.isLoading = false
        draft.memberError = false

        break
      }

      /**
       * UPDATE_MEMBERS_REQUEST_FAILURE
       *
       * /members/{uid}
       * Get/create/update/delete a user
       */
      case getType(actions.updateMembers.failure): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.pendingSave = false
        draft.isLoading = false
        draft.memberError = true
        break
      }

      case getType(actions.membersGalleryPushContent): {
        draft.gallery.body.unshift(action.payload.item)
        draft.gallery.total += 1
        break
      }

      case getType(actions.membersGalleryContentRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.membersGalleryContentRequestSuccess): {
        draft.isLoading = false
        draft.gallery = action.payload.gallery

        break
      }

      case getType(actions.membersGalleryContentRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequest): {
        draft.isLoading = true
        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequestSuccess): {
        draft.isLoading = false
        message.success('Image deleted')
        const find = draft.gallery.body.findIndex(
          (image) => image.id === action.payload.id
        )
        draft.gallery.body.splice(find, 1)

        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * MEMBERS_TEMPLATE_REQUEST
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.membersTemplateRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.isLoading = true
        break
      }

      /**
       * MEMBERS_TEMPLATE_REQUEST_SUCCESS
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.membersTemplateRequestSuccess): {
        draft.isLoading = false
        draft.templates = action.payload.templates
        break
      }

      /**
       * MEMBERS_TEMPLATE_REQUEST_FAILURE
       *
       * /members/{uid}/templates/{id}
       */
      case getType(actions.membersTemplateRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * CREATE_MEMBERS_TEMPLATE_REQUEST
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.createMembersTemplateRequest): {
        draft.isLoading = true
        break
      }

      /**
       * MEMBERS_TEMPLATE_REQUEST_SUCCESS
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.createMembersTemplateRequestSuccess): {
        draft.isLoading = false
        message.success('Sender template created')
        const index = draft.templates.findIndex(
          (template) => template.id === action.payload.template.id
        )

        if (index <= -1) {
          draft.templates.push(action.payload.template)
        } else {
          draft.templates[index] = action.payload.template
          draft.senderVisible = false
        }

        break
      }

      /**
       * MEMBERS_TEMPLATE_REQUEST_FAILURE
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.createMembersTemplateRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * DELETE_MEMBERS_TEMPLATE_REQUEST
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.deleteMembersTemplateRequest): {
        draft.isLoading = true

        break
      }

      /**
       * DELETE_MEMBERS_TEMPLATE_REQUEST_SUCCESS
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.deleteMembersTemplateRequestSuccess): {
        draft.isLoading = false
        const findIndex = draft.templates.findIndex(
          (template) => template.id === action.payload.id
        )

        draft.templates.splice(findIndex, 1)
        message.success('Sender settings deleted')
        draft.senderVisible = false
        break
      }

      /**
       * DELETE_MEMBERS_TEMPLATE_REQUEST_FAILURE
       *
       * /members/{uid}/templates/{id}
       * Get/delete a template
       */
      case getType(actions.deleteMembersTemplateRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * Set a filter
       *
       *
       */
      case getType(actions.setFilterMember): {
        draft.newFilter = action.payload.filter
        break
      }

      /**
       * MEMBERS_ALL_FILTER_REQUEST
       *
       * /members/{uid}/filters
       *  Get the list of filters
       */
      case getType(actions.membersAllFilterRequest): {
        draft.isLoading = true
        //draft.filters = []
        break
      }

      case getType(actions.membersAllFilterRequestSuccess): {
        draft.filters = action.payload.filters
        draft.isLoading = false
        break
      }

      case getType(actions.membersAllFilterRequestFailure): {
        draft.isLoading = false
        draft.filters = []
        break
      }

      /**
       * MEMBERS_FILTER_LIST_REQUEST
       *
       * /members/{uid}/filters/{id}
       * Get a specific filter list
       */
      case getType(actions.membersFilterListRequest): {
        draft.isLoading = true
        draft.selectedFilter = null
        break
      }

      case getType(actions.membersFilterListRequestSuccess): {
        draft.selectedFilter = action.payload.filter
        draft.isLoading = false
        break
      }

      case getType(actions.membersFilterListRequestFailure): {
        draft.selectedFilter = null
        draft.isLoading = false
        break
      }

      /**
       * MEMBERS_FILTER_LIST_DELETE_REQUEST
       *
       * /members/{uid}/filters/{id}
       * Delete a specific filter list
       */
      case getType(actions.membersFilterListDeleteRequest): {
        draft.isLoading = true

        break
      }

      case getType(actions.membersFilterListDeleteRequestSuccess): {
        draft.isLoading = false
        const findIndex = draft.filters.findIndex(
          (filter) => filter.id === action.payload.id
        )

        draft.selectedFilter = null
        draft.filters.splice(findIndex, 1)
        message.success('Segment removed')
        break
      }

      case getType(actions.membersFilterListDeleteRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * UPDATE_MEMBERS_FILTER_REQUEST
       *
       * /members/{uid}/filters
       * Update a specific filter
       */

      case getType(actions.updateMembersFilterRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.updateMembersFilterRequestSuccess): {
        const index = draft.filters.findIndex(
          (filter) => filter.id === action.payload.filter.id
        )

        draft.isLoading = false

        if (index >= 0) {
          draft.filters[index] = action.payload.filter
        } else {
          draft.filtersDetails.push(action.payload.filter)
          draft.filters.push({
            id: action.payload.filter.id,
            name: action.payload.filter.name,
            type: action.payload.filter.type,
          })
        }

        draft.selectedFilter = action.payload.filter

        message.success('Segment updated')
        break
      }

      case getType(actions.updateMembersFilterRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * MEMBERS_NOTIFICATIONS_REQUEST
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.membersNotificationsRequest): {
        draft.isLoading = true
        draft.notifications = null
        break
      }

      /**
       * MEMBERS_NOTIFICATIONS_REQUEST_SUCCESS
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.membersNotificationsRequestSuccess): {
        draft.isLoading = false
        draft.notifications = action.payload.notifications
        break
      }

      /**
       * MEMBERS_NOTIFICATIONS_REQUEST_FAILURE
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.membersNotificationsRequestFailure): {
        draft.isLoading = false

        break
      }

      /**
       * UPDATE_MEMBERS_NOTIFICATIONS_REQUEST
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.updateMembersNotificationsRequest): {
        draft.isLoading = true
        break
      }

      /**
       * UPDATE_MEMBERS_NOTIFICATIONS_REQUEST_SUCCESS
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.updateMembersNotificationsRequestSuccess): {
        draft.isLoading = false
        draft.notifications = action.payload.notifications
        break
      }

      /**
       * UPDATE_MEMBERS_NOTIFICATIONS_REQUEST_FAILURE
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.updateMembersNotificationsRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * DELETE_MEMBERS_NOTIFICATIONS_REQUEST
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.deleteMembersNotificationsRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_MEMBERS_NOTIFICATIONS_REQUEST_SUCCESS
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.deleteMembersNotificationsRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_MEMBERS_NOTIFICATIONS_REQUEST_FAILURE
       *
       * /members/{uid}/notifications
       * Get User's List of Subscribed Notifications
       */
      case getType(actions.deleteMembersNotificationsRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_PASSWORD_REQUEST
       *
       * /password/{uid}
       * Change password of user
       */
      case getType(actions.updatePassword.request): {
        // TODO: implemement logic here
        draft.isLoading = true
        draft.updatePasswordSuccess = false
        break
      }

      /**
       * UPDATE_PASSWORD_REQUEST_SUCCESS
       *
       * /password/{uid}
       * Change password of user
       */
      case getType(actions.updatePassword.success): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.updatePasswordSuccess = true
        break
      }

      /**
       * UPDATE_PASSWORD_REQUEST_FAILURE
       *
       * /password/{uid}
       * Change password of user
       */
      case getType(actions.updatePassword.failure): {
        // TODO: implemement logic here
        draft.isLoading = false
        draft.updatePasswordSuccess = false
        break
      }

      /**
       * PARTNER_ALL_QUOTE_REQUEST
       *
       * /partner/{uid}/quotes
       * Get all quotes for the user
       */
      case getType(actions.membersAllSubscriptionsRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.membersAllSubscriptionsRequestSuccess): {
        draft.isLoading = false
        draft.subscriptionResponse = action.payload.response
        break
      }

      case getType(actions.membersAllSubscriptionsRequestFailure): {
        draft.isLoading = false
        break
      }
      case getType(actions.sendSupportEmailRequest): {
        draft.isEmailSending = true
        break
      }

      case getType(actions.sendSupportEmailRequestSuccess): {
        draft.isEmailSending = false
        message.success('Cancel request is being processed')
        break
      }

      case getType(actions.sendSupportEmailRequestFailure): {
        draft.isEmailSending = false
        break
      }

      case getType(actions.senderVisible): {
        draft.senderVisible = action.payload.visible
        break
      }
    }
  })

export default memberReducer
/* tslint:enable cyclomatic-complexity */
