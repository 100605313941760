import { getAnalytics } from 'firebase/analytics'
import { FC, PropsWithChildren } from 'react'
import { AnalyticsProvider, useFirebaseApp } from 'reactfire'

const FirebaseAnalytics: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const app = useFirebaseApp()

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>{children}</AnalyticsProvider>
  )
}

export default FirebaseAnalytics
