import { DeepPartial } from 'config'
import { Config } from './config.type'
import domain from './domain'

const config: DeepPartial<Config> = {
  appName: 'Stampede Product',
  url: {
    selfservice: `https://api-selfservice.stg.${domain}`,
    api: `https://api.stage.${domain}`,
    connect: `https://product.stage.${domain}`,
    accounts: `https://accounts.stage.${domain}`,
    nearly: `https://stage.nearly.online`,
    mailchimp: `https://mailchimp.stage.${domain}`,
    reviews: `https://reviews.stage.${domain}`,
    service: `https://service.stage.${domain}`,
    urlGenerator: `https://url-generator.stg.${domain}`,
    bookingIntegration: `https://bookings-intergrations.stg.${domain}`,
    forms: `https://forms.stage.${domain}`,
    bookings: `https://api-bookings.stg.${domain}`,
    booking_url: `https://booking.stage.${domain}`,
    gifting: `https://gifting.stage.${domain}`,
    reporting: `https://reporting.stg.${domain}`,
    inbox_email: '@stage.stmpd-mail.com',
  },
  auth: {
    clientId: `${domain}.connect_stage`,
    redirectUri: `https://product.stage.${domain}/code`,
  },
  enableWhyDidYouUpdate: false,
  chargebee: {
    site: 'stampede-test',
    domain: `https://stampede-test.chargebee.com`,
  },
}

export default config
