import { createAction } from 'typesafe-actions'
/**
 * GET Get list of users
 *
 * Path: /members/{uid}/users
 */

// FIXME: add in request and query params
export const membersUsersRequest = createAction(
  'MEMBERS_USERS_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const membersUsersRequestSuccess = createAction(
  'MEMBERS_USERS_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const membersUsersRequestFailure = createAction(
  'MEMBERS_USERS_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'members users request failed' })
)
