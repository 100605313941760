import { createReducer } from '@reduxjs/toolkit'
import message from 'common/message/message'
import { getFirst } from 'utils/common'
import {
  addVenuePersonalisationCards,
  createOrganisationPersonalisationCards,
  getOrganisationPersonalisationCards,
  getVenuePersonalisationCards,
  orderVenuePersonalisationCards,
  removeVenuePersonalisationCards,
  updateOrganisationPersonalisationCards,
} from './personalisation.actions'
import {
  LocationPersonalisationCardsType,
  OrganisationPersonalisationCardType,
} from './personalisation.types'

interface StateType {
  questions: OrganisationPersonalisationCardType[]
  venues: {
    [serial: string]: LocationPersonalisationCardsType[]
  }
  isLoading: boolean
}

const initialState: StateType = {
  questions: [],
  venues: {},
  isLoading: false,
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getOrganisationPersonalisationCards.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getOrganisationPersonalisationCards.fulfilled, (state, action) => {
      state.isLoading = false
      state.questions = action.payload
    })
    .addCase(getOrganisationPersonalisationCards.rejected, (state) => {
      state.isLoading = false
      state.questions = []
    })
    .addCase(createOrganisationPersonalisationCards.pending, (state) => {
      state.isLoading = true
    })
    .addCase(
      createOrganisationPersonalisationCards.fulfilled,
      (state, action) => {
        state.isLoading = false
        state.questions.push(action.payload)
        message.success('Card added')
      }
    )
    .addCase(createOrganisationPersonalisationCards.rejected, (state) => {
      state.isLoading = false
      message.error('Card could not be added')
    })

    .addCase(updateOrganisationPersonalisationCards.pending, (state) => {
      state.isLoading = true
    })
    .addCase(
      updateOrganisationPersonalisationCards.fulfilled,
      (state, action) => {
        state.isLoading = false
        const index = state.questions.findIndex(
          (item) => item.id === action.payload.id
        )
        if (index >= 0) {
          state.questions[index] = action.payload
        }
        Object.entries(state.venues).forEach(([serial, value]) => {
          const id = value.findIndex(
            (venue) => venue.card.id === action.payload.id
          )
          if (id >= 0) {
            state.venues[serial][id].card = action.payload
          }
        })

        message.success('Updated')
      }
    )
    .addCase(updateOrganisationPersonalisationCards.rejected, (state) => {
      state.isLoading = false
      message.error('Card could not be updated')
    })

    .addCase(getVenuePersonalisationCards.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getVenuePersonalisationCards.fulfilled, (state, action) => {
      state.isLoading = false
      const serial = getFirst(action.payload)?.serial
      if (serial) {
        state.venues[serial] = action.payload
      }
    })
    .addCase(getVenuePersonalisationCards.rejected, (state) => {
      state.isLoading = false
    })
    .addCase(addVenuePersonalisationCards.pending, () => {})
    .addCase(addVenuePersonalisationCards.fulfilled, (state, action) => {
      const serial = action.payload.serial
      if (!(serial in state.venues)) {
        state.venues[serial] = []
      }
      state.venues[serial].push(action.payload)
      message.success('Card added to survey')
    })
    .addCase(addVenuePersonalisationCards.rejected, () => {})

    .addCase(removeVenuePersonalisationCards.pending, () => {})

    .addCase(removeVenuePersonalisationCards.fulfilled, (state, action) => {
      state.venues[action.payload.serial] = state.venues[
        action.payload.serial
      ].filter((item) => item.card.id !== action.payload.card_id)
    })

    .addCase(orderVenuePersonalisationCards.pending, () => {})

    .addCase(orderVenuePersonalisationCards.fulfilled, (state, action) => {
      const serial = getFirst(action.payload)?.serial
      if (serial) {
        state.venues[serial] = action.payload.sort(
          (a, b) => a.order_by - b.order_by
        )
      }
      message.success('Order changed')
    })
    .addCase(orderVenuePersonalisationCards.rejected, () => {})
)

export default reducer
