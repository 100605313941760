import { call, put, takeLatest } from 'redux-saga/effects'
import { LocationDebuggerPayload } from 'connect-types/locations/locationDebuggerPayload.type'
import { LocationTemplateType } from 'connect-types/locations/locationTemplate.type'
import { PaymentMethodUpdateRequestBody } from 'connect-types/locations/paymentMethodUpdateRequestBody.type'
import { PaymentPlanCreateRequestBody } from 'connect-types/locations/paymentPlanCreateRequestBody.type'
import { ActionType, getType } from 'typesafe-actions'
import { deleter, get, post, put as putRequest } from 'utils/api'
import * as actions from './locations.actions'

/* Get Location Config Debugger
 */
function* locationDebuggerConfigGetRequest(
  action: ActionType<typeof actions.locationsDebuggerConfigRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: LocationDebuggerPayload
    } = yield call(async () => get(`/locations/${serial}/debugger/config`))
    const connectionDebugger = response.message

    yield put(actions.locationsDebuggerConfigRequestSuccess(connectionDebugger))
  } catch (error) {
    yield put(actions.locationsDebuggerConfigRequestFailure())
  }
}

export function* watchDebuggerConfigGetRequest() {
  yield takeLatest(
    getType(actions.locationsDebuggerConfigRequest),
    locationDebuggerConfigGetRequest
  )
}

/* Get Location Payment Plans
 */
function* locationsPaymentPlansRequest(
  action: ActionType<typeof actions.locationsPaymentPlansRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: PaymentPlanCreateRequestBody[]
    } = yield call(async () => get(`/locations/${serial}/payments/plans`))

    yield put(actions.locationsPaymentPlansRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.locationsPaymentPlansRequestFailure(error.response))
  }
}

export function* watchLocationsPaymentPlansRequest() {
  yield takeLatest(
    getType(actions.locationsPaymentPlansRequest),
    locationsPaymentPlansRequest
  )
}

/* Create Location Payment Plan
 */
function* locationsPaymentPlansPostRequest(
  action: ActionType<typeof actions.createLocationsPaymentPlansRequest>
) {
  try {
    const { serial, locationPlan } = action.payload

    const response: {
      status: number
      message: PaymentPlanCreateRequestBody
    } = yield call(async () =>
      post(`/locations/${serial}/payments/plans`, locationPlan)
    )

    yield put(
      actions.createLocationsPaymentPlansRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.createLocationsPaymentPlansRequestFailure(error.response))
  }
}

export function* watchLocationsPaymentPlansPostRequest() {
  yield takeLatest(
    getType(actions.createLocationsPaymentPlansRequest),
    locationsPaymentPlansPostRequest
  )
}

/* Update Location Payment Plan
 */
function* locationsPaymentPlansPutRequest(
  action: ActionType<typeof actions.updateLocationsPaymentPlansRequest>
) {
  try {
    const { serial, locationPlan, planId } = action.payload

    const response: {
      status: number
      message: PaymentPlanCreateRequestBody
    } = yield call(async () =>
      putRequest(`/locations/${serial}/payments/plans/${planId}`, locationPlan)
    )

    yield put(
      actions.updateLocationsPaymentPlansRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.updateLocationsPaymentPlansRequestFailure(error.response))
  }
}

export function* watchLocationsPaymentPlansPutRequest() {
  yield takeLatest(
    getType(actions.updateLocationsPaymentPlansRequest),
    locationsPaymentPlansPutRequest
  )
}

/* Delete Location Payment Plan
 */
function* locationsPaymentPlansDeleteRequest(
  action: ActionType<typeof actions.deleteLocationsPaymentPlansRequest>
) {
  try {
    const { serial, planId } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      deleter(`/locations/${serial}/payments/plans/${planId}`)
    )

    yield put(
      actions.deleteLocationsPaymentPlansRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.deleteLocationsPaymentPlansRequestFailure(error.response))
  }
}

export function* watchLocationsPaymentPlansDeleteRequest() {
  yield takeLatest(
    getType(actions.deleteLocationsPaymentPlansRequest),
    locationsPaymentPlansDeleteRequest
  )
}

/* GET Location Payment Method
 */
function* locationsPaymentMethodsRequest(
  action: ActionType<typeof actions.locationsPaymentMethodsRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: PaymentMethodUpdateRequestBody
    } = yield call(async () => get(`/locations/${serial}/payments/method`))

    yield put(actions.locationsPaymentMethodsRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.locationsPaymentMethodsRequestFailure(error.response))
  }
}

export function* watchLocationsPaymentMethodsRequest() {
  yield takeLatest(
    getType(actions.locationsPaymentMethodsRequest),
    locationsPaymentMethodsRequest
  )
}

/* Update Location Payment Method
 */
function* updateLocationsPaymentMethodsRequest(
  action: ActionType<typeof actions.updateLocationsPaymentMethodsRequest>
) {
  try {
    const { serial, method } = action.payload

    const response: {
      status: number
      message: PaymentMethodUpdateRequestBody
    } = yield call(async () =>
      putRequest(`/locations/${serial}/payments/method`, method)
    )

    yield put(
      actions.updateLocationsPaymentMethodsRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(
      actions.updateLocationsPaymentMethodsRequestFailure(error.response)
    )
  }
}

export function* watchUpdateLocationsPaymentMethodsRequest() {
  yield takeLatest(
    getType(actions.updateLocationsPaymentMethodsRequest),
    updateLocationsPaymentMethodsRequest
  )
}

function* locationsSettingsTemplateRequest(
  action: ActionType<typeof actions.locationsSettingsTemplateRequest>
) {
  try {
    const { serial } = action.payload

    // TODO: if this response format is standard, could have a type for it
    const response: {
      status: number
      message: LocationTemplateType
    } = yield call(async () => get(`/locations/${serial}/settings/template`))

    const locations = response.message

    yield put(actions.locationsSettingsTemplateRequestSuccess(locations))
  } catch (error) {
    yield put(actions.locationsSettingsTemplateRequestFailure())
  }
}

export function* watchLocationsSettingsTemplateRequest() {
  yield takeLatest(
    getType(actions.locationsSettingsTemplateRequest),
    locationsSettingsTemplateRequest
  )
}

function* createLocationsSettingsTemplateRequest(
  action: ActionType<typeof actions.createLocationsSettingsTemplateRequest>
) {
  try {
    const { serial, data } = action.payload
    // TODO: if this response format is standard, could have a type for it
    const response: {
      status: number
      message: LocationTemplateType
    } = yield call(async () =>
      post(`/locations/${serial}/settings/template`, data)
    )

    const locations = response.message

    yield put(actions.createLocationsSettingsTemplateRequestSuccess(locations))
  } catch (error) {
    yield put(actions.createLocationsSettingsTemplateRequestFailure())
  }
}

export function* watchCreateLocationsSettingsTemplateRequest() {
  yield takeLatest(
    getType(actions.createLocationsSettingsTemplateRequest),
    createLocationsSettingsTemplateRequest
  )
}

function* updateLocationsSettingsTemplateRequest(
  action: ActionType<typeof actions.updateLocationsSettingsTemplateRequest>
) {
  try {
    const { serial, data } = action.payload

    // TODO: if this response format is standard, could have a type for it
    const response: {
      status: number
      message: LocationTemplateType
    } = yield call(async () =>
      putRequest(`/locations/${serial}/settings/template`, data)
    )

    const locations = response.message

    yield put(actions.updateLocationsSettingsTemplateRequestSuccess(locations))
  } catch (error) {
    yield put(actions.updateLocationsSettingsTemplateRequestFailure())
  }
}

export function* watchUpdateLocationsSettingsTemplateRequest() {
  yield takeLatest(
    getType(actions.updateLocationsSettingsTemplateRequest),
    updateLocationsSettingsTemplateRequest
  )
}

function* deleteLocationsSettingsTemplateRequest(
  action: ActionType<typeof actions.deleteLocationsSettingsTemplateRequest>
) {
  try {
    const { serial } = action.payload

    // TODO: if this response format is standard, could have a type for it
    yield call(async () => deleter(`/locations/${serial}/settings/template`))

    yield put(actions.deleteLocationsSettingsTemplateRequestSuccess())
  } catch (error) {
    yield put(actions.deleteLocationsSettingsTemplateRequestFailure())
  }
}

export function* watchDeleteLocationsSettingsTemplateRequest() {
  yield takeLatest(
    getType(actions.deleteLocationsSettingsTemplateRequest),
    deleteLocationsSettingsTemplateRequest
  )
}

export default [
  watchDebuggerConfigGetRequest,

  watchLocationsPaymentPlansRequest,
  watchLocationsPaymentPlansPostRequest,
  watchLocationsPaymentPlansPutRequest,
  watchLocationsPaymentPlansDeleteRequest,
  watchLocationsPaymentMethodsRequest,
  watchUpdateLocationsPaymentMethodsRequest,

  watchLocationsSettingsTemplateRequest,
  watchCreateLocationsSettingsTemplateRequest,
  watchUpdateLocationsSettingsTemplateRequest,
  watchDeleteLocationsSettingsTemplateRequest,
]
