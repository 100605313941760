import config from 'config'
import { LocalStorageCredentials } from 'connect-types/auth/localStorageCredentials.type'

export const getToken = (): LocalStorageCredentials | null => {
  const item = localStorage.getItem(config.localStorageKeys.userCredentials)

  if (!item) {
    return null
  }

  return JSON.parse(item)
}

export const setToken = (token: LocalStorageCredentials) =>
  localStorage.setItem(
    config.localStorageKeys.userCredentials,
    JSON.stringify(token)
  )

export interface ApiUserRefreshTokenResponse {
  access_token?: string
  expires_in?: number
  refresh_token?: string
  scope?: null // FIXME: what type should this be
  token_type?: 'Bearer'
  error?: string
  error_description?: string
}

export interface ApiUserRefreshTokenRequestData {
  client_id: string
  client_secret: string
  grant_type: 'refresh_token'
  refresh_token: string
  scope?: string
}

export const refresh_client = (
  refresh_token: string
): ApiUserRefreshTokenRequestData => ({
  client_id: config.auth.clientId,
  client_secret: config.auth.clientSecret,
  grant_type: 'refresh_token',
  refresh_token,
  scope: config.auth.scope,
})
