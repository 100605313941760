import { Filter } from 'connect-types/members/filter.type'
import { FilterCreateRequestBody } from 'connect-types/members/filterCreateRequestBody.type'
import { GalleryGetResponse } from 'connect-types/members/galleryGetResponse.type'
import { NotificationList } from 'connect-types/members/profileNotificationList.type'
import { SingleTemplate } from 'connect-types/members/template.type'
import { MembersSubscriptionResponse } from 'connect-types/subscriptions/subscription.type'
import { User } from 'connect-types/user/user.type'
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import * as loadedActions from 'state/entities/loaded/loaded.actions'
import * as globalActions from 'state/global/global.actions'
import { RootState } from 'state/reducers'
import { ActionType, getType } from 'typesafe-actions'
import { deleter, get, post, put as putRequest } from 'utils/api'
import { buildUrl } from 'utils/common'
import * as actions from './member.actions'
import { updateMembers, updatePassword } from './member.actions'

/* Update member
 * ========================================================================== */
function* updateMembersSaga(action: ActionType<typeof updateMembers.request>) {
  try {
    const { uid, user } = action.payload

    const {
      message,
    }: {
      status: number
      message: User
    } = yield call(async () => putRequest(`/members/${uid}`, user))

    // yield put(authActions.fetchCurrentUserSuccess(message))
    yield put(actions.updateMembers.success({ user: message, uid }))
  } catch (error) {
    yield put(actions.updateMembers.success(error.response))
  }
}

/* Get Member Templates
 * ========================================================================== */
function* membersTemplateRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global

    const response: {
      status: number
      message: SingleTemplate[]
    } = yield call(async () => get(`/organisations/${orgId}/templates`))

    const templates = response.message

    yield put(loadedActions.setLoaded('sender_templates', true))
    yield put(actions.membersTemplateRequestSuccess(templates))
  } catch (error) {
    yield put(actions.membersTemplateRequestFailure(error.response))
  }
}

/* Create Member Templates
 * ========================================================================== */
function* createMembersTemplateRequest(
  action: ActionType<typeof actions.createMembersTemplateRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { template } = action.payload

    const response: {
      status: number
      message: SingleTemplate
    } = yield call(async () =>
      post(`/organisations/${orgId}/templates`, template)
    )

    yield put(actions.createMembersTemplateRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.createMembersTemplateRequestFailure(error.response))
  }
}

/* Delete Member Templates
 * ========================================================================== */
function* deleteMembersTemplateRequest(
  action: ActionType<typeof actions.deleteMembersTemplateRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { id } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      deleter(`/organisations/${orgId}/templates/${id}`)
    )

    yield put(actions.deleteMembersTemplateRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.deleteMembersTemplateRequestFailure(error.response))
  }
}

/* Get Members Gallery
 * ========================================================================== */
function* membersGalleryRequest(
  action: ActionType<typeof actions.membersGalleryContentRequest>
) {
  try {
    const { orgId, path, kind, offset, label_like, name_like } = action.payload
    const url = buildUrl(`/organisations/${orgId}/gallery`, {
      offset,
      kind,
      path,
      limit: 50,
      label_like,
      name_like,
    })

    const response: GalleryGetResponse = yield call(async () => get(url))

    yield put(actions.membersGalleryContentRequestSuccess(response, kind, path))
  } catch (error) {
    yield put(actions.membersGalleryContentRequestFailure(error.response))
  }
}

/* Delete Members Gallery Image
 * ========================================================================== */
function* deleteMembersGalleryRequest(
  action: ActionType<typeof actions.deleteMembersGalleryContentRequest>
) {
  try {
    const { orgId, id } = action.payload
    yield call(async () => deleter(`/organisations/${orgId}/gallery/${id}`))

    yield put(actions.deleteMembersGalleryContentRequestSuccess(id))
  } catch (error) {
    yield put(actions.deleteMembersGalleryContentRequestFailure(error.response))
  }
}

/* Get all subscriptions
 * ========================================================================== */
function* membersAllSubscriptionsRequest(
  action: ActionType<typeof actions.membersAllSubscriptionsRequest>
) {
  try {
    const { uid, offset, filter } = action.payload

    let path = `/members/${uid}/subscriptions?filter=${filter}`

    if (offset) {
      path += `&offset=${offset}`
    }

    const response: {
      status: number
      message: MembersSubscriptionResponse
    } = yield call(async () => get(path))

    const subscriptionResponse = response.message

    yield put(
      actions.membersAllSubscriptionsRequestSuccess(subscriptionResponse)
    )
  } catch (error) {
    yield put(actions.membersAllSubscriptionsRequestFailure())
  }
}

/* GET Members Notifications
 * ========================================================================== */
function* membersNotificationsRequest(
  action: ActionType<typeof actions.membersNotificationsRequest>
) {
  try {
    const { uid } = action.payload
    const response: {
      status: number
      message: NotificationList
    } = yield call(async () => get(`/members/${uid}/notifications`))

    yield put(actions.membersNotificationsRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.membersNotificationsRequestFailure(error.response))
  }
}

/* PUT Members Notifications
 * ========================================================================== */
function* membersNotificationsPUTRequest(
  action: ActionType<typeof actions.updateMembersNotificationsRequest>
) {
  try {
    const { uid, notification } = action.payload
    const response: {
      status: number
      message: NotificationList
    } = yield call(async () =>
      putRequest(`/members/${uid}/notifications`, notification)
    )

    yield put(
      actions.updateMembersNotificationsRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.updateMembersNotificationsRequestFailure(error.response))
  }
}

/* Update password request
 * ========================================================================== */
function* updatePasswordSaga(
  action: ActionType<typeof updatePassword.request>
) {
  try {
    const { uid, password } = action.payload
    const response: {
      status: number
    } = yield call(async () => putRequest(`/password/${uid}`, { password }))

    yield put(updatePassword.success(response.status))
  } catch (error) {
    yield put(updatePassword.failure(error))
  }
}

/* GET the list of filters
 * ========================================================================== */
function* membersAllFilterRequest(
  action: ActionType<typeof actions.membersAllFilterRequest>
) {
  try {
    const { orgId, type } = action.payload
    const response: {
      status: number
      message: Filter[]
    } = yield call(async () =>
      get(`/organisations/${orgId}/filters?type=${type}`)
    )

    if (type === 'search') {
      yield put(loadedActions.setLoaded('filters', true))
    } else {
      yield put(loadedActions.setLoaded('filters', false))
    }

    yield put(actions.membersAllFilterRequestSuccess(response.message))
  } catch (error) {
    yield put(loadedActions.setLoaded('filters', false))
    yield put(actions.membersAllFilterRequestFailure(error.response))
  }
}

/* GET the list of filters
 * ========================================================================== */
function* membersFilterListRequest(
  action: ActionType<typeof actions.membersFilterListRequest>
) {
  try {
    const { orgId, id } = action.payload
    const response: {
      status: number
      message: FilterCreateRequestBody
    } = yield call(async () => get(`/organisations/${orgId}/filters/${id}`))

    yield put(actions.membersFilterListRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.membersFilterListRequestFailure(error.response))
  }
}

/* GET the list of filters
 * ========================================================================== */
function* membersFilterListDeleteRequest(
  action: ActionType<typeof actions.membersFilterListDeleteRequest>
) {
  try {
    const { orgId, id } = action.payload
    const response: {
      status: number
    } = yield call(async () => deleter(`/organisations/${orgId}/filters/${id}`))

    yield put(
      actions.membersFilterListDeleteRequestSuccess(response.status, id)
    )
  } catch (error) {
    yield put(actions.membersFilterListDeleteRequestFailure(error.response))
  }
}

/* PUT to update a filter
 * ========================================================================== */
function* updateMembersFilterRequest(
  action: ActionType<typeof actions.updateMembersFilterRequest>
) {
  try {
    const { orgId, filter } = action.payload
    const response: {
      status: number
      message: FilterCreateRequestBody
    } = yield call(async () =>
      putRequest(`/organisations/${orgId}/filters`, filter)
    )

    yield put(actions.updateMembersFilterRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.updateMembersFilterRequestFailure(error.response))
  }
}

/* PUT to update a filter
 * ========================================================================== */
function* setNotificationRequest(
  action: ActionType<typeof actions.setNotificationRequest>
) {
  try {
    const { token } = action.payload

    yield call(async () => {
      putRequest(
        '/tokens',
        {
          token,
          instanceId: 'browser',
        },
        'notifications'
      )
    })

    yield put(globalActions.setNotificationToken(token))
    yield put(actions.setNotificationRequestSuccess())
  } catch (error) {
    yield put(actions.setNotificationRequestFailure())
  }
}

/* Send email to support
 * ========================================================================== */
function* sendSupportEmailRequest(
  action: ActionType<typeof actions.sendSupportEmailRequest>
) {
  try {
    const { request } = action.payload
    const response: {
      status: number
      message: any
    } = yield call(async () =>
      post(`/members/me/subscriptions/cancellation-requests`, request)
    )

    yield put(actions.sendSupportEmailRequestSuccess(response))
  } catch (error) {
    yield put(actions.sendSupportEmailRequestFailure())
  }
}

export function* watchMemberRequest() {
  yield takeLatest(getType(updateMembers.request), updateMembersSaga)
  yield takeLatest(
    getType(actions.membersTemplateRequest),
    membersTemplateRequest
  )
  yield takeLatest(
    getType(actions.createMembersTemplateRequest),
    createMembersTemplateRequest
  )
  yield takeLatest(
    getType(actions.membersAllSubscriptionsRequest),
    membersAllSubscriptionsRequest
  )
  yield takeLatest(
    getType(actions.membersGalleryContentRequest),
    membersGalleryRequest
  )
  yield takeLatest(
    getType(actions.deleteMembersGalleryContentRequest),
    deleteMembersGalleryRequest
  )
  yield takeLatest(
    getType(actions.deleteMembersTemplateRequest),
    deleteMembersTemplateRequest
  )
  yield takeLatest(
    getType(actions.membersNotificationsRequest),
    membersNotificationsRequest
  )
  yield takeLatest(
    getType(actions.updateMembersNotificationsRequest),
    membersNotificationsPUTRequest
  )

  yield takeLatest(getType(updatePassword.request), updatePasswordSaga)
  yield takeLeading(
    getType(actions.membersAllFilterRequest),
    membersAllFilterRequest
  )
  yield takeLatest(
    getType(actions.membersFilterListRequest),
    membersFilterListRequest
  )
  yield takeLatest(
    getType(actions.membersFilterListDeleteRequest),
    membersFilterListDeleteRequest
  )
  yield takeLatest(
    getType(actions.updateMembersFilterRequest),
    updateMembersFilterRequest
  )
  yield takeLatest(
    getType(actions.sendSupportEmailRequest),
    sendSupportEmailRequest
  )
  yield takeLatest(
    getType(actions.setNotificationRequest),
    setNotificationRequest
  )

  // yield takeLatest(
  //   getType(actions.createMembersNewListSyncRequest),
  //   createMembersNewListSyncRequest
  // )
}

export default [watchMemberRequest]
