import type { ColorMode } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/react'
import { ReactNode } from 'react'
import theme from 'connect-theme'
const { ToastContainer, toast } = createStandaloneToast({
  theme,
  colorMode:
    (localStorage.getItem('chakra-ui-color-mode') as ColorMode) || 'light',
  defaultOptions: {
    position: 'bottom',
  },
})

export { ToastContainer }

interface MessageSpec {
  description?: string | ReactNode
  duration?: number
  isClosable?: boolean
}

const message = {
  error: (title: string, { description, duration }: MessageSpec = {}) =>
    toast({
      title,
      description,
      status: 'error',
      duration: duration ?? 3000,
      isClosable: true,
      variant: 'subtle',
    }),
  success: (title: string, { description, duration }: MessageSpec = {}) =>
    toast({
      title,
      description,
      status: 'success',
      duration: duration ?? 3000,
      isClosable: true,
      variant: 'subtle',
    }),
  warning: (title: string, { description, duration }: MessageSpec = {}) =>
    toast({
      title,
      description,
      status: 'warning',
      duration: duration ?? 3000,
      isClosable: true,
      variant: 'subtle',
    }),
  info: (title: string, { description, duration }: MessageSpec = {}) =>
    toast({
      title,
      description,
      status: 'info',
      duration: duration ?? 3000,
      isClosable: true,
      variant: 'subtle',
    }),
  loading: (title: string, { description, duration }: MessageSpec = {}) =>
    toast({
      title,
      description,
      status: 'info',
      duration: duration ?? 3000,
      isClosable: true,
      variant: 'subtle',
    }),
}

export default message
