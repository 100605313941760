import * as Sentry from '@sentry/react'
import { env } from 'config'
import { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import {
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
} from 'react-router-dom'

Sentry.init({
  dsn: 'https://f869ca3eacd149fc8bda3fe432cb8c71@o142237.ingest.sentry.io/1202143',
  autoSessionTracking: true,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: env,
  tracesSampleRate: 0.2,
  normalizeDepth: 5,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

const SenEx: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => (
  <>{children}</>
)

export default SenEx
