import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import qs from 'query-string'
import { deleter, get, post, put } from 'utils/api'
import { buildUrl } from 'utils/common'
import { FormatDateForBackend } from './bookings.helpers'
import {
  ActualTableLayoutType,
  AreaType,
  BookingConfigBlockTableTimesType,
  BookingConfigType,
  BookingsByDayWeekMonthYear,
  CalendarResult,
  ChargeResult,
  CombinationType,
  DailyStatsResult,
  FloorPlanType,
  GetSearchBookingsType,
  SectionType,
  ShapeType,
  SpecialDatesAndLoadingType,
  SpecialDatesType,
  TableType,
  TimesObject,
  UserBookingNoteType,
  UserBookingType,
  WeeklyStatsResult,
} from './bookings.types'

export const getBookingsConfig = createAsyncThunk(
  'GET_CONFIG_REQUEST',
  async (props: { orgId: string; serial: string }, { dispatch }) => {
    const { orgId, serial } = props
    const response = await get<BookingConfigType>(
      `/${orgId}/${serial}/config`,
      'bookings'
    )
    dispatch(
      getAreas({
        orgId,
        serial,
      })
    )
    return response
  }
)

export const getBookingsLocations = createAsyncThunk(
  'GET_ORG_CONFIGS_REQUEST',
  async (props: { orgId: string }) => {
    const { orgId } = props
    const response = await get<BookingConfigType[]>(
      `/${orgId}/config`,
      'bookings'
    )
    return response
  }
)

export const getTables = createAsyncThunk(
  'GET_TABLES_REQUEST',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props
    const response = await await get<TableType[]>(
      `/${orgId}/${serial}/units?type=table`,
      'bookings'
    )
    return response
  }
)

export const deleteTable = createAsyncThunk(
  'DELETE_TABLES_REQUEST',
  async (props: { orgId: string; serial: string; id: string }) => {
    const { orgId, serial, id } = props

    await deleter(`/${orgId}/${serial}/unit?id=${id}`, null, 'bookings')

    return id
  }
)

export const updateTable = createAsyncThunk(
  'UPDATE_TABLES_REQUEST',
  async (table: TableType) => {
    const response = put<TableType>(
      `/${table.orgId}/${table.serial}/unit`,
      table,
      'bookings'
    )

    return response
  }
)

export const getFloorPlanTables = createAsyncThunk(
  'GET_FLOOR_PLAN_TABLES_REQUEST',
  async (props: { orgId: string; serial: string; floorPlanId: string }) => {
    const { orgId, serial, floorPlanId } = props

    const response = await get<TableType[]>(
      `/${orgId}/${serial}/units?type=table&floorPlanId=${floorPlanId}`,
      'bookings'
    )
    return response
  }
)

export const getIndividualFloorPlan = createAsyncThunk(
  'GET_FLOOR_PLAN_REQUEST',
  async (props: { orgId: string; serial: string; floorPlanId: string }) => {
    const { orgId, serial, floorPlanId } = props

    const response = await get<FloorPlanType>(
      `/${orgId}/${serial}/floorplan?id=${floorPlanId}`,
      'bookings'
    )
    return response
  }
)

export const createTable = createAsyncThunk(
  'CREATE_TABLE_REQUEST',
  async (props: {
    orgId: string
    serial: string
    table: Partial<TableType>
  }) => {
    const { orgId, serial, table } = props
    const response = await post<TableType>(
      `/${orgId}/${serial}/unit`,
      table,
      null,
      'bookings'
    )
    return response
  }
)

export const getCombinations = createAsyncThunk(
  'GET_COMBINATIONS_REQUEST',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props
    const response = await get<CombinationType[]>(
      `/${orgId}/${serial}/unit-combinations`,
      'bookings'
    )
    return response
  }
)

export const getCombination = createAsyncThunk(
  'GET_COMBINATION_REQUEST',
  async (props: { orgId: string; serial: string; combinationId: string }) => {
    const { orgId, serial, combinationId } = props
    const response = await get<CombinationType>(
      `/${orgId}/${serial}/unit-combination?id=${combinationId}`,
      'bookings'
    )
    return response
  }
)

export const createCombination = createAsyncThunk(
  'CREATE_COMBINATION_REQUEST',
  async (props: {
    orgId: string
    serial: string
    combination: Partial<CombinationType>
  }) => {
    const { orgId, serial, combination } = props
    const response = await post<CombinationType>(
      `/${orgId}/${serial}/unit-combination`,
      combination,
      null,
      'bookings'
    )

    return response
  }
)

export const updateCombination = createAsyncThunk(
  'UPDATE_COMBINATION_REQUEST',
  async (props: {
    orgId: string
    serial: string
    combination: CombinationType
  }) => {
    const { orgId, serial, combination } = props

    const response = await put<CombinationType>(
      `/${orgId}/${serial}/unit-combination`,
      combination,
      'bookings'
    )
    return response
  }
)

export const deleteCombination = createAsyncThunk(
  'DELETE_COMBINATION_REQUEST',
  async (props: { orgId: string; serial: string; id: string }) => {
    const { orgId, serial, id } = props

    await deleter(`/${orgId}/${serial}/unit-combinations?id=${id}`)

    return id
  }
)

export const deleteCombinationUnit = createAsyncThunk(
  'DELETE_COMBINATION_UNIT_REQUEST',
  async (props: {
    orgId: string
    serial: string
    id: string
    unitId: string
  }) => {
    const { orgId, serial, id, unitId } = props
    await deleter(
      `/${orgId}/${serial}/unit-combination?id=${id}&unitId=${unitId}`,
      null,
      'bookings'
    )
    return id
  }
)

export const deleteUnitFromFloorPlan = createAsyncThunk(
  'DELETE_TABLE_REQUEST',
  async (props: {
    orgId: string
    serial: string
    floorPlanId: string
    unitId: string
  }) => {
    const { orgId, serial, floorPlanId, unitId } = props

    await deleter(
      `/${orgId}/${serial}/floorplan/unit?floorPlanId=${floorPlanId}&unitId=${unitId}`,
      null,
      'bookings'
    )

    return { unitId }
  }
)

export const getFloorPlans = createAsyncThunk(
  'GET_FLOORPLANS_REQUEST',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props
    const response = await get<FloorPlanType[]>(
      `/${orgId}/${serial}/floorplans`,
      'bookings'
    )
    return response
  }
)

export const createFloorPlan = createAsyncThunk(
  'CREATE_FLOORPLAN_REQUEST',
  async (props: {
    orgId: string
    serial: string
    floorPlan: Partial<FloorPlanType>
    isSilent?: boolean
  }) => {
    const { orgId, serial, floorPlan } = props
    const response = await post<FloorPlanType>(
      `/${orgId}/${serial}/floorplan`,
      floorPlan,
      null,
      'bookings'
    )
    return response
  }
)

export const deleteFloorPlan = createAsyncThunk(
  'DELETE_FLOORPLAN_REQUEST',
  async (props: { orgId: string; serial: string; floorPlanId: string }) => {
    const { orgId, serial, floorPlanId } = props

    await deleter(
      `/${orgId}/${serial}/floorplan?id=${floorPlanId}`,
      null,
      'bookings'
    )
    return floorPlanId
  }
)

export const updateFloorPlan = createAsyncThunk(
  'UPDATE_FLOORPLAN_REQUEST',
  async (props: FloorPlanType) => {
    const { orgId, serial, ...rest } = props

    const response = await put<FloorPlanType>(
      `/${orgId}/${serial}/floorplan`,
      {
        ...rest,
      },
      'bookings'
    )
    return response
  }
)

export const updateFloorPlanUnits = createAsyncThunk(
  'UPDATE_FLOORPLAN_UNITS_REQUEST',
  async (props: {
    orgId: string
    serial: string
    floorPlanId: string
    units: ActualTableLayoutType[]
    floorPlanAttrs?: Partial<FloorPlanType>
  }) => {
    const { orgId, serial, floorPlanId, units, floorPlanAttrs } = props
    const response = await put<FloorPlanType>(
      `/${orgId}/${serial}/floorplan/units`,
      { floorPlanId: floorPlanId, units },
      'bookings'
    )
    let updateFloorPlanResponse: FloorPlanType | undefined = undefined
    if (floorPlanAttrs) {
      updateFloorPlanResponse = await put(
        `/${orgId}/${serial}/floorplan`,
        {
          orgId,
          serial,
          floorPlanId,
          ...floorPlanAttrs,
        },
        'bookings'
      )
      console.log({ updateFloorPlanResponse })
      /**
       * REDUCER NEEDS WORK HERE?
       */
      //      yield put(updateFloorPlan.success(updateFloorPlanResponse))

      //  dispatch(updateFloorPlan.fulfilled(updateFloorPlanResponse))
    }

    return response
  }
)

export const deleteAuxShape = createAsyncThunk(
  'DELETE_AUXSHAPE_REQUEST',
  async (props: {
    orgId: string
    serial: string
    id: string
    auxShapes: ShapeType[]
    floorPlan: FloorPlanType
  }) => {
    const { orgId, serial, id, auxShapes, floorPlan } = props

    await deleter(
      `/${orgId}/${serial}/floorplan/${floorPlan.id}/auxShape?auxShapeId=${id}`,
      null,
      'bookings'
    )
    const updatedAuxShapes = auxShapes.filter((item) => item.id !== id)

    //persist any unsaved shape changes
    if (auxShapes.length - 1 > floorPlan.auxShapes.length) {
      await put(
        `/${orgId}/${serial}/floorplan`,
        {
          orgId,
          serial,
          floorPlanId: floorPlan.id,
          ...floorPlan,
          auxShapes: updatedAuxShapes,
        },
        'bookings'
      )
    }
    return { updatedAuxShapes }
  }
)

export const createConfig = createAsyncThunk(
  'CREATE_BOOKING_CONFIG_REQUEST',
  async (props: {
    config: Partial<BookingConfigType>
    serial: string
    orgId: string
  }) => {
    const { orgId, serial, config } = props

    const response = await post<BookingConfigType>(
      `/${orgId}/${serial}/config`,
      config,
      {},
      'bookings'
    )

    return response
  }
)

export const saveConfig = createAsyncThunk(
  'SAVE_BOOKING_CONFIG_REQUEST',
  async (props: {
    config: BookingConfigType
    serial: string
    orgId: string
  }) => {
    const { orgId, serial, config } = props
    const response = await put<BookingConfigType>(
      `/${orgId}/${serial}/config`,
      config,
      'bookings'
    )
    return response
  }
)

export const getBookings = createAsyncThunk(
  'GET_BOOKINGS_REQUEST',
  async (props: { date: Date; serial: string; orgId: string }) => {
    const { orgId, serial, date } = props

    const response = await get<BookingsByDayWeekMonthYear[]>(
      `/${orgId}/${serial}/bookings/day?date=${FormatDateForBackend(date)}`,
      'bookings'
    )

    return response
  }
)

export const getBooking = createAsyncThunk(
  'GET_BOOKING_REQUEST',
  async (props: { bookingId: string; serial: string; orgId: string }) => {
    const { orgId, serial, bookingId } = props

    const response = await get<BookingsByDayWeekMonthYear>(
      `/${orgId}/${serial}/booking/${bookingId}`,
      'bookings'
    )
    return response
  }
)

export const updateBookingDetails = createAsyncThunk(
  'UPDATE_BOOKING_DETAILS_REQUEST',
  async (props: {
    details: Partial<UserBookingType>
    serial: string
    orgId: string
  }) => {
    const { orgId, serial, details } = props
    const response = await put<BookingsByDayWeekMonthYear>(
      `/${orgId}/${serial}/booking/details`,
      { ...details },
      'bookings'
    )
    return response
  }
)

export const updateBookingDateTimeWithManual = createAsyncThunk(
  'UPDATE_BOOKING_DATETIME_REQUEST',
  async (props: {
    booking: Partial<UserBookingType>
    serial: string
    orgId: string
    unitId: string[]
    previousDate?: string
  }) => {
    const { orgId, serial, booking, unitId } = props

    const response = await put<BookingsByDayWeekMonthYear>(
      `/${orgId}/${serial}/booking-manual`,
      { ...booking, unitId: unitId },
      'bookings'
    )
    //previousDate ? what's this for?
    return response
  }
)

export const updateBookingDateTimeAsync = createAsyncThunk(
  'UPDATE_BOOKING_REQUEST_ASYNC',
  async (props: {
    booking: Partial<UserBookingType>
    serial: string
    orgId: string
    unitId: string[]
    combination: string
    previousDate?: string
  }) => {
    const { orgId, serial, booking, unitId, combination } = props
    const response = await put<BookingsByDayWeekMonthYear>(
      `/${orgId}/${serial}/booking-manual`,
      { ...booking, unitId: unitId, combination: combination },
      'bookings'
    )
    return response
  }
)

export const updateBookingStatus = createAsyncThunk(
  'UPDATE_BOOKING_STATUS_REQUEST',
  async (props: UserBookingType) => {
    const {
      orgId,
      serial,
      bookingId,
      status,
      reference,
      hash,
      voidCancelCharge,
      shouldSendEmail,
      calculated_partial_charge,
    } = props

    const response = await put<UserBookingType>(
      `/${orgId}/${serial}/booking/status`,
      {
        bookingId,
        status,
        reference,
        hash,
        balance: 1,
        voidCancelCharge,
        shouldSendEmail,
        calculated_partial_charge,
      },
      'bookings'
    )

    return response
  }
)

export const createManualBooking = createAsyncThunk(
  'CREATE_MANUAL_BOOKING_REQUEST',
  async (props: {
    orgId: string
    serial: string
    booking: Partial<UserBookingType>
    unitId: string[]
    combination: string
  }) => {
    const { orgId, serial, booking, unitId } = props
    const response = await post<BookingsByDayWeekMonthYear>(
      `/${orgId}/${serial}/booking-manual`,
      { ...booking, unitId: unitId },
      null,
      'bookings'
    )
    return response
  }
)

export const createNewManualBooking = createAsyncThunk(
  'CREATE_NEW_MANUAL_BOOKING_REQUEST',
  async (
    props: {
      orgId: string
      serial: string
      /**
       * because type is being defined in the reducer here there is a slight issue
       */
      item: {
        startDate: Date
        endDate: Date
        tables: TableType[]
        booking: UserBookingType
      }
      /**
       *
       */
    },
    { requestId }
  ) => {
    const { orgId, serial, item } = props

    const response = await post<BookingsByDayWeekMonthYear>(
      `/${orgId}/${serial}/booking-manual`,
      {
        startDate: item.startDate,
        endDate: item.endDate,
        partySize: item.booking.partySize,
        unitId: item.tables.map((table) => table.id),
        message: item.booking.details,
        feId: requestId,
        /**
         *
         */
        ...item.booking,
      },
      null,
      'bookings'
    )
    return response
  }
)

export const getTimes = createAsyncThunk(
  'GET_TIMES_REQUEST',
  async (props: {
    orgId: string
    serial: string
    partySize: number
    date: string
    section: string
    wheelchair?: boolean
    buggy?: boolean
    highchair?: boolean
  }) => {
    const {
      orgId,
      serial,
      partySize,
      date,
      section,
      wheelchair = false,
      highchair = false,
      buggy = false,
    } = props

    const builtUrl = buildUrl(`/${orgId}/${serial}/times`, {
      date,
      partySize,
      section,
      wheelchair,
      highchair,
      buggy,
    })
    const response = await get<{ [key: string]: TimesObject }>(
      builtUrl,
      'bookings'
    )
    //if (response.status === 'FAILED') {
    //  throw new Error('Times not found')
    // }
    return response
  }
)

export const getOverlap = createAsyncThunk(
  'GET_OVERLAP_REQUEST',
  async (props: {
    orgId: string
    serial: string
    id: string[]
    endTime: string
    startTime: string
    date: string
  }) => {
    const { id, startTime, endTime, date, orgId, serial } = props
    const response = await post<UserBookingType>(
      `/${orgId}/${serial}/booking/overlap`,
      {
        ids: id,
        date,
        time: startTime,
        endTime: endTime,
      },
      null,
      'bookings'
    )

    if (isEmpty(response)) {
      throw new Error('Empty response')
    } else {
      return response
    }
  }
)

export const createSection = createAsyncThunk(
  'CREATE_SECTION_REQUEST',
  async (props: {
    orgId: string
    serial: string
    section: Partial<SectionType>
  }) => {
    const { orgId, serial, section } = props

    const response = await post<SectionType>(
      `/${orgId}/${serial}/config/section`,
      section,
      null,
      'bookings'
    )

    return response
  }
)

export const updateSection = createAsyncThunk(
  'UPDATE_SECTION_REQUEST',
  async (props: {
    orgId: string
    serial: string
    section: Partial<SectionType>
  }) => {
    const { orgId, serial, section } = props
    const response = await put<SectionType>(
      `/${orgId}/${serial}/config/section`,
      section,
      'bookings'
    )
    return response
  }
)

export const getSections = createAsyncThunk(
  'GET_SECTIONS_REQUEST',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props

    const response = await get<SectionType[]>(
      `/${orgId}/${serial}/config/sections`,
      'bookings'
    )

    return response
  }
)

export const deleteSection = createAsyncThunk(
  'DELETE_SECTION_REQUEST',
  async (props: { orgId: string; serial: string; id: string }) => {
    const { orgId, serial, id } = props
    const response = await deleter(
      `/${orgId}/${serial}/config/section?id=${id}`,
      null,
      'bookings'
    )
    return { response: response as string, id }
  }
)

export const getSpecialDates = createAsyncThunk(
  'GET_SPECIALDATES_REQUES',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props
    const response: SpecialDatesType[] = await get(
      `/${orgId}/${serial}/config/special-dates`,
      'bookings'
    )
    return response
  }
)

export const deleteSpecialDate = createAsyncThunk(
  'DELETE_SPECIALDATES_REQUEST',
  async (props: { orgId: string; serial: string; id: string }) => {
    const { orgId, serial, id } = props
    const response = await deleter(
      `/${orgId}/${serial}/config/special-dates?id=${id}`,
      null,
      'bookings'
    )
    return { response: response as string, id }
  }
)

export const createSpecialDate = createAsyncThunk(
  'CREATE_SPECIALDATE_REQUEST',
  async (props: {
    orgId: string
    serial: string
    specialDate: Partial<SpecialDatesType>
  }) => {
    const { orgId, serial, specialDate } = props
    const response = await post<SpecialDatesAndLoadingType>(
      `/${orgId}/${serial}/config/special-date`,
      specialDate,
      null,
      'bookings'
    )
    return response
  }
)

export const updateSpecialDate = createAsyncThunk(
  'UPDATE_SPECIALDATE_REQUEST',
  async (props: {
    orgId: string
    serial: string
    specialDate: Partial<SpecialDatesType>
  }) => {
    const { orgId, serial, specialDate } = props
    const response = await put<SpecialDatesAndLoadingType>(
      `/${orgId}/${serial}/config/special-date`,
      specialDate,
      'bookings'
    )
    return response
  }
)

export const getBookingsCoversTotal = createAsyncThunk(
  'GET_BOOKINGS_COVERS_REQUEST',
  async (props: { orgId: string; serial: string; date: string }) => {
    const { orgId, serial, date } = props
    const response = await get<number>(
      `/${orgId}/${serial}/bookings/day/total?date=${date}`,
      'bookings'
    )
    return response
  }
)

export const getSearchBookings = createAsyncThunk(
  'GET_BOOKINGS_SEARCH_REQUEST',
  async (props: {
    orgId: string
    serial: string
    query: string
    offset: number
    limit: number
  }) => {
    const { orgId, serial, query, offset, limit } = props
    const url = qs.stringifyUrl(
      {
        url: `/${orgId}/${serial}/bookings/all`,
        query: {
          limit,
          query,
          offset,
        },
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    )
    const response = await get<GetSearchBookingsType>(url, 'bookings')
    return response
  }
)

export const createCardCharge = createAsyncThunk(
  'CREATE_CARD_CHARGE_REQUEST',
  async (props: {
    orgId: string
    serial: string
    id: string
    calculated_partial_charge: number
  }) => {
    const { orgId, serial, id, calculated_partial_charge } = props
    const response = await post<ChargeResult>(
      `/${orgId}/${serial}/charge-card`,
      { id, calculated_partial_charge },
      null,
      'bookings'
    )
    return response
  }
)

export const getDailyStats = createAsyncThunk(
  'GET_DAILY_STATS_REQUEST',
  async (props: { orgId: string; serial: string; date: string }) => {
    const { orgId, serial, date } = props

    const response = await get<DailyStatsResult>(
      `/${orgId}/${serial}/stats/daily-summary?date=${date}`,
      'bookings'
    )
    return response
  }
)
export const getWeeklyStats = createAsyncThunk(
  'GET_WEEKLY_STATS_REQUEST',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props

    const response = await get<WeeklyStatsResult>(
      `/${orgId}/${serial}/stats/weekly-summary`,
      'bookings'
    )
    return response
  }
)

export const getRangeSummary = createAsyncThunk(
  'GET_RANGE_SUMMARY_REQUEST',
  async (props: {
    orgId: string
    serial: string
    rangeStart: string
    rangeEnd: string
  }) => {
    const { orgId, serial, rangeStart, rangeEnd } = props

    const response = await get<CalendarResult>(
      `/${orgId}/${serial}/stats/range-summary?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
      'bookings'
    )
    return response
  }
)

export const updateBookingFromWebSocket = createAction(
  'SET_BOOKINGS',
  (booking: (BookingsByDayWeekMonthYear & { feId?: string })[]) => ({
    payload: booking,
  })
)

export const setTable = createAction('SET_TABLE', (table: TableType) => ({
  payload: table,
}))

export const clearConfig = createAction('CLEAR_BOOKINGS_CONFIG')

export const setCurrentConfigSerial = createAction(
  'SET_CONFIG_SERIAL',
  (serial: string) => ({ payload: serial })
)

export const clearSelectedFloorPlan = createAction('CLEAR_SELECTED_FLOORPLAN')

export const createBookingNote = createAsyncThunk(
  'CREATE_BOOKING_NOTE_REQUEST',
  async (props: {
    serial: string
    orgId: string
    note: Partial<UserBookingNoteType>
  }) => {
    const { orgId, serial, note } = props
    const response = await post<UserBookingNoteType>(
      `/${orgId}/${serial}/note`,
      note,
      null,
      'bookings'
    )
    return response
  }
)

export const updateBookingNote = createAsyncThunk(
  'UPDATE_BOOKING_NOTE_REQUEST',
  async (props: {
    serial: string
    orgId: string
    note: UserBookingNoteType
  }) => {
    const { orgId, serial, note } = props

    const response = await put<UserBookingNoteType>(
      `/${orgId}/${serial}/note`,
      note,

      'bookings'
    )
    return response
  }
)

export const deleteBookingNote = createAsyncThunk(
  'DELETE_BOOKING_NOTE_REQUEST',
  async (props: {
    serial: string
    orgId: string
    note: UserBookingNoteType
  }) => {
    const { serial, orgId, note } = props
    await deleter(`/${orgId}/${serial}/note?id=${note.id}`, null, 'bookings')

    return note
  }
)

export const getAllBookingNotes = createAsyncThunk(
  'GET_BOOKING_NOTES_REQUEST',
  async (props: { serial: string; orgId: string; bookingId: string }) => {
    const { orgId, serial, bookingId } = props

    const response = await get<UserBookingNoteType[]>(
      `/${orgId}/${serial}/notes?id=${bookingId}`,
      'bookings'
    )
    return response
  }
)

export const getBookingConfigBlockTimes = createAsyncThunk(
  'GET_BOOKING_CONFIG_BLOCK_TIMES_REQUEST',
  async (props: { serial: string; orgId: string; date: string }) => {
    const { orgId, serial, date } = props
    const response = await get<{
      times: string[]
      tables: { id: string; slots: string[] }[]
    }>(`/${orgId}/${serial}/block-times?date=${date}`, 'bookings')
    if (!response.tables) {
      response.tables = []
    }
    if (!response.times) {
      response.times = []
    }
    return response
  }
)
/*
<
  ,
  {
    items: {
      times: string[]
      tables: { id: string; slots: string[] }[]
    }
    date: string
  },
  Error
>()
*/

export const updateBookingConfigBlockTimes = createAsyncThunk(
  'UPDATE_BOOKING_CONFIG_BLOCK_TIMES_REQUEST',
  async (props: {
    serial: string
    orgId: string
    date: string
    data: BookingConfigBlockTableTimesType
  }) => {
    const { data, serial, orgId, date } = props
    const response = await put<BookingConfigBlockTableTimesType>(
      `/${orgId}/${serial}/block-times?date=${date}`,
      data,
      'bookings'
    )
    return response
  }
)

export const getAreas = createAsyncThunk(
  'GET_BOOKING_AREAS_REQUEST',
  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props
    const response = await get<AreaType[]>(
      `/${orgId}/${serial}/areas`,
      'bookings'
    )

    // if (!response) {
    //   return
    // }
    return response
  }
)

export const postArea = createAsyncThunk(
  'POST_BOOKING_AREAS_REQUEST',
  async (props: { orgId: string; serial: string; area: Partial<AreaType> }) => {
    const { orgId, serial, area } = props
    const response = await post<AreaType>(
      `/${orgId}/${serial}/areas`,
      { name: area.name, position: area.position, units: area.units },
      null,
      'bookings'
    )

    return response
  }
)

export const putArea = createAsyncThunk(
  'PUT_BOOKING_AREAS_REQUEST',
  async (props: {
    orgId: string
    serial: string
    area: AreaType
    reorder?: boolean
  }) => {
    const { orgId, serial, area, reorder = false } = props

    const response = await put<AreaType>(
      `/${orgId}/${serial}/area/${area.id}`,
      { ...area, reorder, tables: [] },
      'bookings'
    )

    return response
  }
)

export const putAllAreas = createAsyncThunk(
  'PUT_ALL_BOOKING_AREAS_REQUEST',
  async (props: { orgId: string; serial: string; areaIds: string[] }) => {
    const { orgId, serial, areaIds } = props
    const response = await put<AreaType[]>(
      `/${orgId}/${serial}/areas`,
      {
        areaIds,
      },
      'bookings'
    )

    return response
  }
)

export const deleteArea = createAsyncThunk(
  'DELETE_BOOKING_AREA_REQUEST',
  async (props: { orgId: string; serial: string; area: AreaType }) => {
    const { orgId, serial, area } = props
    //@ts-ignore
    const response: AreaType[] = await deleter(
      `/${orgId}/${serial}/area/${area.id}`,
      null,
      'bookings'
    )

    return response
  }
)
