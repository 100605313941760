import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { BandwidthSettingsUpdateRequestBody } from 'connect-types/locations/bandwidthSettingsUpdateRequestBody.type'
import { TimeoutSettingsUpdateRequestBody } from 'connect-types/locations/timeoutSettingsUpdateRequestBody.type'
import { WifiSettingsUpdateRequestBody } from 'connect-types/locations/wifiSettingsUpdateRequestBody.type'
import { FacebookAccount } from 'connect-types/reviews/facebookAccounts.type'
import { FacebookPage } from 'connect-types/reviews/facebookPages.type'
import { ActionType, getType } from 'typesafe-actions'
import { get, post, put as putRequest } from 'utils/api'
import * as loadedActions from '../loaded/loaded.actions'
import * as actions from './settings.actions'

/*
 * GET Location Timeout Settings
 *
 */

function* locationTimeoutSettingsGetRequest(
  action: ActionType<typeof actions.locationsTimeoutsSettingsRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: any
    } = yield call(async () => get(`/locations/${serial}/settings/timeouts`))

    const settings: TimeoutSettingsUpdateRequestBody = {
      free: response.message[0],
      paid: response.message[1],
    }

    yield put(actions.locationsTimeoutsSettingsRequestSuccess(settings))
  } catch {
    yield put(actions.locationsTimeoutsSettingsRequestFailure())
  }
}

export function* watchLocationTimeoutSettingsGetRequest() {
  yield takeLeading(
    getType(actions.locationsTimeoutsSettingsRequest),
    locationTimeoutSettingsGetRequest
  )
}

/*
 * Update Location Timeout Settings
 *
 */
function* locationTimeoutSettingsUpdateRequest(
  action: ActionType<typeof actions.updateLocationsTimeoutsSettingsRequest>
) {
  try {
    const { serial, timeoutSettings } = action.payload

    console.log('timeout-settings', serial, timeoutSettings)
    const response: {
      status: number
      message: TimeoutSettingsUpdateRequestBody
    } = yield call(async () =>
      putRequest(`/locations/${serial}/settings/timeouts`, timeoutSettings)
    )

    const settings = response.message

    yield put(actions.updateLocationsTimeoutsSettingsRequestSuccess(settings))
  } catch {
    yield put(actions.updateLocationsTimeoutsSettingsRequestFailure())
  }
}

export function* watchlocationTimeoutSettingsUpdateRequest() {
  yield takeLatest(
    getType(actions.updateLocationsTimeoutsSettingsRequest),
    locationTimeoutSettingsUpdateRequest
  )
}

/*
 * GET Location Bandwidth Settings
 * TODO: FIX API GET
 */

function* locationBandwidthSettingsGetRequest(
  action: ActionType<typeof actions.locationsBandwidthSettingsRequest>
) {
  try {
    const { serial } = action.payload

    const response: {
      status: number
      message: any
    } = yield call(async () => get(`/locations/${serial}/settings/bandwidth`))

    const settings: BandwidthSettingsUpdateRequestBody = {
      free: response.message[0],
      paid: response.message[1],
    }

    yield put(actions.locationsBandwidthSettingsRequestSuccess(settings))
  } catch {
    yield put(actions.locationsBandwidthSettingsRequestFailure())
  }
}

export function* watchLocationBandwidthSettingsGetRequest() {
  yield takeLatest(
    getType(actions.locationsBandwidthSettingsRequest),
    locationBandwidthSettingsGetRequest
  )
}

/*
 * Update Location Bandwidth Settings
 *
 */
function* locationBandwidthSettingsUpdateRequest(
  action: ActionType<typeof actions.updateLocationsBandwidthSettingsRequest>
) {
  try {
    const { serial, bandwidthSettings } = action.payload

    const response: {
      status: number
      message: BandwidthSettingsUpdateRequestBody
    } = yield call(async () =>
      putRequest(`/locations/${serial}/settings/bandwidth`, bandwidthSettings)
    )

    const settings = response.message

    yield put(actions.updateLocationsBandwidthSettingsRequestSuccess(settings))
  } catch {
    yield put(actions.updateLocationsBandwidthSettingsRequestFailure())
  }
}

export function* watchlocationBandwidthSettingsUpdateRequest() {
  yield takeLatest(
    getType(actions.updateLocationsBandwidthSettingsRequest),
    locationBandwidthSettingsUpdateRequest
  )
}

/*
 * GET Location WiFi Settings
 *
 */

/*
 * Update Location Timeout Settings
 *
 */
function* locationWiFiSettingsUpdateRequest(
  action: ActionType<typeof actions.updateLocationsWifiSettingsRequest>
) {
  try {
    const { serial, wifiSettings } = action.payload

    const response: {
      status: number
      message: WifiSettingsUpdateRequestBody
    } = yield call(async () =>
      putRequest(`/locations/${serial}/settings/wifi`, wifiSettings)
    )

    const settings = response.message

    yield put(actions.updateLocationsWifiSettingsRequestSuccess(settings))
  } catch {
    yield put(actions.updateLocationsWifiSettingsRequestFailure())
  }
}

export function* watchLocationWiFiSettingsUpdateRequest() {
  yield takeLatest(
    getType(actions.updateLocationsWifiSettingsRequest),
    locationWiFiSettingsUpdateRequest
  )
}

/*
 * Get Facebook Accounts Associated with Member
 * =========================
 */
function* locationsFacebookAccountsRequest(
  action: ActionType<typeof actions.locationsFacebookAccountsRequest>
) {
  try {
    const { orgId } = action.payload

    const response: {
      status: number
      message: FacebookAccount[]
    } = yield call(async () => get(`/facebook/accounts/${orgId}`))
    yield put(loadedActions.setLoaded('facebook_accounts', true))
    yield put(actions.locationsFacebookAccountsRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.locationsFacebookAccountsRequestFailure(error.response))
  }
}

export function* watchLocationsFacebookAccountsRequest() {
  yield takeLatest(
    getType(actions.locationsFacebookAccountsRequest),
    locationsFacebookAccountsRequest
  )
}

/*
 * Get Facebook Pages Associated with Member
 * =========================
 */
function* locationsFacebookAccountsPagesRequest(
  action: ActionType<typeof actions.locationsFacebookAccountsPagesRequest>
) {
  try {
    const { orgId, accountId } = action.payload

    const response: {
      status: number
      message: FacebookPage[]
    } = yield call(async () =>
      get(`/facebook/accounts/${orgId}/${accountId}/pages`)
    )

    yield put(
      actions.locationsFacebookAccountsPagesRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(
      actions.locationsFacebookAccountsPagesRequestFailure(error.response)
    )
  }
}

export function* watchLocationsFacebookAccountsPagesRequest() {
  yield takeLatest(
    getType(actions.locationsFacebookAccountsPagesRequest),
    locationsFacebookAccountsPagesRequest
  )
}

/*
 * Authorize Facebook
 * =========================
 */
function* locationsFacebookAuthorizeAccountRequest(
  action: ActionType<typeof actions.locationsFacebookAuthorizeAccountRequest>
) {
  try {
    const { orgId, appName, redirectUrl } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      get(
        `/facebook/authorize/${orgId}?redirect_url=${redirectUrl}&app_name=${appName}`
      )
    )

    yield put(
      actions.locationsFacebookAuthorizeAccountRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(
      actions.locationsFacebookAuthorizeAccountRequestFailure(error.response)
    )
  }
}

export function* watchLocationsFacebookAuthorizeAccountRequest() {
  yield takeLatest(
    getType(actions.locationsFacebookAuthorizeAccountRequest),
    locationsFacebookAuthorizeAccountRequest
  )
}

/*
 * Update or Create Facebook Page Reference
 * =========================
 */
function* updateLocationsFacebookPageRequest(
  action: ActionType<typeof actions.updateLocationsFacebookPageRequest>
) {
  try {
    const { orgId, pageId, token, review_id } = action.payload

    const response: {
      status: number
      message: FacebookPage
    } = yield call(async () =>
      putRequest(`/facebook/accounts/${orgId}/${token}/pages/${pageId}`, {
        review_id,
      })
    )

    yield put(
      actions.updateLocationsFacebookPageRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.updateLocationsFacebookPageRequestFailure(error.response))
  }
}

export function* watchUpdateLocationsFacebookPageRequest() {
  yield takeLatest(
    getType(actions.updateLocationsFacebookPageRequest),
    updateLocationsFacebookPageRequest
  )
}

/*
 * Issue Generic Super Command
 * =========================
 */
function* genericSuperCommandRequest(
  action: ActionType<typeof actions.locationsIssueSuperCommandRequest>
) {
  try {
    const { serial, command } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      post(`/locations/${serial}/settings/super`, command)
    )

    yield put(
      actions.locationsIssueSuperCommandRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.locationsIssueSuperCommandRequestFailure(error.response))
  }
}

export function* watchGenericSuperCommandRequest() {
  yield takeLatest(
    getType(actions.locationsIssueSuperCommandRequest),
    genericSuperCommandRequest
  )
}

/*
 * Issue Export Super Command
 * =========================
 */
function* exportSuperCommandRequest(
  action: ActionType<typeof actions.locationsIssueExportCommandRequest>
) {
  try {
    const { serial, command } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      post(`/locations/${serial}/settings/super`, command)
    )

    yield put(
      actions.locationsIssueExportCommandRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.locationsIssueExportCommandRequestFailure(error.response))
  }
}

export function* watchExportSuperCommandRequest() {
  yield takeLatest(
    getType(actions.locationsIssueExportCommandRequest),
    exportSuperCommandRequest
  )
}

export default [
  watchlocationTimeoutSettingsUpdateRequest,

  watchLocationTimeoutSettingsGetRequest,
  watchLocationBandwidthSettingsGetRequest,
  watchlocationBandwidthSettingsUpdateRequest,

  watchLocationWiFiSettingsUpdateRequest,

  watchLocationsFacebookAuthorizeAccountRequest,
  watchLocationsFacebookAccountsPagesRequest,
  watchUpdateLocationsFacebookPageRequest,

  watchGenericSuperCommandRequest,
  watchExportSuperCommandRequest,
]
