import {
  defaultServicePagination,
  ServiceCursorPaginatedResponseType,
} from 'connect-types/backend/service'

/*
Stephen Davies
15:31
Hey, wondering if you can take a look at this and see if it’s something you can work with for front end?
(no auth on the routes yet, but that will be added)
# paginated list of all threads, ordered by updated_at DESC
GET https://inbox.stg.stampede.ai/f163ff62-2116-11ec-bd66-0a58a9feac02/threads

# view contacts on the thread, these will be people who will receive a notification when a user replies
GET https://inbox.stg.stampede.ai/f163ff62-2116-11ec-bd66-0a58a9feac02/threads/ef4c52ec-9bc4-4c31-806b-9c90c111d4ca/contacts

# add a new contact to a thread, need to provide method, name and email
POST https://inbox.stg.stampede.ai/f163ff62-2116-11ec-bd66-0a58a9feac02/threads/ef4c52ec-9bc4-4c31-806b-9c90c111d4ca/contacts

# remove a contact from the thread
DELETE https://inbox.stg.stampede.ai/f163ff62-2116-11ec-bd66-0a58a9feac02/threads/ef4c52ec-9bc4-4c31-806b-9c90c111d4ca/contacts/{id}

# view paginated list of all events on the thread, this will include things like emails, notes, messages
GET https://inbox.stg.stampede.ai/f163ff62-2116-11ec-bd66-0a58a9feac02/threads/ef4c52ec-9bc4-4c31-806b-9c90c111d4ca/events

# post a private note to a thread
POST https://inbox.stg.stampede.ai/f163ff62-2116-11ec-bd66-0a58a9feac02/threads/ef4c52ec-9bc4-4c31-806b-9c90c111d4ca/notes
*/

export interface EventableOutgoingEmailType {
  id: string
  to_email: string
  to_name: string
  subject: string

  created_at: Date

  sent_at: Date | null
  delivered_at: Date | null
  first_opened_at: Date | null
}

export type EventableContactType = {
  email: string
  id: string
  name: string
  type: 'from' | 'to' | 'cc'
}

export type EventableType =
  | {
      eventable_type: 'Email'
      eventable: InboxThreadEventEventableEmailType
    }
  | {
      eventable_type: 'Message'
      eventable: InboxThreadEventEventableMessageType
    }
  | {
      eventable_type: 'Note'
      eventable: InboxThreadEventEventableNoteType
    }
  | {
      eventable_type: 'SystemNote'
      eventable: InboxThreadEventEventableSystemNoteType
    }
  | {
      eventable_type: 'Incoming SMS Message'
      eventable: InboxThreadEventEventableSmsMessage
    }

export type InboxThreadEventType = {
  id: string
  created_at: Date
} & EventableType

export type InboxContextTypes = 'booking' | 'review' | 'gifting'

export interface InboxThreadType {
  id: string
  created_at: Date
  updated_at: Date
  archived_at: Date
  subject: string
  contacts: InboxThreadContactsType[]

  last_thread_event_at: Date

  read_at: Date | null
  serial?: string
  context_type?: InboxContextTypes
  context_id?: string
  /**
   * FOR FRONT END USE
   */
  isLoading?: boolean
  events: ServiceCursorPaginatedResponseType<InboxThreadEventType>
  no_event_data: boolean
  method: 'sms' | 'email'
}

export interface InboxThreadContactsType {
  id: string
  method: 'email'
  name: string
  email: string
  org_reg_id: string
  mobile: string
}

export interface InboxThreadEventEventableNoteType {
  created_at: Date
  description: string
  id: string
  user_email: string
  user_id: string
  user_name: string
}

export interface InboxThreadEventEventableSystemNoteType {
  created_at: Date
  description: string
  format: 'text'
  id: string
}

export interface InboxThreadEventEventableSmsMessage {
  created_at: Date
  id: string
  from: string
  message: string
  recieved_at: string
  to: string
}

export interface InboxThreadEventEventableMessageType {
  created_at: Date
  description: string
  id: string
  user_email: string
  user_id: string
  user_name: string
  outgoing_emails: EventableOutgoingEmailType[]
}

export interface InboxThreadEventEventableEmailType {
  created_at: Date
  html_body: string
  html_body_trimmed: string
  id: string
  sent_at: string
  subject: string
  text_body: string
  contacts: EventableContactType[]
  attachments: {
    content_type: string
    filename: string
    url: string
  }[]
}

export const initThread: InboxThreadType = {
  events: defaultServicePagination,
  id: null,
  created_at: new Date(),
  subject: '',
  updated_at: new Date(),
  contacts: [],
  archived_at: new Date(),
  read_at: null,
  last_thread_event_at: new Date(),
  no_event_data: false,
  method: 'email',
}
