import { createAction } from 'typesafe-actions'

import { User } from 'connect-types/user/user.type'

/**
 * User token refresh
 */

export const setUserRole = createAction(
  'SET_USER_ROLE',
  (resolve) => (role: number) => resolve({ role })
)

/**
 * Oauth test
 */

export const fetchOauthTestRequest = createAction(
  'FETCH_OAUTH_TEST_REQUEST',
  (resolve) => () => resolve()
)

export const fetchOauthTestRequestSuccess = createAction(
  'FETCH_OAUTH_TEST_SUCCESS',
  (resolve) => (currentUser: User) => resolve({ currentUser })
)

export const fetchOauthTestRequestFailure = createAction(
  'FETCH_OAUTH_TEST_FAILURE',
  (resolve) => (error: any) => resolve({ error })
)
