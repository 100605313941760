import { TimeFormats } from 'common/datepicker/utilities/formats'
import { format, set } from 'date-fns'

export const getBookingEndTime = (
  startTime: string,
  allowedDurationInSeconds: number
) => {
  const start = startTime
  const offset = allowedDurationInSeconds
  const date = new Date(+new Date(`1970-01-01T${start}Z`) + offset * 1000)
  const formattedStr = [date.getUTCHours(), date.getUTCMinutes()]
    .map((n) => n.toString().padStart(2, '0'))
    .join(':')
  return formattedStr
}

export const displayBookingDate = (date: string) => {
  const splicer = (date || '').split('/').map((item) => Number(item))

  if (splicer.length !== 3) {
    return ''
  }

  const day = splicer[0]
  const month = splicer[1] - 1
  const year = splicer[2]
  console.log({ year, month, date: day })
  const newDate = set(new Date(), { year, month, date: day })
  try {
    return format(newDate, 'do MMM, yyyy')
  } catch (displayBookingDateError) {
    console.log({ displayBookingDateError })
    return ''
  }
}

export const sortObjectByKeys = (object: Record<any, any>) => {
  return (
    Object.keys(object)
      .sort()
      // eslint-disable-next-line no-sequences
      .reduce((r, k) => ((r[k] = object[k]), r), {})
  )
}

export const FormatDateForBackend = (date: Date) =>
  format(date, TimeFormats.BackendYearMonthDay)
