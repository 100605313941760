import { AccessVenueType } from 'components/IntegrationsPage/Access/access.types'
import { RollerVenuesType } from 'components/IntegrationsPage/Roller/roller.types'
import { DefaultAnalyticsRequest } from 'connect-types/analytics/request.type'
import { MarketingCampaignReport } from 'connect-types/marketing/marketingCampaign.type'
import { OrganisationServerType } from 'connect-types/organisations/organisations.type'
import { createAction, createAsyncAction } from 'typesafe-actions'
import { OrganisationRegistrationReportType } from '../insight/insight.type'
import {
  LinkGeneratorRequestType,
  LinkGeneratorType,
  OrganisationLabelType,
} from './organisations.types'

export const organisationPushNewCustomer = createAction(
  'ORGANISATION_PUSH_NEW_CUSTOMER',
  (resolve) => (customer: OrganisationServerType) => resolve({ customer })
)

export const organisationRegistrationReport = createAction(
  'ORGANISATION_REGISTRATION_REPORT_REQUEST',
  (resolve) => (orgId: string, query: DefaultAnalyticsRequest) =>
    resolve({ orgId, query })
)

export const organisationRegistrationReportSuccess = createAction(
  'ORGANISATION_REGISTRATION_REPORT_SUCCESS',
  (resolve) => (report: OrganisationRegistrationReportType) =>
    resolve({ report })
)

export const organisationRegistrationReportFailure = createAction(
  'ORGANISATION_REGISTRATION_REPORT_FAILURE',
  (resolve) => (error: any) => resolve({ error })
)

export const organisationMarketingReport = createAction(
  'ORGANISATION_MARKETING_REPORT_REQUEST',
  (resolve) => (orgId: string) => resolve({ orgId })
)

export const organisationMarketingReportSuccess = createAction(
  'ORGANISATION_MARKETING_REPORT_SUCCESS',
  (resolve) => (report: MarketingCampaignReport) => resolve({ report })
)

export const organisationMarketingReportFailure = createAction(
  'ORGANISATION_MARKETING_REPORT_FAILURE',
  (resolve) => (error: any) => resolve({ error })
)

export const getComparisonReport = createAsyncAction(
  'GET_COMPARISON_REPORT_REQUEST',
  'GET_COMPARISON_REPORT_REQUEST_SUCCESS',
  'GET_COMPARISON_REPORT_REQUEST_FAILURE'
)<
  { orgId: string; query: DefaultAnalyticsRequest },
  {
    now: OrganisationRegistrationReportType
    previous: OrganisationRegistrationReportType
  },
  Error
>()

export const getOrganisationLinks = createAsyncAction(
  'GET_STORE_KIT_LINKS_REQUEST',
  'GET_STORE_KIT_LINKS_REQUEST_SUCCESS',
  'GET_STORE_KIT_LINKS_REQUEST_FAILURE'
)<{ orgId: string }, LinkGeneratorType[], Error>()

export const createOrganisationLinks = createAsyncAction(
  'CREATE_STORE_KIT_LINKS_REQUEST',
  'CREATE_STORE_KIT_LINKS_REQUEST_SUCCESS',
  'CREATE_STORE_KIT_LINKS_REQUEST_FAILURE'
)<LinkGeneratorRequestType, LinkGeneratorType, Error>()

export const getAccessBookingsVenues = createAsyncAction(
  'GET_ACCESS_BOOKINGS_VENUES_REQUEST',
  'GET_ACCESS_BOOKINGS_VENUES_SUCCESS',
  'GET_ACCESS_BOOKINGS_VENUES_FAILURE'
)<{ orgId: string }, AccessVenueType[], Error>()

export const getRollerData = createAsyncAction(
  'GET_ROLLER_REQUEST',
  'GET_ROLLER_REQUEST_SUCCESS',
  'GET_ROLLER_REQUEST_FAILURE'
)<
  { orgId: string },
  {
    [key: string]: RollerVenuesType
  },
  Error
>()

export const postRollerData = createAsyncAction(
  'POST_ROLLER_REQUEST',
  'POST_ROLLER_REQUEST_SUCCESS',
  'POST_ROLLER_REQUEST_FAILURE'
)<
  {
    orgId: string
    venues: {
      [key: string]: Omit<RollerVenuesType, 'id' | 'webhook' | 'webhookId'>
    }
  },
  {
    [key: string]: RollerVenuesType
  },
  Error
>()

export const deleteRollerData = createAsyncAction(
  'DELETE_ROLLER_REQUEST',
  'DELETE_ROLLER_REQUEST_SUCCESS',
  'DELETE_ROLLER_REQUEST_FAILURE'
)<{ orgId: string; serial: string }, { serial: string }, Error>()

export const getOrganisationLabels = createAsyncAction(
  'GET_ORGANISATION_LABELS',
  'GET_ORGANISATION_LABELS_SUCCESS',
  'GET_ORGANISATION_LABELS_FAILURE'
)<
  {
    orgId: string
  },
  OrganisationLabelType[],
  Error
>()

export const addOrganisationLabelToImage = createAsyncAction(
  'ADD_ORGANISATION_LABELS_TO_IMAGE',
  'ADD_ORGANISATION_LABELS_TO_IMAGE_SUCCESS',
  'ADD_ORGANISATION_LABELS_TO_IMAGE_FAILURE'
)<
  {
    orgId: string
    imageId: string
    name: string
  },
  OrganisationLabelType,
  Error
>()

export const deleteOrganisationLabel = createAsyncAction(
  'DELETE_ORGANISATION_LABELS',
  'DELETE_ORGANISATION_LABELS_SUCCESS',
  'DELETE_ORGANISATION_LABELS_FAILURE'
)<
  {
    orgId: string
    label: OrganisationLabelType
  },
  OrganisationLabelType,
  Error
>()
