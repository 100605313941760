import { AirshipAuthType } from 'connect-types/integrations/airship.type'
import { IntegrationEnabled } from 'connect-types/integrations/integrationEnabled.type'
import { ListSync } from 'connect-types/integrations/listSync.type'
import {
  ExternalListType,
  ListUnifyRequestBody,
} from 'connect-types/integrations/listUnifyRequestBody'
import { PaypalAccountUpdateRequestBody } from 'connect-types/integrations/paypalAccountUpdateRequestBody.type'
import { PaypalLocationAccount } from 'connect-types/integrations/paypalLocationAccount.type'
import { UnifyUserCredentialCreateRequestBody } from 'connect-types/integrations/unifyUserCredentialCreateRequestBody.type'
import { createAction } from 'typesafe-actions'

/**
 * GET Retrieve a All PayPal Accounts for User
 * Path: /integration/{uid}/paypal
 */
export const integrationPaypalAccountsAllRequest = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_ALL_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

export const integrationPaypalAccountsAllRequestSuccess = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_ALL_REQUEST_SUCCESS',
  (resolve) => (paypalAccounts: PaypalAccountUpdateRequestBody[]) =>
    resolve({ paypalAccounts })
)

export const integrationPaypalAccountsAllRequestFailure = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_ALL_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({
      error: 'integration paypal all accounts request failed',
    })
)

/**
 * GET Retrieve a Specific PayPal Account
 *
 * Path: /integration/{uid}/paypal/{id}
 */

// FIXME: add in request and query params
export const integrationPaypalAccountRequest = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_REQUEST',
  (resolve) => (uid: string, id: string) => resolve({ uid, id })
)

// FIXME: add in response object from saga
export const integrationPaypalAccountRequestSuccess = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_REQUEST_SUCCESS',
  (resolve) => (paypalAccount: PaypalAccountUpdateRequestBody) =>
    resolve({ paypalAccount })
)

export const integrationPaypalAccountRequestFailure = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'integration paypal account request failed' })
)
/**
 * PUT Update a PayPal Account
 *
 * Path: /integration/{uid}/paypal/{id}
 */

// FIXME: add in request and query params
export const updateIntegrationPaypalAccountRequest = createAction(
  'UPDATE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST',
  (resolve) =>
    (uid: string, id: string, paypalAccount: PaypalAccountUpdateRequestBody) =>
      resolve({ uid, id, paypalAccount })
)

export const updateIntegrationPaypalAccountRequestSuccess = createAction(
  'UPDATE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_SUCCESS',
  (resolve) => (paypalAccount: PaypalAccountUpdateRequestBody) =>
    resolve({ paypalAccount })
)

export const updateIntegrationPaypalAccountRequestFailure = createAction(
  'UPDATE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'update integration paypal account request failed' })
)
/**
 * DELETE Delete a PayPal Account
 *
 * Path: /integration/{uid}/paypal/{id}
 */

// FIXME: add in request and query params
export const deleteIntegrationPaypalAccountRequest = createAction(
  'DELETE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST',
  (resolve) => (uid: string, id: string) => resolve({ uid, id })
)

// FIXME: add in response object from saga
export const deleteIntegrationPaypalAccountRequestSuccess = createAction(
  'DELETE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_SUCCESS',
  (resolve) => (id: string) => resolve({ id })
)

export const deleteIntegrationPaypalAccountRequestFailure = createAction(
  'DELETE_INTEGRATION_PAYPAL_ACCOUNT_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'delete integration paypal account request failed' })
)

export const integrationPaypalAccountLocationRequest = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST',
  (resolve) => (uid: string, id: string, serial: string) =>
    resolve({ uid, id, serial })
)

export const integrationPaypalAccountLocationRequestSuccess = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST_SUCCESS',
  (resolve) => (paypalLocationAccount: PaypalLocationAccount) =>
    resolve({ paypalLocationAccount })
)

export const integrationPaypalAccountLocationRequestFailure = createAction(
  'INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST_FAILURE',
  (resolve) => () => resolve({})
)

/**
 * PUT Associate a PayPal account with a Location
 *
 * Path: /integration/{uid}/paypal/{id}/location/{serial}
 */

// FIXME: add in request and query params
export const updateIntegrationPaypalAccountLocationRequest = createAction(
  'UPDATE_INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST',
  (resolve) => (uid: string, id: string, serial: string) =>
    resolve({ uid, id, serial })
)

// FIXME: add in response object from saga
export const updateIntegrationPaypalAccountLocationRequestSuccess =
  createAction(
    'UPDATE_INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST_SUCCESS',
    (resolve) => (paypalLocationAccount: PaypalLocationAccount) =>
      resolve({ paypalLocationAccount })
  )

export const updateIntegrationPaypalAccountLocationRequestFailure =
  createAction(
    'UPDATE_INTEGRATION_PAYPAL_ACCOUNT_LOCATION_REQUEST_FAILURE',
    (resolve) => () =>
      resolve({
        error: 'update integration paypal account location request failed',
      })
  )
/**
 * GET Get the user's current details
 *
 * Path: /integration/{uid}/mailchimp
 */

export const integrationMailchimpRequest = createAction(
  'INTEGRATION_MAILCHIMP_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

export const integrationMailchimpRequestSuccess = createAction(
  'INTEGRATION_MAILCHIMP_REQUEST_SUCCESS',
  (resolve) => (mailchimpApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ mailchimpApiKey })
)

export const integrationMailchimpRequestFailure = createAction(
  'INTEGRATION_MAILCHIMP_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'integration mailchimp request failed' })
)
/**
 * PUT Create/update user credentials
 *
 * Path: /integration/{uid}/mailchimp
 */
export const updateIntegrationMailchimpRequest = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_REQUEST',
  (resolve) =>
    (uid: string, mailchimpApiKey: UnifyUserCredentialCreateRequestBody) =>
      resolve({ uid, mailchimpApiKey })
)

export const updateIntegrationMailchimpRequestSuccess = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_REQUEST_SUCCESS',
  (resolve) => (mailchimpApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ mailchimpApiKey })
)

export const updateIntegrationMailchimpRequestFailure = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) =>
    resolve({
      error: errorResponse,
    })
)

/**
 *
 *
 *
 */

export const updateIntegrationMailchimpInStore = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_IN_STORE',
  (resolve) => (mailChimpApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ mailChimpApiKey })
)

/**
 *
 *
 *
 */

export const nativeIntegrationListRequest = createAction(
  'INTEGRATION_LIST_REQUEST',
  (resolve) => (uid: string, serial: string, integration: string) =>
    resolve({ uid, serial, integration })
)

export const nativeIntegrationListRequestSuccess = createAction(
  'INTEGRATION_LIST_REQUEST_SUCCESS',
  (resolve) => (list: ListUnifyRequestBody[]) => resolve({ list })
)

export const nativeIntegrationListRequestFailure = createAction(
  'NATIVE_INTEGRATION_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 *
 * USING SINGLE ACTION FOR NATIVE INTEGRATION / LOCATION CONFIG
 */

export const nativeIntegrationExternalListRequest = createAction(
  'NATIVE_INTEGRATION_EXTERNAL_LIST_REQUEST',
  (resolve) => (uid: string, integration: string, detailId?: string) =>
    resolve({ uid, integration, detailId })
)

export const nativeIntegrationExternalListRequestSuccess = createAction(
  'NATIVE_INTEGRATION_EXTERNAL_LIST_REQUEST_SUCCESS',
  (resolve) => (list: ExternalListType[]) => resolve({ list })
)

export const nativeIntegrationExternalListRequestFailure = createAction(
  'NATIVE_INTEGRATION_EXTERNAL_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Remove a specific list (in-depth)
 *
 * Path: /integration/{uid}/mailchimp/{serial}/{id}
 */

export const removeNativeIntegrationSpecificListRequest = createAction(
  'REMOVE_NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST',
  (resolve) => (uid: string, serial: string, integration: string, id: string) =>
    resolve({ uid, serial, integration, id })
)

export const removeNativeIntegrationSpecificListRequestSuccess = createAction(
  'REMOVE_NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST_SUCCESS',
  (resolve) => (listId: string) => resolve({ listId })
)

export const removeNativeIntegrationSpecificListRequestFailure = createAction(
  'REMOVE_NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const nativeIntegrationSpecificListRequest = createAction(
  'NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST',
  (resolve) => (uid: string, serial: string, integration: string, id: string) =>
    resolve({ uid, serial, integration, id })
)

export const nativeIntegrationSpecificListRequestSuccess = createAction(
  'NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST_SUCCESS',
  (resolve) => (list: ListSync) => resolve({ list })
)

export const nativeIntegrationSpecificListRequestFailure = createAction(
  'NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const addNewListSync = createAction(
  'ADD_NEW_LIST_SYNC',
  (resolve) => (list: ListSync) => resolve({ list })
)

export const updateNewListSync = createAction(
  'UPDATE_NEW_LIST_SYNC',
  (resolve) => (list: ListSync) => resolve({ list })
)

/**
 *
 *
 *
 */

export const updateNativeIntegrationSpecificListRequest = createAction(
  'UPDATE_NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST',
  (resolve) =>
    (uid: string, serial: string, integration: string, details: ListSync) =>
      resolve({ uid, serial, integration, details })
)

export const updateNativeIntegrationSpecificListRequestSuccess = createAction(
  'UPDATE_NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST_SUCCESS',
  (resolve) => (list: ListSync) => resolve({ list })
)

export const updateNativeIntegrationSpecificListRequestFailure = createAction(
  'UPDATE_NATIVE_INTEGRATION_SPECIFIC_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get the user's current details
 *
 * Path: /integration/{uid}/campaign-monitor
 */

export const integrationCampaignMonitorRequest = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const integrationCampaignMonitorRequestSuccess = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS',
  (resolve) => (campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ campaignMonitorApiKey })
)

export const integrationCampaignMonitorRequestFailure = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * PUT Create/update user credentials
 *
 * Path: /integration/{uid}/campaign-monitor
 */

export const updateIntegrationCampaignMonitorRequest = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST',
  (resolve) =>
    (
      uid: string,
      campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody
    ) =>
      resolve({ uid, campaignMonitorApiKey })
)

export const updateIntegrationCampaignMonitorRequestSuccess = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS',
  (resolve) => (campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ campaignMonitorApiKey })
)

export const updateIntegrationCampaignMonitorRequestFailure = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 *
 *
 *
 */

export const updateIntegrationCampaignMonitorInStore = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_IN_STORE',
  (resolve) => (campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ campaignMonitorApiKey })
)

/**
 * GET Get the user's current details
 *
 * Path: /integration/{uid}/campaign-monitor
 */

export const integrationAirshipRequest = createAction(
  'INTEGRATION_AIRSHIP_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const integrationAirshipRequestSuccess = createAction(
  'INTEGRATION_AIRSHIP_REQUEST_SUCCESS',
  (resolve) => (airship: AirshipAuthType[]) => resolve({ airship })
)

export const integrationAirshipRequestFailure = createAction(
  'INTEGRATION_AIRSHIP_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * PUT Create/update user credentials
 *
 * Path: /integration/{uid}/campaign-monitor
 */

export const updateIntegrationAirshipRequest = createAction(
  'UPDATE_INTEGRATION_AIRSHIP_REQUEST',
  (resolve) => (uid: string, airship: AirshipAuthType) =>
    resolve({ uid, airship })
)

export const updateIntegrationAirshipRequestSuccess = createAction(
  'UPDATE_INTEGRATION_AIRSHIP_REQUEST_SUCCESS',
  (resolve) => (airship: AirshipAuthType) => resolve({ airship })
)

export const updateIntegrationAirshipRequestFailure = createAction(
  'UPDATE_INTEGRATION_AIRSHIP_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
/**
 *
 *
 *
 */

export const integrationCampaignMonitorListRequest = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_LIST_REQUEST',
  (resolve) => (uid: string, serial: string) => resolve({ uid, serial })
)

export const integrationCampaignMonitorListRequestSuccess = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_LIST_REQUEST_SUCCESS',
  (resolve) => (campaignMonitorLists: ListUnifyRequestBody[]) =>
    resolve({ campaignMonitorLists })
)

export const integrationCampaignMonitorListRequestFailure = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get a specific list (in-depth)
 *
 * Path: /integration/{uid}/mailchimp/{serial}/{id}
 */

export const integrationCampaignMonitorSpecificListRequest = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST',
  (resolve) => (uid: string, serial: string, id: string) =>
    resolve({ uid, serial, id })
)

export const integrationCampaignMonitorSpecificListRequestSuccess =
  createAction(
    'INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_SUCCESS',
    (resolve) => (campaignMonitorList: ListSync) =>
      resolve({ campaignMonitorList })
  )

export const integrationCampaignMonitorSpecificListRequestFailure =
  createAction(
    'INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_FAILURE',
    (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
  )

/**
 *
 *
 *
 */

export const updateIntegrationCampaignMonitorSpecificListRequest = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST',
  (resolve) =>
    (uid: string, serial: string, campaignMonitorListDetails: ListSync) =>
      resolve({ uid, serial, campaignMonitorListDetails })
)

export const updateIntegrationCampaignMonitorSpecificListRequestSuccess =
  createAction(
    'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_SUCCESS',
    (resolve) => (campaignMonitorListDetails: ListSync) =>
      resolve({ campaignMonitorListDetails })
  )

export const updateIntegrationCampaignMonitorSpecificListRequestFailure =
  createAction(
    'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_FAILURE',
    (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
  )

/**
 * GET Retrieve PayPal Account associated with the user
 *
 * Path: /integration/{uid}/paypal
 */

// FIXME: add in request and query params
export const integrationPaypalRequest = createAction(
  'INTEGRATION_PAYPAL_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const integrationPaypalRequestSuccess = createAction(
  'INTEGRATION_PAYPAL_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const integrationPaypalRequestFailure = createAction(
  'INTEGRATION_PAYPAL_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'integration paypal request failed' })
)
/**
 * POST Add a PayPal account
 *
 * Path: /integration/{uid}/paypal
 */

// FIXME: add in request and query params
export const createIntegrationPaypalRequest = createAction(
  'CREATE_INTEGRATION_PAYPAL_REQUEST',
  (resolve) => (uid: string, paypalAccount: PaypalAccountUpdateRequestBody) =>
    resolve({ uid, paypalAccount })
)

export const createIntegrationPaypalRequestSuccess = createAction(
  'CREATE_INTEGRATION_PAYPAL_REQUEST_SUCCESS',
  (resolve) => (paypalAccount: PaypalAccountUpdateRequestBody) =>
    resolve({ paypalAccount })
)

export const createIntegrationPaypalRequestFailure = createAction(
  'CREATE_INTEGRATION_PAYPAL_REQUEST_FAILURE',
  (resolve) => () =>
    resolve({ error: 'create integration paypal request failed' })
)

/**
 * GET Associate an SSID with a Location
 *
 * Path: /integration/{uid}/connected
 */
export const integrationEnabledRequest = createAction(
  'INTEGRATION_ENABLED_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

export const integrationEnabledRequestSuccess = createAction(
  'INTEGRATION_ENABLED_REQUEST_SUCCESS',
  (resolve) => (integrationEnabled: Partial<IntegrationEnabled>) =>
    resolve({ integrationEnabled })
)

export const integrationEnabledRequestFailure = createAction(
  'INTEGRATION_ENABLED_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'integration enabled request failed' })
)

/**
 * PUT Add or update a list
 *
 * Path: /integration/{uid}/{unify}/lists/{serial}
 */

export const createUpdateIntegrationSpecificListRequest = createAction(
  'CREATE_UPDATE_INTEGRATION_SPECIFIC_LIST_REQUEST',
  (resolve) =>
    (uid: string, integration: string, serial: string, listSync: ListSync) =>
      resolve({ uid, integration, serial, listSync })
)

export const createUpdateIntegrationSpecificListRequestSuccess = createAction(
  'CREATE_UPDATE_INTEGRATION_SPECIFIC_LIST_REQUEST_SUCCESS',
  (resolve) => (listSync: ListSync) => resolve({ listSync })
)

export const createUpdateIntegrationSpecificListRequestFailure = createAction(
  'CREATE_UPDATE_INTEGRATION_SPECIFIC_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
