import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CursorSearchType,
  ServiceCursorPaginatedResponseType,
} from 'connect-types/backend/service'
import { EndUser } from 'connect-types/user/user.type'
import { get, post } from 'utils/api'
import {
  OrganizationUserWiFiPaymentType,
  WiFiSessionDataType,
} from './organisation-user.types'

type ActionPropTypes = { id: string; orgId: string } & CursorSearchType

export const getUserSearch = createAsyncThunk(
  'ORGANIZATION_REGISTRATION_USER_SEARCH_REQUEST',
  async (props: Partial<ActionPropTypes> & { search: string }) => {
    const { orgId, search } = props
    const response = await get<
      ServiceCursorPaginatedResponseType<EndUser & { org_reg_id: string }>
    >(`/organisations/${orgId}/user-profiles/search?search=${search}&limit=5`)
    return response
  }
)

export const updateUserWifiPaymentDevices = createAsyncThunk(
  'ORGANIZATION_REGISTRATION_UPDATE_WIFI_PAYMENTS_DEVICES_REQUEST',
  async (
    props: Partial<ActionPropTypes> & { data: Partial<WiFiSessionDataType> }
  ) => {
    const { orgId, id, data } = props
    const clonedData = { ...data }
    await post<string>(
      `/organisations/${orgId}/user-profiles/${id}/wifi-payment/slots`,
      clonedData
    )
    return {
      ...clonedData,
      auth_status: String(clonedData.auth_status) === 'true',
    }
  }
)

export const getUserWifiPaymentDevices = createAsyncThunk(
  'ORGANIZATION_REGISTRATION_WIFI_PAYMENTS_DEVICES_REQUEST',
  async (props: Partial<ActionPropTypes>) => {
    const { orgId, id } = props
    const response = await get<
      ServiceCursorPaginatedResponseType<WiFiSessionDataType>
    >(`/organisations/${orgId}/user-profiles/${id}/wifi-payment/slots`)
    return response
  }
)

export const getUserWiFiPayments = createAsyncThunk(
  'ORGANIZATION_REGISTRATION_WIFI_PAYMENTS_REQUEST',
  async (props: ActionPropTypes) => {
    const { orgId, id, cursor } = props
    const response = await get<
      ServiceCursorPaginatedResponseType<OrganizationUserWiFiPaymentType>
    >(
      `/organisations/${orgId}/user-profiles/${id}/wifi-payment?cursor=${cursor}`
    )
    return response
  }
)
