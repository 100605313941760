import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { dateParse } from 'common/datepicker/utilities/formats'
import {
  defaultServicePagination,
  ServiceCursorPaginatedResponseType,
} from 'connect-types/backend/service'
import { deleter, get, post, put } from 'utils/api'
import { buildUrl } from 'utils/common'
import {
  InboxContextTypes,
  InboxThreadContactsType,
  InboxThreadEventType,
  InboxThreadType,
} from './inbox.types'

/**
 * GET|HEAD        {orgId}/emails/{emailId}/attachments
 * GET|HEAD        {orgId}/emails/{emailId}/attachments/{attachmentId}
 *
 *
 * GET|HEAD        {orgId}/threads?show_archived=ture/false
 * GET|HEAD        {orgId}/threads/{threadId}
 * PUT             {orgId}/threads/{threadId}/archive-thread
 * GET|HEAD        {orgId}/threads/{threadId}/contacts
 * POST            {orgId}/threads/{threadId}/contacts
 *
 *
 * DELETE          {orgId}/threads/{threadId}/contacts/{threadContactId}
 *
 *
 * GET|HEAD        {orgId}/threads/{threadId}/events
 * POST            {orgId}/threads/{threadId}/messages
 *
 * description ..
 *
 * POST            {orgId}/threads/{threadId}/notes
 *
 * description ..
 *
 * PUT             {orgId}/threads/{threadId}/unarchive-thread
 */

export const createNewEmailThread = createAsyncThunk(
  'CREATE_EMAIL_THREAD',
  async (props: {
    orgId: string
    data: {
      contacts: { email: string; name: string }[]
      serial?: string
      subject: string
      content: string

      context_type?: InboxContextTypes
      context_id?: string
    }
  }) => {
    const { orgId, data } = props
    console.log(data)
    const thread = await post<{
      thread: InboxThreadType
    }>(
      `/${orgId}/threads`,
      {
        subject: data.subject,
        serial: data.serial,
        context_id: data.context_id,
        context_type: data.context_type,
      },
      null,
      'inbox'
    )

    const payload = {
      ...thread.thread,
      contacts: [],
      events: { ...defaultServicePagination },
    } as InboxThreadType

    for await (const contact of data.contacts) {
      const createContact = await post<{
        thread_contact: InboxThreadContactsType
      }>(
        `/${orgId}/threads/${payload.id}/contacts`,
        {
          method: 'email',
          ...contact,
        },
        null,
        'inbox'
      )

      payload.contacts.push(createContact.thread_contact)
    }

    const threadEvent = await post<{
      message: string
      thread_event: InboxThreadEventType
    }>(
      `/${orgId}/threads/${payload.id}/messages`,
      { description: data.content, format: 'html' },
      null,
      'inbox'
    )
    console.log({ threadEvent }, payload.events)
    // payload.events.data.push(threadEvent.thread_event)

    return payload
  }
)

export const createEmailContactsOnThread = createAsyncThunk(
  'CREATE_THREAD_EMAIL_CONTACTS',
  async (props: {
    orgId: string
    threadId: string
    data: {
      method: 'email'
      email: string
      name: string
    }
  }) => {
    const { orgId, data, threadId } = props

    const contact = await post<{
      thread_contact: InboxThreadContactsType
      message: string
    }>(`/${orgId}/threads/${threadId}/contacts`, data, null, 'inbox')
    return contact
  }
)

export const removeEmailContactsOnThread = createAsyncThunk(
  'REMOVE_THREAD_EMAIL_CONTACTS',
  async (props: { orgId: string; threadId: string; contactId: string }) => {
    const { orgId, threadId, contactId } = props

    await deleter(
      `/${orgId}/threads/${threadId}/contacts/${contactId}`,
      null,
      'inbox'
    )
  }
)

export const createThread = createAsyncThunk(
  'CREATE_THREAD',
  async (props: {
    orgId: string
    data: {
      serial?: string
      subject: string
      context_type?: 'booking' | 'review'
      context_id?: string
    }
  }) => {
    const { orgId, data } = props

    const thread = await post<{
      thread: Omit<InboxThreadType, 'contacts' | 'events'>
    }>(`/${orgId}/threads`, data, null, 'inbox')

    return thread.thread
  }
)

export const getInboxThreads = createAsyncThunk(
  'GET_INBOX_THREADS',
  async (props: {
    orgId: string
    query?: {
      page?: number
      limit?: number
      archived?: boolean
      serial?: string
      context_type?: 'booking' | 'review' | 'gifting'
      context_id?: string
      cursor?: string
    }
  }) => {
    const { orgId, query } = props

    const url = buildUrl(`/${orgId}/threads`, {
      ...query,
      archived: query.archived ? 1 : 0,
    })

    const response = await get<
      ServiceCursorPaginatedResponseType<InboxThreadType>
    >(url, 'inbox')
    return response
  }
)

export const getInboxThreadEvents = createAsyncThunk(
  'GET_INBOX_THREAD_EVENTS',
  async (props: { orgId: string; threadId: string; cursor: string }) => {
    const { orgId, threadId, cursor = '' } = props

    const response = await get<
      ServiceCursorPaginatedResponseType<InboxThreadEventType>
    >(`/${orgId}/threads/${threadId}/events?cursor=${cursor}`, 'inbox')

    return response
  }
)

export const getInboxThreadEvent = createAsyncThunk(
  'GET_INBOX_THREAD_EVENT',
  async (props: { orgId: string; threadId: string; eventId: string }) => {
    const { orgId, threadId, eventId } = props

    const response = await get<{ data: InboxThreadEventType }>(
      `/${orgId}/threads/${threadId}/events/${eventId}`,
      'inbox'
    )

    return response.data
  }
)

export const getInboxThread = createAsyncThunk(
  'GET_INBOX_THREAD',
  async (props: { orgId: string; threadId: string }) => {
    const { orgId, threadId } = props

    const response = await get<{ data: InboxThreadType }>(
      `/${orgId}/threads/${threadId}`,
      'inbox'
    )

    return response.data
  }
)

export const sendInboxThreadEvents = createAsyncThunk(
  'SEND_INBOX_THREAD',
  async (props: {
    orgId: string
    threadId: string
    type: 'messages' | 'notes'
    description: string
    format: 'html' | 'text'
  }) => {
    const { orgId, threadId, type, description, format } = props

    const response = await post<{
      message: string
      thread_event: InboxThreadEventType
    }>(
      `/${orgId}/threads/${threadId}/${type}`,
      { description, format },
      null,
      'inbox'
    )

    return response.thread_event
  }
)

export const archiveInboxThread = createAsyncThunk(
  'ARCHIVE_INBOX_THREAD',
  async (props: { orgId: string; threadId: string; archive: boolean }) => {
    const { orgId, threadId, archive } = props

    const response = await put<unknown>(
      `/${orgId}/threads/${threadId}/${
        archive ? 'archive-thread' : 'unarchive-thread'
      }`,
      {},
      'inbox'
    )
    return response
  }
)

export const markInboxThreadAsRead = createAsyncThunk(
  'MARK_INBOX_AS_READ_THREAD',
  async (props: { orgId: string; threadId: string; read: boolean }) => {
    const { orgId, threadId, read } = props

    const response = await put<{
      thread: Omit<InboxThreadType, 'events'>
    }>(`/${orgId}/threads/${threadId}/read-status`, { read }, 'inbox')
    if (response.thread.read_at) {
      response.thread.read_at = dateParse(response.thread.read_at)
    }
    return response
  }
)

export const getUnreadInboxThread = createAsyncThunk(
  'GET_INBOX_UNREAD_THREAD',
  async (props: { orgId: string }) => {
    const { orgId } = props

    const response = await get<{ open: number; closed: number }>(
      `/${orgId}/threads/unread-counts`,
      'inbox'
    )

    return response
  }
)

export const clearThreads = createAction('CLEAR_INBOX_THREADS')
