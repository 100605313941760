import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import { DeviceWhitelistRequestBody } from 'connect-types/locations/deviceWhitelistRequestBody.type'
import { NetworkDevice } from 'connect-types/locations/networkDevice.type'

import * as actions from './manage.actions'

export type ManageAction = ActionType<typeof actions>

export interface StateType {
  connectedDevices: Record<string, unknown>[] // TODO: need ConnectedDevices or Devices type
  isLoading: boolean
  whitelistedDevices: DeviceWhitelistRequestBody[]
  networkDevices: NetworkDevice[]
}

const initialState: StateType = {
  connectedDevices: [],
  isLoading: false,
  whitelistedDevices: [],
  networkDevices: [],
}

/* tslint:disable cyclomatic-complexity */
const manageReducer: Reducer<StateType, ManageAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.locationsWhitelistRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.locationsWhitelistRequestSuccess): {
        draft.isLoading = false
        draft.whitelistedDevices = action.payload.listOfWhitelistedDevices
        break
      }

      case getType(actions.locationsWhitelistRequestFailure): {
        draft.isLoading = false
        draft.whitelistedDevices = []
        break
      }

      case getType(actions.locationsWhitelistCreateRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.locationsWhitelistCreateRequestSuccess): {
        draft.isLoading = false
        draft.whitelistedDevices.push(action.payload.whitelistedDevice)
        break
      }

      case getType(actions.locationsWhitelistCreateRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * UPDATE_LOCATIONS_WHITELIST_REQUEST
       *
       * /locations/{serial}/device/whitelist/{id}
       * Delete device from the whitelist
       */
      case getType(actions.updateLocationsWhitelistRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * UPDATE_LOCATIONS_WHITELIST_REQUEST_SUCCESS
       *
       * /locations/{serial}/device/whitelist/{id}
       * Delete device from the whitelist
       */
      case getType(actions.updateLocationsWhitelistRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        const find = draft.whitelistedDevices.findIndex(
          (device) => device.id === action.payload.whitelistedDevice.id
        )

        draft.whitelistedDevices[find] = action.payload.whitelistedDevice
        break
      }

      /**
       * UPDATE_LOCATIONS_WHITELIST_REQUEST_FAILURE
       *
       * /locations/{serial}/device/whitelist/{id}
       * Delete device from the whitelist
       */
      case getType(actions.updateLocationsWhitelistRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      /**
       * DELETE_LOCATIONS_WHITELIST_REQUEST
       *
       * /locations/{serial}/device/whitelist/{id}
       * Delete device from the whitelist
       */
      case getType(actions.deleteLocationsWhitelistRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * DELETE_LOCATIONS_WHITELIST_REQUEST_SUCCESS
       *
       * /locations/{serial}/device/whitelist/{id}
       * Delete device from the whitelist
       */
      case getType(actions.deleteLocationsWhitelistRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false

        const find = draft.whitelistedDevices.findIndex(
          (device) => device.id === action.payload.id
        )

        draft.whitelistedDevices.splice(find, 1)
        break
      }

      /**
       * DELETE_LOCATIONS_WHITELIST_REQUEST_FAILURE
       *
       * /locations/{serial}/device/whitelist/{id}
       * Delete device from the whitelist
       */
      case getType(actions.deleteLocationsWhitelistRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }

      /**
       * GET_LOCATION_NETWORK_DEVICES
       *
       * /locations/{serial}/device
       * Get Devices associated with Network
       */
      case getType(actions.locationsDeviceRequest): {
        draft.isLoading = true
        break
      }

      /**
       * GET_LOCATION_NETWORK_DEVICES_SUCCESS
       *
       * /locations/{serial}/device
       * Get Devices associated with Network
       */
      case getType(actions.locationsDeviceRequestSuccess): {
        draft.isLoading = false
        draft.networkDevices = action.payload.listOfNetworkDevices
        break
      }

      /**
       * GET_LOCATION_NETWORK_DEVICES_FAILURE
       *
       * /locations/{serial}/device
       * Get Devices associated with Network
       */
      case getType(actions.locationsDeviceRequestFailure): {
        draft.isLoading = false
        draft.networkDevices = []
        break
      }

      case getType(actions.createLocationsDeviceRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.createLocationsDeviceRequestSuccess): {
        draft.isLoading = false
        draft.networkDevices.push(action.payload.networkDevice)
        break
      }

      case getType(actions.createLocationsDeviceRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * UPDATE_LOCATIONS_DEVICE_REQUEST
       *
       * /locations/{serial}/device/{id}
       * Update a device
       */
      case getType(actions.updateLocationsDeviceRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * UPDATE_LOCATIONS_DEVICE_REQUEST_SUCCESS
       *
       * /locations/{serial}/device/{id}
       * Update a device
       */
      case getType(actions.updateLocationsDeviceRequestSuccess): {
        draft.isLoading = false
        const find = draft.networkDevices.findIndex(
          (device) => device.id === action.payload.networkDevice.id
        )

        draft.networkDevices[find] = action.payload.networkDevice

        break
      }

      /**
       * UPDATE_LOCATIONS_DEVICE_REQUEST_FAILURE
       *
       * /locations/{serial}/device/{id}
       * Update a device
       */
      case getType(actions.updateLocationsDeviceRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * DELETE_LOCATIONS_DEVICE_REQUEST
       *
       * /locations/{serial}/device/{id}
       * Delete a device
       */
      case getType(actions.deleteLocationsDeviceRequest): {
        // TODO: implemement logic here
        draft.isLoading = true
        break
      }

      /**
       * DELETE_LOCATIONS_DEVICE_REQUEST_SUCCESS
       *
       * /locations/{serial}/device/{id}
       * Delete a device
       */
      case getType(actions.deleteLocationsDeviceRequestSuccess): {
        // TODO: implemement logic here
        draft.isLoading = false
        const find = draft.networkDevices.findIndex(
          (device) => device.id === action.payload.networkDevice.id
        )

        draft.networkDevices.splice(find, 1)
        break
      }

      /**
       * DELETE_LOCATIONS_DEVICE_REQUEST_FAILURE
       *
       * /locations/{serial}/device/{id}
       * Delete a device
       */
      case getType(actions.deleteLocationsDeviceRequestFailure): {
        // TODO: implemement logic here
        draft.isLoading = false
        break
      }
    }
  })

export default manageReducer
/* tslint:enable cyclomatic-complexity */
