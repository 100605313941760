/* tslint:disable no-var-requires non-literal-require no-console no-require-imports */
import merge from 'lodash/merge'

import { Config } from './config.type'

export const env =
  process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV || 'development'

// console.log('Environment:', env)
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T
// eslint-disable-next-line @typescript-eslint/no-var-requires
const commonConfig: Config = require('./config.common').default
//const commonConfig: Config = require('./config.common').default
// const envConfig: DeepPartial<Config> = require(`./config.development`).default
// FIXME: uncomment this and make sure config works for both `development` and `production` envs
// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: DeepPartial<Config> = require(`./config.${env}`).default

const config: Config = merge(commonConfig, envConfig)

export default config
