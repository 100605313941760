import { createAction } from 'typesafe-actions'
import { SecondNavMenuType, HeaderSettingsType } from './ui.types'

export const setSecondNav = createAction(
  'SET_SECOND_NAV',
  (resolve) => (settings: Partial<SecondNavMenuType>) => resolve({ settings })
)

export const setHeader = createAction(
  'SET_HEADER',
  (resolve) => (settings: HeaderSettingsType) => resolve({ settings })
)

export const setPartialHeader = createAction(
  'SET_PARTIAL_HEADER',
  (resolve) => (settings: Partial<HeaderSettingsType>) => resolve({ settings })
)

export const setVenuesModalVisible = createAction(
  'SET_VENUES_MODAL_VISIBLE',
  (resolve) => (visible: boolean) => resolve({ visible })
)

export const setAddBookingModalVisible = createAction(
  'TOGGLE_ADD_BOOKING_MENU',
  (resolve) => (open: boolean) => resolve({ open })
)

export const setUpdateAvailable = createAction(
  'SET_UPDATE_AVAILABLE',
  (resolve) =>
    (updateAvailable: boolean, registration?: ServiceWorkerRegistration) =>
      resolve({ updateAvailable, registration })
)
