import { createReducer } from '@reduxjs/toolkit'
import {
  defaultServicePagination,
  ServiceCursorPaginatedResponseType,
} from 'connect-types/backend/service'
import { UserReview } from 'connect-types/reviews/reviews.type'
import { EndUser } from 'connect-types/user/user.type'
import {
  OrganizationRegistrationTransactionType,
  ProfileInteractionType,
} from '../interactions/interactions.types'
import {
  getUserSearch,
  getUserWifiPaymentDevices,
  getUserWiFiPayments,
  updateUserWifiPaymentDevices,
} from './organisation-user.actions'
import {
  OrganizationRegistrationType,
  OrganizationUserBookingType,
  OrganizationUserWiFiPaymentType,
  WiFiSessionDataType,
} from './organisation-user.types'

interface InitialStateType {
  reviews: ServiceCursorPaginatedResponseType<UserReview>
  user: OrganizationRegistrationType
  bookings: ServiceCursorPaginatedResponseType<OrganizationUserBookingType>
  registrations: ServiceCursorPaginatedResponseType<ProfileInteractionType>
  wifi_payments: ServiceCursorPaginatedResponseType<OrganizationUserWiFiPaymentType>
  transactions: ServiceCursorPaginatedResponseType<OrganizationRegistrationTransactionType>
  wifi_devices: ServiceCursorPaginatedResponseType<WiFiSessionDataType>
  wifi_payments_devices: ServiceCursorPaginatedResponseType<WiFiSessionDataType>
  user_search: ServiceCursorPaginatedResponseType<
    EndUser & { org_reg_id: string }
  >
  isLoading: boolean
}

const initialState: InitialStateType = {
  reviews: defaultServicePagination,
  bookings: defaultServicePagination,
  registrations: defaultServicePagination,
  transactions: defaultServicePagination,
  wifi_payments: defaultServicePagination,
  wifi_payments_devices: defaultServicePagination,
  user_search: defaultServicePagination,
  wifi_devices: defaultServicePagination,
  user: null,
  isLoading: false,
}

const reducer = createReducer(initialState, (builder) =>
  builder

    .addCase(updateUserWifiPaymentDevices.pending, (state, action) => {
      const itemIndex = state.wifi_payments_devices.data.findIndex(
        (item) => item.mac === action.meta.arg.data.mac
      )
      state.wifi_payments_devices.data[itemIndex] = {
        ...state.wifi_payments_devices.data[itemIndex],
        isLoading: true,
      }
    })
    .addCase(updateUserWifiPaymentDevices.fulfilled, (state, action) => {
      const itemIndex = state.wifi_payments_devices.data.findIndex(
        (item) => item.mac === action.payload.mac
      )
      state.wifi_payments_devices.data[itemIndex] = {
        ...state.wifi_payments_devices.data[itemIndex],
        ...action.payload,
        isLoading: false,
      }
    })
    .addCase(updateUserWifiPaymentDevices.rejected, (state) => {
      state.wifi_payments_devices.data = state.wifi_payments_devices.data.map(
        (item) => ({ ...item, isLoading: false })
      )
    })
    .addCase(getUserWifiPaymentDevices.pending, (state) => {
      state.wifi_payments_devices.isLoading = true
    })
    .addCase(getUserWifiPaymentDevices.fulfilled, (state, action) => {
      state.wifi_payments_devices = action.payload
    })
    .addCase(getUserWifiPaymentDevices.rejected, (state) => {
      state.wifi_payments_devices = defaultServicePagination
    })
    .addCase(getUserSearch.pending, (state) => {
      state.user_search.isLoading = true
    })
    .addCase(getUserSearch.fulfilled, (state, action) => {
      state.user_search = action.payload
    })
    .addCase(getUserSearch.rejected, (state) => {
      state.user_search = defaultServicePagination
    })
    .addCase(getUserWiFiPayments.pending, (state) => {
      state.wifi_payments.isLoading = true
    })
    .addCase(getUserWiFiPayments.fulfilled, (state, action) => {
      state.wifi_payments = action.payload
    })
    .addCase(getUserWiFiPayments.rejected, (state) => {
      state.wifi_payments = defaultServicePagination
    })
)

export default reducer
