import { configureStore, StoreEnhancer } from '@reduxjs/toolkit'

import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import backendApi from './backend/backend.slice'
import bookingApi from './booking/booking.slice'
import sentryMiddleware from './middleware/sentry'
import {
  offlineEnhanceReducer,
  offlineEnhanceStore,
  offlineMiddleware,
  rootReducer,
} from './reducers'
import rootSaga from './sagas'
import serviceApi from './service/service.slice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import morpheusApi from './morpheus/morpheus.slice'

const configureAppStore = (preloadedState: any = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const enhancers = [offlineEnhanceStore, sentryMiddleware] as StoreEnhancer[]
  const middleware: SagaMiddleware<object>[] = [
    sagaMiddleware,
    offlineMiddleware as SagaMiddleware<object>,
    /** @ts-ignore */
  ]
    /** @ts-ignore */
    .concat(backendApi.middleware)
    /** @ts-ignore */
    .concat(serviceApi.middleware)
    /** @ts-ignore */
    .concat(bookingApi.middleware)
    /** @ts-ignore */
    .concat(morpheusApi.middleware)

  //const midApply = applyMiddleware(...middleware, offlineMiddleware)

  /*
  middleware.push(
    offlineMiddleware({
      additionalTriggers: REHYDRATE,
    })
  )
    //const suspendSagaMiddleware = suspendSaga(sagaMiddleware)

    middleware.push(suspendSagaMiddleware)
  */
  //middleware.push(createOfflineMiddleware(offlineConfig))

  //middleware.push(consumeActionMiddleware())

  const theStore = configureStore({
    reducer: offlineEnhanceReducer(rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        //  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }).concat(middleware),

    preloadedState,
    enhancers: [...enhancers],
    // enhancers: (defaultEnhancers) => [enhancers, ...defaultEnhancers],
    devTools: process.env.NODE_ENV === 'development',
  })

  setupListeners(theStore.dispatch)

  sagaMiddleware.run(rootSaga)
  /*
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => theStore.replaceReducer(rootReducer))
    module.hot.accept('./sagas', () => {
      const getNewSagas = require('./sagas')
      sagaTask.cancel()
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(getNewSagas.default)
      })
    })
  }
  */

  return theStore
}

export default () => {
  const store = configureAppStore()
  // const persistor = persistStore(store)

  return { store }
}
