import { PortalSession } from 'connect-types/billing/chargebee.types'
import { createAction } from 'typesafe-actions'

export const getMemberPortalRequest = createAction(
  'GET_MEMBER_PORTAL_REQUEST',
  (resolve) => (orgId: string, open: boolean) => resolve({ orgId, open })
)

export const getMemberPortalRequestSuccess = createAction(
  'GET_MEMBER_PORTAL_REQUEST_SUCCESS',
  (resolve) => (portal: PortalSession, open: boolean) =>
    resolve({ portal, open })
)

export const getMemberPortalRequestFailure = createAction(
  'GET_MEMBER_PORTAL_REQUEST_FAILURE',
  (resolve) => () => resolve()
)
