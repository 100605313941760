import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import message from 'common/message/message'
import {
  EndUserSummary,
  EndUserLogInSummary,
  EndUserPaymentSummary,
} from 'connect-types/user/endUserSummary.type'
import { EndUser } from 'connect-types/user/user.type'
import {
  UserSearchCriteria,
  UserSearchResponse,
} from 'connect-types/user/userSearchCriteria.type'
import { EndUserPayment } from 'connect-types/user/endUserPayment.type'

import * as actions from './user.actions'
import { OrganizationRegistrationType } from './user.types'

export type UserAction = ActionType<typeof actions>

export interface StateType {
  users: EndUser[]
  devices: Record<string, unknown>[] // TODO: need a Device type
  connections: Record<string, unknown>[] // TODO: need a Connection type
  activePayment: EndUserPayment
  currentEndUser: EndUserSummary
  isRequestLoading: boolean
  searchUserCriteria: UserSearchCriteria
  searchUserResponse: UserSearchResponse
  organisation_registration: OrganizationRegistrationType
}

const payment: EndUserPayment = {
  cost: null,
  customer: {
    email: null,
  },
  deviceAllowance: null,
  duration: null,
  method: 'manual',
  paymentKey: null,
  serial: null,
}

const initialState: StateType = {
  users: [],
  devices: [],
  connections: [],
  activePayment: payment,
  currentEndUser: null,
  isRequestLoading: false,
  searchUserCriteria: {
    offset: 0,
  },
  searchUserResponse: null,
  organisation_registration: null,
}

/* tslint:disable cyclomatic-complexity */
const userReducer: Reducer<StateType, UserAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.createUserPaymentRequest): {
        draft.isRequestLoading = true
        break
      }

      case getType(actions.createUserPaymentRequestSuccess): {
        draft.isRequestLoading = false
        message.success('Payment Created')
        break
      }

      case getType(actions.createUserPaymentRequestFailure): {
        draft.isRequestLoading = false
        break
      }

      case getType(actions.updateUserPaymentRequest): {
        draft.isRequestLoading = true

        break
      }

      case getType(actions.updateUserPaymentRequestSuccess): {
        draft.isRequestLoading = false
        if (draft.currentEndUser) {
          Object.keys(draft.currentEndUser.events).map((day) =>
            draft.currentEndUser.events[day].paymentEvents.forEach(
              (paymentEvent: EndUserPaymentSummary) => {
                if (paymentEvent.id === action.payload.payment.id) {
                  paymentEvent.paymentAmount = action.payload.payment.cost
                }
              }
            )
          )
          draft.activePayment = payment
        }
        break
      }

      case getType(actions.updateUserPaymentRequestFailure): {
        console.log({ error: action.payload })
        draft.isRequestLoading = false
        break
      }

      case getType(actions.updateUserActivePaymentInStore): {
        draft.activePayment = action.payload.payment
        break
      }

      /**
       * UPDATE_USER_DEVICES_BLOCK_REQUEST
       *
       * /user/{id}/devices/block
       * Block a user from a location
       */
      case getType(actions.updateUserDevicesBlockRequest): {
        draft.isRequestLoading = true
        break
      }

      /**
       * UPDATE_USER_DEVICES_BLOCK_REQUEST_SUCCESS
       *
       * /user/{id}/devices/block
       * Block a user from a location
       */
      case getType(actions.updateUserDevicesBlockRequestSuccess): {
        draft.isRequestLoading = false
        Object.keys(draft.currentEndUser.events).map((day) =>
          draft.currentEndUser.events[day].loginEvents.forEach(
            (loginEvent: EndUserLogInSummary) => {
              if (
                loginEvent.mac === action.payload.deviceBlock.mac &&
                loginEvent.serial === action.payload.deviceBlock.serial
              ) {
                loginEvent.blocked = true

                if (action.payload.deviceBlock.action === 'unblock') {
                  loginEvent.blocked = false
                }
              }
            }
          )
        )
        break
      }

      /**
       * UPDATE_USER_DEVICES_BLOCK_REQUEST_FAILURE
       *
       * /user/{id}/devices/block
       * Block a user from a location
       */
      case getType(actions.updateUserDevicesBlockRequestFailure): {
        draft.isRequestLoading = false
        break
      }
    }
  })

export default userReducer
/* tslint:enable cyclomatic-complexity */
