import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'


import * as actions from './payment.actions'

export type PaymentAction = ActionType<typeof actions>

export interface StateType {
  paymentPlans: Record<string, unknown>[] // TODO: need PaymentPlan type
  paymentMethods: Record<string, unknown>[] // TODO: need PaymentMethod type
}

const initialState: StateType = {
  paymentPlans: [],
  paymentMethods: [],
}

/* tslint:disable cyclomatic-complexity */
const paymentReducer: Reducer<StateType, PaymentAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (_draft) => {
    switch (action.type) {
      /**
       * LOCATIONS_PAYMENT_PLAN_REQUEST
       *
       * /locations/{serial}/payments/plans/{planId}
       * Get a specific plan
       */
      case getType(actions.locationsPaymentPlanRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/plans/{planId}
       * Get a specific plan
       */
      case getType(actions.locationsPaymentPlanRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/plans/{planId}
       * Get a specific plan
       */
      case getType(actions.locationsPaymentPlanRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST
       *
       * /locations/{serial}/payments/plans/{planId}
       * Update a specific plan
       */
      case getType(actions.updateLocationsPaymentPlanRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/plans/{planId}
       * Update a specific plan
       */
      case getType(actions.updateLocationsPaymentPlanRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/plans/{planId}
       * Update a specific plan
       */
      case getType(actions.updateLocationsPaymentPlanRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST
       *
       * /locations/{serial}/payments/plans/{planId}
       * Delete a specific plan
       */
      case getType(actions.deleteLocationsPaymentPlanRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/plans/{planId}
       * Delete a specific plan
       */
      case getType(actions.deleteLocationsPaymentPlanRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * DELETE_LOCATIONS_PAYMENT_PLAN_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/plans/{planId}
       * Delete a specific plan
       */
      case getType(actions.deleteLocationsPaymentPlanRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_PAYMENT_METHOD_REQUEST
       *
       * /locations/{serial}/payments/method
       * Get location methods
       */
      case getType(actions.locationsPaymentMethodRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_PAYMENT_METHOD_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/method
       * Get location methods
       */
      case getType(actions.locationsPaymentMethodRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * LOCATIONS_PAYMENT_METHOD_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/method
       * Get location methods
       */
      case getType(actions.locationsPaymentMethodRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_PAYMENT_METHOD_REQUEST
       *
       * /locations/{serial}/payments/method
       * Update payment methods for a transaction
       */
      case getType(actions.updateLocationsPaymentMethodRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_PAYMENT_METHOD_REQUEST_SUCCESS
       *
       * /locations/{serial}/payments/method
       * Update payment methods for a transaction
       */
      case getType(actions.updateLocationsPaymentMethodRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * UPDATE_LOCATIONS_PAYMENT_METHOD_REQUEST_FAILURE
       *
       * /locations/{serial}/payments/method
       * Update payment methods for a transaction
       */
      case getType(actions.updateLocationsPaymentMethodRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }
    }
  })

export default paymentReducer
/* tslint:enable cyclomatic-complexity */
