import message from 'common/message/message'
import config from '../config/config.common'
import { PortalSession } from '../connect-types/billing/chargebee.types'
import { SubscriptionResponse } from '../connect-types/billing/subscriptionResponse.type'
import { whenAvailable } from './tracking'

declare global {
  interface Window {
    Chargebee: any
  }
}

whenAvailable('Chargebee', (Chargebee: any) => {
  return Chargebee.init({
    site: config.chargebee.site,
  })
})

export const setSession = (portalData: PortalSession): unknown =>
  chargebeeInstance().setPortalSession(portalData)

let passThruContent = ''

export const setSubscription = (subscription: SubscriptionResponse): any => {
  chargebeeInstance().openCheckout({
    async hostedPage() {
      passThruContent = subscription.pass_thru_content

      // tslint:disable-next-line: no-inferred-empty-object-type
      return new Promise((resolve) => resolve(subscription))
    },
    success() {
      window.location.href = `${config.url.connect}/location/${passThruContent}`
    },
  })

  return passThruContent
}

export const setHostedPage = (subscription: SubscriptionResponse): any => {
  chargebeeInstance().openCheckout({
    async hostedPage() {
      passThruContent = subscription.pass_thru_content

      // tslint:disable-next-line: no-inferred-empty-object-type
      return new Promise((resolve) => resolve(subscription))
    },
    success() {
      message.success('Checkout complete')
    },
  })

  return passThruContent
}

const chargebeeInstance = () => window.Chargebee.getInstance()

const callbacks = {
  close() {
    /**
     * maybe push to saga on close
     */
  },
  paymentSourceAdd() {
    // Optional
    // called whenever a new payment source is added in portal
    chargebeeInstance().closeAll()
    message.success('Payment method added')
  },
  paymentSourceRemove() {
    message.success('Payment method removed')
  },
}

const portal = () => chargebeeInstance().createChargebeePortal()

export const addPaymentMethod = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
  })

export const addAddress = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().ADDRESS,
  })

export const billingHistory = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
  })

export const addAccountDetails = () =>
  portal().open(callbacks, {
    sectionType: window.Chargebee.getPortalSections().ACCOUNT_DETAILS,
  })

export const openBilling = () => portal().open(callbacks)
