import { AxiosError } from 'axios'
import message from 'common/message/message'
import {
  isAjvError,
  isAxiosError,
  isBackendError,
  isMorpheusError,
  isServiceError,
} from 'utils/axiosHelper'
import { getFirst } from 'utils/common'

export interface BackendResponseType<T> {
  message: T
  status: number
}

export interface ServiceLinkType {
  url: string | null
  label: string
  active: boolean
}

export interface CursorSearchType {
  cursor?: string | null
}

export interface PagingLinksType {
  first: string //'http://service.stage.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses?page=1'
  last: string //'http://service.stage.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses?page=1'
  prev: string | null
  next: string | null
  current?: string | null
}

export interface ServiceCursorPaginatedResponseType<T> {
  data: T[]
  links: PagingLinksType
  meta: {
    path: string //'http://service.stage.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses'
    per_page: number

    next_cursor: string | null
    prev_cursor: string | null

    total?: number
  }
  isLoading?: boolean
}
export interface ServicePaginatedResponseType<T> {
  data: T[]
  links: PagingLinksType
  meta: {
    current_page: number
    from: number
    last_page: number
    links: ServiceLinkType[]
    path: string //'http://service.stage.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses'
    per_page: number
    to: number
    total: number

    /**
     *
     */
    next_cursor: string | null
    prev_cursor: string | null
  }
  isLoading?: boolean
}

export interface ServiceErrorType {
  message: string
  errors?: { [key: string]: string[] }
  line?: number
  file?: string
}

export interface ApiErrorType {
  detail: string
  instance: string
  status: number
  type: string
  title?: string
}

export interface AvjErrorType {
  context: string
  error: string
}

const ServiceErrorResponse = (error: AxiosError<ServiceErrorType>) => {
  const { data } = error.response
  if (data.errors && Array.isArray(Object.keys(data.errors))) {
    const arrayOfErrors = Object.keys(data.errors).map((index) =>
      data.errors[index].join(', ')
    )
    return message.warning(data.message, {
      description: arrayOfErrors.join(', '),
      duration: 10000,
    })
  }
  if (data.message) {
    return message.error(data.message, {
      duration: 10000,
    })
  }
}

export const ServiceErrorHandler = (error: Error | unknown) => {
  if (!isAxiosError(error)) {
    return
  }
  if (!error.response) {
    return
  }
  if (
    isMorpheusError<{ message: string | { instancePath: string }[] }>(error)
  ) {
    if (Array.isArray(error.response.data.message)) {
      if ('instancePath' in getFirst(error.response.data.message)) {
        return message.warning('Validation error', {
          description: error.response.data.message
            .map((item) => item.instancePath)
            .join(', '),
          duration: 10000,
        })
      }
      return
    }
    return message.error(error.response.data.message, {
      duration: 10000,
    })
  }
  if (isAjvError<AvjErrorType>(error)) {
    return message.error(error.response.data.error, {
      duration: 10000,
    })
  }
  if (isBackendError<ApiErrorType>(error)) {
    return message.error(error.response.data.detail, {
      duration: 10000,
    })
  }

  if (isServiceError<ServiceErrorType>(error)) {
    return ServiceErrorResponse(error)
  }
}

export const defaultServicePagination: ServicePaginatedResponseType<undefined> =
  {
    isLoading: false,
    data: [],
    links: {
      first: '',
      last: '',
      prev: '',
      next: '',
    },
    meta: {
      current_page: 1,
      from: 0,
      last_page: 0,
      links: [],
      path: '',
      per_page: 25,
      to: 0,
      total: 0,
      prev_cursor: null,
      next_cursor: null,
    },
  }
