import { createOffline } from '@redux-offline/redux-offline'
import { OfflineState } from '@redux-offline/redux-offline/lib/types'
import { StateType } from 'typesafe-actions'

import auth from './auth/auth.reducer'
import entities from './entities'
import global from './global/global.reducer'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index'
import backendApi from './backend/backend.slice'
import serviceApi from './service/service.slice'
import bookingApi from './booking/booking.slice'
import action from './action.reducer'
import { combineReducers } from '@reduxjs/toolkit'
import morpheusApi from './morpheus/morpheus.slice'

//import { persistCombineReducers, PersistConfig } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
// -----------------------------------------------------------------------------
// REDUCER

// TODO: getting a type mismatch error in VSCode, but code compiles properly
/*
const config: PersistConfig<RootState> = {
  storage,
  key: 'root',
  //transforms: [],
  whitelist: ['auth', 'global'], // We have to explicitly include the reducers to be persisted
  throttle: 250,
}

*/

const reducers = {
  auth,
  entities,
  global,

  action,
  [backendApi.reducerPath]: backendApi.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  [morpheusApi.reducerPath]: morpheusApi.reducer,
}
export type RootState = StateType<typeof reducers & { offline?: OfflineState }>

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
})

const rootReducer = combineReducers(reducers)

export {
  offlineMiddleware,
  offlineEnhanceStore,
  rootReducer,
  offlineEnhanceReducer,
}

export type AppDispatch = any

// -----------------------------------------------------------------------------
// PUBLIC SELECTORS

// TODO: add in all public selectors here (might eventually put them in
// separate *.selectors.tsx files)
// Idea source: https://hackernoon.com/selector-pattern-painless-redux-store-destructuring-bfc26b72b9ae

// TODO: we should use the 'reselect' library to add memoization and improve the
// performance of our selectors https://github.com/reduxjs/reselect
