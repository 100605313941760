import App from 'app'
import Sentry from 'common/Sentry/Sentry'
import { StrictMode } from 'react'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { unregister } from 'serviceWorkerRegistration'
import configureStore from 'state/store'
import './a-console-remover'
import './index.css'

if (window.location.hostname === 'localhost') {
  document.domain = 'localhost'
}

if (window.location.hostname.includes('stampede')) {
  document.domain = 'stampede.ai'
}

// old site - when we are ready we will force people over to the new stampede site
if (window.location.hostname === 'product.blackbx.io') {
  window.location.href = `https://product.stampede.ai${window.location.pathname}${window.location.search}`
}

if (window.location.hostname.indexOf('connect') === 0) {
  window.location.href = `https://${window.location.hostname.replace(
    'connect',
    'product'
  )}/${window.location.pathname}`
}
const { store } = configureStore()

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <Sentry>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </Sentry>
  </StrictMode>
)

/*
register({
  onUpdate: () => {
    // store.dispatch(setUpdateAvailable(true, registration))
  },
  onSuccess: (registration) => {
    console.log('registered app for offline use. details:', registration)
  },
})
*/

unregister()
