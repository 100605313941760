import { theme } from '@chakra-ui/pro-theme'
import { extendTheme, Theme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
}

/*
interface ColorHues {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}
const convertToColor = (color: Color | null, opacity: number): string =>
  color ? colorString.to.rgb([color[0], color[1], color[2], opacity]) : ''


const makePallet = (color: string): Partial<ColorHues> => ({
  50: convertToColor(colorString.get.rgb(color), 0.02),
  100: convertToColor(colorString.get.rgb(color), 0.2),
  200: convertToColor(colorString.get.rgb(color), 0.4),
  300: convertToColor(colorString.get.rgb(color), 0.6),
  400: convertToColor(colorString.get.rgb(color), 0.8),
  500: convertToColor(colorString.get.rgb(color), 1),
  600: convertToColor(colorString.get.rgb(color), 0.8),
  700: convertToColor(colorString.get.rgb(color), 1),
  800: convertToColor(colorString.get.rgb(color), 1),
  900: convertToColor(colorString.get.rgb(color), 1),
})
*/

const Input = {
  defaultProps: {
    size: 'md',
    fontWeight: '600',
  },
  variants: {
    outline: (_props: Record<string, any>) => ({
      field: {},
    }),
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 'md',
      },
    },
  },
}

const config: Partial<Theme | Record<string, any>> = {
  breakpoints,
  components: {
    Tooltip: {
      baseStyle: (props: Record<string, any>) => ({
        borderRadius: 'lg',
        fontWeight: '400',
        px: 3,
        py: 2,
        boxShadow: 'lg',
        color: mode('black', `white`)(props),
        bg: mode('white', `gray.800`)(props),
      }),
    },
    Link: {
      textDecoration: 'underline',
    },
    Radio: {
      baseStyle: {
        label: {
          fontWeight: '500',
          fontSize: '12px',
        },
      },
      sizes: {
        md: {
          label: {
            fontSize: 'xs',
          },
        },
      },
    },
    Divider: {
      variants: {
        frost: {
          borderColor: 'blue.800',
          borderStyle: 'solid',
          width: 'full',
          opacity: 0.5,
        },
      },
    },
    Text: {
      baseStyle: {
        lineHeight: '1.7',
      },
      variants: {
        desc: {
          opacity: 0.6,
          fontSize: 'sm',

          margin: 0,
        },
        time: {
          opacity: 0.6,
          fontSize: 'xs',
          fontWeight: '500',
          margin: 0,
        },
        stat: {
          fontSize: 'lg',
          fontWeight: '600',
          margin: 0,
        },
        inputLabel: {},
        label: {
          fontSize: '11px',
          textTransform: 'uppercase',
          fontWeight: '700',
          opacity: 0.6,
          margin: 0,
        },
      },
    },
    Avatar: {
      baseStyle: {
        container: {
          fontWeight: '900',
        },
      },
    },
    Code: {
      baseStyle: {
        fontSize: '10px',
      },
    },
    Tag: {
      baseStyle: {
        container: {
          fontWeight: '600',
          justifyContent: 'center',
        },
      },
      sizes: {
        sm: {
          container: {
            px: '5px',
          },
          closeButton: {},
        },
      },
    },
    Accordion: {
      baseStyle: {
        button: {
          textAlign: 'left',
          fontSize: 'md',
          fontWeight: 600,
          _focus: {
            boxShadow: 'none',
          },
          px: 5,
          py: 3,
        },
      },
    },
    Modal: {
      overlay: {},
      dialogContainer: {},
      dialog: {},
      header: {
        py: 5,
      },
      closeButton: {
        top: 5,
      },
      body: {},
      footer: {},
    },
    Drawer: {
      defaultProps: {
        size: 'xs',
      },
      baseStyle: {
        overlay: {},
        dialogContainer: {},
        dialog: {
          overflow: 'hidden',
          px: 4,
        },
        closeButton: {
          top: 5,
        },
        header: {
          fontWeight: '700',
          fontSize: 'lg',
          py: 5,
        },
      },
    },

    Popover: {
      baseStyle: {
        popper: {
          zIndex: 1501,
          // maxW: 500,
        },
        content: {
          overflow: 'initial',
        },
      },
    },
    Menu: {
      /*
      defaultProps: {
        size: 'md',
      },
      baseStyle: {
        groupTitle: {
          fontWeight: '700',
          fontSize: 11,
          textTransform: 'uppercase',
          opacity: 0.6,
          ml: 3,
        },
        list: {
          boxShadow: 'md',
          borderWidth: 0,
          zIndex: 5,
        },
        item: {
          fontWeight: '600',
          fontSize: 'xs',
          size: 'md',
        },
      },
    */
    },
    FormLabel: {
      baseStyle: {
        // mb: 0,
        fontSize: 'sm',
        opacity: 0.9,
        //  textTransform: '',
        fontWeight: '600',
      },
      variants: {
        label: {
          textTransform: 'uppercase',
          fontWeight: '600',
          opacity: 0.8,
        },
      },
    },

    Button: {
      baseStyle: {
        // letterSpacing: '-0.2px',
      },
      sizes: {
        md: {
          fontWeight: '500',
          fontSize: 'initial',
          px: 4,
        },
      },
      defaultProps: {
        size: 'sm',
      },
      variants: {
        solid: {
          /*
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.2)',
*/
        },
        bold: (props: Record<string, any>) => ({
          borderRadius: 'full',
          size: 'lg',
          boxShadow: '0 20px 15px -10px rgba(0, 0, 0, 0.2)',
          fontWeight: '700',
          textTransform: 'uppercase',
          padding: '10px 30px',
          color: mode('white', `gray.800`)(props),
          bg: mode(
            `${props.colorScheme}.500`,
            `${props.colorScheme}.200`
          )(props),
          height: 50,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.2)',
        }),
        bottom: (props: Record<string, any>) => ({
          borderBottomRadius: 'none',
          borderTopRadius: 'lg',
          size: 'lg',
          position: 'fixed',
          margin: 'auto',

          bottom: 0,
          fontWeight: '700',
          textTransform: 'uppercase',
          padding: '10px 30px',
          color: mode('white', `gray.800`)(props),
          bg: mode(
            `${props.colorScheme}.500`,
            `${props.colorScheme}.200`
          )(props),
          height: 50,
        }),
      },
    },
    Textarea: Input,
    Input,
    Select: {
      ...Input,
    },
    NumberInput: Input,
    Heading: {
      baseStyle: {
        letterSpacing: '-0.025em',
        size: 'xs',
        fontWeight: 'bold',
      },
    },
    Table: {
      baseStyle: (_props: Record<string, any>) => ({
        table: {
          background: 'transparent',
        },
        td: {
          // color: mode('gray.600', `white`)(props),
          //  background: 'transparent',
        },
        th: {
          //  background: 'pink',
        },
      }),
      variants: {
        simple: {
          td: {
            borderBottom: 0,
          },
        },
      },
      sizes: {
        md: {
          th: {
            pl: 4,
            pr: 4,
          },
          td: {
            pt: 2,
            pb: 2,
            pl: 4,
            pr: 4,
            mt: 1,
            mb: 1,
          },
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tablist: {
            borderBottomWidth: 0,
            px: 0,
          },
          tab: {
            px: 0,
            pb: 2,
            mr: 5,
            zIndex: 10,
            opacity: 0.8,
            fontWeight: 600,
            _selected: {
              fontWeight: 800,
              opacity: 1,
            },
            _active: {
              fontWeight: 800,
              opacity: 1,
              background: 'transparent',
            },
          },
        },
      },
      sizes: {
        sm: {
          tab: {},
        },
      },
      defaultProps: {
        size: 'sm',
        colorScheme: 'brand',
      },

      baseStyle: {
        tab: {
          _focus: {
            boxShadow: 'none',
          },
        },
        tablist: {
          // borderBottomWidth: 1,
        },

        tabpanel: {
          _focus: {
            outline: 'none',
          },
          p: 0,
        },
      },
    },
  },
  semanticTokens: {
    colors: {
      'bg-canvas-2': {
        default: 'white',
        _dark: 'gray.900',
      },
      's-success': {
        default: '#A5F6E0',
        _dark: '#A5F6E0',
      },
      's-success-muted': {
        default: '#4D9984',
        _dark: '#4D9984',
      },
      's-gray': {
        default: '#ACACB1',
        _dark: '#ACACB1',
      },
      's-gray-muted': {
        default: '#17183FB2',
        _dark: '#17183FB2',
      },
      's-light-gray-bg': {
        default: '#17183f1a',
        _dark: '#17183f1a',
      },
      's-blue': {
        default: '#98D2FC',
        _dark: '#98D2FC',
      },
      's-purple': {
        default: '#DCD3FB',
        _dark: '#DCD3FB',
      },
      's-purple-muted': {
        default: '#9C7FE9',
        _dark: '#9C7FE9',
      },
      's-blue-muted': {
        default: '#3B4BF6',
        _dark: '#3B4BF6',
      },
      's-blue-muted-alt': {
        default: '#3B4BF6',
        _dark: '#3B4BF6',
      },
      's-red': {
        default: '#FEA6A6',
        _dark: '#FEA6A6',
      },
      's-red-muted': {
        default: '#F50100',
        _dark: '#F50100',
      },
      's-yellow': {
        default: '#ECFFA0',
        _dark: '#ECFFA0',
      },
      's-yellow-muted': {
        default: '#f7f0df',
        _dark: '#f7f0df',
      },
      's-green-alt': {
        default: '#306100',
        _dark: '#306100',
      },
      's-blue-text': {
        default: '#17183F',
        _dark: '#17183F',
      },
      's-blue-text-muted': {
        default: '#17183FB2',
        _dark: '#17183FB2',
      },
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  fonts: {
    heading: 'Montserrat, "Open Sans", Helvetica, Arial, sans-serif',
    mono: 'Menlo, monospace',
  },

  colors: {
    blue: {
      '50': '#ECEDF8',
      '100': '#CBCCEC',
      '200': '#A9AAE0',
      '300': '#8789D3',
      '400': '#6668C7',
      '500': '#4447BB',
      '600': '#373995',
      '700': '#292B70',
      '800': '#1B1C4B',
      '900': '#0E0E25',
    },
    brand: {
      900: '#ff3a9b',
      800: '#FF4DA4',
      700: '#FF61AD',
      600: '#FF74B6',
      500: '#ff3a9b',
      400: '#FF5DAB',
      300: '#FF80BB',
      200: '#FFA2CC',
      100: '#FFC5DC',
      50: '#FFE8EC',
    },
    /**
     * @todo
     * FIX THIS...
     */
    success: {
      500: '#4D9984',
    },
    successMuted: {
      500: '#D1FDF1',
    },
    bookingsBlue: {
      500: '#3B4BF6',
    },
    bookingsBlueMuted: {
      500: '#E3E6FF',
    },
    bookingsRed: {
      500: '#E25C5C',
    },
    bookingsRedMuted: {
      500: '#F9D6D6',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        background:
          props.colorMode === 'dark'
            ? 'var(--chakra-colors-gray-900)'
            : 'white',

        fontFeatureSettings: '"kern"',
      },
      ':root': {
        '--danger': props.theme.colors.red[500],
        '--success': props.theme.colors.green[500],
        '--blue': props.theme.colors.blue[500],
        '--warning': props.theme.colors.yellow[500],
        '--primary': props.theme.colors.brand[500],
        '--chakra-zIndices-modal': 1501,
      },
      '*, *::before, ::after': {
        borderColor: mode('rgba(0,0,0,0.06)', 'rgba(255,255,255,0.15)')(props),
      },
      a: {},
      'chakra-tabs': {
        flex: 1,
      },
    }),
  },
}

export default extendTheme(theme, config)
