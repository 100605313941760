import {
  BaseQueryFn,
  createApi,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'
import message from 'common/message/message'
import config from 'config'
import {
  ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
  ServicePaginatedResponseType,
} from 'connect-types/backend/service'
import {
  OrganisationSubscription,
  PlanTypes,
} from 'connect-types/billing/organisation.type'
import {
  OrganisationSettings,
  OrganisationType,
} from 'connect-types/organisations/organisations.type'
import { User } from 'connect-types/user/user.type'
import cloneDeep from 'lodash/cloneDeep'
import { UserRoleType } from 'state/auth/auth.types'
import { InteractionRequestType } from 'state/entities/interactions/interactions.types'
import { allSegment } from 'state/entities/segment/segment.mock'
import {
  Segment,
  SegmentPreviewTotalQueryType,
  SegmentType,
} from 'state/entities/segment/segment.types'
import { customAxios } from 'utils/axiosHelper'
import { buildUrl, currencyLocale } from 'utils/common'
import { InteractionType } from './types/interaction'
import { SimpleOrganisationType } from './types/organisation'
import {
  OrganizationRegistrationTransactionTotalsType,
  OrganizationRegistrationTransactionType,
} from './types/organization_registration/transactions.types'
import { SegmentProfileType } from './types/segments/customers'
import { SimpleVenueType, VenueSettingsType } from './types/venue'
import {
  WebsiteProfileCookie,
  WebsiteType,
  WebstieEventType,
} from './types/websites/index.types'
import {
  CsvUploadRequestType,
  CsvUploadResponseType,
  CsvUploadsPaginatedResponse,
  SignedCsvUrlRequestType,
  SignedCsvUrlResponseType,
} from 'connect-types/sources/csv.type'

const parseRoot = (value: Partial<Segment>) => {
  const sendNode: any = cloneDeep(value)
  if (!sendNode.segment.root) {
    return sendNode
  }
  if (
    Array.isArray(sendNode.segment.root.nodes) &&
    sendNode.segment.root.nodes.length === 1
  ) {
    sendNode.segment.root = sendNode.segment.root.nodes[0]
  }

  if (
    Array.isArray(sendNode.segment.root.nodes) &&
    sendNode.segment.root.nodes.length === 0
  ) {
    sendNode.segment.root = null
  }
  return sendNode
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')
      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }
console.log({ base: config.url.api })

export const convertKeysToSnake = (item: Record<string, unknown>) => {
  const newObject = {}
  function camelToUnderscore(key: string) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
  for (var camel in item) {
    newObject[camelToUnderscore(camel)] = item[camel]
  }
  return newObject
}

const morpheusApi = createApi({
  reducerPath: 'morpheus',
  baseQuery: axiosBaseQuery({ baseUrl: config.url.morpheus }),

  tagTypes: [
    'Subscription',
    'Organisation',
    'User',
    'OrganisationSettings',
    'Venue',
    'Websites',
    'MarketingMenus',
    'Csv',
    'Segments',
  ],
  endpoints: (build) => ({
    /**
     * Segments start
     */
    getSegments: build.query<Segment[], { orgId: string }>({
      async queryFn({ orgId }, _queryApi, _extra, fetcher) {
        const segments = await fetcher({
          method: 'get',
          url: `/organisations/${orgId}/crm/segments`,
        })
        const allReach = await fetcher({
          method: 'post',
          url: `/organisations/${orgId}/crm/segments/preview/reach`,
          data: { root: null },
        })

        const data = (segments.data as Segment[]).map((item) => ({
          ...item,
          segment: {
            ...item.segment,
            serials: item.segment?.serials ? item.segment.serials : [],
          },
        })) as Segment[]

        if (data && allReach.data) {
          data.sort((a, b) => b.reach.reach.all - a.reach.reach.all)

          data.unshift({
            ...allSegment,

            reach: {
              reach: allReach.data as Segment['reach']['reach'],
              version: 0,
            },
          })
        }

        return segments.data
          ? {
              data,
            }
          : { error: segments.error as FetchBaseQueryError }
      },

      providesTags: (items) => [
        { type: 'Segments', id: 'LIST' },
        ...items.map((item) => ({ type: 'Segments' as const, id: item.id })),
      ],
    }),
    getSegment: build.query<Segment, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `/organisations/${orgId}/crm/segments/${id}`,
        method: 'GET',
      }),
      providesTags: ({ id }) => [{ type: 'Segments', id }],
    }),
    getSegmentPreview: build.query<
      ServicePaginatedResponseType<SegmentProfileType>,
      {
        orgId: string
        query: SegmentPreviewTotalQueryType
        cursor: { limit: number; cursor: string }
      }
    >({
      query: ({ orgId, query, cursor }) => ({
        url: buildUrl(`/organisations/${orgId}/crm/segments/preview`, cursor),
        method: 'POST',
        data: query,
      }),
    }),
    createSegment: build.mutation<
      Segment,
      {
        orgId: string
        segment: Partial<Segment>
      }
    >({
      query: ({ orgId, segment }) => {
        const sendNode: any = parseRoot(segment)
        if (!sendNode.segment.is_venue_segment) {
          sendNode.segment.serials = []
        }
        return {
          method: 'post',
          url: `/organisations/${orgId}/crm/segments`,
          data: sendNode,
        }
      },
      invalidatesTags: () => [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: Segment) => {
        message.success(`${item.name} created`)
        return item
      },
    }),
    deleteSegment: build.mutation<Segment, Segment>({
      query: (segment) => ({
        method: 'DELETE',
        url: `/organisations/${segment.organization_id}/crm/segments/${segment.id}`,
      }),
      invalidatesTags: () => [{ type: 'Segments', id: 'LIST' }],
      transformResponse: (item: Segment) => {
        message.success(`${item.name} deleted`)
        return item
      },
    }),
    updateSegment: build.mutation<
      Segment,
      {
        orgId: string
        segment: Segment
      }
    >({
      query: ({ orgId, segment }) => {
        const sendNode: any = parseRoot(segment)
        if (!sendNode.segment.is_venue_segment) {
          sendNode.segment.serials = []
        }

        return {
          method: 'put',
          url: `/organisations/${orgId}/crm/segments/${segment.id}`,
          data: sendNode,
        }
      },
      invalidatesTags: (item) =>
        item?.id ? [{ type: 'Segments', id: item.id }] : [],
      transformResponse: (item: Segment) => {
        message.success(`${item.name} updated`)
        return item
      },
    }),
    getSegmentPreviewReach: build.query<
      { all: number; sms: number; email: number },
      {
        orgId: string
        query: SegmentPreviewTotalQueryType
      }
    >({
      query: ({ orgId, query }) => ({
        url: `/organisations/${orgId}/crm/segments/preview/reach`,
        method: 'POST',
        data: query,
      }),
    }),
    getSegmentMetadata: build.query<
      SegmentType,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/crm/segments/metadata`,
        method: 'GET',
      }),
      transformResponse(res: SegmentType) {
        const fieldsArray = Object.entries(res.fields).map(
          ([_key, value]) => value
        )

        return { ...res, fieldsArray }
      },
    }),
    /**
     * Segments end
     */

    getSubscription: build.query<OrganisationSubscription, string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/billing`,
        method: 'get',
      }),
      providesTags: ['Subscription'],
      transformResponse(data: OrganisationSubscription) {
        const plan = data.plan as any
        if (
          !['free', 'starter', 'growth', 'enterprise', 'self-serve'].includes(
            plan
          )
        ) {
          data.plan = PlanTypes.Starter
          data.is_legacy = true
        }

        data.needs_venue_upgrade = data.used_venues - data.venues
        data.needs_contact_upgrade = data.used_contacts - data.contacts

        if (data.needs_venue_upgrade < 0) {
          data.needs_venue_upgrade = 0
        }

        if (data.needs_contact_upgrade < 0) {
          data.needs_contact_upgrade = 0
        }

        data.numberFormatter = currencyLocale(data?.currency)
        /*
          data.subscriptionChange = convertSubscriptionToFrame(
            data
          )
          */
        return data
      },
    }),
    updateSubscriptionStatus: build.mutation<
      string,
      { orgId: string; subscriptionId: string; status: string }
    >({
      query: ({ orgId, subscriptionId, status }) => ({
        url: `/organisations/${orgId}/billing/${subscriptionId}/status`,
        data: { status },
        method: 'put',
      }),

      invalidatesTags: ['Subscription'],
    }),
    updateSubscriptionLink: build.mutation<
      string,
      { orgId: string; subscriptionId: string }
    >({
      query: ({ orgId, subscriptionId }) => ({
        url: `/organisations/${orgId}/billing/stripe/link`,
        data: { subscriptionId },
        method: 'post',
      }),

      invalidatesTags: ['Subscription'],
    }),

    getOrganisations: build.query<
      ServiceCursorPaginatedResponseType<SimpleOrganisationType>,
      { cursor: string; limit: number; search?: string }
    >({
      query: ({ limit, search, cursor }) => ({
        url: buildUrl(`/organisations`, { cursor, limit, search }),
        method: 'get',
      }),
    }),
    getLocations: build.query<
      ServiceCursorPaginatedResponseType<SimpleVenueType>,
      { cursor: string; limit: number; search?: string }
    >({
      query: ({ limit, search, cursor }) => ({
        url: buildUrl(`/venues`, { cursor, limit, search }),
        method: 'get',
      }),
    }),
    /**
     *  organisations
     */
    getOrganisation: build.query<OrganisationType, string>({
      keepUnusedDataFor: 600,
      query: (orgId) => ({ url: `/organisations/${orgId}`, method: 'get' }),
      transformResponse(data: OrganisationType) {
        data.children = []

        return data
      },
      providesTags: (result) => [{ type: 'Organisation', id: result?.id }],
    }),
    updateOrganisation: build.mutation<
      OrganisationType,
      { orgId: string; data: { website: string; name: string } }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}`,
        method: 'put',
        data,
      }),

      invalidatesTags: (result) => [{ type: 'Organisation', id: result.id }],
    }),
    getOrganisationSettings: build.query<OrganisationSettings, string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/settings`,
        method: 'get',
      }),
      providesTags: ['OrganisationSettings'],
    }),
    updateOrganisationSettings: build.mutation<
      OrganisationSettings,
      { orgId: string; data: OrganisationSettings }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/settings`,
        method: 'put',
        data,
      }),
      invalidatesTags: ['OrganisationSettings'],
    }),
    getOrganisationChildren: build.query<OrganisationType[], string>({
      query: (orgId) => ({
        url: `/organisations/${orgId}/children`,
        method: 'get',
      }),
      transformResponse(items: OrganisationType[]) {
        return items.map((item) => ({
          ...item,
          children: item.other_organization,
        }))
      },
      providesTags: (results) => {
        if (!results) return
        return [
          ...(results || []).map((item) => ({
            type: 'Organisation' as const,
            id: item.id,
          })),
          { type: 'Organisation', id: 'LIST' },
        ]
      },
    }),
    updateOrganisationLocations: build.mutation<
      OrganisationType,
      { orgId: string; serials: string[]; originalOrgId: string }
    >({
      query: ({ orgId, serials, originalOrgId }) => ({
        url: `/organisations/${orgId}/children`,
        method: 'put',
        data: { serials, originalOrgId },
      }),
      transformResponse(data: OrganisationType) {
        message.success(`Venue(s) moved`)
        return data
      },
      invalidatesTags: () => [{ type: 'Organisation', id: 'LIST' }],
    }),
    createOrganisation: build.mutation<
      OrganisationType,
      { orgId: string; name: string }
    >({
      query: ({ orgId, name }) => ({
        url: `/organisations/${orgId}/children`,
        method: 'POST',
        data: { name },
      }),
      transformResponse(data: OrganisationType) {
        message.success(`${data.name} created`)
        return data
      },
      invalidatesTags: [{ type: 'Organisation', id: 'LIST' }],
    }),

    /**
     * End organisations
     */

    getUser: build.query<User, string>({
      keepUnusedDataFor: 600,
      query: (_uid) => ({
        url: `/members`,
        method: 'get',
      }),

      providesTags: ['User'],
    }),
    getOrganisationUser: build.query<
      UserRoleType,
      { orgId: string; uid: string }
    >({
      query: ({ orgId, uid }) => ({
        url: `/organisations/${orgId}/users/${uid}/role`,
        method: 'get',
      }),
    }),
    getHubspotAuthToken: build.query<{ token: string }, unknown>({
      query: () => ({
        url: `/oauth/hubspot-visitor-identification`,
        method: 'get',
      }),
    }),

    /**
     * Venue
     */
    getOperatingTypes: build.query<
      { id: string; name: string; sic_code: string }[],
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/venues/vertical-types`,
        method: 'get',
      }),
    }),
    addVenue: build.mutation<VenueSettingsType, { orgId: string }>({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/venues`,
        method: 'post',
      }),
      invalidatesTags: (_, _1, { orgId }) => [
        { type: 'Organisation', id: orgId },
      ],
      transformResponse(data: VenueSettingsType) {
        message.success(`Venue added`)
        return data
      },
    }),
    getLocation: build.query<
      VenueSettingsType,
      { orgId: string; serial: string }
    >({
      query: ({ serial, orgId }) => ({
        url: `/organisations/${orgId}/venues/${serial}`,
        method: 'get',
      }),
      transformResponse(data: VenueSettingsType) {
        if (!data.paymentType) {
          data.paymentType = ''
        }
        return data
      },
      providesTags: (_, _error, { serial }) => [{ type: 'Venue', id: serial }],
    }),
    updateLocation: build.mutation<
      VenueSettingsType,
      { orgId: string; serial: string; venue: Partial<VenueSettingsType> }
    >({
      query: ({ serial, orgId, venue }) => ({
        url: `/organisations/${orgId}/venues/${serial}`,
        method: 'put',
        data: venue,
      }),
      transformResponse(data: VenueSettingsType) {
        message.success(`${data.alias} updated`)
        return data
      },
      invalidatesTags: (_, _error, { serial }) => [
        { type: 'Venue', id: serial },
      ],
    }),
    /**
     * End venue
     */

    /**
     * Reports
     */
    getTransactions: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistrationTransactionType>,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/transactions`, query),
      }),
    }),
    getTransactionTotals: build.query<
      OrganizationRegistrationTransactionTotalsType,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/transactions/totals`, query),
      }),
    }),
    getInteractions: build.query<
      ServiceCursorPaginatedResponseType<InteractionType>,
      {
        orgId: string
        query: InteractionRequestType
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/interactions`, {
          ...query,
          query_type: 'basic',
        }),
      }),
    }),
    /**
     * Website
     */
    getWebsites: build.query<
      WebsiteType[],
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        method: 'get',
        url: `/organisations/${orgId}/website`,
      }),
      providesTags: [{ id: 'LIST', type: 'Websites' }],
    }),
    createWebsite: build.mutation<WebsiteType, { orgId: string; url: string }>({
      query: ({ orgId, url }) => ({
        url: `/organisations/${orgId}/website`,
        method: 'POST',
        data: { url },
      }),
      invalidatesTags: [{ type: 'Websites', id: 'LIST' }],
      transformResponse(data: WebsiteType) {
        message.success(`${data.url} created`)
        return data
      },
    }),
    getWebsiteEvents: build.query<
      ServiceCursorPaginatedResponseType<WebstieEventType>,
      { cursor: string; limit: number; search?: string; orgId: string }
    >({
      query: ({ orgId, cursor, limit, search }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/website/events`, {
          search,
          limit,
          cursor,
        }),
      }),
    }),
    getWebsiteProfiles: build.query<
      ServiceCursorPaginatedResponseType<WebsiteProfileCookie>,
      { cursor: string; limit: number; search?: string; orgId: string }
    >({
      query: ({ orgId, cursor, limit, search }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/website/profiles`, {
          search,
          limit,
          cursor,
        }),
      }),
    }),
    createCsvSignedUrl: build.mutation<
      SignedCsvUrlResponseType,
      SignedCsvUrlRequestType
    >({
      query: ({
        orgId,
        serials,
        data_source,
        default_region,
        original_file_name = new Date().toTimeString(),
        tags,
      }) => ({
        method: 'post',
        url: buildUrl(`/organisations/${orgId}/csv/uploads`, {}),
        data: {
          serials,
          data_source,
          region: default_region,
          original_file_name,
          type: 'user',
          tags: tags,
        },
      }),
    }),
    createCsvUpload: build.mutation<
      CsvUploadResponseType,
      CsvUploadRequestType
    >({
      query: ({ url, data }) => ({
        method: 'put',
        url,
        headers: {
          'Content-Type': 'text/csv',
        },
        timeout: 1000 * 60,
        data,
      }),
      transformResponse(data: CsvUploadResponseType) {
        message.success('Csv uploaded for processing')
        return data
      },
      transformErrorResponse: (error) => {
        message.warning('Something wents wrong')
        return error
      },
    }),
    getCsvUploads: build.mutation<
      CsvUploadsPaginatedResponse,
      { orgId: string; page: { offset: number; limit: number } }
    >({
      query: ({ orgId, page }) => ({
        method: 'get',
        url: buildUrl(`/organisations/${orgId}/csv/uploads`, page),
      }),
    }),
  }),
})

export default morpheusApi
