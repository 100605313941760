import { createReducer } from '@reduxjs/toolkit'
import message from 'common/message/message'
import { parseISO } from 'date-fns'
import {
  defaultServicePagination,
  ServiceCursorPaginatedResponseType,
} from 'connect-types/backend/service'

import {
  getInboxThreads,
  getInboxThreadEvents,
  sendInboxThreadEvents,
  archiveInboxThread,
  getInboxThread,
  createNewEmailThread,
  createEmailContactsOnThread,
  removeEmailContactsOnThread,
  getInboxThreadEvent,
  markInboxThreadAsRead,
  getUnreadInboxThread,
  clearThreads,
} from './inbox.actions'
import {
  InboxThreadEventType,
  InboxThreadType,
  initThread,
} from './inbox.types'

interface StateType {
  threads: ServiceCursorPaginatedResponseType<InboxThreadType>
  unread_counts: { open: number; closed: number; isLoading: boolean }
}

export const dateParse = (date: Date | string = '') => {
  if (typeof date === 'string') return parseISO(date)
  return date
}

const initialState: StateType = {
  threads: defaultServicePagination,
  unread_counts: { open: 0, closed: 0, isLoading: false },
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearThreads, (state) => {
      state.threads = defaultServicePagination
    })
    .addCase(getUnreadInboxThread.pending, (state) => {
      state.unread_counts.isLoading = true
    })
    .addCase(getUnreadInboxThread.fulfilled, (state, action) => {
      state.unread_counts.isLoading = false
      state.unread_counts.closed = action.payload.closed
      state.unread_counts.open = action.payload.open
    })
    .addCase(getUnreadInboxThread.rejected, (state) => {
      state.unread_counts = { ...initialState.unread_counts }
    })
    .addCase(getInboxThread.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = true
      } else {
        state.threads.data.push({
          ...initThread,
          id: action.meta.arg.threadId,
          isLoading: true,
        })
      }
    })
    .addCase(getInboxThread.fulfilled, (state, action) => {
      const doesItemExist = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (!doesItemExist) {
        state.threads.data.push({
          ...initThread,
          id: action.meta.arg.threadId,
          isLoading: true,
        })
      }

      const itemIndex = state.threads.data.findIndex(
        (item) => item.id === action.meta.arg.threadId
      )

      const item = state.threads.data[itemIndex]

      state.threads.data[itemIndex] = {
        ...item,
        ...action.payload,
        created_at: dateParse(action.payload.created_at),
        archived_at: action.payload.archived_at
          ? dateParse(action.payload.archived_at)
          : null,
        read_at: action.payload.read_at
          ? dateParse(action.payload.read_at)
          : null,
        updated_at: dateParse(action.payload.updated_at),
        last_thread_event_at: dateParse(action.payload.last_thread_event_at),
        events: item.events || defaultServicePagination,
        isLoading: false,
      }
      console.log({ newItem: state.threads.data[itemIndex] })
    })
    .addCase(getInboxThread.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = false
      }
    })

    .addCase(getInboxThreads.pending, (state, action) => {
      state.threads.isLoading = true
      if (action.meta.arg.query.context_id) {
        const item = state.threads.data.find(
          (item) =>
            item.context_id === action.meta.arg.query.context_id &&
            item.context_type === action.meta.arg.query.context_type
        )
        if (item) {
          item.isLoading = true
        } else {
          state.threads.data.push({
            ...initThread,
            isLoading: true,
            context_id: action.meta.arg.query.context_id,
            context_type: action.meta.arg.query.context_type,
          })
        }
      }
    })
    .addCase(getInboxThreads.fulfilled, (state, action) => {
      const data = [
        ...new Map(
          [...action.payload.data, ...state.threads.data].map((item) => [
            item.id,
            item,
          ])
        ).values(),
      ].map((item) => ({
        ...item,
        created_at: dateParse(item.created_at),
        archived_at: item.archived_at ? dateParse(item.archived_at) : null,
        updated_at: dateParse(item.updated_at),
        last_thread_event_at: dateParse(item.last_thread_event_at),
        events: item.events || defaultServicePagination,
      }))

      if (action.meta.arg.query.context_id) {
        const item = data.find(
          (item) =>
            item.context_id === action.meta.arg.query.context_id &&
            item.context_type === action.meta.arg.query.context_type
        )
        item.isLoading = false
      }

      state.threads = {
        ...action.payload,
        data,
      }
    })
    .addCase(getInboxThreads.rejected, (state) => {
      state.threads.isLoading = false
    })

    .addCase(getInboxThreadEvent.pending, () => {})
    .addCase(getInboxThreadEvent.fulfilled, (state, action) => {
      const payload = {
        ...action.payload,
        created_at: dateParse(action.payload.created_at),
        eventable: {
          ...action.payload.eventable,
          created_at: dateParse(action.payload.eventable.created_at),
        },
      } as InboxThreadEventType
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        const findKey = item.events.data.findIndex(
          (idx) => idx.id === action.meta.arg.eventId
        )
        if (findKey < 0) {
          item.events.data.push(payload)
        } else {
          item.events.data[findKey] = payload
        }
      }
    })
    .addCase(getInboxThreadEvent.rejected, () => {})
    .addCase(getInboxThreadEvents.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )

      item.events.isLoading = true
    })
    .addCase(getInboxThreadEvents.fulfilled, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )

      if (item) {
        item.events.isLoading = false

        const data = [
          ...new Map(
            [...item.events.data, ...action.payload.data].map((item) => [
              item.id,
              item,
            ])
          ).values(),
        ]
          .map((item) => ({
            ...item,
            created_at: dateParse(item.created_at),
            eventable: {
              ...item.eventable,
              created_at: dateParse(item.eventable.created_at),
            },
          }))
          .sort((a, b) => a.created_at.getTime() - b.created_at.getTime())
        /** @ts-ignore */
        item.events = { ...action.payload, data }
        item.no_event_data = item.events.data.length === 0
      }
    })
    .addCase(getInboxThreadEvents.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.events.isLoading = false
        item.no_event_data = true
      }
    })
    .addCase(sendInboxThreadEvents.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      item.isLoading = true
    })
    .addCase(sendInboxThreadEvents.fulfilled, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      action.payload.created_at = dateParse(action.payload.created_at)
      action.payload.eventable.created_at = dateParse(
        action.payload.eventable.created_at
      )

      if (item) {
        item.isLoading = false
        item.last_thread_event_at = new Date()
        const findIndex = item.events.data.findIndex(
          (it) => it.id === action.payload.id
        )
        if (findIndex >= 0) {
          item.events.data[findIndex] = action.payload
        } else {
          item.events.data.push(action.payload)
        }
      }
    })
    .addCase(sendInboxThreadEvents.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      item.isLoading = false
    })

    .addCase(archiveInboxThread.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      item.isLoading = true
    })
    .addCase(archiveInboxThread.fulfilled, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )

      if (item) {
        item.isLoading = false

        item.archived_at = action.meta.arg.archive ? new Date() : null
      }
      message.success(`Thread ${action.meta.arg.archive ? 'closed' : 'opened'}`)
    })
    .addCase(archiveInboxThread.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      item.isLoading = false
    })
    .addCase(createNewEmailThread.pending, (state, action) => {
      console.log('thread-pending', state.threads.data, action.meta)

      if (action.meta.arg.data.context_id) {
        const item = state.threads.data.find(
          (item) =>
            item.context_id === action.meta.arg.data.context_id &&
            item.context_type === action.meta.arg.data.context_type
        )
        if (item) {
          item.isLoading = true
        }
      }
    })
    .addCase(createNewEmailThread.fulfilled, (state, action) => {
      if (action.meta.arg.data.context_id) {
        const foundIndex = state.threads.data.findIndex(
          (item) =>
            item.context_id === action.meta.arg.data.context_id &&
            item.context_type === action.meta.arg.data.context_type
        )

        state.threads.data[foundIndex] = {
          ...action.payload,
          created_at: dateParse(action.payload.created_at),
          updated_at: dateParse(action.payload.updated_at),
          last_thread_event_at: dateParse(action.payload.last_thread_event_at),
          events: {
            ...action.payload.events,
            data: action.payload.events.data.map((item) => ({
              ...item,
              created_at: dateParse(item.created_at),
            })),
          },
        }
      }
      console.log('thread', state.threads.data, action.payload)
    })
    .addCase(createNewEmailThread.rejected, (state, action) => {
      console.log('thread-rejected', state.threads.data, action)

      if (action.meta.arg.data.context_id) {
        const item = state.threads.data.find(
          (item) =>
            item.context_id === action.meta.arg.data.context_id &&
            item.context_type === action.meta.arg.data.context_type
        )
        if (item) {
          item.isLoading = false
        }
      }
    })

    /**
     * CONTACTS
     */
    .addCase(createEmailContactsOnThread.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = true
      }
    })
    .addCase(createEmailContactsOnThread.fulfilled, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )

      if (item) {
        item.contacts.push(action.payload.thread_contact)
        item.isLoading = false
      }
    })
    .addCase(createEmailContactsOnThread.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = false
      }
    })

    .addCase(removeEmailContactsOnThread.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = true
      }
    })
    .addCase(removeEmailContactsOnThread.fulfilled, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )

      if (item) {
        item.contacts = item.contacts.filter(
          (ite) => ite.id !== action.meta.arg.contactId
        )
        item.isLoading = false
      }
    })
    .addCase(removeEmailContactsOnThread.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = false
      }
    })

    .addCase(markInboxThreadAsRead.pending, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = true
      }
    })
    .addCase(markInboxThreadAsRead.fulfilled, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )

      if (item) {
        item.isLoading = false
        item.read_at = action.payload.thread.read_at
      }
    })
    .addCase(markInboxThreadAsRead.rejected, (state, action) => {
      const item = state.threads.data.find(
        (item) => item.id === action.meta.arg.threadId
      )
      if (item) {
        item.isLoading = false
      }
    })
)

export default reducer
