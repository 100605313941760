import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosRequestConfig } from 'axios'
import config from 'config'
import { parseISO, subMinutes } from 'date-fns'
import {
  CoverSettings,
  DailyStatsType,
} from 'state/entities/bookings/bookings.types'
import { ServiceErrorHandler } from 'connect-types/backend/service'
import { customAxios } from 'utils/axiosHelper'
import { format } from 'date-fns/esm'
import { TimeFormats } from 'common/datepicker/utilities/formats'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      if (method !== 'get' && err.response.status !== 401) {
        ServiceErrorHandler(axiosError)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const bookingApi = createApi({
  reducerPath: 'booking',
  baseQuery: axiosBaseQuery({ baseUrl: config.url.bookings }),
  tagTypes: ['CoverSettings', 'EmailTemplates'],
  endpoints: (build) => ({
    getCoverSettings: build.query<
      CoverSettings,
      { orgId: string; serial: string }
    >({
      query: ({ orgId, serial }) => ({
        url: `/${orgId}/${serial}/covers`,
        method: 'get',
      }),
      providesTags: (_1, _2, { serial }) => [
        { id: serial, type: 'CoverSettings' },
      ],
      transformResponse: (item: CoverSettings) => {
        const d2 = new Date().getTimezoneOffset()
        Object.keys(item).forEach((dayName) => {
          item[dayName].open = item[dayName].open.map((item) =>
            subMinutes(parseISO(item), d2)
          )
          item[dayName].close = item[dayName].close.map((item) =>
            subMinutes(parseISO(item), d2)
          )
        })

        return item
      },
    }),
    getDailyStats: build.query<
      DailyStatsType,
      { orgId: string; serial: string; date: Date }
    >({
      query: ({ orgId, serial, date }) => ({
        url: `/${orgId}/${serial}/stats/daily-summary?date=${format(
          date,
          TimeFormats.BackendYearMonthDay
        )}`,
        method: 'get',
      }),
    }),
  }),
})

export default bookingApi
