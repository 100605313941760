import * as Sentry from '@sentry/react'
import { RootState } from 'state/reducers'

const sentryMiddleware = Sentry.createReduxEnhancer({
  // Optionally pass options
  configureScopeWithState: (scope, { global, auth }: RootState) => {
    if (global.orgId) {
      scope.setTag('organization.id', global.orgId)
    }
    if (global.selectedLocation) {
      scope.setTag('serial', global.selectedLocation.serial)
    }
    if (auth.currentUser) {
      scope.setUser({
        email: auth.currentUser.email,
        id: auth.currentUser.uid,
      })
    }
  },
})

export default sentryMiddleware
